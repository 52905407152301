import { useState } from "react";
import { TagType } from "../../../services/TagType";

export const useSubmitTagTypeLogic = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const saveTagType = async (data) => {
    try {
      setIsSubmitting(true);
      const tagType = await TagType.addTagType(data);
      setIsSubmitting(false);
      if (tagType.success) {
        return {
          success: true,
          data: tagType.data.tagType,
          msg: tagType.msg
        };
      } else {
        throw new Error(tagType?.error?.message || "Unable to save link type");
      }
    } catch (error) {
      setIsSubmitting(false);
      return {
        success: false,
        msg: error.message || "Unable to save link type",
        data: null
      };
    }
  };

  const updateTagType = async (id, data) => {
    try {
      setIsSubmitting(true);
      const tagType = await TagType.updateTagType(id, data);
      setIsSubmitting(false);
      if (tagType.success) {
        return {
          success: true,
          data: tagType.data.tagType,
          msg: tagType.msg
        };
      } else {
        throw new Error(
          tagType?.error?.message || "Unable to update Link Type"
        );
      }
    } catch (error) {
      setIsSubmitting(false);
      return {
        success: false,
        msg: error.message || "Unable to update Link Type",
        data: null
      };
    }
  };

  return {
    saveTagType,
    isSubmitting,
    updateTagType
  };
};
