import React from "react";
import { useDomainFormLogic } from "../logic/domainForm.logic";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/CloseSharp";
import Button from "@material-ui/core/Button";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

const DomainForm = ({ domainId = "", openTable }) => {
  const {
    formValues,
    loadingDomainData,
    handleFormValueChange,
    handleSubmit,
    handleCancel,
    isSubmitting,
    editing,
    errorValues
  } = useDomainFormLogic(domainId, openTable);

  if (loadingDomainData.isLoading) {
    return (
      <div className="loading-div">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="default-values__form">
      <h5>{editing.isEditing ? `Editing Domain` : "Adding Domain"}</h5>
      <form className="flow-content" onSubmit={handleSubmit}>
        <div>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              label="Domain"
              value={formValues.domain}
              onChange={handleFormValueChange}
              placeholder={`Enter Domain`}
              name="domain"
            />
          </FormControl>
          {errorValues.domain && (
            <div className="error-text" style={{ marginTop: "5px" }}>
              {errorValues.domain}
            </div>
          )}
        </div>
        <div className="default-values__form__buttons">
          <Button
            color="primary"
            variant="contained"
            size="medium"
            startIcon={<CheckIcon />}
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancel}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default DomainForm;
