import React from "react";
import StatCard from "../../../../components/StatCard/StatCard";
import PropTypes from "prop-types";
import {
  MONETIZED_SEARCHES,
  PUBLISHER_REVENUE
} from "../../../../helpers/constant/filters";

import { formatNumberAddComma } from '../../../../helpers/formatters'
/**
 *  Component to render simple Stats
 */

const Stats = ({ reportStats, fields, displayColumns }) => {
  let { publisher_revenue, monetized_searches, total_searches } = reportStats;
  return (
    <div className="flex-box  full-width-children dashboard-stats">
      {displayColumns.includes(MONETIZED_SEARCHES) && (
        <StatCard title={fields?.monetized_searches || "Monetized Searches"}>
          <span>{monetized_searches ? formatNumberAddComma(monetized_searches) : "No Info"}</span>
        </StatCard>
      )}
      {displayColumns.includes("publisher_revenue") && (
        <StatCard title={fields?.[PUBLISHER_REVENUE] || "Net Revenue"}>
          <span>
            {publisher_revenue ? (parseFloat(publisher_revenue)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) : "No Net Revenue"}
          </span>
        </StatCard>
      )}
      {displayColumns.includes("advertiser_search_count") && (
        <StatCard title={fields?.total_searches || "Total Searches"}>
          <span>{total_searches ? formatNumberAddComma(total_searches) : "No Search Info"}</span>
        </StatCard>
      )}
    </div>
  );
};

export default Stats;

Stats.propTypes = {
  reportStats: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired
};
