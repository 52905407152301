import React from "react";
import PropTypes from "prop-types";
//
import {formatNumberAddComma} from '../../helpers/formatters';

const DailyCapValue = ({ daily_cap, remaining_cap }) => {
  return (
    <span
      style={{
        verticalAlign: "middle",
        fontSize: "0.9rem"
      }}
    >
      Total:{formatNumberAddComma(daily_cap)}
      <br />
      Remaining:{formatNumberAddComma(remaining_cap)}
    </span>
  );
};

DailyCapValue.propTypes = {
  daily_cap: PropTypes.number,
  remaining_cap: PropTypes.number
};

export default DailyCapValue;
