import { useState } from "react";
import { toastr } from "react-redux-toastr";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { useSubmitDomainLogic } from "./submitDomain.logic";
import { useFormTouched } from "../../../hooks/useFormTouched";
import { changeEmptyStringToNull } from "../../../helpers";
import { Domain } from "../../../services/Domain";
import { validURL } from "../../../helpers/validation";
const initialFormValues = {
  domain: ""
};

const initialErrorValues = {
  domain: null
};

export const useDomainFormLogic = (domainId, openTable) => {
  const [formValues, setFormValues] = useState(() => initialFormValues);
  const [errorValues, setErrorValues] = useState(() => initialErrorValues);

  const { isSubmitting, saveDomain, updateDomain } = useSubmitDomainLogic();

  const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
    cleanupFunction: () => {}
  });

  const [editing, setIsEditing] = useState({
    id: "",
    isEditing: false
  });

  const [loadingDomainData, setLoadingDomainStatus] = useState({
    isLoading: false,
    error: false
  });

  const isMounted = useIsMounted();

  async function getDomain(domainId) {
    try {
      setLoadingDomainStatus({ isLoading: true, error: false });
      const domain = await Domain.fetchDomain(domainId);
      if (domain.success && isMounted()) {
        setLoadingDomainStatus({ isLoading: false, error: false });
        setFormValues({
          domain: domain.data.domain.domain || ""
        });
        setIsEditing({
          id: domain.data.domain.id,
          isEditing: true
        });
      } else {
        throw new Error(domain.error.message);
      }
    } catch (error) {
      if (isMounted()) {
        setLoadingDomainStatus({ isLoading: false, error: true });
        console.trace(error.message);
        toastr.warning("Oops!", error.message);
      }
    }
  }

  const handleFormValueChange = (e) => {
    handleFormTouched();
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    setErrorValues({
      ...errorValues,
      [name]: null
    });
  };

  const handleAddDomain = async (dataToSend) => {
    const savedDomain = await saveDomain(dataToSend);
    if (savedDomain.success) {
      toastr.success("Success!", savedDomain.msg);
      setIsEditing({
        id: savedDomain.data.id,
        isEditing: true
      });
      clearFormTouched();
      openTable();
    } else {
      toastr.error("Oops!", savedDomain.msg);
    }
  };

  const handleUpdateDomain = async (dataToSend) => {
    const updatedDomain = await updateDomain(editing.id, dataToSend);
    if (updatedDomain.success) {
      toastr.success("Success!", updatedDomain.msg);
      clearFormTouched();
      openTable();
    } else {
      toastr.error("Oops!", updatedDomain.msg);
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (!changeEmptyStringToNull(formValues.domain)) {
      setErrorValues({
        ...errorValues,
        domain: "Please provide domain"
      });
      isValid = false;
    }
    if (formValues.domain && !validURL(formValues.domain)) {
      setErrorValues({
        ...errorValues,
        domain: "Please enter valid domain"
      });
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    let dataToSend = {
      domain: formValues.domain.trim()
    };
    if (editing.isEditing) {
      handleUpdateDomain(dataToSend);
    } else {
      handleAddDomain(dataToSend);
    }
  };

  const handleCancel = () => {
    clearFormTouched();
    openTable();
  };

  useState(() => {
    if (domainId) {
      getDomain(domainId);
    }
  }, [domainId]);

  return {
    formValues,
    errorValues,
    loadingDomainData,
    handleFormValueChange,
    handleSubmit,
    handleCancel,
    isSubmitting,
    editing,
    formTouched
  };
};
