import Api from "../api";

class MappedFields {
  fetchMappedFields = async ({ providerId }) => {
    const { body } = await Api.get(`mapping/${providerId}`);
    return body;
  };

  storeMappedFields = async ({ data }) => {
    const { body } = await Api.post(`store/mapping`, data);
    return body;
  };

  updateMappedFields = async ({ data, id }) => {
    const { body } = await Api.put(`update/mapping/${id}`, data);
    return body;
  };
}
// eslint-disable-next-line
export default new MappedFields();
