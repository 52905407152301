import { useCallback, useEffect, useState } from "react";
import { Provider as ProviderService } from "../../../services/Provider"
import { toastr } from "react-redux-toastr";

export const useFetchLatestHistory = ({ id = null, page, limit, startFetch = true, setStartFetch }) => {
  const [latestHistoryData, setLatestHistoryData] = useState(null);
  const [isLatestHistoryLoading, setIsLatestHistoryLoading] = useState(false);
  const [totalHistoryCount, setTotalHistoryCount] = useState(null);
  
  const getLatestHistory = useCallback(async (id, limit, page) => {
    setIsLatestHistoryLoading(true);
    try {
      let queryParamString = '';
      if (limit) {
        queryParamString += `?limit=${limit}`;
        if (page) {
          queryParamString += `&page=${page}`;
        }
      }
      const response = await ProviderService.fetchLatestHistory(id, queryParamString);
      setTotalHistoryCount(response?.data?.latest_history_data?.count);
      setLatestHistoryData(l => {
        return l && page !== 1 && response?.data?.latest_history_data?.rows
          ? [...l, ...response.data.latest_history_data.rows]
          : response.data.latest_history_data.rows
      });
      setIsLatestHistoryLoading(false);
      setStartFetch(false);
    } catch (error) {
      console.trace(error?.message || "Oops! there was an error");
      setLatestHistoryData(null);
      setIsLatestHistoryLoading(false);
      toastr.error("Oops!", error.message);
    }
  }, [setStartFetch]);

  useEffect(() => {
    if (id && startFetch) getLatestHistory(id, limit, page);
  }, [getLatestHistory, id, limit, page, startFetch]);

  return { latestHistoryData, isLatestHistoryLoading, totalHistoryCount, setLatestHistoryData };
}
