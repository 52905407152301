import { useState } from "react";
import { removeElementFromArray } from "../helpers";
export const useMultipleSelect = (selectedValue = []) => {
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(selectedValue);

  function handleSelectedDataChange(value) {
    setSelectedData(value);
  }

  function removeSelectedDataFromList(index) {
    setSelectedData(removeElementFromArray(selectedData, index));
  }

  function removeAll() {
    setSelectedData([]);
  }

  return {
    setData,
    selectedData,
    data,
    handleSelectedDataChange,
    removeSelectedDataFromList,
    removeAll
  };
};
