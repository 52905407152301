import React, { useEffect, useState, useCallback } from "react";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import InfoIcon from "@material-ui/icons/Info";

import Details from "./Details";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";
import { PublisherApiService } from "../../../services/publisherApi";
import { getCurrentUser } from "../../../helpers/AuthHelper";

const PublisherApiInfo = (props) => {
  const [isExistInfoV2, setIsExistInfoV2] = useState(false);
  const [apiDetailsV2, setApiDetailsV2] = useState({});
  const [confirm_box_openV2, setConfirmBoxOpenV2] = useState(false);

  const { id } = useParams() || null;
  let currentUser = useSelector(getCurrentUser);

  const fetchApiDetails = useCallback(async () => {
    try {
      // id will exist for admin page
      // and will fetch token of the given id
      if (id) {
        const responseV2 = await PublisherApiService.fetchPublisherApiInfoV2(
          id
        );

        if (responseV2) {
          if (responseV2?.success && responseV2?.data) {
            setApiDetailsV2(responseV2.data);
            setIsExistInfoV2(true);
          }
        } else {
          throw new Error("Something went wrong");
        }
      } else {
        const responseV2 =
          await PublisherApiService.fetchPublisherApiInfoByAccountIdV2(
            currentUser.id
          );
        if (responseV2) {
          if (responseV2?.success && responseV2?.data) {
            setApiDetailsV2(responseV2.data);
            setIsExistInfoV2(true);
          }
        } else {
          throw new Error("Something went wrong");
        }
      }
    } catch (error) {
      toastr.error("Error", error.message);
    }
    // eslint-disable-next-line
  }, [id, isExistInfoV2, currentUser.id]);

  useEffect(() => {
    fetchApiDetails();
  }, [fetchApiDetails]);

  const icon = <InfoIcon fontSize="inherit" color="primary" />;

  const regenerateTokenV2 = async () => {
    if (id) {
      const generated = await PublisherApiService.regenerateTokeV2({}, id);
      if (generated) {
        setIsExistInfoV2(true);
        fetchApiDetails();
      }
    }
  };

  const handleRegenerateV2 = () => {
    setConfirmBoxOpenV2(true);
  };

  return (
    <>
      {confirm_box_openV2 && (
        <ConfirmBox
          message="Are you sure You Want to regenerate token?"
          confirmAction={async () => {
            await regenerateTokenV2();
            setConfirmBoxOpenV2(false);
          }}
          denyAction={() => setConfirmBoxOpenV2(false)}
        />
      )}

      <div className="publishers-container">
        <div className="publishers-container__header">
          <ContentHeader icon={icon} title="API Informations" />
        </div>
        <div className="publishers-container__buttons">
          {id && (
            <div className="publishers-container__generateBtn">
              <Button
                onClick={handleRegenerateV2}
                variant="contained"
                size="medium"
                color="primary"
              >
                Regenerate Token
              </Button>
            </div>
          )}
        </div>
        <div className="publisher-apis">
          <Details dataNew={apiDetailsV2} isExistInfoNew={isExistInfoV2} />
        </div>
      </div>
    </>
  );
};

export default PublisherApiInfo;
