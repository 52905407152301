/**
 * Formula to calculate the Ad coverage
 * @param {number} total_searches Sum of total searches
 * @param {number} total_monetized_searches Sum of Monetized Searches
 * @return {number}
 */
const zeroValue = Number(0).toFixed(3);
const calculateAdCoverage = (total_searches, total_monetized_searches) => {
    if (total_searches && total_searches > 0) {
        const value = (total_monetized_searches * 100) / total_searches;
        return value.toFixed(3);
    } else return zeroValue;
};

/**
 * Formula to calculate rpc
 * @param {number} total_clicks
 * @param {number} total_gross_revenue
 * @returns {number}
 */
const calculateRPC = (total_clicks, total_gross_revenue) => {
    if (total_clicks && total_clicks > 0) {
        const value = total_gross_revenue / total_clicks;
        return value.toFixed(3);
    } else return zeroValue;
};

/**
 *
 * @param {number} total_searches
 * @param {number} total_searches
 * @returns {number}
 */
const calculateCTR = (total_clicks, total_searches) => {
    if (total_searches && total_searches > 0) {
        const value = (total_clicks * 100) / total_searches;
        return value.toFixed(3);
    } else return zeroValue;
};

const calculateMonetizedCTR = (total_clicks, total_monetized_searches) => {
    if (total_clicks && total_monetized_searches > 0) {
        const value = (total_clicks * 100) / total_monetized_searches;
        return value.toFixed(3);
    } else return zeroValue;
};

const calculateRPM = (total_searches, gross_revenue) => {
    if (total_searches && total_searches > 0) {
        const value = (gross_revenue * 1000) / total_searches;
        return value.toFixed(3);
    } else return 0.0;
};

const calculateRPPM = (total_gross_revenue, total_monetized_searches) => {
    if (total_monetized_searches && total_monetized_searches > 0) {
        const value = (total_gross_revenue * 1000) / total_monetized_searches;
        return value.toFixed(3);
    } else return zeroValue;
};

const calculateServerSearchesRPM = (
    total_gross_revenue,
    total_search_counts
) => {
    if (total_search_counts && total_search_counts > 0) {
        const value = (total_gross_revenue * 1000) / total_search_counts;
        return Math.min(100, Math.max(0, value.toFixed(3)));
    } else return zeroValue;
};

const calculateServerAdCoverage = (
    total_monetized_searches,
    total_server_searches
) => {
    if (total_server_searches && total_server_searches > 0) {
        const value = (total_monetized_searches * 100) / total_server_searches;
        return Math.min(100, Math.max(0, value.toFixed(3)));
    } else return zeroValue;
};

const calculateDiscrepancy = (total_searches, total_server_searches) => {
    if (total_server_searches && total_server_searches > 0) {
        const value = Math.min(100, Math.max(0, 100 - (Number.parseInt(total_searches) * 100) / Number.parseInt(total_server_searches)));
        return value.toFixed(3);
    } else return zeroValue;
};
module.exports = {
    calculateAdCoverage,
    calculateRPC,
    calculateCTR,
    calculateMonetizedCTR,
    calculateRPM,
    calculateRPPM,
    calculateServerSearchesRPM,
    calculateServerAdCoverage,
    calculateDiscrepancy
};
