import { IconButton, Tooltip, useTheme } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { formatNumberAddComma } from "../../../helpers/formatters";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { LAST_SEVEN_DAYS, PER_DAY } from "../../../helpers/constant/filters";

const TagStats = ({ publisherChannelStats }) => {
  const theme = useTheme();
  if (!publisherChannelStats) return <></>;

  if (publisherChannelStats.loading) {
    return (
      <div className="tag-stats">
        <pre>...Loading Stats</pre>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        marginRight: 0
      }}
      className="tag-stats"
    >
      <span style={{ color: theme.palette.primary.main, fontSize: "0.8rem" }}>
        Data Last Updated:{" "}
        {publisherChannelStats.data?.report_timestamp.value
          ? moment(publisherChannelStats.data.report_timestamp.value).format(
              "LLLL"
            )
          : "n/a"}
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          alignItems: "center",
          fontSize: "1.1rem"
        }}
      >
        {publisherChannelStats.data?.publisher_channel_id ? (
          <a
            href={`${window.location.origin}/revenuereport?pub_link_id=${publisherChannelStats.data.publisher_channel_id}&interval=${PER_DAY}&period=${LAST_SEVEN_DAYS}&group_by=publisher_id-publisher_channel_id`}
            target="_blank"
            style={{ marginRight: "10px" }}
            rel="noopener noreferrer"
          >
            <Tooltip title="Revenue Report">
              <IconButton>
                <MonetizationOnIcon
                  style={{ fill: theme.palette.primary.main }}
                />
              </IconButton>
            </Tooltip>
          </a>
        ) : null}
        <p style={{ marginTop: 0, marginBottom: 0 }}>
          MTD Searches:{" "}
          {formatNumberAddComma(
            publisherChannelStats.data?.monthly_search_count || 0
          )}
        </p>
        <p style={{ marginTop: 0, marginBottom: 0 }}>
          Current Searches:
          {formatNumberAddComma(
            publisherChannelStats.data?.current_search_count || 0
          )}
        </p>
        <p style={{ marginTop: 0, marginBottom: 0 }}>
          Yesterday Searches:
          {formatNumberAddComma(
            publisherChannelStats.data?.yesterday_search_count || 0
          )}
        </p>
        <p style={{ marginTop: 0, marginBottom: 0 }}>
          Filtered:
          {formatNumberAddComma(
            publisherChannelStats.data?.filtered_count || 0
          )}
        </p>
        <p style={{ marginTop: 0, marginBottom: 0 }}>
          Overcapped:
          {formatNumberAddComma(publisherChannelStats.data?.overcap_count || 0)}
        </p>
      </div>
    </div>
  );
};

export default TagStats;
