import React from "react";
import { useTagTypeFormLogic } from "../logic/tagTypeForm.logic";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/CloseSharp";
import Button from "@material-ui/core/Button";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

const TagTypeForm = ({ tagTypeId = "", openTable }) => {
    const {
        formValues,
        loadingTagTypeData,
        handleFormValueChange,
        handleSubmit,
        handleCancel,
        isSubmitting,
        editing,
        errorValues
    } = useTagTypeFormLogic(tagTypeId, openTable);

    if (loadingTagTypeData.isLoading) {
        return (
            <div className="loading-div">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div className="default-values__form">
            <h5>{editing.isEditing ? `Editing Link Type` : "Adding Link Type"}</h5>
            <form className="flow-content" onSubmit={handleSubmit}>
                <div>
                    <FormControl fullWidth>
                        <TextField
                            variant="outlined"
                            label="Link Type Name"
                            value={formValues.name}
                            onChange={handleFormValueChange}
                            placeholder={`Enter Link Type Name`}
                            name="name"
                        />
                    </FormControl>
                    {errorValues.name && (
                        <div className="error-text">Please provide link type name</div>
                    )}
                </div>
                <div>
                    <FormControl fullWidth>
                        <TextField
                            label="Details / Description"
                            multiline
                            rows={4}
                            variant="outlined"
                            name="details"
                            value={formValues.details}
                            onChange={handleFormValueChange}
                            fullWidth
                        />
                    </FormControl>
                </div>
                <div className="default-values__form__buttons">
                    <Button
                        color="primary"
                        variant="contained"
                        size="medium"
                        startIcon={<CheckIcon />}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCancel}
                        startIcon={<CloseIcon />}
                    >
                        Cancel
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default TagTypeForm;
