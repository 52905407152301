import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Redirect, useParams, useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
// icon
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { auth } from "../../helpers";
import { changePassword, checkToken } from "../../services/Auth";

const ChangePassword = ({ location }) => {
  const { token, id } = useParams();
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [is_validated, setIsValidated] = useState(false);
  const [password_error, setPasswordError] = useState("");
  const [confirm_password_error, setConfirmPasswordError] = useState("");
  const [is_loading, setIsLoading] = useState(false);
  const [is_new_password_visible, setIsNewPasswordVisible] = useState(false);
  const [is_confirm_password_visible, setIsConfirmPasswordVisible] =
    useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticated = useSelector(auth.checkIfAuthenticated);

  const setNewPassword = (event) => {
    event.preventDefault();
    let canSubmit = true;
    const user_password = password.trim();
    const user_confirm_password = confirm_password.trim();
    if (user_password === "") {
      setPasswordError("Please Enter Your Password");
      canSubmit = false;
    } else if (user_password.length < 6) {
      setPasswordError("Password Must be Greater Than 6 characters");
      canSubmit = false;
    } else if (user_password.length > 30) {
      setPasswordError("Password Must be Less then 30 characters");
      canSubmit = false;
    }

    if (password !== user_confirm_password) {
      setConfirmPasswordError("Confirm Password Must be Same as Password");
      canSubmit = false;
    }

    if (canSubmit) {
      setIsLoading(true);
      dispatch(
        changePassword(
          { password, token, id },
          () => {
            history.push("/login");
          },
          setIsLoading
        ),
        () => {}
      );
    }
  };

  useEffect(() => {
    if (token && id) {
      dispatch(
        checkToken({ token, id }, (status) => {
          if (status) {
            setIsValidated(true);
          } else {
            history.push("/forgot-password");
          }
        }),
        (error) => {
          console.log("error");
          console.log(error);
        }
      );
    } else {
      location.pathname("/login");
    }
  }, [token, dispatch, id, location, history]);

  if (isAuthenticated) {
    toastr.success("Welcome", `You are already Logged In`);
    return <Redirect to="/" />;
  }
  if (!token) {
    toastr.warning("No Token Provided");
    return <Redirect to="/" />;
  }

  if (!is_validated) {
    return <></>;
  }
  const is_password_error = password_error.length > 0;
  const is_confirm_password_error = confirm_password_error.length > 0;
  return (
    <div className="container-fluid">
      <div className="login-container">
        <div className="card login-form bg-light col-md-8">
          <div className="card-body">
            <h1>Update Password</h1>
            <form>
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">Password</label>
                <div className="col-sm-10">
                  <input
                    type={is_new_password_visible ? "text" : "password"}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError("");
                    }}
                    value={password}
                    className={
                      is_password_error ? "error form-control" : "form-control"
                    }
                    placeholder="Password"
                  />
                  <IconButton
                    onClick={() =>
                      setIsNewPasswordVisible(!is_new_password_visible)
                    }
                  >
                    {is_new_password_visible ? (
                      <Visibility color="primary" />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                  <span
                    className={`login-error-msgField ${
                      is_password_error && "show-error-msgField"
                    }`}
                  >
                    {password_error ||
                      "Password Must be more than 6 and less than 30 characters"}
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">
                  Confirm Password
                </label>
                <div className="col-sm-10">
                  <input
                    type={is_confirm_password_visible ? "text" : "password"}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setConfirmPasswordError("");
                    }}
                    value={confirm_password}
                    className={
                      is_confirm_password_error
                        ? "error form-control"
                        : "form-control"
                    }
                    placeholder="Confirm Password"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setNewPassword(e);
                      }
                    }}
                  />
                  <IconButton
                    onClick={() =>
                      setIsConfirmPasswordVisible(!is_confirm_password_visible)
                    }
                  >
                    {is_confirm_password_visible ? (
                      <Visibility color="primary" />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                  <span
                    className={`login-error-msgField ${
                      is_confirm_password_error && "show-error-msgField"
                    }`}
                  >
                    {confirm_password_error ||
                      "Confirm password and password must be same"}
                  </span>
                </div>
              </div>

              <div className="reset-button">
                <button
                  type="button"
                  onClick={setNewPassword}
                  className="btn btn-outline-primary"
                  disabled={is_loading}
                >
                  {is_loading ? "Loading..." : "Change"}
                </button>
              </div>
              <div className="link">
                <Link to="/login">Back to Login</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ChangePassword);
