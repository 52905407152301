import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Redirect, useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";

import { auth } from "../../helpers";
import { sendMail } from "../../services/Auth";

const ForgotPassword = ({ location }) => {
  const [is_loading, setIsLoading] = useState(false);
  const [email, setIdentification] = useState("");
  const [errors, setErrors] = useState({ email: false });
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticated = useSelector(auth.checkIfAuthenticated);

  const handleSubmit = (event) => {
    event.preventDefault();
    let canSubmit = true;

    if (email.trim() === "") {
      setErrors({ ...errors, email: true });
      canSubmit = false;
    }
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())) {
      setErrors({ ...errors, email: true });
      canSubmit = false;
    }
    if (canSubmit) {
      setIsLoading(true);
      console.log(is_loading);
      dispatch(
        sendMail(
          { email },
          () => {
            history.push("/login");
          },
          setIsLoading
        ),
        (error) => {}
      );
    }
  };

  if (isAuthenticated) {
    toastr.success("Welcome", `Login Successfully`);
    return <Redirect to="/" />;
  }

  return (
    <div className="container-fluid">
      <div className="login-container">
        <div className="card login-form bg-light col-md-8">
          <div className="card-body">
            {/* {loginError && loginError.isError && (
              <span>{loginError.message}</span>
            )} */}
            <h1>Reset Your Password</h1>
            <form>
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">Email</label>
                <div className="col-sm-10">
                  <input
                    type="email"
                    name="email"
                    onChange={(e) => {
                      setIdentification(e.target.value);
                      setErrors({ ...errors, email: false });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      }
                    }}
                    value={email}
                    id="email"
                    placeholder="Enter email"
                    className={
                      errors.email
                        ? "error form-control login-textfield"
                        : "form-control login-textfield"
                    }
                  />
                  <span
                    className={`login-error-msgField ${
                      errors.email && "show-error-msgField"
                    }`}
                  >
                    Please enter valid email!
                  </span>
                </div>
              </div>

              <div className="reset-button">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn btn-outline-primary"
                  disabled={is_loading}
                >
                  {is_loading ? "Loading ..." : "Reset Password"}
                </button>
              </div>
              <div className="link">
                <Link to="/login">Back To Login</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ForgotPassword);
