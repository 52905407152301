/**
 * CUSTOM TOOLTIP OPTION FOR ADVETISER LINKS THAT ARE USED IN THE DROPDOWN
 * OF PUBLISHER TAG (TARGETING) FORM
 */
import React from "react";
import DropDownToolTip from "./DropDownTooltip";
import RelatedProviderLinkIcon from "../../../pages/ProviderLinks/RelatedProviderLinkIcon";
import IsLiveProviderLinkIcon from "../../../pages/ProviderLinks/IsLiveProviderLinkIcon";
// import QualityScoreText from "../../../pages/ProviderLinks/QualityScoreText";
import DailyCapValue from "../../../pages/ProviderLinks/DailyCapValue";
import LinkPropertiesIcon from '../../../pages/ProviderLinks/LinkPropertiesIcon'

const PtLinksTooltipOption = ({
    option,
    getLabel,
    showValueInLabel,
    disabledMenuClassName,
    enabledMenuClassName,
    extraDependencies
}) => {
    const isDisabled = option?.disabled || false;
    const { openModal } = extraDependencies;
    return (
        < div className={`autoselect-value ${isDisabled ? disabledMenuClassName : enabledMenuClassName}`}>
            <DropDownToolTip title={option.label}>
                <div className="ptLinksTooltipOption">
                    {
                        option?.reusable ? (<span className="green-text">{getLabel(option, showValueInLabel)}</span>) : (<span  >{getLabel(option, showValueInLabel)}</span>)
                    }

                    <RelatedProviderLinkIcon
                        openModal={() => openModal(option.value)}
                        noOfRules={option.no_of_rules}
                        noOfActiveRules={option.no_of_active_rules}
                    />
                    <DailyCapValue
                        daily_cap={option.daily_cap}
                        remaining_cap={option.remaining_cap}
                    />
                    <IsLiveProviderLinkIcon is_live={option.is_live} />
                    <LinkPropertiesIcon link={option} />
                </div>
            </DropDownToolTip>
        </div >
    );
};

export default PtLinksTooltipOption;
