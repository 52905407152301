const DIRECT = `direct`
const PREMIUM = `premium`
const MID = `mid`
const LOW = `low`
export const quality_score = {
    DIRECT,
    PREMIUM,
    MID,
    LOW
};
export const QualityScoreDropDown = [
    {label: DIRECT.toUpperCase(), value: DIRECT},
    {label: PREMIUM.toUpperCase(), value: PREMIUM},
    {label: MID.toUpperCase(), value: MID},
    {label: LOW.toUpperCase(), value: LOW}
]