import { useState } from "react";
import { Domain } from "../../../services/Domain";

export const useSubmitDomainLogic = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const saveDomain = async (data) => {
    try {
      setIsSubmitting(true);
      const domain = await Domain.addDomain(data);
      setIsSubmitting(false);
      if (domain.success) {
        return {
          success: true,
          data: domain.data.domain,
          msg: domain.msg
        };
      } else {
        throw new Error(domain?.error?.message || "Unable to save domain");
      }
    } catch (error) {
      setIsSubmitting(false);
      return {
        success: false,
        msg: error.message || "Unable to save domain",
        data: null
      };
    }
  };

  const updateDomain = async (id, data) => {
    try {
      setIsSubmitting(true);
      const domain = await Domain.updateDomain(id, data);
      setIsSubmitting(false);
      if (domain.success) {
        return {
          success: true,
          data: domain.data.domain,
          msg: domain.msg
        };
      } else {
        throw new Error(domain?.error?.message || "Unable to update Domain");
      }
    } catch (error) {
      setIsSubmitting(false);
      return {
        success: false,
        msg: error.message || "Unable to update Domain",
        data: null
      };
    }
  };

  return {
    saveDomain,
    isSubmitting,
    updateDomain
  };
};
