import React, { useState, useEffect, useCallback } from "react";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFilter } from "../../../reducers/target/target.action";
import { getSelectedFilter } from "../../../reducers/target/target.selector";

const TargetFilterBox = ({
  setSearchValue,
  published,
  setPublished,
  draft,
  setDraft,
  archive = false,
  setSelected,
  setTag
}) => {
  const selectedFilter = useSelector(getSelectedFilter);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  //   const [tagId, setTagId] = useState(selectedFilter.tagId)
  const [query, setQuery] = useState(selectedFilter.query);

  const handleSearchButton = () => {
    setSearchValue(query);
    setSelected([]);
    stableDispatch(
      setSelectedFilter({
        ...selectedFilter,
        query
      })
    );
  };

  const handleChange = (type) => {
    let isPublished, isDraft;
    if (type === "published") {
      if (!published) {
        setDraft(false);
        isDraft = false;
      }
      isPublished = !published;
      setPublished(!published);
    } else {
      if (!draft) {
        setPublished(false);
        isPublished = false;
      }
      isDraft = !draft;
      setDraft(!draft);
    }
    stableDispatch(
      setSelectedFilter({
        ...selectedFilter,
        draft: isDraft,
        published: isPublished
      })
    );
  };

  const handleClearAll = () => {
    setQuery("");
    setSearchValue("");
    setPublished(false);
    setDraft(false);
    setTag(null);
    stableDispatch(
      setSelectedFilter({
        query: "",
        publisher: "",
        published: false,
        draft: false
      })
    );
  };

  return (
    <div className="filter-box__container">
      <div className="filter-box">
        <div className="filter-box__controls">
          <div className="filter-box__searchbar">
            {/* <h4>Search Text</h4> */}
            <div className="filter-box__searchField">
              <TextField
                variant="standard"
                margin="normal"
                label="Type to search ..."
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                onKeyPress={(e) => {
                  e.key === "Enter" && handleSearchButton();
                }}
                value={query}
                fullWidth
              />
            </div>
          </div>
        </div>
        <div></div>
        <div className="filter-box__options">
          {/* publisher select */}
          {/* <div className="filter-box__options-select">
            <AutoCompleteSelect
              selected={publisher ? publisher : ""}
              data={publishers || []}
              handleChange={(value) => handlePublisherChange(value)}
              label={"Publisher"}
              showValueInLabel={true}
              enableMultiple={false}
              withDotsForStatus={true}
              cannotSelectDisabled={false}
            />
          </div> */}

          {/* <div className="filter-box__options">
            <div className="filter-box__options-select">
              <TextField
                variant="outlined"
                margin="normal"
                label="Pub Link Id"
                type="number"
                onChange={(e) => {
                  setTagId(e.target.value);
                }}
                onKeyPress={(e) => {
                  e.key === "Enter" && handleSearchButton();
                }}
                value={tagId}
              />
            </div>
          </div> */}

          {!archive && (
            <div>
              <h4>Filter By Status</h4>
              <div style={{ display: "flex" }}>
                <div className="filter-box__options-checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={published}
                        onChange={() => handleChange("published")}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="published"
                      />
                    }
                    label="Published"
                  />
                </div>
                <div className="filter-box__options-checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={draft}
                        onChange={() => handleChange("draft")}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="draft"
                      />
                    }
                    label="Draft"
                  />
                </div>
                {/* <div className="filter-box__options-checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={is_live === true}
                        onChange={() => handleLiveChange("live")}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="cusDomain"
                      />
                    }
                    label="Is Live"
                  />
                </div>
                <div className="filter-box__options-checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={is_live === false}
                        onChange={() => handleLiveChange("notLive")}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="cusDomain"
                      />
                    }
                    label="Is Not Live"
                  />
                </div> */}
              </div>
            </div>
          )}
        </div>

        <div className="filter-box__button">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleSearchButton}
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleClearAll}
            startIcon={<ClearAllIcon />}
          >
            Clear All
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TargetFilterBox;
