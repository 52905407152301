import ProviderActionTypes from "./provider.types";

export const addError = () => ({
  type: ProviderActionTypes.ADD_ERROR_MESSAGE,
  payload: "Sorry, Facing some errors."
});

/**
 * INDIVIDUAL PRODUCT ACTIONS
 */

export const fetchProviderStart = () => ({
  type: ProviderActionTypes.FETCH_PROVIDER_START
});

export const fetchProviderSuccess = () => ({
  type: ProviderActionTypes.FETCH_PROVIDER_SUCCESS
});

export const fetchProviderFailure = (error) => ({
  type: ProviderActionTypes.FETCH_PROVIDER_FAILURE,
  payload: error
});

/**
 * PRODUCT LISTS ACTIONS
 */

export const fetchProvidersListStart = () => ({
  type: ProviderActionTypes.FETCH_PROVIDERS_LIST_START
});

export const fetchProvidersListSuccess = () => ({
  type: ProviderActionTypes.FETCH_PROVIDERS_LIST_SUCCESS
});

export const fetchProvidersListFailure = (error) => ({
  type: ProviderActionTypes.FETCH_PROVIDERS_LIST_FAILURE
});

export const updateDisabledProviders = (disabledProviders) => ({
  type: ProviderActionTypes.UPDATE_DISABLED_PROVIDERS_LIST,
  payload: disabledProviders
});

export const updateProviders = (providers) => ({
  type: ProviderActionTypes.UPDATE_PROVIDERS,
  payload: providers
});

/**
 * PRODUCTS LINKS
 */
export const updateProviderLinks = (links) => ({
  type: ProviderActionTypes.UPDATE_PROVIDER_LINKS,
  payload: links
});

export const updateDeletedProviderLinks = (deletedLinks) => ({
  type: ProviderActionTypes.UPDATE_DELETED_PROVIDER_LINKS,
  payload: deletedLinks
});

export const updateProviderLinksError = (formErrors) => ({
  type: ProviderActionTypes.UPDATE_PROVIDER_LINKS_ERRORS,
  payload: formErrors
});

export const clearProviderLinksAndErrors = () => ({
  type: ProviderActionTypes.CLEAR_PROVIDER_LINKS_AND_ERRORS
});

export const setSelectedFilter = (filters) => ({
    type: ProviderActionTypes.SET_PROVIDER_SELECTED_FILTER,
    payload: filters
});
