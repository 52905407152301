import moment from "moment";
/**
 *  Custom Hook to download admin reports
 */
import { Report } from "../../services/Report";
import { toastr } from "react-redux-toastr";
import { CUSTOM } from "../../helpers/constant/filters";
import { useRevColHeadersContext } from "../../contexts/revenue-context";

export const useReportDownload = ({ filterValues }) => {
    const [colHeaders] = useRevColHeadersContext();
    let tableHeaders = colHeaders.tableHeaders;

    function getParams() {
        let params = {};
        const {
          selectedProviders,
          selectedCountries,
          selectedPublishers,
          fromDate,
          toDate,
          selectedInterval,
          selectedGroupBy,
          selectedRuleIds,
          selectedTagIds,
          selectedAdvertiserLinkGroupIds,
          selectedTagTypes,
          selectedPlatforms,
          selectedSearchEngines,
          selectedChannels,
          selectedPublisherAccounts,
          selectedPeriod,
          selectedRemark,
          orderBy,
          orderDirection
        } = filterValues;
        if (selectedChannels?.length > 0) {
          let selection = [];
          for (let i in selectedChannels) {
            selection.push(selectedChannels[i]);
          }
          params["channels"] = selection;
        }
        // TODO remove this
        if (selectedPublisherAccounts?.length > 0) {
          let selection = [];
          for (let i in selectedPublisherAccounts) {
            selection.push(selectedPublisherAccounts[i]);
          }
          params["pub_account_id"] = selection;
        }

        if (selectedProviders?.length > 0) {
          let selection = [];
          for (let i in selectedProviders) {
            selection.push(selectedProviders[i]);
          }
          params["providers"] = selection;
        }

        if (selectedRuleIds?.length > 0) {
          let selection = [];
          for (let i in selectedRuleIds) {
            selection.push(selectedRuleIds[i]);
          }
          params["rules"] = selection;
        }

        if (selectedTagIds?.length > 0) {
          let selection = [];
          for (let i in selectedTagIds) {
            selection.push(selectedTagIds[i]);
          }
          params["tags"] = selection;
        }

        if (selectedAdvertiserLinkGroupIds?.length > 0) {
          let selection = [];
          for (let i in selectedAdvertiserLinkGroupIds) {
            selection.push(selectedAdvertiserLinkGroupIds[i]);
          }
          params["advertiser_link_groups"] = selection;
        }

        if (selectedCountries?.length > 0) {
            let selection = [];
            for (let i in selectedCountries) {
                selection.push(selectedCountries[i]);
            }
            params["countries"] = selection;
        }

        if (selectedInterval) {
            params["aggregateBy"] = selectedInterval;
        }
        if (selectedRemark) {
            params["remarksFilter"] = selectedRemark;
        }
        if (selectedGroupBy?.length > 0) {
            let selection = [];
            for (let i in selectedGroupBy) {
                selection.push(selectedGroupBy[i]);
            }
            params["groupByKeys"] = selection;
        }

        if (selectedPublishers.length > 0) {
            let selection = [];
            for (let i in selectedPublishers) {
                selection.push(selectedPublishers[i]);
            }
            params["publishers"] = selection;
        }

        if (selectedTagTypes?.length > 0) {
            let selection = [];
            for (let i in selectedTagTypes) {
                selection.push(selectedTagTypes[i]);
            }
            params["tagTypes"] = selection;
        }

        if (selectedPlatforms?.length > 0) {
            let selection = [];
            for (let i in selectedPlatforms) {
                selection.push(selectedPlatforms[i]);
            }
            params["platforms"] = selection;
        }

        if (selectedSearchEngines?.length > 0) {
            let selection = [];
            for (let i in selectedSearchEngines) {
                selection.push(selectedSearchEngines[i]);
            }
            params["searchEngines"] = selection;
        }

        if (selectedPeriod !== CUSTOM) {
            if (selectedPeriod) params[selectedPeriod] = true;
        } else {
            if (fromDate && moment(fromDate).isValid()) {
                params["fromDate"] = moment(fromDate).format("YYYY-MM-DD");
            }
            if (toDate && moment(toDate).isValid()) {
                params["toDate"] = moment(toDate).format("YYYY-MM-DD");
            }
        }
        // TODO check for interval and assign date as order by
        if (orderBy) {
            let modified_order_by = orderBy;
            const key_mapping = {
                geo: "country",
                advertiser_id: "provider_id",
                tag_id: "targeting_id",
                click: "clicks",
                gross_revenue: "shared_revenue",
                pub_revenue: "publisher_revenue",
                server_search_counts: "server_search_count",
                total_searches: "adv_search_count",
                server_searches_rpm: "ssrpm"
            };
            const old_keys = Object.keys(key_mapping);
            if (old_keys.includes(orderBy)) {
                modified_order_by = key_mapping[orderBy];
            }
            params["orderBy"] = modified_order_by;
            params["orderDirection"] = orderDirection;
        }

        let filteredTableHeaders = tableHeaders
            .filter((header) => header.show && header.value !== "sno")
            .map((header) => {
                const key_mapping = {
                    geo: "country",
                    publisher: "publisher_id",
                    advertiser_id: "advertiser_id",
                    tag_id: "publisher_channel_id",
                    click: "clicks",
                    gross_revenue: "shared_revenue",
                    pub_revenue: "publisher_revenue",
                    server_search_counts: "server_search_count",
                    total_searches: "advertiser_search_count",
                    server_searches_rpm: "ssrpm"
                };

                if (Object.keys(key_mapping).includes(header.value)) {
                    return key_mapping[header.value];
                }
                return header.value;
            });

        if (filteredTableHeaders.includes('advertiser_id')) {
            filteredTableHeaders.splice(filteredTableHeaders.indexOf('advertiser_id') + 1, 0, 'advertiser_name')
        }

        if (filteredTableHeaders.includes('publisher_id')) {
            filteredTableHeaders.splice(filteredTableHeaders.indexOf('publisher_id') + 1, 0, 'publisher_name')
        }

        if (filteredTableHeaders.includes('tag_type_id')) {
            filteredTableHeaders.splice(filteredTableHeaders.indexOf('tag_type_id') + 1, 0, 'tag_type_name')
        }

        if (filteredTableHeaders.includes('platform_id')) {
            filteredTableHeaders.splice(filteredTableHeaders.indexOf('platform_id') + 1, 0, 'platform_name')
        }
        if (filteredTableHeaders.includes('search_engine_id')) {
            filteredTableHeaders.splice(filteredTableHeaders.indexOf('search_engine_id') + 1, 0, 'search_engine_name')
        }

        params["csvHeaders"] = filteredTableHeaders;
        return params;
    }

    const downloadMissingSubIds = (headers) => {
        let headersList = [];
        let subHeaderList = [];
        if (headers.length > 0) {
            headers.forEach((em) => {
                headersList.push(em?.title?.trim());
                subHeaderList.push(em?.stat?.trim());
            });
        }
        Report.downloadMissingSubIds(getParams(), headersList, subHeaderList)
            .then(({ data, status }) => {
                if (status === 200) {
                    const url = window.URL.createObjectURL(data);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${new Date()}.csv`);
                    document.body.appendChild(link);
                    link.click();
                } else {
                    throw new Error(JSON.stringify(data?.message || "Cannot download"));
                }
            })
            .catch((error) => {
                console.trace(error.message);
                toastr.error("Oops!", "Cannot download at the moment!");
            });
    };

    const downloadReport = () => {
        Report.downloadReportV2BigQuery(getParams())
            .then(({ data, status }) => {
                if (status === 200) {
                    const url = window.URL.createObjectURL(data);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${new Date()}.csv`);
                    document.body.appendChild(link);
                    link.click();
                } else {
                    throw new Error(JSON.stringify(data?.message || "Cannot download"));
                }
            })
            .catch((error) => {
                console.trace(error.message);
                toastr.error("Oops!", "Cannot download at the moment!");
            });
    };

    return {
        downloadReport,
        downloadMissingSubIds
    };
};
