export const useRelatedLinkStatAssign = (stats, rules) => {
    return rules.map((rule) => {
        const data = stats.find((item) => item.rule_id === rule.rule_id);
        if (data) {
          return {
              ...rule, 
              yesterdaysSearchCount: data.yesterdays_count || 0, 
              dailyCap: data.daily_cap || 0
          }
        } else {
            return {
                ...rule,
                yesterdaysSearchCount: 0, 
                dailyCap: 0
            }
        }
    })
}
