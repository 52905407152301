import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from "../../configs/firebase/configuration";
import { v4 as uuidv4 } from 'uuid';

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const uniqueDeviceId = () => {
    let device_id = localStorage.getItem('ninaya-device-id');
    if (!device_id || device_id === undefined) {
        // generate unique device id
        device_id = uuidv4();
        localStorage.setItem('ninaya-device-id', device_id);
    }
    return device_id;
}

export const requestGetToken = async () => {
    try {
        let permissionGranted = false;
        if (Notification.permission === 'default') {
            const permission = await Notification.requestPermission()
            if (permission === 'granted') {
                permissionGranted = true;
            }
        } else if (Notification.permission === 'granted') {
            permissionGranted = true;
        }

        if (permissionGranted) {
            const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });
            return token;
        }
        throw new Error("Notification permission is not granted!");
    } catch (err) {
        throw err;
    }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});
