import { useState } from "react";
import { onMessageListener } from "../../services/Firebase";
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from "@material-ui/lab";
import { getCurrentUser } from "../../helpers/AuthHelper";
import { useSelector } from "react-redux";
import Roles from "../../helpers/constant/role-types";

const FirebaseNotification = () => {
    const currentUser = useSelector(getCurrentUser);
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({title: 'test', body: 'This is test body'});

    onMessageListener()
        .then(payload => {
            setShow(true);
            setNotification({title: payload.notification.title, body: payload.notification.body})
        })
        .catch(err => console.log('failed: ', err));

    const handleClose = () => {
        setShow(false);
    }
    
    return (
        <>
            {
                Roles.SUPER_ADMIN === currentUser?.role?.role && 
                <Snackbar 
                    onClose={handleClose} 
                    open={show} 
                    autoHideDuration={5000} 
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <Alert onClose={handleClose} severity="info" className="custom_push_notification">
                            <AlertTitle>{notification.title}</AlertTitle>
                            <div> {notification.body} </div>
                        </Alert>
                </Snackbar>
            }
        </>
    )
}

export default FirebaseNotification;
