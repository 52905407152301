import React from "react";
import { useTagTypeFilters } from "../logic/tagTypesFilters.logic";
import { useFetchTagTypes } from "../logic/fetchTagTypes.logic";
import { useTagTypeTableLogic } from "../logic/tagTypeTable.logic";
// import TagTypeTableTabMenu from "./TagTypeTableTabMenu";
import Table from "../../../components/Table/Table";
import TagTypeTableFilter from "./TagTypeTableFilter";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";

const TagTypeTable = ({ openEditForm }) => {
  const filterValuesLogic = useTagTypeFilters();

  const {
    clearAllSelection,
    filterValues,
    // selectionAddActions,
    filtersClearToggle
  } = filterValuesLogic;

  const {
    loadingTagTypes,
    tagTypes,
    page,
    setPage,
    size,
    setSize,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    total,
    getTagTypes
  } = useFetchTagTypes({
    filterValues,
    filtersClearToggle
  });

  // const { setArchivedData } = selectionAddActions;

  const {
    headers,
    actions,
    archiveModalOpen,
    deleteModalOpen,
    restoreModalOpen,
    handleModalClose,
    archiveTagType,
    deleteTagType,
    restoreTagType
  } = useTagTypeTableLogic({
    filterValues,
    getTagTypes,
    openEditForm
  });

  return (
    <div className="flow-content">
      {/* Removing archived section for now */}
      {/* <TagTypeTableTabMenu
        archivedData={filterValues.archivedData}
        setArchivedData={setArchivedData}
      /> */}

      <TagTypeTableFilter
        handleSearch={getTagTypes}
        clearAllSelection={clearAllSelection}
        filterValuesLogic={filterValuesLogic}
        loadingTagTypes={loadingTagTypes}
      />
      <div>
        {tagTypes && (
          <Table
            headers={headers}
            rowValues={tagTypes}
            actions={actions}
            isLoading={loadingTagTypes.isLoading}
            noIds={true}
            noCheckboxes={true}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            total={total}
            rowsPerPageOptions={[10, 20, 30, 50, 100, 200]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            tableType="tagTypes"
          />
        )}
      </div>
      {archiveModalOpen && (
        <ConfirmBox
          title="Archive Link Type"
          message="Are you sure you want to archive this link type?"
          confirmAction={archiveTagType}
          denyAction={handleModalClose}
        />
      )}
      {deleteModalOpen && (
        <ConfirmBox
          title="Delete Link Type"
          message="Are you sure you want to delete this link type?"
          confirmAction={deleteTagType}
          denyAction={handleModalClose}
        />
      )}
      {restoreModalOpen && (
        <ConfirmBox
          title="Restore Link Type"
          message="Are you sure you want to restore this link Type?"
          confirmAction={restoreTagType}
          denyAction={handleModalClose}
        />
      )}
    </div>
  );
};

export default TagTypeTable;
