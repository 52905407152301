import React from "react";
import TagStats from "./TagStats";
import ZapButtons from "./ZapButtons";

const FormAddtionalHeaders = ({ targetingID, publisherChannelStats }) => {
  return (
    <>
      <ZapButtons targetingID={targetingID} />
      <TagStats publisherChannelStats={publisherChannelStats} />
    </>
  );
};

export default FormAddtionalHeaders;
