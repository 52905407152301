import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useReportDataRemove } from "./logic/DeleteRevenueLog.logic";
import SimpleDateRangePicker from "../../components/SimpleDateRangePicker/SimpleDateRangePicker";
// import PublisherAccountRemoveButtons from "./layouts/PublisherAccountRemoveButtons";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import PublisherAccountRemoveButtons from "../publisherAccounts/layouts/PublisherAccountRemoveButtons";
import AutocompleteSelect from "../../components/AutoCompleteSelect/AutoCompleteSelect";
const AdvertiserRevenueLogDelete = () => {
    const { id } = useParams();
    const location = useLocation();
    const provider = location.state.data;
    const {
        fromDate,
        toDate,
        selectedChannels,
        channels,
        loadAdvertiserChannels,
        handleChannelChange,
        handleDateChange,
        loadingRemovalResult,
        handleRemoveAction,
        isValid,
        handleCancelAction,
        removeModal,
        confirmRemoveAction,
        closeRemoveModal
    } = useReportDataRemove(id);

    if (!id) {
        return <div>Sorry, no id for advertiser found</div>;
    }
    if (!channels) {
        loadAdvertiserChannels()
    }

    const icon = <AddToPhotosIcon fontSize="inherit" color="primary" />;

    return (
        <div className="remove-report flow-content">
            <ContentHeader icon={icon} title="Remove Report Data" />
            <fieldset className="custom-fieldset flow-content">
                <legend>
                    Please select the dates to remove revenue log for advertiser
                </legend>

                <div className="remove-report__info">
                    <span> Advertiser: {id} - {provider.name}</span>
                </div>

                <div className="remove-report__date-picker">
                    <SimpleDateRangePicker
                        startDate={fromDate ? fromDate : null}
                        endDate={toDate ? toDate : null}
                        handleDateChange={handleDateChange}
                    />
                </div>

                <div>
                    <AutocompleteSelect
                        selected={selectedChannels}
                        data={channels || []}
                        handleChange={handleChannelChange}
                        label={"Channels"}
                        showValueInLabel={false}
                        enableMultiple={true}
                        withDotsForStatus={false}
                    />
                </div>

                <div className="remove-report__advertiser_channel">
                </div>

                <PublisherAccountRemoveButtons
                    loading={loadingRemovalResult.loading}
                    cancelAction={handleCancelAction}
                    submitAction={handleRemoveAction}
                />

                {!isValid && (
                    <div className="error-text">
                        <p>Please select a valid date range</p>
                    </div>
                )}
            </fieldset>

            {loadingRemovalResult.success && (
                <div className="remove-report__message">
                    <h3>Deleted {loadingRemovalResult.deletedCount} rows of data.</h3>
                </div>
            )}

            {removeModal && (
                <ConfirmBox
                    title="Remove Report Data"
                    message={`Are you sure you want to delete revenue log for advertiser ${id} - ${provider.name}?`}
                    confirmAction={confirmRemoveAction}
                    denyAction={closeRemoveModal}
                />
            )}
        </div>
    );
};

export default AdvertiserRevenueLogDelete;
