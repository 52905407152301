import React from "react";
import PropTypes from "prop-types";
import StatCard from "../../components/StatCard/StatCard";
import {formatNumberAddComma} from "../../helpers/formatters";

/**
 *  Component to render simple Stats
 */

const Stats = ({ reportStats, fields }) => {
  let { net_revenue, monetized_searches, total_searches } =
    reportStats || "No Info";
  return (
    <div className="flex-box justify-between full-width-children dashboard-stats">
      <StatCard title={fields?.monetized_searches || "Monetized Searches"}>
        <span>{formatNumberAddComma(monetized_searches) || "No Info"}</span>
      </StatCard>
      <StatCard title={fields?.net_revenue || "Net Revenue"}>
        <span>{formatNumberAddComma(+(+net_revenue).toFixed(3)) || "No Net Revenue"}</span>
      </StatCard>
      <StatCard title={fields?.total_searches || "Total Searches"}>
        <span>{formatNumberAddComma(total_searches) || "No Search Info"}</span>
      </StatCard>
    </div>
  );
};

export default Stats;

Stats.propTypes = {
  reportStats: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired
};
