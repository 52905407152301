import Api from "../api";
import moment from "moment";

class Report {
  fetchDashboardData = async (params = {}) => {
    const { body } = await Api.get(`dashboard`, null, params);
    return body;
  };

  fetchDashboardDataV2 = async (
    params = {},
    useGCP = false,
    publisher = false
  ) => {
    const date_format = "YYYY-MM-DD";
    const today_date = moment().format(date_format);

    if (!params.fromDate) {
      params.fromDate = moment().startOf("month").format(date_format);
    }
    if (!params.toDate) {
      params.toDate = moment().format(date_format);
    }
    // modify parameters according to the new api
    // period
    if (params.today) {
      params.fromDate = today_date;
      params.toDate = today_date;
      delete params.today;
    }
    if (params.yesterday) {
      params.fromDate = moment().subtract(1, "d").format(date_format);
      params.toDate = moment().subtract(1, "d").format(date_format);
      delete params.yesterday;
    }
    if (params.previousmonths) {
      params.fromDate = moment()
        .subtract(1, "month")
        .startOf("month")
        .format(date_format);
      params.toDate = moment()
        .subtract(1, "month")
        .endOf("month")
        .format(date_format);
      delete params.previousmonths;
    }
    if (params.currentmonths) {
      params.fromDate = moment().startOf("month").format(date_format);
      params.toDate = moment().endOf("month").format(date_format);
      delete params.currentmonths;
    }
    if (params.lastSevenDays) {
      params.fromDate = moment().subtract(6, "days").format(date_format);
      params.toDate = moment().format(date_format);
      delete params.lastSevenDays;
    }

    if (params.geo) {
      params.countries = params.geo;
      delete params.geo;
    }

    if (useGCP) {
      if (publisher) {
        const { body } = await Api.getGCP(
          `api/v1/bigquery/publisher-reports`,
          null,
          params
        );
        return body;
      } else {
        const { body } = await Api.getGCP(
          `api/v1/bigquery/revenue-reports`,
          null,
          params
        );
        return body;
      }
    }
    const { body } = await Api.getV2(
      `v2/dashboard/group_and_filter`,
      null,
      params
    );
    return body;
  };

  downloadReport = async (params = {}) => {
    const { body, status } = await Api.get(
      `dashboard/download/csv`,
      "blob",
      params
    );
    return { data: body, status };
  };
  downloadReportV2 = async (params = {}) => {
    const date_format = "YYYY-MM-DD";
    const today_date = moment().format(date_format);
    if (!params.fromDate) {
      params.fromDate = moment().startOf("month").format(date_format);
    }
    if (!params.toDate) {
      params.toDate = moment().format(date_format);
    }
    if (params.today) {
      params.fromDate = today_date;
      params.toDate = today_date;
      delete params.today;
    }
    if (params.yesterday) {
      params.fromDate = moment().subtract(1, "d").format(date_format);
      params.toDate = moment().subtract(1, "d").format(date_format);
      delete params.yesterday;
    }
    if (params.previousmonths) {
      params.fromDate = moment()
        .subtract(1, "month")
        .startOf("month")
        .format(date_format);
      params.toDate = moment()
        .subtract(1, "month")
        .endOf("month")
        .format(date_format);
      delete params.previousmonths;
    }
    if (params.currentmonths) {
      params.fromDate = moment().startOf("month").format(date_format);
      params.toDate = moment().endOf("month").format(date_format);
      delete params.currentmonths;
    }
    const { body, status } = await Api.getV2(
      `v2/dashboard/group_and_filter?downloadCSV=${true}`,
      "blob",
      params
    );
    return { data: body, status };
  };

  downloadReportV2BigQuery = async (params = {}) => {
    const date_format = "YYYY-MM-DD";
    const today_date = moment().format(date_format);
    if (!params.fromDate) {
      params.fromDate = moment().startOf("month").format(date_format);
    }
    if (!params.toDate) {
      params.toDate = moment().format(date_format);
    }
    if (params.today) {
      params.fromDate = today_date;
      params.toDate = today_date;
      delete params.today;
    }
    if (params.yesterday) {
      params.fromDate = moment().subtract(1, "d").format(date_format);
      params.toDate = moment().subtract(1, "d").format(date_format);
      delete params.yesterday;
    }
    if (params.previousmonths) {
      params.fromDate = moment()
        .subtract(1, "month")
        .startOf("month")
        .format(date_format);
      params.toDate = moment()
        .subtract(1, "month")
        .endOf("month")
        .format(date_format);
      delete params.previousmonths;
    }
    if (params.currentmonths) {
      params.fromDate = moment().startOf("month").format(date_format);
      params.toDate = moment().endOf("month").format(date_format);
      delete params.currentmonths;
    }
    if (params.lastSevenDays) {
        params.fromDate = moment().subtract(6, "days").format(date_format);
        params.toDate = moment().format(date_format);
        delete params.lastSevenDays;
      }
    const { body, status } = await Api.getGCP(
      `api/v1/bigquery/revenue-reports?download=true`,
      "blob",
      params
    );
    return { data: body, status };
  };

  downloadReportV2BigQueryPublisher = async (params = {}) => {
    const date_format = "YYYY-MM-DD";
    const today_date = moment().format(date_format);
    if (!params.fromDate) {
      params.fromDate = moment().startOf("month").format(date_format);
    }
    if (!params.toDate) {
      params.toDate = moment().format(date_format);
    }
    if (params.today) {
      params.fromDate = today_date;
      params.toDate = today_date;
      delete params.today;
    }
    if (params.yesterday) {
      params.fromDate = moment().subtract(1, "d").format(date_format);
      params.toDate = moment().subtract(1, "d").format(date_format);
      delete params.yesterday;
    }
    if (params.previousmonths) {
      params.fromDate = moment()
        .subtract(1, "month")
        .startOf("month")
        .format(date_format);
      params.toDate = moment()
        .subtract(1, "month")
        .endOf("month")
        .format(date_format);
      delete params.previousmonths;
    }
    if (params.currentmonths) {
      params.fromDate = moment().startOf("month").format(date_format);
      params.toDate = moment().endOf("month").format(date_format);
      delete params.currentmonths;
    }
    const { body, status } = await Api.getGCP(
      `api/v1/bigquery/publisher-reports?download=true`,
      "blob",
      params
    );
    return { data: body, status };
  };

  fetchMonthlyStats = async (params = {}) => {
    const { body } = await Api.get(`dashboard/monthlyStats`, null, params);
    return body;
  };

  fetchMonthlyStatsV2 = async (params = {}) => {
    const date_format = "YYYY-MM-DD";
    const fromDate = moment().startOf("month").format(date_format);
    const toDate = moment().endOf("month").format(date_format);
    const { body } = await Api.get("v2/dashboard/group_and_filter", null, {
      fromDate,
      toDate
    });
    return body;
  };

  fetchMonthlyStatsGCP = async (params = {}) => {
    const { body } = await Api.getGCP(
      "api/v1/bigquery/dashboard-stats",
      null,
      null
    );
    return body;
  };

  removeReportData = async (accountId, params = {}) => {
    const { body } = await Api.destroy(
      `remove/reports/${accountId}`,
      {},
      params
    );
    return body;
  };

  removeReportsDataByPubAdv = async (data, params = {}) => {
    const { body } = await Api.destroy(
      `provider/${data.provider_id}/delete-revenue-logs`,
      data,
      params
    );
    return body;
  };

  removeReportsDataByProvider = async (data, params = {}) => {
    const { body } = await Api.destroy(
      `/provider/${data.advertiser_id}/delete-revenue-logs`,
      data,
      params
    );
    return body;
  };
  removeReportsDataFromSetting = async (
    data,
    params = {},
    publisherId = null
  ) => {
    const { body } = await Api.destroy(
      `remove/reports/publisher/${publisherId}`,
      data,
      params
    );
    return body;
  };

  fetchChannelsByAdId = async (params = {}) => {
    const { body } = await Api.get(
      `dashboard/advertisers/subids`,
      null,
      params
    );
    return body;
  };

  //for fetching missing advertiser's subid's
  fetchMissingSubIds = async (params = {}) => {
    const date_format = "YYYY-MM-DD";
    const today_date = moment().format(date_format);

    if (!params.fromDate) {
      params.fromDate = moment().startOf("month").format(date_format);
    }
    if (!params.toDate) {
      params.toDate = moment().format(date_format);
    }
    // modify parameters according to the new api
    // period
    if (params.today) {
      params.fromDate = today_date;
      params.toDate = today_date;
      delete params.today;
    }
    if (params.yesterday) {
      params.fromDate = moment().subtract(1, "d").format(date_format);
      params.toDate = moment().subtract(1, "d").format(date_format);
      delete params.yesterday;
    }
    if (params.previousmonths) {
      params.fromDate = moment()
        .subtract(1, "month")
        .startOf("month")
        .format(date_format);
      params.toDate = moment()
        .subtract(1, "month")
        .endOf("month")
        .format(date_format);
      delete params.previousmonths;
    }
    if (params.currentmonths) {
      params.fromDate = moment().startOf("month").format(date_format);
      params.toDate = moment().endOf("month").format(date_format);
      delete params.currentmonths;
    }
    const { body } = await Api.getV2(
      `v2/dashboard/group_and_filter`,
      null,
      params
    );
    return body;
  };
}
// eslint-disable-next-line
export default new Report();
