import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { Card } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/CloseSharp";
import CheckIcon from "@material-ui/icons/Check";
import GroupIcon from "@material-ui/icons/Group";

import { useFormControls } from "./editUserFormCControll";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import { useHistory, useParams } from "react-router-dom";
import UserPasswordForm from "./userPasswordForm";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
//services
import { User } from "../../../services/ReportingUser";
import { Publisher } from "../../../services/Publisher";
import { Prompt } from "react-router-dom";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../helpers/constant/misc";
export default function EditUser() {
  // const userId = match.params.id;
  const { id: userId } = useParams();
  const {
    values,
    handleInputValue,
    handleFormSubmit,
    errors,
    set_UserId,
    formTouched,
    handleselectbox
  } = useFormControls();
  // const [accounts, set_Account] = useState([]);
  const [roles, set_Role] = useState([]);
  const [publishers, set_Publishers] = useState([]);
  // const [advertisers, set_Advertisers] = useState([]);
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [roleId, set_roleId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [pubId, set_pubId] = useState("");

  const params = {
    order_by: "id",
    order_direction: "ASC",
    size: DEFAULT_FETCH_PARAM_SIZE
  };

  useEffect(() => {
    set_UserId(userId);
    // fetchAccounts();
    fetchRolls();
    fetchPublisher();
    // fetchProviders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // const fetchAccounts = async () => {
  //   try {
  //     const account = await User.fetchAccount();
  //     if (account.success) {
  //       set_Account(account.data.accounts);
  //     } else {
  //       throw new Error("Failed to fetch accounts");
  //     }
  //   } catch (error) {
  //     console.trace(error.message);
  //   }
  // };

  const fetchRolls = async () => {
    try {
      const role = await User.fetchRole();
      if (role.success) {
        const roleData = role.data.roles.map((role) => {
          return { value: role.id, label: role.role };
        });
        set_Role(roleData);
      } else {
        throw new Error("Failed to fetch roles");
      }
    } catch (error) {
      console.trace(error.message);
    }
  };

  const fetchPublisher = async () => {
    try {
      const publisher = await Publisher.fetchPublishers(params);
      if (publisher.success) {
        const publisherData = publisher.data.publishers.map((pub) => {
          return { value: pub.id, label: pub.name, status: pub.status };
        });
        set_Publishers(publisherData);
        // set_Publishers(publisher.data.publishers);
      } else {
        throw new Error("Failed to fetch Publisher");
      }
    } catch (error) {
      console.trace(error.message);
    }
  };

  // const fetchProviders = async () => {
  //   try {
  //     const params = {};
  //     const provider = await Provider.fetchProviders(params);
  //     if (provider.success) {
  //       set_Advertisers(provider.data.providers);
  //     } else {
  //       throw new Error("Failed to fetch Providers");
  //     }
  //   } catch (error) {
  //     console.trace(error.message);
  //   }
  // };

  const FORMCARDSTYLE = { padding: "10px 30px", margin: "auto" };
  const icon = <GroupIcon fontSize="inherit" color="primary" />;

  return (
    <div className="userform">
      <div className="formsection">
        <ContentHeader icon={icon} title="Edit User" />
        <Card style={FORMCARDSTYLE}>
          {/* <h2>User Form</h2> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "50%" }}>
              <FormControl fullWidth style={{ margin: "10px 0px" }}>
                <TextField
                  variant="outlined"
                  label="First Name"
                  name="first_name"
                  placeholder="First Name"
                  type="text"
                  value={values.first_name}
                  autoComplete="none"
                  onChange={handleInputValue}
                />
              </FormControl>

              <FormControl fullWidth style={{ margin: "10px 0px" }}>
                <TextField
                  variant="outlined"
                  label="Last Name"
                  name="last_name"
                  placeholder="Last Name"
                  type="text"
                  value={values.last_name}
                  autoComplete="none"
                  onChange={handleInputValue}
                />
              </FormControl>

              <FormControl fullWidth style={{ margin: "10px 0px" }}>
                <TextField
                  variant="outlined"
                  label="Email"
                  name="email"
                  placeholder="Enter your email"
                  type="email"
                  autoComplete="none"
                  value={values.email}
                  onChange={handleInputValue}
                  onBlur={handleInputValue}
                  {...(errors["email"] && {
                    error: true
                  })}
                />
                {errors["email"] && (
                  <FormHelperText style={{ color: "red" }}>
                    {errors["email"]}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                style={{ margin: "10px 0px" }}
              >
                <AutoCompleteSelect
                  selected={values?.publisher_id ? values?.publisher_id : ""}
                  data={publishers || []}
                  handleChange={(value) => {
                    set_pubId(value);
                    handleselectbox(value, "publisher_id");
                  }}
                  label={"Select Publisher"}
                  showValueInLabel={true}
                  enableMultiple={false}
                  withDotsForStatus={true}
                  cannotSelectDisabled={false}
                />
                {errors["publisher_id"] && (
                  <FormHelperText style={{ color: "red", margin: 0 }}>
                    {errors["publisher_id"]}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                style={{ margin: "10px 0px" }}
              >
                <AutoCompleteSelect
                  selected={values?.role_id ? values?.role_id : ""}
                  data={roles || []}
                  handleChange={(value) => {
                    set_roleId(value);
                    handleselectbox(value, "role_id");
                  }}
                  label={"Select Role"}
                  showValueInLabel={true}
                  enableMultiple={false}
                  withDotsForStatus={true}
                  cannotSelectDisabled={false}
                />
                {errors["role_id"] && (
                  <FormHelperText style={{ color: "red", margin: 0 }}>
                    {errors["role_id"]}
                  </FormHelperText>
                )}
              </FormControl>

              <div
                className="profile__basic-info__form__buttons"
                style={{ margin: "10px 0px" }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  size="medium"
                  startIcon={<CheckIcon />}
                  type="submit"
                  onClick={(e) => {
                    handleFormSubmit(e, values);
                  }}
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    history.push(`/usermanagement`);
                  }}
                  variant="contained"
                  color="secondary"
                  startIcon={<CloseIcon />}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <UserPasswordForm
              userId={userId}
              handleCancel={() => {
                history.push(`/usermanagement`);
              }}
            />
          </div>
        </Card>
        <Prompt
          when={formTouched.current}
          message="Are you sure you want to leave?"
        />
      </div>
    </div>
  );
}
