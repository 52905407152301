import { useEffect } from "react";
import { useSelect } from "../../../hooks/useSelect";
import { useTextInput } from "../../../hooks/useTextInput";
import { useState } from "react";
import { SHOW_EDIT, SHOW_NEW_CREATION_FORM } from "./formUI.logic";
import { validURL } from "../../../helpers/validation";
import { toastr } from "react-redux-toastr";
import { changeNullToString, changeEmptyStringToNull } from "../../../helpers";
import { Provider } from "../../../services/Provider";
import { useModal } from "../../../hooks/useModal";
import { useFetchLatestHistory } from "./fetchLatestHistory";

const inital_form_errors = {
    linkID: false,
    link: false,
    description: false,
    searchEngineId: false,
    tagTypeId: false,
    platformId: false,
    providerId: false,
    link_source_identifier: false,
    link_source_identifier_value: false,
    daily_cap: false,
    quality_score: false
};
const NO_KEY_ERROR_MESSAGE =
    "Specified Channel Variable and Channel Value mismatch with the one found in link";
const NO_VALUE_ERROR_MESSAGE = "The Channel Variable doesn't exist in the link";
export const useProviderLinkBox = ({
    pageData,
    currentPage,
    getProviderLinks,
    showAddButton
}) => {
    // latest history
    const [historyTargId, setHistoryTargId] = useState(null);
    const [historyPage, setHistoryPage] = useState(1);
    const historyLimit = 5;
    const [startFetchHistory, setStartFetchHistory] = useState(false);
    const { latestHistoryData, isLatestHistoryLoading, totalHistoryCount } = useFetchLatestHistory({ id: historyTargId, page: historyPage, limit: historyLimit, startFetch: startFetchHistory, setStartFetch: setStartFetchHistory });

    const no_value_text = "?";
    const queryPlaceHolder = "[Q]";

    const [submittingForm, setSubmittingForm] = useState(false);
    const [createdAt, setCreateAt] = useState("");

    const [id, handleIdChange, setId] = useTextInput({ initialValue: "" });
    const [link, handleLinkChange, setLink] = useTextInput({ initialValue: "" });
    const [description, handleDescriptionChange, setDescription] = useTextInput({
        initialValue: ""
    });
    const [daily_cap, handleDailyCap, setDailyCap] = useTextInput({
        initialValue: ""
    });
    const [reusable, setReuseable] = useState(false);
    const [
        link_source_identifier,
        handleLinkSourceIdentifierChange,
        setLinkSourceIdentifier
    ] = useTextInput({ initialValue: no_value_text });
    const [
        link_source_identifier_value,
        handleLinkSourceIdentifierValue,
        setLinkSourceIdentifierValue
    ] = useTextInput({ initialValue: no_value_text });

    const [
        advertiser_link_source_identifiers,
        setAdvertiserLinkSourceIdentifiers
    ] = useState([]);
    const [is_custom_link_source, setIsCustomLinkSource] = useState(false);

    const [backEndDuplicates, setBackEndDuplicates] = useState({
        hasDuplicatesInBackend: false,
        duplicateLinkIdsInBackend: []
    });

    const [remainingCap, setRemainingCap] = useState(0);

    const [formErrors, setFormErrors] = useState({ ...inital_form_errors });

    const [duplicateModalOpen, openDuplicateModal, closeDuplicateModal] =
        useModal();

    const {
        selectedData: selectedSearchEngine,
        changeSelectedData: changeSelectedSearchEngine
    } = useSelect();

    const {
        selectedData: selectedLinkQuality,
        changeSelectedData: changeSelectedLinkQuality
    } = useSelect();

    const {
        selectedData: selectedTagType,
        changeSelectedData: changeSelectedTagType
    } = useSelect();

    const {
        selectedData: selectedPlatform,
        changeSelectedData: changeSelectedPlatform
    } = useSelect();

    const {
        selectedData: selectedProvider,
        changeSelectedData: changeSelectedProvider
    } = useSelect();

    const handleReusable = () => {
        setReuseable(reusable === true ? false : true);
    };

    /**
     * Wrapper function to validate provider id and link
     * Currently used in onblur of link source and link source value text field
     * @param {Function} func
     */
    function validate(func) {
        // validation
        if (!formValues.providerId) {
            return;
        }
        if (!formValues?.link || formValues.link.trim().length === 0) {
            return;
        }
        return func;
    }

    function getKeyByValue() {
        try {
            const trim_link_source_identifier_value =
                formValues.link_source_identifier_value?.trim() || "";
            if (
                trim_link_source_identifier_value === "" ||
                trim_link_source_identifier_value === no_value_text
            )
                return;
            const parsedURL = new URL(formValues.link);
            let matching_key = null;
            for (let key of parsedURL.searchParams.keys()) {
                const key_value = parsedURL.searchParams.get(key);
                if (key_value === formValues.link_source_identifier_value) {
                    matching_key = key;
                }
            }
            if (matching_key) {
                setLinkSourceIdentifier(matching_key);
                if (
                    formErrors.link_source_identifier_value ||
                    formErrors.link_source_identifier
                ) {
                    setFormErrors({
                        ...formErrors,
                        link_source_identifier: false,
                        link_source_identifier_value: false
                    });
                }
            } else {
                // if the specified channel value is not in query params the check in url path
                const pathArray = parsedURL.pathname
                    .split("/")
                    .map((item) => item.trim())
                    .filter((item) => item !== "");
                if (pathArray.includes(trim_link_source_identifier_value)) {
                    setFormErrors({
                        ...formErrors,
                        link_source_identifier_value: false,
                        link_source_identifier: false
                    });
                } else {
                    setFormErrors({
                        ...formErrors,
                        link_source_identifier_value:
                            "Channel value not found in the provided link"
                    });
                }
            }
        } catch (error) {
            console.trace(error);
        }
    }

    function getValueByKey() {
        try {
            const trim_link_source_identifier =
                formValues.link_source_identifier?.trim() || "";
            if (
                trim_link_source_identifier !== "" &&
                trim_link_source_identifier !== no_value_text
            ) {
                const parsedLink = new URL(formValues.link);
                // case when the link source identifiers are set
                if (advertiser_link_source_identifiers) {
                    const urlSearchParams = parsedLink.searchParams;
                    let matching_key = null;
                    for (let key of urlSearchParams.keys()) {
                        if (
                            key.toLowerCase() ===
                            formValues.link_source_identifier.toLowerCase()
                        ) {
                            matching_key = key;
                        }
                    }
                    // if any one the rul is in link source identifier then we put the key and value in the text
                    const matching_value = matching_key
                        ? urlSearchParams.get(matching_key)
                        : no_value_text;
                    // if the value for the key exist the update the field
                    // else show error message
                    if (
                        matching_key &&
                        matching_value &&
                        matching_value !== queryPlaceHolder &&
                        matching_value !== no_value_text
                    ) {
                        setLinkSourceIdentifierValue(matching_value);
                        if (
                            formErrors.link_source_identifier ||
                            formErrors.link_source_identifier_value
                        ) {
                            setFormErrors({
                                ...formErrors,
                                link_source_identifier_value: false,
                                link_source_identifier: false
                            });
                        }
                    } else {
                        const errorMessage = {
                            ...formErrors,
                            link_source_identifier: NO_VALUE_ERROR_MESSAGE
                        };
                        setFormErrors(errorMessage);
                    }
                }
                // else when the link source identifier is null
                else {
                    const pathname = parsedLink.pathname;
                    const pathSubstring = pathname.substring(
                        pathname.indexOf("/"),
                        pathname.lastIndexOf("/")
                    );
                    const filteredPaths = pathSubstring
                        .split("/")
                        .filter((el) => el !== "");
                    if (filteredPaths.length >= 1) {
                        setLinkSourceIdentifierValue(
                            filteredPaths[filteredPaths.length - 1]
                        );
                    } else {
                        setLinkSourceIdentifierValue(no_value_text);
                    }
                    setLinkSourceIdentifier("");
                }
            } else {
                setFormErrors({
                    ...formErrors,
                    link_source_identifier: false
                });
            }
        } catch (error) {
            console.trace(error);
        }
    }

    /**
     *
     * @param {number} provider_id The id of provider
     * @param {string} link_sources The link source identifier array
     * @param {boolean} disable_custom Flag enable and disable key check or custom key check
     * @returns
     */
    function extractKeyAndValue(
        provider_id,
        link_sources,
        disable_custom = false
    ) {
        // validation
        if (!provider_id) {
            return;
        }
        if (!formValues.link || formValues.link.trim().length === 0) {
            return;
        }
        try {
            const parsedLink = new URL(formValues.link);
            // in case of custom
            if (disable_custom === false && is_custom_link_source === true) {
                const v_link_source = formValues.link_source_identifier?.trim() || "";
                const v_link_value = formValues.link_source_identifier_value;
                if (v_link_source === "" || v_link_source === no_value_text) {
                    return;
                }
                if (v_link_source) {
                    const parsedQuery = Array.from(parsedLink.searchParams);
                    const keyArray = parsedQuery.map((item) => item[0]);
                    const valueArray = parsedQuery.map((item) => item[1]);
                    let newError = { ...formErrors };
                    // validate link source identifier and validate link source identifier value
                    if (!valueArray.includes(v_link_value)) {
                        newError.link_source_identifier_value = NO_KEY_ERROR_MESSAGE;
                    }
                    if (
                        keyArray
                            .map((item) => item.toLowerCase())
                            .includes(v_link_source.toLowerCase())
                    ) {
                        // const link_source_value = parsedLink.searchParams.get(v_link_source);
                        const link_source_index = keyArray
                            .map((item) => item.toLowerCase())
                            .indexOf(v_link_source.toLowerCase());
                        let link_source_value = null;
                        if (link_source_index > -1) {
                            link_source_value = valueArray[link_source_index];
                        }
                        if (link_source_value && link_source_value !== "") {
                            if (link_source_value !== v_link_value) {
                                setLinkSourceIdentifierValue(link_source_value);
                            }
                            newError.link_source_identifier = false;
                            newError.link_source_identifier_value = false;
                        } else {
                            newError.link_source_identifier = NO_VALUE_ERROR_MESSAGE;
                        }
                    } else {
                        newError.link_source_identifier = NO_VALUE_ERROR_MESSAGE;
                    }
                    setFormErrors(newError);
                }
                return;
            }
            // TODO check for custom
            // Two case when there no link source specified for advertiser and when there is.
            // k-yes-link
            //case when the link identifiers are set
            if (link_sources) {
                const urlSearchParams = parsedLink.searchParams;
                let matching_index = -1;
                // from array converts the search params into an array where the first index is array
                const queryArray = Array.from(urlSearchParams);
                // find the index of [Q] from url and remove it
                let queryIndex = queryArray.findIndex(
                    (element) => element[1] === "[Q]"
                );
                if (queryIndex !== -1) {
                    queryArray.splice(queryIndex, 1);
                }
                queryArray.forEach((item, index) => {
                    if (
                        link_sources
                            .map((item) => item.toLowerCase())
                            .includes(item[0].toLowerCase())
                    ) {
                        matching_index = index;
                    }
                });
                if (matching_index > -1) {
                    setLinkSourceIdentifier(
                        queryArray[matching_index][0] || no_value_text
                    );
                    setLinkSourceIdentifierValue(
                        queryArray[matching_index][1] || no_value_text
                    );
                } else {
                    setLinkSourceIdentifier(no_value_text);
                    setLinkSourceIdentifierValue(no_value_text);
                }
            } else {
                // k-null-link-source
                // case when the link source identifiers are null
                // in this case we extract the channel from the url which should be between  / and /
                /**
                 * e.g
                 * if url is google.com/pa/ja the channel should be pa
                 * if url is google.com/na/ta/cs the channel should be ta
                 */
                const pathname = parsedLink.pathname;
                const pathSubString = pathname.substring(
                    pathname.indexOf("/"),
                    pathname.lastIndexOf("/")
                );
                const filteredPaths = pathSubString
                    .split("/")
                    .filter((el) => el !== "");
                if (filteredPaths.length >= 1) {
                    setLinkSourceIdentifierValue(filteredPaths[filteredPaths.length - 1]);
                } else {
                    setLinkSourceIdentifierValue(no_value_text);
                }
                setLinkSourceIdentifier("");
            }
            setFormErrors({
                ...formErrors,
                link_source_identifier: false,
                link_source_identifier_value: false
            });
        } catch (error) {
            setLinkSourceIdentifier(no_value_text);
            setLinkSourceIdentifierValue(no_value_text);
        }
    }
    /**
     * Get link source identifiers of provider/advertiser.
     * Should be called when ever the provider value change that is when the dropdown value change and when we click the edit button
     * @param {number} providerId
     * @param {boolean} with_extract if true will also perform identifier and value extraction form the url
     * @returns
     */
    function getAdvertiserLinkSources(providerId, with_extract = false) {
        if (!providerId) return;
        Provider.fetchProvider(providerId)
            .then((response) => {
                if (response.success) {
                    const providerData = response.data?.provider;
                    let identifiers = null;
                    if (providerData && providerData.link_source_identifier) {
                        identifiers = providerData.link_source_identifier.split(",");
                    }
                    setAdvertiserLinkSourceIdentifiers(identifiers);
                    if (with_extract) {
                        extractKeyAndValue(providerId, identifiers, true);
                    }
                }
            })
            .catch((error) => {
                console.trace(error);
                toastr.error("Error Fetching Advertiser Link sources");
            });
    }

    /**
     * Function to check if the link source and value that is being used if custom
     * Meaning that if the admin has specifies channel value and identifier other than the ones suggested by the system.
     * Normally the system would suggest the key and value at the end of url and the one one which is also in advertiser link source.
     * The main reason that we need is custom value is so that when the user edits the link we need to check if be need to extract the last or not.
     * This is mainly used to when the user edits a link and we need to check if the values set were custom.
     * @param {string} url
     * @param {string} value
     * @param {string} score
     * @returns {boolean}
     */
    function isCustom(url, value, source) {
        try {
            const parsedURL = new URL(url);
            // first we check if there are any link source identifiers of that advertiser
            if (source) {
                let paramsArray = Array.from(parsedURL.searchParams);
                /* Logic for find is custom in case of advertiser link sources */
                //  remove the [Q] from the link;
                const qIndex = paramsArray.findIndex(
                    (element) => element[1] === queryPlaceHolder
                );
                if (qIndex !== -1) {
                    paramsArray.splice(qIndex, 1);
                }
                // then we find the index of the current value
                const valueIndex = paramsArray.findIndex(
                    (element) => element[1] === value
                );
                //
                if (valueIndex !== -1) {
                    // If the value is present at the end of the array if may infer that no custom value and key were provided
                    // Note that this may not be correctly accurate but this logic is to know if we need to traverse to the last of the link to extract value
                    if (valueIndex === paramsArray.length - 1) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            } else {
                /* Logic for checking if is custom in case of null link source */
                const pathArray = parsedURL.pathname.split("/");
                if (pathArray) {
                    const valueIndex = pathArray.indexOf(value);
                    if (valueIndex !== -1) {
                        if (valueIndex === pathArray.length - 1) {
                            return false;
                        }
                    } else {
                        return true;
                    }
                } else return false;
            }
        } catch (error) {
            console.trace(error);
        }
    }

    useEffect(() => {
        if (pageData) {
            setHistoryTargId(pageData.id);
            setHistoryPage(1);
            setStartFetchHistory(true);
            setFormErrors({ ...inital_form_errors });
            setId(currentPage === SHOW_NEW_CREATION_FORM ? "" : pageData.id);
            setLink(pageData.link);
            setDescription(changeNullToString(pageData.description));
            setLinkSourceIdentifier(pageData?.link_source_identifier || "");
            setLinkSourceIdentifierValue(
                pageData?.link_source_identifier_value || no_value_text
            );
            if (currentPage === SHOW_NEW_CREATION_FORM) {
                setReuseable(true);
            } else {
                setReuseable(pageData?.reusable);
            }
            setDailyCap(
                pageData?.daily_cap
                    ? parseInt(pageData.daily_cap.replaceAll(",", "")) || ""
                    : ""
            );
            changeSelectedPlatform(pageData?.platform?.id);
            changeSelectedSearchEngine(pageData?.search_engine?.id);
            changeSelectedTagType(pageData?.tag_type?.id);
            changeSelectedProvider(pageData?.provider?.id);
            changeSelectedLinkQuality(pageData?.quality_score?.toLowerCase());
            setCreateAt(pageData?.createdAt || "");
            setRemainingCap(pageData?.remaining_cap);
            if (pageData?.provider?.id && pageData?.provider?.id !== "") {
                getAdvertiserLinkSources(pageData?.provider?.id);
            }
            if (
                pageData?.provider?.id !== "" &&
                pageData?.link_source_identifier_value &&
                pageData.link
            ) {
                if (!pageData.link_source_identifier) {
                    setIsCustomLinkSource(false);
                } else {
                    setIsCustomLinkSource(
                        isCustom(
                            pageData.link,
                            pageData.link_source_identifier_value,
                            pageData.link_source_identifier
                        )
                    );
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageData, currentPage]);

    let formValues = {
        linkID: id,
        link: link,
        link_source_identifier,
        link_source_identifier_value,
        searchEngineId: selectedSearchEngine,
        tagTypeId: selectedTagType,
        platformId: selectedPlatform,
        description: description,
        providerId: selectedProvider,
        quality_score: selectedLinkQuality,
        daily_cap: daily_cap,
        advertiser_link_source_identifiers,
        createdAt: createdAt,
        reusable: reusable,
        remainingCap: remainingCap
    };

    let handleChangeFunctions = {
        changeSelectedSearchEngine,
        changeSelectedTagType,
        changeSelectedPlatform,
        changeSelectedProvider,
        changeSelectedLinkQuality,
        setIsCustomLinkSource,
        setReuseable,
        handleIdChange,
        handleLinkSourceIdentifierChange,
        handleLinkSourceIdentifierValue,
        handleDailyCap,
        setLinkSourceIdentifier,
        setLinkSourceIdentifierValue,
        handleLinkChange,
        handleDescriptionChange,
        handleReusable
    };

    const getFormData = () => {
        let formData = {
            provider_id: selectedProvider,
            link: link,
            description: changeEmptyStringToNull(description)
                ? description.trim()
                : null,
            platform_id: selectedPlatform,
            search_engine_id: selectedSearchEngine,
            tag_type_id: selectedTagType,
            link_source_identifier:
                link_source_identifier === "" ||
                    link_source_identifier === no_value_text
                    ? null
                    : link_source_identifier,
            link_source_identifier_value: link_source_identifier_value,
            daily_cap: daily_cap,
            quality_score: selectedLinkQuality,
            reusable: reusable
        };
        if (currentPage === SHOW_EDIT) {
            formData["id"] = pageData.id;
        }
        return formData;
    };

    /**
     * Function to check if the channel value is present in the url
     * Note: This function assumes that the value of channel_value is not ? or ''
     * @param {string} link The url
     * @param {string} channel_value The channel value
     */
    function isChannelValueValid(link, channel_value) {
        try {
            const parsedLink = new URL(link);
            const pathArray = parsedLink.pathname
                .split("/")
                .filter((item) => item !== "");
            const queryValues = Array.from(parsedLink.searchParams)
                .map((item) => item[1])
                .filter((item) => item !== "");
            if (
                pathArray.includes(channel_value) ||
                queryValues.includes(channel_value)
            ) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    const formValidate = (formData) => {
        let isValid = true;
        let currentFormErrors = { ...inital_form_errors };
        // check the no key/value error exists
        // if error exists don't let submit form
        if (formErrors.link_source_identifier_value !== NO_KEY_ERROR_MESSAGE) {
            if (
                formData.link_source_identifier_value === "" ||
                formData.link_source_identifier_value === no_value_text
            ) {
                currentFormErrors.link_source_identifier_value =
                    "Channel Value is Empty/Mismatch";
                isValid = false;
            }
        } else {
            currentFormErrors.link_source_identifier_value = NO_KEY_ERROR_MESSAGE;
            isValid = false;
        }

        if (formErrors.link_source_identifier !== NO_VALUE_ERROR_MESSAGE) {
            if (formData.link_source_identifier === no_value_text) {
                currentFormErrors.link_source_identifier =
                    "Channel Variable is Empty/Mismatch";
                isValid = false;
            }
        } else {
            currentFormErrors.link_source_identifier = NO_VALUE_ERROR_MESSAGE;
            isValid = false;
        }

        // finally if there are no errors for channel identifier and channel value we perform
        // a final check to see the channel value is in the link
        if (
            currentFormErrors.link_source_identifier === false &&
            currentFormErrors.link_source_identifier_value === false
        ) {
            if (
                !isChannelValueValid(
                    formValues.link,
                    formValues.link_source_identifier_value
                )
            ) {
                currentFormErrors.link_source_identifier_value =
                    "Channel value not found in the provided link";
                isValid = false;
            }
        }

        if (!formData.quality_score) {
            currentFormErrors.quality_score = true;
            isValid = false;
        }

        if (!formData.link.includes("[Q]")) {
            isValid = false;
            currentFormErrors.link = true;
        }

        if (validURL(formData.link)) {
            if (
                formData.link.includes("{") ||
                formData.link.includes("}") ||
                formData.link.includes("(") ||
                formData.link.includes(")")
            ) {
                isValid = false;
                currentFormErrors.link = true;
            }
        }

        if (!formData["search_engine_id"]) {
            isValid = false;
            currentFormErrors.searchEngineId = true;
        }
        if (formData["daily_cap"] === "") {
            isValid = false;
            currentFormErrors.daily_cap = "Daily Cap Cannot be set empty";
        } else if ((parseInt(formData["daily_cap"]) || 0) <= 0) {
            isValid = false;
            currentFormErrors.daily_cap = "Daily Cap must be greater then 0";
        }

        if (!formData["platform_id"]) {
            isValid = false;
            currentFormErrors.platformId = true;
        }
        if (!formData["tag_type_id"]) {
            isValid = false;
            currentFormErrors.tagTypeId = true;
        }

        if (!formData["provider_id"]) {
            isValid = false;
            currentFormErrors.providerId = true;
        }

        if (!isValid) {
            setFormErrors(currentFormErrors);
        }

        return [isValid, currentFormErrors];
    };

    async function fetchBackEndIdenticalLinks(providerId, formData) {
        let data = [formData.link];
        let link_id = formData.id ? formData.id : null;

        let hasDuplicatesInBackend = false;
        const duplicateLinkIdsInBackend = [];
        try {
            const identicalLinks = await Provider.getIdenticalLinks(
                providerId,
                {
                    data
                },
                true,
                link_id
            );
            if (identicalLinks.success) {
                if (identicalLinks.data.is_Found_Identical) {
                    hasDuplicatesInBackend = true;
                    const duplicateLinks = identicalLinks.data.links;
                    duplicateLinks.forEach((linkArray) => {
                        let link = linkArray[0];
                        duplicateLinkIdsInBackend.push({
                            linkId: link.id,
                            providerId: link.provider.id,
                            providerName: link.provider.name
                        });
                    });
                }
            } else {
                throw new Error(JSON.stringify(identicalLinks.error));
            }
        } catch (error) {
            console.trace(error.message);
            toastr.error("Oops!", error.message);
        }
        return { hasDuplicatesInBackend, duplicateLinkIdsInBackend };
    }

    async function checkBackEndIdenticalLinks(providerId, formData) {
        const { hasDuplicatesInBackend, duplicateLinkIdsInBackend } =
            await fetchBackEndIdenticalLinks(providerId, formData);
        setBackEndDuplicates({
            hasDuplicatesInBackend,
            duplicateLinkIdsInBackend
        });
        return { hasDuplicatesInBackend };
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmittingForm(true);
        let formData = getFormData();
        let [isValid, newFormErrors] = formValidate(formData);
        if (!isValid) {
            setFormErrors(newFormErrors);
            setSubmittingForm(false);
            toastr.warning("Oops!", "Please fix the errors before saving!");
            return;
        }
        //check back end duplicate links
        const { hasDuplicatesInBackend } = await checkBackEndIdenticalLinks(
            formData.provider_id,
            formData
        );

        // If no duplicate links, submit Links
        if (!hasDuplicatesInBackend) {
            submitLinks(formData);
        } else {
            // If duplicate Links, open confirmation model box
            openDuplicateModal();
        }
    };

    const submitLinks = (formData) => {
        setFormErrors({ ...inital_form_errors });
        Provider.saveProviderLinks(formData.provider_id, formData)
            .then((response) => {
                if (response.success) {
                    getProviderLinks();
                    closeDuplicateModalHandler();
                    showAddButton();
                    toastr.success("Success", "Advertiser link saved!");
                } else {
                    throw new Error(JSON.stringify(response.error));
                }
            })
            .catch((error) => {
                console.trace(error.message);
                closeDuplicateModalHandler();
                try {
                    const parsedMessage = JSON.parse(error.message);
                    if (parsedMessage.message) {
                        toastr.error(parsedMessage.message);
                        // setFormErrors({
                        //   ...inital_form_errors,
                        //   link_source_identifier_value: parsedMessage.message
                        // })
                    } else {
                        toastr.error(error.message);
                    }
                } catch (error) {
                    toastr.error(error.message);
                }
            });

        closeDuplicateModalHandler();
    };

    const closeDuplicateModalHandler = () => {
        setSubmittingForm(false);
        closeDuplicateModal();
    };
    return {
        formValues,
        formErrors,
        handleChangeFunctions,
        submittingForm,
        handleSubmit,
        backEndDuplicates,
        closeDuplicateModal,
        duplicateModalOpen,
        getFormData,
        submitLinks,
        closeDuplicateModalHandler,
        getKeyByValue,
        getValueByKey,
        extractKeyAndValue,
        getAdvertiserLinkSources,
        validate,
        latestHistoryData,
        historyTargId,
        historyPage,
        setHistoryPage,
        historyLimit,
        totalHistoryCount,
        isLatestHistoryLoading,
        setStartFetchHistory,
    };
};
