import React from "react";
// material
import {
  FormControl,
  TextField,
  FormHelperText,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  Tooltip,
  makeStyles
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import HelpIcon from "@material-ui/icons/Help";
import CheckIcon from "@material-ui/icons/Check";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

// import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
//
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import { useProviderLinkBox } from "../logic/providerlinkbox.logic";
import DuplicateWarningModal from "../../ProviderLinks/layouts/DuplicateWarningModal";
import InfoToolTip from "../../../components/InfoToolTip/InfoToolTip";
import ParamInfo from "../../ProviderLinks/layouts/ParamInfo";
import { SHOW_EDIT } from "../logic/formUI.logic";
import { QualityScoreDropDown } from "../../../helpers/constant/quality-score";
import { LAST_SEVEN_DAYS, PER_DAY } from "../../../helpers/constant/filters";
import AdditionalHeaderActions from "../../target/layouts/AdditionalHeaderActions";

const useStyles = makeStyles((theme) => ({
  contentHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

export const ProviderLinkBox = ({
  pageData,
  currentPage,
  showAddButton,
  tagTypes,
  platforms,
  searchEngines,
  providers,
  getProviderLinks
}) => {
  const classes = useStyles();
  const {
    formValues,
    formErrors,
    handleChangeFunctions,
    submittingForm,
    handleSubmit,
    backEndDuplicates,
    duplicateModalOpen,
    getFormData,
    submitLinks,
    closeDuplicateModalHandler,
    getKeyByValue,
    getValueByKey,
    extractKeyAndValue,
    getAdvertiserLinkSources,
    validate,
    latestHistoryData,
    historyTargId,
    historyPage,
    setHistoryPage,
    historyLimit,
    totalHistoryCount,
    isLatestHistoryLoading,
    setStartFetchHistory,
  } = useProviderLinkBox({
    pageData,
    currentPage,
    getProviderLinks,
    showAddButton, 
    platforms,
    searchEngines,
    tagTypes
  });

  const {
    changeSelectedSearchEngine,
    changeSelectedTagType,
    changeSelectedPlatform,
    changeSelectedProvider,
    changeSelectedLinkQuality,
    handleLinkChange,
    handleDescriptionChange,
    handleLinkSourceIdentifierChange,
    handleLinkSourceIdentifierValue,
    handleDailyCap,
    setIsCustomLinkSource,
    handleReusable
  } = handleChangeFunctions;

  return (
    <div className="flex flow-content custom-fieldset provider-link-box">
      <div className={classes.contentHeader}>
        <div className="provider-links__helpers">
          <h4>Supported Dynamic Parameters</h4>
          <InfoToolTip title={<ParamInfo />} className="targeting-option__info">
            <div className="gray-icon ">
              <HelpIcon fontSize="small" />
            </div>
          </InfoToolTip>
          <a
            href={`${window.location.origin}/revenuereport?interval=${PER_DAY}&period=${LAST_SEVEN_DAYS}&group_by=publisher_id-advertiser_id-channel&channel=${formValues.link_source_identifier_value}&advertiser_id=${formValues.providerId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Tooltip title="Revenue Report">
              <MonetizationOnIcon fontSize="small" color="primary" />
            </Tooltip>
          </a>
        </div>
        {latestHistoryData?.length > 0 && (
          <AdditionalHeaderActions 
            activityLog={latestHistoryData} 
            tableType="ProviderLinkLatestHistory"
            historyTargId={historyTargId}
            historyPage={historyPage}
            setHistoryPage={setHistoryPage}
            historyLimit={historyLimit}
            totalHistoryCount={totalHistoryCount}
            isLatestHistoryLoading={isLatestHistoryLoading}
            setStartFetchHistory={setStartFetchHistory} />
        )}
      </div>
      {/* <div className="provider-link-box__advertiser"> */}
      <Grid container spacing={3} alignItems="center">
        <Grid item sm={12} md={1}>
          <FormControl fullWidth>
            <TextField
              label="ID"
              variant="outlined"
              name="link-id"
              type="number"
              value={formValues.linkID}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item sm={12} md={3}>
          <AutoCompleteSelect
            selected={formValues.providerId}
            data={providers}
            handleChange={(event) => {
              changeSelectedProvider(event);
              getAdvertiserLinkSources(event, true);
            }}
            label={"Advertiser"}
            enableMultiple={false}
            cannotSelectDisabled={false}
            withDotsForStatus={true}
            disabled={currentPage === SHOW_EDIT || submittingForm}
          />
          {formErrors?.providerId && (
            <FormHelperText className="error-text">
              Please select a advertiser
            </FormHelperText>
          )}
        </Grid>
        <Grid item sm={12} md={5}>
          <FormControl fullWidth>
            <TextField
              label="Description"
              variant="outlined"
              name="description"
              value={formValues.description}
              onChange={handleDescriptionChange}
              disabled={submittingForm}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item sm={12} md={1}>
          <div className="filter-box__options-checkbox">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formValues.reusable ? true : false}
                  onChange={handleReusable}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  name="cusDomain"
                  disabled={
                    formValues.remainingCap === 0 || formValues.linkID === ""
                      ? true
                      : false
                  }
                />
              }
              style={{ whiteSpace: "nowrap" }}
              label="Ready to use"
            />
          </div>
        </Grid>
        <Grid item sm={12} md={2}>
          <span style={{ color: "#949494" }}>{formValues.createdAt || ""}</span>
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ padding: "10px 0 5px 0" }}>
        <Grid item sm={12} md={12} lg={7}>
          <FormControl fullWidth>
            <TextField
              label="Link"
              variant="outlined"
              name="link"
              value={formValues.link}
              onChange={handleLinkChange}
              disabled={submittingForm}
              onBlur={() =>
                extractKeyAndValue(
                  formValues.providerId,
                  formValues.advertiser_link_source_identifiers
                )
              }
            />
            {formErrors?.link && (
              <FormHelperText className="error-text">
                Please make sure the link is valid, includes [Q] and uses only [
                ] Brackets.
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={12} md={12} lg={5}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6} lg={6}>
              {/* k-text-field */}
              <FormControl fullWidth>
                <TextField
                  label="Channel Variable"
                  variant="outlined"
                  name="link-source-identifier"
                  type="string"
                  value={formValues.link_source_identifier}
                  onChange={handleLinkSourceIdentifierChange}
                  onKeyDown={() => setIsCustomLinkSource(true)}
                  onBlur={validate(getValueByKey)}
                  disabled={
                    !formValues.providerId ||
                    !formValues?.advertiser_link_source_identifiers ||
                    submittingForm ||
                    formValues.link?.trim() === ""
                  }
                />
                {formErrors.link_source_identifier && (
                  <FormHelperText className="error-text">
                    {" "}
                    {formErrors.link_source_identifier}{" "}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <FormControl fullWidth>
                <TextField
                  label="Channel Value"
                  variant="outlined"
                  name="channel-value"
                  type="string"
                  value={formValues.link_source_identifier_value}
                  onChange={handleLinkSourceIdentifierValue}
                  onKeyDown={() => setIsCustomLinkSource(true)}
                  onBlur={validate(getKeyByValue)}
                  disabled={
                    !formValues.providerId ||
                    !formValues?.advertiser_link_source_identifiers ||
                    submittingForm ||
                    formValues.link?.trim() === ""
                  }
                />
                {formErrors.link_source_identifier_value && (
                  <FormHelperText className="error-text">
                    {" "}
                    {formErrors.link_source_identifier_value}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={12} md={12} lg={10}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6} lg={3}>
              <AutoCompleteSelect
                selected={formValues.searchEngineId}
                data={searchEngines}
                handleChange={changeSelectedSearchEngine}
                label={"Search Engine"}
                enableMultiple={false}
                cannotSelectDisabled={false}
                disabled={submittingForm}
              />
              {formErrors?.searchEngineId && (
                <FormHelperText className="error-text">
                  Please make sure to select the search engine
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <AutoCompleteSelect
                selected={formValues.tagTypeId}
                data={tagTypes}
                handleChange={changeSelectedTagType}
                label={"Link Types"}
                enableMultiple={false}
                cannotSelectDisabled={false}
                disabled={submittingForm}
              />
              {formErrors?.tagTypeId && (
                <FormHelperText className="error-text">
                  Please make sure to select the Link Type
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <AutoCompleteSelect
                selected={formValues.platformId}
                data={platforms}
                handleChange={changeSelectedPlatform}
                label={"Platforms"}
                enableMultiple={false}
                cannotSelectDisabled={false}
                disabled={submittingForm}
              />
              {formErrors?.platformId && (
                <FormHelperText className="error-text">
                  Please make sure to select the Platform
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <AutoCompleteSelect
                selected={formValues.quality_score}
                data={QualityScoreDropDown}
                handleChange={changeSelectedLinkQuality}
                label={"Quality Score"}
                enableMultiple={false}
                cannotSelectDisabled={false}
                disabled={submittingForm}
                showValueInLabel={false}
              />
              {formErrors.quality_score && (
                <FormHelperText className="error-text">
                  {" "}
                  Please make sure to select the Quality Score
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={12} lg={2}>
          <Grid item sm={12} md={12}>
            <FormControl fullWidth>
              <TextField
                label="Daily Cap"
                variant="outlined"
                name="daily-cap"
                type="number"
                value={formValues.daily_cap}
                onChange={handleDailyCap}
              />
              {formErrors?.daily_cap && (
                <FormHelperText className="error-text">
                  {" "}
                  {formErrors.daily_cap}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <div className="provider-link-box__buttons">
        {/* k-btn-save */}
        <Button
          color="primary"
          variant="contained"
          size="medium"
          startIcon={<CheckIcon />}
          type="submit"
          onClick={handleSubmit}
          disabled={submittingForm}
        >
          Save
        </Button>
        <Button
          onClick={showAddButton}
          variant="contained"
          size="medium"
          color="secondary"
          startIcon={<ClearIcon />}
        >
          Cancel
        </Button>
      </div>
      {duplicateModalOpen && (
        <DuplicateWarningModal
          frontEndDuplicates={[]}
          backEndDuplicates={backEndDuplicates}
          denyAction={closeDuplicateModalHandler}
          confirmAction={() => {
            submitLinks(getFormData());
          }}
        />
      )}
    </div>
  );
};

export default ProviderLinkBox;
