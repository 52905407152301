import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Table from "../../../components/Table/Table";
import { useDomainTableLogic } from "../logic/domainTable.logic";
import { useFetchDomain } from "../logic/fetchDomain.logic";

const DomainTable = ({ openEditForm }) => {
  const {
    loadingDomainData,
    domainData,
    page,
    setPage,
    size,
    setSize,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    total,
    getDomains
  } = useFetchDomain();

  const {
    headers,
    actions,
    confirmDelete,
    setConfirmDelete,
    handleDelete,
    isLoading
  } = useDomainTableLogic(openEditForm, getDomains);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className="flow-content">
        {domainData && !isLoading && (
          <Table
            headers={headers}
            actions={actions}
            rowValues={domainData}
            isLoading={loadingDomainData.isLoading}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            total={total}
            rowsPerPageOptions={[10, 20, 30, 50, 100, 200]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            tableType="domains"
            noIds={false}
            noCheckboxes={true}
          />
        )}
      </div>
      {confirmDelete && (
        <ConfirmBox
          message={"Are you sure you want to delete this domain?"}
          confirmAction={() => handleDelete(confirmDelete)}
          denyAction={() => setConfirmDelete(null)}
        />
      )}
    </div>
  );
};

export default DomainTable;
