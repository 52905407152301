import api from "../api";

class Skype {
  removeConversationId = async (data) => {
    const { body } = await api.put('api/remove-conversation-id', data);
    return body;
  };
}

// eslint-disable-next-line
export default new Skype();