import Api from "../api";

class Setting {
  fetchSettings = async (params = {}) => {
    const { body } = await Api.get(`settings`, null, params);
    return body;
  };

  fetchSettingsByKey = async (key) => {
    const { body } = await Api.get(`setting/key/${key}`);
    return body;
  };

  saveSetting = async (data) => {
    const { body } = await Api.post(`setting`, data);
    return body;
  };

  updateSetting = async (data) => {
    const { body } = await Api.post(`setting`, data);
    return body;
  };

  deleteSetting = async (id) => {
    const { body } = await Api.destroy(`setting/${id}`);
    return body;
  };
}
// eslint-disable-next-line
export default new Setting();
