import React from "react";
import { useSearchEngineFormLogic } from "../logic/searchEngineForm.logic";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/CloseSharp";
import Button from "@material-ui/core/Button";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

const SearchEngineForm = ({ searchEngineId = "", openTable }) => {
    const {
        formValues,
        loadingSearchEngineData,
        handleFormValueChange,
        handleSubmit,
        handleCancel,
        isSubmitting,
        editing,
        errorValues
    } = useSearchEngineFormLogic(searchEngineId, openTable);

    if (loadingSearchEngineData.isLoading) {
        return (
            <div className="loading-div">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div className="default-values__form">
            <h5>
                {editing.isEditing ? `Editing Search Engine` : "Adding Search Engine"}
            </h5>
            <form className="flow-content" onSubmit={handleSubmit}>
                <div>
                    <FormControl fullWidth>
                        <TextField
                            variant="outlined"
                            label="Search Engine Name"
                            value={formValues.name}
                            onChange={handleFormValueChange}
                            placeholder={`Enter Search Engine Name`}
                            name="name"
                        />
                    </FormControl>
                    {errorValues.name && (
                        <div className="error-text">Please provide search engine name</div>
                    )}
                </div>
                <div>
                    <FormControl fullWidth>
                        <TextField
                            label="Details / Description"
                            multiline
                            minRows={4}
                            variant="outlined"
                            name="details"
                            value={formValues.details}
                            onChange={handleFormValueChange}
                            fullWidth
                        />
                    </FormControl>
                </div>
                <div className="default-values__form__buttons">
                    <Button
                        color="primary"
                        variant="contained"
                        size="medium"
                        startIcon={<CheckIcon />}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCancel}
                        startIcon={<CloseIcon />}
                    >
                        Cancel
                    </Button>
                </div>
            </form>
        </div>
    );
};

SearchEngineForm.propTypes = {
    searchEngineId: PropTypes.any.isRequired
};

export default SearchEngineForm;
