/**
 * Component that maps form values from redux and returns it for use in rule section
 */
export const useGetFormValues = ({
  targetingRule,
  targetingRuleError,
  linkStatus
}) => {
  let dailyCap = targetingRule?.daily_cap;
  let revenueSharePercentage = targetingRule?.revenue_share_percentage;
  let comment = targetingRule?.comment || "";
  let dailyFrequency = "";
  if (targetingRule) {
    if (
      targetingRule?.daily_frequency !== null ||
      targetingRule?.daily_frequency !== undefined
    ) {
      dailyFrequency = targetingRule?.daily_frequency;
    }
  }

  let disabled = targetingRule?.disabled || false;
  let trafficOne = targetingRule?.provider_details?.[0]?.traffic || "";
  let trafficTwo = targetingRule?.provider_details?.[1]?.traffic || 0;

  let providerOne = targetingRule?.provider_details?.[0]?.provider_id || "";
  let providerTwo = targetingRule?.provider_details?.[1]?.provider_id || "";
  let providerOneLink = targetingRule.group
    ? targetingRule?.provider_details?.[0]?.advertiser_link_group_id
    : targetingRule?.provider_details?.[0]?.provider_link || "";
  let providerTwoLink =
    targetingRule?.provider_details?.[1]?.provider_link || "";
  let rule_id = targetingRule?.id || "";

  //defining form error values
  let dailyCapError = targetingRuleError?.dailyCap || false;
  let trafficOneError = targetingRuleError?.trafficOne || false;
  let trafficTwoError = targetingRuleError?.trafficTwo || false;
  let providerOneError = targetingRuleError?.providerOne || false;
  let providerTwoError = targetingRuleError?.providerTwo || false;
  let providerOneLinkError = targetingRuleError?.providerOneLink || false;
  let providerTwoLinkError = targetingRuleError?.providerTwoLink || false;
  let dailyFrequencyError =
    (targetingRuleError?.dailyFrequency && !targetingRule.disabled) || false;
  let revenueSharePercentageError =
    targetingRuleError?.revenue_share_percentage || false;

  const formValues = {
    dailyCap,
    revenueSharePercentage,
    comment,
    dailyFrequency,
    ...{
      disabled: !linkStatus ? true : disabled
    },
    trafficOne,
    trafficTwo,
    providerOne,
    providerTwo,
    providerOneLink,
    providerTwoLink,
    rule_id
  };

  const formErrors = {
    dailyCapError,
    trafficOneError,
    trafficTwoError,
    providerOneError,
    providerTwoError,
    providerOneLinkError,
    providerTwoLinkError,
    dailyFrequencyError,
    revenueSharePercentageError
  };

  return {
    formValues,
    formErrors
  };
};
