import {useState, useEffect} from "react";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { Provider } from "../../../services/Provider"

export const useFetchRelatedLinkStats = (ruleIds) => {
    const [relatedLinkStat, setRelatedLinkStat] = useState([]);
    const [loadingLinkStats, setRelatedLinkLoading] = useState(false);

    const isMounted = useIsMounted();

    async function getRelatedLinkStats(){
        try {
            setRelatedLinkLoading({ isLoading: true, error: false});
            const resStats = await Provider.getRelatedLinkStats(
              ruleIds,
            );
            if(resStats && resStats?.stats && isMounted()){
                setRelatedLinkStat(resStats.stats);
            }else{
                setRelatedLinkStat([]);
            }
            setRelatedLinkLoading({isLoading: false, error: false});
        } catch (error) {
            if(isMounted()){
                setRelatedLinkStat([]);
                setRelatedLinkLoading({isLoading: false, error: true});
            }
         console.trace(error) ;
        }
    }

    useEffect(()=>{
        getRelatedLinkStats();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        loadingLinkStats,
        relatedLinkStat
    }
}
