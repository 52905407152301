import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";

import DaysAndHours from '../DaysAndHours/DaysAndHours';

/**
 * Styling for accordion
 */
const useStyles = makeStyles((theme) => ({
    textField: {
        marginTop: "10px",
    },
    sectionTop: {
        marginTop: "15px"
    },
    errorText: {
        color: 'red'
    },
    error: {
        borderBottom: "1px solid red"
    }
}));

const dialogModalStyle = {
    style: {
        maxWidth: 'lg',
        width: '100%',
    }   
}

export default function FormDialog({
    hasTitle = false,
    title,
    hasMessage = true,
    message,
    handleClose,
    open = false,
    handleSubmit,
    actionValue = null,
    validationErrors = {}
}) {
    const classes = useStyles();
    const [notes, setNotesValue] = useState("");
    const [dateTime, setDateTime] = useState("");

    const handleSearchButton = () => {
        let payload = {};
        if (notes) payload.notes = notes;
        if (dateTime) payload.snoozed_until = dateTime;
        handleSubmit(payload);
    };

    return (
        <div>
            <Dialog 
                open={open} 
                onClose={handleClose} 
                aria-labelledby="form-dialog-title" 
                PaperProps={dialogModalStyle}
            >
                {
                    hasTitle && 
                    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                }
                <DialogContent >
                    {hasMessage && 
                        <DialogContentText>
                            {message}
                        </DialogContentText>
                    }
                    <div>
                        <TextField
                            variant="standard"
                            margin="normal"
                            label="Notes"
                            onChange={(e) => {
                                setNotesValue(e.target.value);
                            }}
                            value={notes}
                            fullWidth
                            className={`${classes.textField} ${validationErrors?.notes && classes.error}`}
                            required
                        />
                    </div>
                    {
                        validationErrors?.notes && 
                        <span className={classes.errorText}><small>{validationErrors.notes}</small></span>
                    }
                    {
                        actionValue === "snoozed" && (
                            <div className={classes.sectionTop}>
                                <DaysAndHours label="Snooze until" setDateTime={setDateTime} required={true} className={validationErrors?.snoozed_until && classes.error}/>
                                { validationErrors?.snoozed_until && 
                                    <span className={classes.errorText}><small>{validationErrors.snoozed_until}</small></span> }
                            </div>
                        )   
                    }
                </DialogContent>
                <DialogActions className="confirm-box__buttons">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearchButton}
                    >
                        Proceed
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
