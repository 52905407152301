import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { InputLabel, MenuItem } from "@material-ui/core";

const UserManagementFilterBox = ({
  active,
  inActive,
  setSearchValue,
  selectbox = false,
  setActive,
  setInActive,
  archive = false,
  setSelected,
  handleChangeSelcetBox
}) => {
  const [query, setQuery] = useState("");

  const handleSearchButton = () => {
    setSearchValue(query);
    setSelected([]);
  };

  const handleChange = (type) => {
    if (type === "active") {
      if (!active) setInActive(false);
      setActive(!active);
    } else {
      if (!inActive) setActive(false);
      setInActive(!inActive);
    }
  };

  const handleClearAll = () => {
    setQuery("");
    setActive(false);
    setInActive(false);
    setSearchValue("");
  };

  return (
    <div className="filter-box__container">
      <div className="filter-box">
        <div className="filter-box__controls">
          <div className="filter-box__searchbar">
            {/* <h4>Search Text</h4> */}
            <div className="filter-box__searchField">
              <TextField
                variant="standard"
                margin="normal"
                label="Type to search ..."
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                onKeyPress={(e) => {
                  e.key === "Enter" && handleSearchButton();
                }}
                value={query}
                fullWidth
              />
            </div>
          </div>
          <div className="filter-box__options">
            {!archive && (
              <>
                <h4>Filter By Status</h4>
                <div className="filter-box__options-checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={active}
                        onChange={() => handleChange("active")}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="cusDomain"
                      />
                    }
                    label="Active"
                  />
                </div>
                <div className="filter-box__options-checkbox">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={inActive}
                        onChange={() => handleChange("in-active")}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="cusDomain"
                      />
                    }
                    label="In-active"
                  />
                </div>
              </>
            )}
          </div>

          <div className="filter-selectbox">
            {selectbox && (
              <div className="userselctbox">
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ margin: "10px 0px" }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Account
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Select Account"
                    name="provider_id"
                    value={handleChangeSelcetBox?.value}
                    onChange={handleChangeSelcetBox?.onChange}
                  >
                    <MenuItem>None</MenuItem>
                    {handleChangeSelcetBox.data.map((d1, i) => (
                      <MenuItem key={i} value={d1.id}>
                        {d1.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </div>
        </div>
        <div className="filter-box__button">
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleSearchButton}
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleClearAll}
            startIcon={<ClearAllIcon />}
          >
            Clear All
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserManagementFilterBox;
