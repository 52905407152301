import SimpleModal from "../../../components/SimpleModal/SimpleModal";

const ConnectedAdvertiserLinksModal = ({ targetLinkId = null, providerLinkObj, closeAction }) => {
  return (
    <>
      <SimpleModal handleClose={closeAction}>
        <div className="related-connected-box flow-content">
          <h2 className="related-connected-box__heading">
            Advertiser Links list
            {targetLinkId && (<span> (Pub Link ID: {targetLinkId}) </span>)}
          </h2>
          <div>
            {
              providerLinkObj?.length > 0 && providerLinkObj.map((el, index) => (
                <div
                  className={`connected-ad-box flow-content--small white-border-box`}
                  key={index}
                  onClick={() => window.open(`/advertisers/links?edit=true&provider_link_id=${el?.provider_link_id}`, "_blank")}
                >
                  <div className="connected-ad-box__nuetral">Advertiser: {el?.provider_name}</div>
                  <div className="connected-ad-box__nuetral">Advertiser Link Id: {el?.provider_link_id}</div>
                  <div className="connected-ad-box__nuetral">Advertiser Link: {el?.provider_link}</div>
                  <div className="connected-ad-box__nuetral">Rule status: {el?.rule_disabled ? 'disabled' : 'active'}</div>
                </div>
              ))
            }
          </div>
        </div>
      </SimpleModal>
    </>
  )
}

export default ConnectedAdvertiserLinksModal;
