import React from "react";

const LinkInfo = ({ link }) => {
  return (
    <div className="dynamic-parameter-info" style={{ width: "600px" }}>
      <p className="dynamic-parameter-info__text">
        <span>Advertiser:</span>{" "}
        <span>
          {link.group ? "NO ADVERTISER" : link?.provider?.name || "No data"}
        </span>
      </p>
      <p className="dynamic-parameter-info__text">
        <span>Quality score:</span>{" "}
        <span>
          {link.group
            ? link.quality_scores.join(",").toUpperCase()
            : link?.quality_score?.toUpperCase() || "No data"}
        </span>
      </p>
      <p className="dynamic-parameter-info__text">
        <span>Platform:</span>{" "}
        <span>
          {link.group ? link.platforms.join(",") : link?.platform?.name}
        </span>
      </p>
      <p className="dynamic-parameter-info__text">
        <span>Search engine:</span>{" "}
        <span>
          {link.group
            ? link.search_engines.join(",")
            : link?.search_engine?.name || "No data"}
        </span>
      </p>

      <p className="dynamic-parameter-info__text">
        <span>Tag type:</span>{" "}
        <span>
          {link.group
            ? link.tag_types.join(",")
            : link?.tag_type?.name || "No data"}
        </span>
      </p>

      <p className="dynamic-parameter-info__text">
        <span>Remaining Cap:</span>{" "}
        <span>
          {`${link?.remaining_cap} / ${link?.daily_cap}` || "No data"}
        </span>
      </p>
    </div>
  );
};

export default LinkInfo;
