import { useCallback, useEffect, useRef, useState } from "react";
import Table from "../../components/Table/Table";
import { Advisor } from "../../services/Advisor";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { useDispatch, useSelector } from "react-redux";
import { getAdvisorPartner, getAdvisorStatus, getAdvisors } from "../../reducers/advisor/advisor.selector";
import { updateAdvisors, updateAdvisorsPartner, updateAdvisorsStatus } from "../../reducers/advisor/advisor.action";
import SimpleAccordion from "../../components/Accordion/Accordion";
import AdvisorFilterBox from "./layouts/AdvisorFilterBox";
import { textFieldValid } from "../../helpers/validation";
import FormDialog from "../../components/FormDialog/FormDialog";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import { toastr } from "react-redux-toastr";
import moment from 'moment';

const Index = () => {
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, [dispatch]);

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(50);
    const [orderBy, setOrderBy] = useState("id");
    const [orderDirection, setOrderDirection] = useState("DESC"); //ASC and DESC
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const prevPage = useRef(1); //!!IMP : SET IT SAME AS DEFAULT VALUE FOR PAGE STATE
    const advisors = useSelector(getAdvisors);
    const [actionStatus, setActionStatus] = useState(false);
    const [errors, setErrors] = useState({});
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [payload , setPayload] = useState({});

    // filters
    const statusFilterList = useSelector(getAdvisorStatus);
    const partnerFilterList = useSelector(getAdvisorPartner);
    const [advisorStatus, setAdvisorStatus] = useState("active");
    const [advisorPartner, setAdvisorPartner] = useState("");
    const [searchValue, setSearchValue] = useState("");

    // actions
    const [actionDialogObject, setActionDialogObject] = useState({});

    const icon = <AssignmentIndIcon fontSize="inherit" color="primary" />
  
    const headers = [
        { title: "ID", value: "id" },
        { title: "Partner", value: "partner" },
        { title: "Description", value: "description", disableOrderBy: true },
        { title: "Status", value: "status", disableOrderBy: true },
        { title: "Last updated", value: "updatedAt", disableOrderBy: true },
    ];

    const handleAction = (dataId, data, status) => {
        const actionObject = { id: dataId, status };
        setActionDialogObject(actionObject);
        setActionStatus(true);
    }

    const actions = [
        {
          name: "active",
          action: handleAction,
          icon: "activeIcon"
        },
        {
          name: "snoozed",
          action: handleAction,
          icon: "snoozeIcon"
        },
        {
            name: "closed",
            action: handleAction,
            icon: "closeIcon"
        },
    ];

    const getRequestParams = useCallback(() => {
        let params = {};
    
        if (textFieldValid(searchValue)) {
            params["q"] = searchValue.trim();
        }

        if (advisorStatus !== "") {
            params['status'] = advisorStatus;
        }

        if (advisorPartner !== "") {
            params['partner'] = advisorPartner;
        }

        params["page"] = page;
        params["size"] = size;
        params["order_by"] = orderBy;
        params["order_direction"] = orderDirection;
    
        return params;
      }, [
        searchValue,
        advisorStatus,
        orderBy,
        orderDirection,
        page,
        size,
        advisorPartner,
    ]);

    const fetchAdvisors = useCallback(
        (params = {}) => {
            setLoading(true);
            Advisor.fetchAdvisorList(params)
            .then((response) => {
                setLoading(false);
                if (response.success) {
                    let advisorList = response.data?.data;
                    stableDispatch(updateAdvisors(advisorList));
                    setTotal(response.data.total);

                    const statusList = [];
                    if (response.data?.statusFilter?.length > 0) {
                        for (let p of response.data.statusFilter) {
                            let label = p.charAt(0).toUpperCase() + p.slice(1);
                            statusList.push({ label, value: p });
                        }
                        stableDispatch(updateAdvisorsStatus(statusList));
                    }

                    const partnerList = [];
                    if (response.data?.partnerFilter?.length > 0) {
                        for (let p of response.data.partnerFilter) {
                            if (p && typeof p !== undefined) {
                                let label = p.charAt(0).toUpperCase() + p.slice(1);
                                partnerList.push({ label, value: p });
                            }
                        }
                        stableDispatch(updateAdvisorsPartner(partnerList));
                    }
                } else {
                    throw new Error(JSON.stringify(response.error));
                }
            })
            .catch((err) => {
                setLoading(false);
                toastr.error("Oops!", err.message);
                console.trace(err.message);
            });
        }, [stableDispatch]
    );

    useEffect(() => {
        fetchAdvisors(getRequestParams());
        if (page !== prevPage.current) {
          prevPage.current = page;
        }
    }, [fetchAdvisors,
        getRequestParams, 
        size,
        orderBy,
        orderDirection,
        page
    ]);

    const validateActionPayload = (payload) => {
        let success = true;
        let errors = {};
        if (!payload.notes) {
            errors.notes = "Notes field is required";
            success = false;
        }

        if (actionDialogObject.status === "snoozed") {
            if (!payload.snoozed_until) {
                errors.snoozed_until = "Snooze until field is required";
                success = false;
            } else if (payload.snoozed_until <= new Date(moment().format("YYYY-MM-DDTHH:mm")).toISOString()) {
                errors.snoozed_until = "Snooze date time must be greater than current date time";
                success = false;
            }
        }
        setErrors(errors);

        // to auto remove validation message after 5 seconds
        setTimeout(function () {
            setErrors({});
        }, 5000)

        return success;
    }

    const performSnoozedAction = () => {
        setLoading(true);
        Advisor.updateSnoozedStatus(actionDialogObject.id, payload)
        .then((response) => {
            setLoading(false);
            if (response.success) {
                toastr.success("Success", response.msg);
                fetchAdvisors(getRequestParams());
            } else {
                throw new Error(JSON.stringify(response.error));
            }
        })
        .catch((err) => {
            setLoading(false);
            toastr.error("Oops!", err.message);
            console.trace(err.response);
        });
    }

    const performClosedAction = () => {
        setLoading(true);
        Advisor.updateClosedStatus(actionDialogObject.id, payload)
        .then((response) => {
            setLoading(false);
            if (response.success) {
                toastr.success("Success", response.msg);
                fetchAdvisors(getRequestParams());
            } else {
                throw new Error(JSON.stringify(response.error));
            }
        })
        .catch((err) => {
            setLoading(false);
            toastr.error("Oops!", err.message);
            console.trace(err.response);
        });
    }

    const performAction = () => {
        switch (actionDialogObject.status) {
            case "snoozed":
                performSnoozedAction();
                break;

            case "closed":
                performClosedAction();
                break;
        
            default:
                break;
        }
    }

    const processAction = (payload) => {
        if (validateActionPayload(payload)) {
            setConfirmDialog(true);
            setPayload(payload);
        }
    };


    const handleDialogClose = () => {
        setActionStatus(false);
        setConfirmDialog(false);
        setErrors({});
    };

    // modal
    const actionModel = (
        <FormDialog
            message={`Perform action "${actionDialogObject.status}" to the advice ID ${actionDialogObject.id}.`}
            open={actionStatus}
            handleClose={handleDialogClose}
            handleSubmit={processAction}
            actionValue={actionDialogObject.status}
            validationErrors={errors}
        />
    );

    const confirmActionModal = (
        <ConfirmBox
          message={`Are you sure you want to perform this action?`}
          denyAction={handleDialogClose}
          confirmAction={() => {
            performAction();
            handleDialogClose();
          }}
        ></ConfirmBox>
    );

    return (
        <div className="advisors-container">
            <div className="advisors-container__header">
                <ContentHeader icon={icon} title="Advisor List" />
            </div>
            <div className="flow-content advisors-filter__root">
                <SimpleAccordion 
                    header="Filters" 
                    key="advisor-filterbox"
                    defaultExpanded={true}
                >
                    <AdvisorFilterBox
                        setSearchValue={setSearchValue}
                        advisorStatus={advisorStatus}
                        setAdvisorStatus={setAdvisorStatus}
                        statusFilterList={statusFilterList}
                        affirmitiveText="Search"
                        dessentingText="Reset Filters"
                        partnerFilterList={partnerFilterList}
                        advisorPartner={advisorPartner}
                        setAdvisorPartner={setAdvisorPartner}
                        setOrderBy={setOrderBy}
                        setOrderDirection={setOrderDirection}
                    />
                </SimpleAccordion>
            </div>
            {advisors && 
                <Table
                    headers={headers}
                    rowValues={advisors}
                    tableType='advisors'
                    actions={actions}
                    page={page}
                    setPage={setPage}
                    size={size}
                    setSize={setSize}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    orderDirection={orderDirection}
                    setOrderDirection={setOrderDirection}
                    total={total}
                    noCheckboxes={true}
                    isLoading={loading}
                    rowsPerPageOptions={[10, 20, 50, 100, 150, 200]}
                />
            }
            {actionStatus && actionModel}
            {confirmDialog && confirmActionModal}
        </div>
    );
}

export default Index;
