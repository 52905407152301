import api from "../api";

class Advisor {
    fetchAdvisorList = async (params = {}) => {
        const { body } = await api.get('advisors', null, params);
        return body;
    };
    updateSnoozedStatus = async (id, data) => {
        const { body } = await api.post(
            `advisors/snooze/${id}`,
            data
        );
        return body;
    };
    updateClosedStatus = async (id, data) => {
        const { body } = await api.post(
            `advisors/close/${id}`,
            data
        );
        return body;
    };
}
// eslint-disable-next-line
export default new Advisor();

