import React from "react";
import TagStats from "./TagStats";
import ZapButtons from "./ZapButtons";
import AdditionalHeaderActions from "./AdditionalHeaderActions";

const FormAddtionalHeaders = ({ 
  targetingID, 
  publisherChannelStats, 
  activityLog, 
  historyPage, 
  setHistoryPage, 
  historyLimit, 
  totalHistoryCount,
  isLatestHistoryLoading,
  setStartFetchHistory,
  }) => {
  return (
    <>
      <ZapButtons targetingID={targetingID} />
      {activityLog && 
        <AdditionalHeaderActions 
        activityLog={activityLog} 
        publisherChannelStats={publisherChannelStats} 
        tableType="PublisherLatestHistory"
        historyTargId={targetingID}
        historyPage={historyPage}
        setHistoryPage={setHistoryPage}
        historyLimit={historyLimit}
        totalHistoryCount={totalHistoryCount}
        isLatestHistoryLoading={isLatestHistoryLoading}
        setStartFetchHistory={setStartFetchHistory} />}
      <TagStats publisherChannelStats={publisherChannelStats} />
    </>
  );
};

export default FormAddtionalHeaders;
