import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";

import { FormControl, TextField } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/CloseSharp";
import Button from "@material-ui/core/Button";

import { useFormTouched } from "../../../hooks/useFormTouched";
import { changeNullToString } from "../../../helpers";

const FormLayout = ({ emaildata, onCancel, onSubmit }) => {
    const DEFAULT_ALERTS = "DEFAULT ALERT EMAILS";
    const [email, setEmail] = useState("");
    const [showPrompt, setShowPrompt] = useState(true);
    const [fieldErrors, setFieldErrors] = useState({
        email: false,
        message: ""
    });
    const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
        cleanupFunction: () => { }
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowPrompt(false);
        if (validateForm()) {
            clearFormTouched();
            onSubmit({
                email: email,
                type: DEFAULT_ALERTS
            });
        }
    };

    useEffect(() => {
        setEmail(changeNullToString(emaildata));
    }, [emaildata]);
    const validateForm = function () {
        setFieldErrors({});
        if (email.length <= 0 && email === "") {
            setFieldErrors({
                ...fieldErrors,
                email: true,
                message: `Please enter valid email`
            });
            return false;
        }
        if (email.length > 0) {
            let splitemail = email.split(",");
            if (splitemail.length > 3) {
                setFieldErrors({
                    ...fieldErrors,
                    email: true,
                    message: `Emails should not be more than 3`
                });
                return false;
            }
            for (let i = 0; i < splitemail.length; i++) {
                if (splitemail[i].length > 0) {
                    // eslint-disable-next-line
                    let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                    if (!regEmail.test(splitemail[i].trim())) {
                        setFieldErrors({
                            ...fieldErrors,
                            email: true,
                            message: `Please enter valid email`
                        });
                        return false;
                    }
                }
            }
        }
        return true;
    };
    return (
        <div className="default-values__form">
            <h5>Configuration Of Emails</h5>
            <div>
                <form className="flow-content">
                    <div>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label="Set email"
                                value={email || ""}
                                onChange={(e) => {
                                    handleFormTouched();
                                    setEmail(e.target.value);
                                    setFieldErrors({
                                        ...fieldErrors,
                                        email: false,
                                        message: ""
                                    });
                                }}
                                placeholder="Set emails for all type of alerts"
                                type="text"
                                name="email"
                            />
                            {fieldErrors.email && (
                                <div className="error-text">{fieldErrors.message}</div>
                            )}
                        </FormControl>
                    </div>
                    <div className="default-values__form__buttons">
                        <Button
                            color="primary"
                            variant="contained"
                            size="medium"
                            onClick={handleSubmit}
                            startIcon={<CheckIcon />}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={onCancel}
                            startIcon={<CloseIcon />}
                        >
                            cancel
                        </Button>
                    </div>
                </form>
                <Prompt
                    when={showPrompt && formTouched.current}
                    message="Are you sure you want to leave?"
                />
            </div>
        </div>
    );
};
export default FormLayout;
