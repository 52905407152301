/**
 *  Custom Hook to download admin reports
 */
import moment from "moment";
import { Report } from "../../../../services/Report";
import { toastr } from "react-redux-toastr";
import { CUSTOM } from "../../../../helpers/constant/filters";
import { useRevColHeadersContext } from "../../../../contexts/revenue-context";

export const useReportDownload = ({ filterValues }) => {
  const [colHeaders] = useRevColHeadersContext();
  let tableHeaders = colHeaders.tableHeaders;

  function getParams() {
    let params = {};
    const {
      selectedCountries,
      selectedInterval,
      selectedGroupBy,
      fromDate,
      toDate,
      selectedTagTypes,
      selectedPlatforms,
      selectedSearchEngines,
      selectedPeriod,
      orderBy,
      orderDirection
    } = filterValues;

    if (selectedCountries?.length > 0) {
      let selection = [];
      for (let i in selectedCountries) {
        selection.push(selectedCountries[i]);
      }
      params["countries"] = selection;
    }

    if (selectedInterval) {
      params["aggregateBy"] = selectedInterval;
    }

    if (selectedGroupBy?.length > 0) {
      let selection = [];
      for (let i in selectedGroupBy) {
        selection.push(selectedGroupBy[i]);
      }
      params["groupByKeys"] = selection;
    }

    if (selectedTagTypes?.length > 0) {
      let selection = [];
      for (let i in selectedTagTypes) {
        selection.push(selectedTagTypes[i]);
      }
      params["tagTypes"] = selection;
    }

    if (selectedPlatforms?.length > 0) {
      let selection = [];
      for (let i in selectedPlatforms) {
        selection.push(selectedPlatforms[i]);
      }
      params["platforms"] = selection;
    }

    if (selectedSearchEngines?.length > 0) {
      let selection = [];
      for (let i in selectedSearchEngines) {
        selection.push(selectedSearchEngines[i]);
      }
      params["searchEngines"] = selection;
    }

    if (selectedPeriod !== CUSTOM) {
      if (selectedPeriod) params[selectedPeriod] = true;
    } else {
      if (fromDate && moment(fromDate).isValid()) {
        params["fromDate"] = moment(fromDate).format("YYYY-MM-DD");
      }
      if (toDate && moment(toDate).isValid()) {
        params["toDate"] = moment(toDate).format("YYYY-MM-DD");
      }
    }

    if (orderBy) {
      let modified_order_by = orderBy;
      const key_mapping = {
        geo: "country",
        advertiser_id: "provider_id",
        channel: "targeting_id",
        click: "clicks",
        gross_revenue: "shared_revenue",
        pub_revenue: "publisher_revenue",
        server_search_counts: "server_search_count",
        total_searches: "adv_search_count",
        server_searches_rpm: "ssrpm"
      };
      const old_keys = Object.keys(key_mapping);
      if (old_keys.includes(orderBy)) {
        modified_order_by = key_mapping[orderBy];
      }
      params["orderBy"] = modified_order_by;
      params["orderDirection"] = orderDirection;
    }

    let filteredTableHeaders = tableHeaders
      .filter((header) => header.show && header.value !== "sno")
      .map((header) => {
        let value = header.value;
        const key_mapping = {
          geo: "country",
        //   advertiser_id: "provider_id",
          // channel: "targeting_id",
          click: "clicks",
          shared_revenue: "gross_revenue",
          pub_revenue: "publisher_revenue",
          server_search_counts: "search_count",
          total_searches: "advertiser_search_count"
        };
        const old_keys = Object.keys(key_mapping);
        if (old_keys.includes(value)) {
          value = key_mapping[value];
        }
        return value;
      });

    params["csvHeaders"] = filteredTableHeaders;

    return params;
  }

  const downloadReport = () => {
    console.log("downloading report");
    Report.downloadReportV2BigQueryPublisher(getParams())
      .then(({ data, status }) => {
        if (status === 200) {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${new Date()}.csv`);
          document.body.appendChild(link);
          link.click();
        } else {
          throw new Error(JSON.stringify(data?.message || "Cannot download"));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Cannot download at the moment!");
      });
  };

  return {
    downloadReport
  };
};
