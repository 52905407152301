export const usePublisherAccountsTableLogic = () => {
  const headers = [
    { title: "ID", value: "id" },
    { title: "Publisher Name", value: "publisher_id" },
    { title: "Advertiser Name", value: "provider_id" },
    { title: "Rule Id", value: "rule_id" },
    { title: "Link Id", value: "link_id" },
    { title: "Status", value: "status" },
    { title: "Channel", value: "sin" },
    { title: "Pub link ID", value: "tid" },
    { title: "Link Source Identifier", value: "source_identifier" },
    { title: "Revenue Share", value: "share_revenue" },
    { title: "Api Key", value: "api_key" }
  ];

  function edit(id, data) {
    window.open(`/publisheraccountmanagement/edit/${id}`, "_blank");
  }

  function redirectToDataRemovePage(id, data) {
    window.open(`/remove/reports/${id}`, "_blank");
  }

  let actions = [
    { name: "edit", action: edit, icon: "editIcon" },
    {
      name: "removeReport",
      action: redirectToDataRemovePage,
      icon: "removeReport"
    }
  ];

  return {
    headers,
    actions
  };
};
