import SimpleModal from "../../../components/SimpleModal/SimpleModal";

const ConnectedPublisherLinksModal = ({
  id = null,
  providerLinkObj,
  closeAction
}) => {
  console.log({ providerLinkObj });
  return (
    <>
      <SimpleModal handleClose={closeAction}>
        <div className="related-connected-box flow-content">
          <h2 className="related-connected-box__heading">
            Publisher Links list
            {id && <span> (Advertiser Link Group: {id}) </span>}
          </h2>
          <div>
            {providerLinkObj?.length > 0 &&
              providerLinkObj.map((el, index) => (
                <div
                  className={`advertiser-link-group-rule-box flow-content--small white-border-box`}
                  style={{ borderColor: el.is_active ? "green" : "red" }}
                  key={index}
                  onClick={() =>
                    window.open(`/target/edit/${el?.targeting_id}`, "_blank")
                  }
                >
                  <div className="connected-ad-box__nuetral">
                    Publisher: {el?.publisher_name}
                  </div>
                  <div className="connected-ad-box__nuetral">
                    Publisher Link Id: {el?.targeting_id}
                  </div>
                  <div className="connected-ad-box__nuetral">
                    Publisher Link: {el?.targeting_link}
                  </div>
                  <div className="connected-ad-box__nuetral">
                    Rule status: {el.is_active ? "active" : "disabled"}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </SimpleModal>
    </>
  );
};

export default ConnectedPublisherLinksModal;
