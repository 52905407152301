import React, { useEffect, useCallback } from "react";
import moment from "moment";
//
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Icon, Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

//
import EditableCell from "./layouts/EditableCell";
import NoData from "./layouts/NoData";
import WithSpinner from "../WithSpinner/WithSpinner";
import { getActionIcons } from "../../helpers/icon";
import HoverActionCell from "./layouts/HoverActionCell";
import { CheckBoxOutlineBlank } from "@material-ui/icons";
import HTMLRenderer from "../HtmlRender";
import SimpleAccordion from "../Accordion/Accordion";
import InfoToolTip from "../InfoToolTip/InfoToolTip";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles({
  capitalize: {
    textTransform: "capitalize"
  },
  accordianRoot: {
    width: "100%"
  },
  accordianHeaderText: {
    fontSize: "0.875rem"
  },
  tableContainer: {
    maxHeight: "80vh"
  }
});
const ListingTable = React.memo(
  ({
    headers,
    rowValues,
    actions,
    selected = [],
    setSelected,
    tableType = "",
    handleStatusToggle,
    handleCellValueUpdate,
    page,
    setPage,
    size = 5,
    setSize,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    total,
    noActions = false,
    noIds = false,
    noCheckboxes = false,
    rowsPerPageOptions = [5, 10, 15, 20],
    handleEditAction = null,
    paginateRequired = true,
    handleRouteChange,
    hoverButtonMsg,
    children,
    hoverButtonAction,
    ...props
  }) => {
    const classes = useStyles();
    const stableSetPage = useCallback(setPage, [setPage]);
    //fix for when table page exceeds data value
    useEffect(() => {
      if (rowValues.length === 0 && page !== 1) {
        stableSetPage(1);
      }
      if (!page) {
        stableSetPage(1);
      }
    }, [rowValues, page, stableSetPage]);

    const onChangePage = (event, nextPage) => {
      setPage(nextPage + 1);
    };
    const onChangeRowsPerPage = (event) => {
      setPage(1);
      setSize(event.target.value);
    };
    const handleRowClick = (id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    };
    const handleSelectAllClick = (event) => {
      let currentSelected = [...selected];
      if (event.target.checked) {
        rowValues.forEach((r) => {
          if (r.is_disabled) {
            return;
          }
          let selectedIndex = currentSelected.indexOf(r.id);
          if (selectedIndex === -1) {
            currentSelected.push(r.id);
          }
        });
      } else {
        rowValues.forEach((r) => {
          let selectedIndex = currentSelected.indexOf(r.id);
          if (selectedIndex !== -1) {
            currentSelected.splice(selectedIndex, 1);
          }
        });
      }
      setSelected(currentSelected);
    };

    const isSelected = (id) => {
      const selectedIndex = selected.indexOf(id);
      if (selectedIndex === -1) {
        return false; //not selected yet
      }
      return true; //selected
    };

    const changeStatus = (status) => {
      if (status === "Failed") {
        return "Failed API";
      }
      if (status === "Processing") {
        return "Processing";
      }
      if (status === "Uploaded") {
        return "Uploaded";
      }
      if (status === "Constant") {
        return "Initialized";
      } else {
        return status;
      }
    };

    //function to handle editable cells or cells that can be toggled
    const getCellValue = (header, data, tableHeader) => {
      if (header === "Publisher") {
        return (
          <TableCell align="center" key={`ab+${header}`}>
            {data.publisher_info?.name}
          </TableCell>
        );
      }
      /**
       * If cell value is not null or undefined
       */
      if (data[header] !== undefined && data[header] !== null) {
        /**
         * For Publisher and provider, name and detail cell value,
         * return a editable cell
         */
        if (
          (header === "name" || header === "details") &&
          (tableType === "publisher" || tableType === "provider")
        ) {
          return (
            <EditableCell
              key={`${data.id}+${header}`}
              header={header}
              data={data}
              handleCellValueUpdate={handleCellValueUpdate}
            />
          );
        }
        /**
         * For Publisher and provider , status cell needs to be of toggle type
         * that updates the status
         */
        if (
          header === "status" &&
          (tableType === "publisher" || tableType === "provider")
        ) {
          return (
            <TableCell align="center" key={`ab+${header}`}>
              <FormControlLabel
                control={
                  <Switch
                    checked={data.status === "active"}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleStatusToggle(data.id, data.status, data);
                    }}
                    //prevent event bubbling on double click
                    onDoubleClick={(e) => e.stopPropagation()}
                    name="Status"
                  />
                }
              />
            </TableCell>
          );
        }
        if (tableType === "providerLinks" && header === "reusable") {
          return (
            <TableCell align="center" key={`ab+${header}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.reusable}
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="reusable"
                    onChange={() =>
                      tableHeader.rowFunction(data.id, data.reusable)
                    }
                    disabled={data.remaining_cap === 0}
                  />
                }
              />
            </TableCell>
          );
        }

        if (header === "display_in_upload_screen" && tableType === "provider") {
          return (
            <TableCell align="center" key={`ab+${header}`}>
              <FormControlLabel
                control={
                  <Switch
                    checked={data.display_in_upload_screen}
                    onChange={(e) => {
                      e.stopPropagation();
                      props.handleDisplayStatus(
                        data.id,
                        data.display_in_upload_screen,
                        data
                      );
                    }}
                    //prevent event bubbling on double click
                    onDoubleClick={(e) => e.stopPropagation()}
                    name="Display in CSV UPLOAD"
                  />
                }
              />
            </TableCell>
          );
        }

        if (header === "redirected_from_cid") {
          if (data.redirected_from_cid && data.redirected_from_cid !== "-") {
            return (
              <TableCell align="center" key={`ab+${header}`}>
                <Tooltip title="Open Targeting">
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href={
                      window.location.origin +
                      "/target/edit/" +
                      data.redirected_from_cid
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data[header].toString()}
                  </a>
                </Tooltip>
              </TableCell>
            );
          }
        }
        if (tableType === "providerLinks")
          if (header === "disabled" && tableType === "providerLinks") {
            /** For provider links, link status comes in "disabled" header,
             * if disabled is false, the switch is checked else not checked
             */
            return (
              <TableCell align="center" key={`ab+${header}`}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!data.disabled}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleStatusToggle(data.id, data.disabled, data);
                      }}
                      //prevent event bubbling on double click
                      onDoubleClick={(e) => e.stopPropagation()}
                      name="Status"
                    />
                  }
                />
              </TableCell>
            );
          }

        if (header === "blocked" && tableType === "user") {
          return (
            <TableCell align="center" key={`ab+${header}`}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!data.blocked}
                    onChange={() =>
                      handleStatusToggle(data.id, data.blocked, data)
                    }
                    name="Status"
                  />
                }
              />
            </TableCell>
          );
        }

        if (header === "provider" && tableType === "user") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.provider?.name}
            </TableCell>
          );
        }

        if (header === "provider" && tableType === "skippedrows") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.provider?.id + "-" + data?.provider.name}
            </TableCell>
          );
        }

        if (header === "createdAt" && tableType === "skippedrows") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {moment(data?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            </TableCell>
          );
        }

        if (header === "publisher" && tableType === "user") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.publisher?.name}
            </TableCell>
          );
        }

        if (header === "publisher_id" && tableType === "publisheraccount") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.publisher?.id + "-" + data?.publisher?.name}
            </TableCell>
          );
        }

        if (header === "publisher_id" && tableType === "missing_subIds") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.publisher_id?.id + "-" + data?.publisher_id?.name}
            </TableCell>
          );
        }

        if (header === "provider" && tableType === "missing_subIds") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.provider?.id + "-" + data?.provider?.name}
            </TableCell>
          );
        }

        if (header === "provider_id" && tableType === "publisheraccount") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.provider?.id + "-" + data?.provider?.name}
            </TableCell>
          );
        }

        if (header === "advertiser_id" && tableType === "apiDetails") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.provider?.id + "-" + data?.provider?.name}
            </TableCell>
          );
        }

        if (header === "last_updated_db" && tableType === "apiDetails") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {moment(data?.last_updated_db).format("MMMM Do YYYY, h:mm:ss a")}
            </TableCell>
          );
        }

        if (header === "date" && tableType === "missing_subIds") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.interval === "month"
                ? moment(data.date).format("MMM, YYYY")
                : moment(data.date).format("MMM DD, YYYY")}
            </TableCell>
          );
        }

        if (header === "apply_from_data" && tableType === "apiDetails") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {moment(data?.apply_from_data).format("YYYY-MM-DD")}
            </TableCell>
          );
        }
        if (header === "apply_to_date" && tableType === "apiDetails") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {moment(data?.apply_to_date).format("YYYY-MM-DD")}
            </TableCell>
          );
        }

        if (header === "called_api_date" && tableType === "apiDetails") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {moment(data?.called_api_date).format("MMMM Do YYYY, h:mm:ss a")}
            </TableCell>
          );
        }

        if (header === "uploaded_status" && tableType === "apiDetails") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {changeStatus(data?.uploaded_status)}
            </TableCell>
          );
        }

        if (header === "status" && tableType === "publisheraccount") {
          return (
            <TableCell align="center" key={`ab+${header}`}>
              <FormControlLabel
                control={
                  <Switch
                    checked={data.status}
                    // onChange={() =>
                    //   handleStatusToggle(data.id, data.status, data)
                    // }
                    name="Status"
                  />
                }
              />
            </TableCell>
          );
        }

        if (header === "account" && tableType === "user") {
          return (
            <TableCell
              align="center"
              key={`ab+${header}`}
              className="cursor-pointer link-text"
            >
              {data?.account?.name}
            </TableCell>
          );
        }
        /**
         * For Targetings , "Active" cell needs to be of toggle type
         * that updates the current status of the targeting
         */
        if (header === "is_active" && tableType === "targetings") {
          return (
            <TableCell align="center" key={`ab+${header}`}>
              <FormControlLabel
                control={
                  <Switch
                    checked={data.is_active}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleStatusToggle(data.id, data.is_active, data);
                    }}
                    //prevent event bubbling on double click
                    onDoubleClick={(e) => e.stopPropagation()}
                    name="Is Active"
                  />
                }
              />
            </TableCell>
          );
        }
        /**
         * Log Table, Rule Cell value that needs to redirect to Targeting Page
         * using cid i.e the id of targeting
         */
        if (tableType === "logs" && header === "cid") {
          return (
            <TableCell align="center" key={`ab+${header}`}>
              <Tooltip title="Open Targeting">
                <a
                  style={{ textDecoration: "none", color: "inherit" }}
                  href={window.location.origin + "/target/edit/" + data.cid}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data[header].toString()}
                </a>
              </Tooltip>
            </TableCell>
          );
        }

        if (tableType === "logs" && header === "redirected_from_cid") {
          if (data.redirected_from_cid && data.redirected_from_cid !== "-") {
            return (
              <TableCell align="center" key={`ab+${header}`}>
                <Tooltip title="Open Targeting">
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href={
                      window.location.origin +
                      "/target/edit/" +
                      data.redirected_from_cid
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data[header].toString()}
                  </a>
                </Tooltip>
              </TableCell>
            );
          }
        }

        if (tableType === "systemLogs" && header === "channel") {
          return (
            <TableCell align="center" key={`ab+${header}`}>
              <span>{data[header].toString() || "-"}</span>
            </TableCell>
          );
        }

        if (tableType === "advisors" && header === "updatedAt") {
          return (
            <TableCell align="center" key={`ab+${header}`}>
              <span style={{ whiteSpace: "nowrap" }}>
                {moment(data[header]).format("YYYY-MM-DD hh:mm:ss")}
              </span>
            </TableCell>
          );
        }

        if (tableType === "systemLogsV2" && header === "channel") {
          const isNull = !data?.advertiser_channel_id;
          return (
            <TableCell align="center" key={`ab+${header}`}>
              {isNull ? (
                <span>{data[header].toString()}</span>
              ) : (
                <Tooltip title="Open Advertiser Link">
                  <a
                    href={
                      window.location.origin +
                      `/advertisers/links?provider_link_id=${data.advertiser_channel_id}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data[header].toString()}
                  </a>
                </Tooltip>
              )}
            </TableCell>
          );
        }

        if (tableType === "systemLogs" && header === "advertiser_id") {
          const isNull = data[header] === "FALLBACK_URL";
          return (
            <TableCell align="center" key={`ab+${header}`}>
              {isNull ? (
                <span>{data[header].toString()}</span>
              ) : (
                <Tooltip title="Open Advertiser">
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href={
                      window.location.origin +
                      "/advertisers/edit/" +
                      data.provider_id
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data[header].toString()}
                  </a>
                </Tooltip>
              )}
            </TableCell>
          );
        }

        if (tableType === "systemLogsV2" && header === "advertiser_id") {
          const isNull = data[header] === "FALLBACK_URL";
          return (
            <TableCell align="center" key={`ab+${header}`}>
              {isNull ? (
                <span>{data[header].toString()}</span>
              ) : (
                <Tooltip title="Open Advertiser">
                  <a
                    href={
                      window.location.origin +
                      "/advertisers/edit/" +
                      data.advertiser_id.toString().split("-")[0].trim()
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data[header].toString()}
                  </a>
                </Tooltip>
              )}
            </TableCell>
          );
        }

        if (tableType === "systemLogs" && header === "publisher_id") {
          const isNull = data[header] === "-";
          let trimmedId = null;
          // the publisher_id is not string when the publisher id not in the system
          if (
            data &&
            data.publisher_id &&
            typeof data.publisher_id === "string"
          ) {
            trimmedId = data.publisher_id.split("-")[0].trim();
          } else {
            trimmedId = data.publisher_id;
          }
          return (
            <TableCell align="center" key={`ab+${header}`}>
              {isNull ? (
                <span>{data[header].toString()}</span>
              ) : trimmedId ? (
                <Tooltip title="Open Publisher">
                  {
                    <a
                      style={{ textDecoration: "none", color: "inherit" }}
                      href={
                        window.location.origin + "/publishers/edit/" + trimmedId
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data[header].toString()}
                    </a>
                  }
                </Tooltip>
              ) : (
                <Tooltip title="Fallback">
                  <span>--</span>
                </Tooltip>
              )}
            </TableCell>
          );
        }

        if (tableType === "systemLogsV2" && header === "publisher_id") {
          const isNull = data[header] === "-";
          let trimmedId = null;
          // the publisher_id is not string when the publisher id not in the system
          if (
            data &&
            data.publisher_id &&
            typeof data.publisher_id === "string"
          ) {
            trimmedId = data.publisher_id.split("-")[0].trim();
          } else {
            trimmedId = data.publisher_id;
          }
          return (
            <TableCell align="center" key={`ab+${header}`}>
              {isNull ? (
                <span>{data[header].toString()}</span>
              ) : trimmedId ? (
                <Tooltip title="Open Publisher">
                  {
                    <a
                      href={
                        window.location.origin + "/publishers/edit/" + trimmedId
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data[header].toString()}
                    </a>
                  }
                </Tooltip>
              ) : (
                <Tooltip title="Fallback">
                  <span>--</span>
                </Tooltip>
              )}
            </TableCell>
          );
        }

        if (
          tableType === "systemLogsV2" &&
          [
            "publisher_channel_id",
            "redirect_from_publisher_channel_id"
          ].includes(header)
        ) {
          let tagId = null;
          header === "publisher_channel_id"
            ? (tagId = data.publisher_channel_id)
            : (tagId = data.redirect_from_publisher_channel_id);
          const is_tagId_null = tagId === "-" || tagId === null;
          return (
            <TableCell align="center" key={`ab+${header}`}>
              {is_tagId_null ? (
                <span>{data[header].toString()}</span>
              ) : (
                <Tooltip title="Open Targeting">
                  <a
                    className="custom__anchor__tag"
                    href={window.location.origin + "/target/edit/" + tagId}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data[header].toString()}
                  </a>
                </Tooltip>
              )}
            </TableCell>
          );
        }

        if (tableType === "systemLogs" && header === "tag_id") {
          const is_tagId_null = data.tag_id === "-" || data.tag_id === null;
          return (
            <TableCell align="center" key={`ab+${header}`}>
              {is_tagId_null ? (
                <span>{data[header].toString()}</span>
              ) : (
                <Tooltip title="Open Targeting">
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    className="custom__anchor__tag"
                    href={
                      window.location.origin + "/target/edit/" + data.tag_id
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data[header].toString()}
                  </a>
                </Tooltip>
              )}
            </TableCell>
          );
        }

        if (
          (tableType === "systemLogsV2" || tableType === "publisherTable") &&
          header === "filtered_reason"
        ) {
          return (
            <TableCell align="right" key={`ab+${header}`}>
              <span>{data[header].toString()}</span>
            </TableCell>
          );
        }

        if (tableType === "logs" && header === "provider_id") {
          return (
            <TableCell align="center" key={`ab+${header}`}>
              {data.provider?.id ? (
                <Tooltip title="Open Advertiser">
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href={
                      window.location.origin +
                      "/advertisers/edit/" +
                      data.provider.id
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data[header].toString()}
                  </a>
                </Tooltip>
              ) : (
                <span>{data[header].toString()}</span>
              )}
            </TableCell>
          );
        }

        if (tableType === "logs" && header === "pid") {
          return (
            <TableCell align="center" key={`ab+${header}`}>
              {data.publisher?.id ? (
                <Tooltip title="Open Publisher">
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href={
                      window.location.origin +
                      "/publishers/edit/" +
                      data.publisher.id
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data[header].toString()}
                  </a>
                </Tooltip>
              ) : (
                <span>{data[header].toString()}</span>
              )}
            </TableCell>
          );
        }

        if (
          tableType === "systemLogsV2" &&
          [
            "monetized_ctr",
            "ad_coverage",
            "rpc",
            "rpm",
            'server_searches_rpm',
            'ad_coverage_server_percentage',
            "invalid_traffic"
          ].includes(header)
        ) {
          return (
            <TableCell
              align="center"
              key={`${header}`}
              style={{ textAlign: "center", padding: "2px" }}
            >
              <div
                className={getColorClassName(data, header)}
                style={{ padding: "5px" }}
              >
                {data[header]}
              </div>
            </TableCell>
          );
        }

        /**
         * Targeting Table, Link Cell Value
         */
        if (tableType === "targetings") {
          if (header === "link") {
            return (
              <HoverActionCell
                header={header}
                data={data}
                key={`hoverable + ${header}`}
                hoverButtonAction={() => hoverButtonAction(data.id)}
                hoverButtonMsg={hoverButtonMsg}
              />
            );
          }

          if (header === "id") {
            return (
              <TableCell align="center" key={`ab + ${header}`}>
                <a
                  href={"/target/edit/" + data["id"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data[header].toString()}
                </a>
              </TableCell>
            );
          }
        }

        /**
         * Targeting Table, Link Cell Value
         */
        if (tableType === "advisors") {
          if (header === "description") {
            const despContent = data[header].toString();
            return (
              <TableCell align="center" key={`ab + ${header}`}>
                <SimpleAccordion
                  header={data["title"] ?? ""}
                  key="provider-filter-box"
                  type="small"
                >
                  <HTMLRenderer
                    htmlString={despContent}
                    className={classes.accordianRoot}
                  />
                </SimpleAccordion>
              </TableCell>
            );
          }
          if (header === "status") {
            let content = data[header].toString();
            return (
              <TableCell align="center" key={`ab + ${header}`}>
                <div style={{ display: "flex" }}>
                  {content.charAt(0).toUpperCase() + content.slice(1)}
                  {data?.history?.length > 0 && (
                    <span>
                      <InfoToolTip
                        title={`Notes: ${
                          data.history[0]?.payload?.notes
                        } [by: ${data.history[0]?.user?.first_name ?? ""} ${
                          data.history[0]?.user?.last_name ?? ""
                        }]`}
                        className="custom-fallback__info"
                        style={{ paddingLeft: "10px" }}
                      >
                        <div className="gray-icon ">
                          <HelpIcon fontSize="small" />
                        </div>
                      </InfoToolTip>
                    </span>
                  )}
                </div>
              </TableCell>
            );
          }
          if (header === "updatedAt") {
            return (
              <TableCell align="center" key={`ab + ${header}`}>
                {moment(data[header]).format("YYYY-MM-DD hh:mm a")}
              </TableCell>
            );
          }
        }

        if (tableType === "PublisherLatestHistory") {
          if (header === "table") {
            const changeMessage =
              data[header] && data[header] === "Targeting"
                ? "Targeting"
                : `Rule ID ${data["rule_id"]}`;
            return (
              <TableCell align="left" key={`ab + ${header}`}>
                {changeMessage}
              </TableCell>
            );
          }
          if (["oldValue", "newValue"].includes(header)) {
            const records = Object.entries(JSON.parse(data[header]));
            return (
              <TableCell align="left" key={`ab + ${header}`}>
                <ul>
                  {records.map(([key, value]) =>
                    key === "updatedAt" ? (
                      <li key={key}>
                        <strong>{key}:</strong>{" "}
                        {value
                          ? moment(value).format("YYYY-MM-DD hh:mm A")
                          : "null"}
                      </li>
                    ) : (
                      <li key={key}>
                        <strong>{key}: </strong>
                        {value
                          ? typeof value === "boolean"
                            ? value
                              ? "true"
                              : "false"
                            : Array.isArray(value)
                            ? key === "provider_details"
                              ? JSON.stringify(value[0])
                              : value.join(", ")
                            : value
                          : "-"}
                      </li>
                    )
                  )}
                </ul>
              </TableCell>
            );
          }
          return (
            <TableCell align="left" key={`ab + ${header}`}>
              {data[header].toString()}
            </TableCell>
          );
        }

        if (tableType === "ProviderLinkLatestHistory") {
          if (["old_value", "new_value"].includes(header)) {
            const records = Object.entries(JSON.parse(data[header]));
            return (
              <TableCell align="left" key={`ab + ${header}`}>
                <ul>
                  {records.map(([key, value]) =>
                    key === "updatedAt" ? (
                      <li key={key}>
                        <strong>{key}:</strong>{" "}
                        {value
                          ? moment(value).format("YYYY-MM-DD HH:mm A")
                          : "null"}
                      </li>
                    ) : (
                      <li key={key}>
                        <strong>{key}:</strong>{" "}
                        {value
                          ? typeof value === "boolean"
                            ? value
                              ? "true"
                              : "false"
                            : value
                          : "-"}
                      </li>
                    )
                  )}
                </ul>
              </TableCell>
            );
          }
          return (
            <TableCell align="center" key={`ab + ${header}`}>
              {data[header].toString()}
            </TableCell>
          );
        }

        /**
         * Normal value that is not falsy and does not match the above conditions
         */
        return (
          <TableCell align="center" key={`ab + ${header}`}>
            {data[header].toString()}
          </TableCell>
        );
      } else {
        /**
         * Undefined cell value for name and details that needs to be editable
         */
        if (
          (header === "name" || header === "details") &&
          (tableType === "publisher" || tableType === "provider")
        ) {
          return (
            <EditableCell
              key={`${data.id} + ${header}`}
              header={header}
              data={data}
              handleCellValueUpdate={handleCellValueUpdate}
            />
          );
        }
      }

      return <TableCell align="center" key={`ab + ${header}`}></TableCell>;
    };

    const currentRowsSelected = () => {
      let selectedCount = 0;
      rowValues.forEach((r) => {
        let selectedIndex = selected.indexOf(r.id);
        if (selectedIndex !== -1) {
          selectedCount = selectedCount + 1;
        }
      });
      // because there are disabled rows in api details table of api management while counting the total no of checked
      // the disabled rows are ignored
      if (tableType === "apiDetails") {
        const disabled_count = rowValues.filter(
          (obj) => obj.is_disabled
        ).length;
        return selectedCount + disabled_count;
      }
      return selectedCount;
    };

    const handleOrderByChange = (newOrderBy) => {
      //return in case of Serial Number Header being clicked
      if (newOrderBy === "sno") return;
      newOrderBy !== orderBy && setOrderBy(newOrderBy);
      let newOrderDirection = orderDirection === "DESC" ? "ASC" : "DESC";
      setOrderDirection(newOrderDirection);
    };

    const handleValueRowDoubleClick = (e, id, data) => {
      if (tableType === "targetings") {
        handleEditAction(id);
      }
      if (tableType === "providerLinks") {
        if (handleEditAction) {
          handleEditAction(id, data);
        }
      }
      if (tableType === "advertiser-link-group") {
        if (handleEditAction) {
          handleEditAction(id, data);
        }
      }
    };

    const getActionColumnPublisher = (
      value,
      dataId,
      noOfTargetings = "0",
      data
    ) => {
      let icon;
      if (value.icon === "TargetinglinkIcon") {
        icon = parseInt(noOfTargetings) > 0 ? getActionIcons(value.icon) : null;
      } else if (value.icon === "TargetinglinkIconDisabled") {
        icon =
          parseInt(noOfTargetings) === 0 ? getActionIcons(value.icon) : null;
      } else if (value.icon === "linkIcon") {
        icon = data.has_account ? getActionIcons(value.icon) : null;
      } else if (value.icon === "disabledLinkIcon") {
        icon = !data.has_account ? getActionIcons(value.icon) : null;
      } else if (value.icon === "skypeCode") {
        icon = !data.skype_conversation_id ? getActionIcons(value.icon) : null;
      } else if (value.icon === "removeSkypeConversation") {
        icon = data.skype_conversation_id ? getActionIcons(value.icon) : null;
      } else {
        icon = getActionIcons(value.icon);
      }
      return (
        icon && (
          <span
            className="action-icon"
            key={value.name}
            onClick={() => value.action(dataId, data)}
          >
            {icon}
          </span>
        )
      );
    };

    const getActionColumnAdvisor = (value, dataId, data = {}) => {
      if (value.name !== "active") {
        return (
          <span
            className="action-icon"
            key={value.name}
            onClick={(e) => {
              e.stopPropagation();
              // to disable action. active status can snooze and close, snooze status cann close
              if (data.status === "active") {
                value.action(dataId, data, value.name);
              } else if (data.status === "snoozed") {
                if (value.icon === "closeIcon") {
                  value.action(dataId, data, value.name);
                }
              }
            }}
            //prevent event bubbling on double click
            onDoubleClick={(e) => e.stopPropagation()}
          >
            {getActionIcons(value.icon, data)}
          </span>
        );
      }
    };

    const getActionColumnProvider = (value, dataId, data = {}) => {
      let showIcon = false;
      if (value.name === "skypeCode") {
        if (!data.skype_conversation_id) showIcon = true;
      } else if (value.name === "removeSkypeConversation") {
        if (data.skype_conversation_id) showIcon = true;
      } else {
        showIcon = true;
      }

      return (
        showIcon && (
          <span
            className="action-icon"
            key={value.name}
            onClick={(e) => {
              e.stopPropagation();
              value.action(dataId, data);
            }}
            //prevent event bubbling on double click
            onDoubleClick={(e) => e.stopPropagation()}
          >
            {getActionIcons(value.icon)}
          </span>
        )
      );
    };

    const getActionColumnProviderLinks = (value, dataId, data = {}) => {
      let showIcon = false;
      if (value.name === "history") {
        if (
          data?.provider_link_activity_logs &&
          data.provider_link_activity_logs.length > 0
        )
          showIcon = true;
      } else {
        showIcon = true;
      }

      return (
        showIcon && (
          <span
            className="action-icon"
            key={value.name}
            onClick={(e) => {
              e.stopPropagation();
              value.action(dataId, data);
            }}
            //prevent event bubbling on double click
            onDoubleClick={(e) => e.stopPropagation()}
          >
            {getActionIcons(value.icon, data)}
          </span>
        )
      );
    };

    const getActionColumnTargetings = (value, dataId, data = {}) => {
      let showIcon = false;
      if (value.name === "history") {
        if (data?.activity_logs) {
          showIcon = true;
        }
      } else {
        showIcon = true;
      }

      return (
        showIcon && (
          <span
            className="action-icon"
            key={value.name}
            onClick={(e) => {
              e.stopPropagation();
              value.action(dataId, data);
            }}
            //prevent event bubbling on double click
            onDoubleClick={(e) => e.stopPropagation()}
          >
            {getActionIcons(value.icon)}
          </span>
        )
      );
    };

    const getActionColumn = (value, dataId, data = {}) => (
      <span
        className="action-icon"
        key={value.name}
        onClick={(e) => {
          e.stopPropagation();
          value.action(dataId, data);
        }}
        //prevent event bubbling on double click
        onDoubleClick={(e) => e.stopPropagation()}
      >
        {tableType === "providerLinks"
          ? getActionIcons(value.icon, data)
          : getActionIcons(value.icon)}
      </span>
    );

    const getColorClassName = (data, column) => {
      const colorclassNames = {
        red: "color_red",
        green: "color_green",
        orange: "color_orange",
        yellow: "color_yellow",
        default: "color_black"
      };

      let { country, channel, search_count, tag_type_id, invalid_traffic } =
        data;
      search_count = search_count
        ? search_count.replaceAll(",", "")
        : search_count;
      invalid_traffic = invalid_traffic
        ? invalid_traffic.replaceAll(",", "")
        : invalid_traffic;
      const value = parseFloat(Number(data[column]));

      if (!channel || !tag_type_id || parseFloat(value) === 0) {
        return null;
      }

      if (country && country === "US") {
        switch (column) {
          case "monetized_ctr":
            if (value < 6 || value > 10.5) {
              return colorclassNames.red;
            } else if (value < 7.5) {
              return colorclassNames.yellow;
            } else if (value < 10.5) {
              return colorclassNames.green;
            }
            break;
          case "invalid_traffic":
            const filtered_percentage = (invalid_traffic * 100) / search_count;
            if (filtered_percentage <= 4) {
              return colorclassNames.green;
            } else if (filtered_percentage <= 15) {
              return colorclassNames.yellow;
            } else if (filtered_percentage > 15) {
              return colorclassNames.red;
            }
            break;
          case "ad_coverage":
            if (value >= 85) {
              return colorclassNames.green;
            } else if (value >= 77) {
              return colorclassNames.yellow;
            } else if (value < 77) {
              return colorclassNames.red;
            }
            break;
          case "rpm":
            if (value < 35 || value > 100) {
                return colorclassNames.red;
            } else if (value < 45) {
                return colorclassNames.yellow;
            } else {
                return colorclassNames.green;
            }
          case "server_searches_rpm":
            if (value < 35 || value > 100) {
              return colorclassNames.red;
            } else if (value < 45) {
              return colorclassNames.yellow;
            } else {
              return colorclassNames.green;
            }
          case "ad_coverage_server_percentage":
            if (value >= 85) {
              return colorclassNames.green;
            } else if (value >= 77) {
              return colorclassNames.yellow;
            } else if (value < 77) {
              return colorclassNames.red;
            }
            break;
          default:
            return colorclassNames.default;
        }
      } else {
          switch (column) {
          case "monetized_ctr":
            if (value < 7.5 || value > 10.5) {
              return colorclassNames.red;
            } else if (value < 9) {
              return colorclassNames.yellow;
            } else if (value < 10.5) {
              return colorclassNames.green;
            }
            break;
          case "ad_coverage":
            if (value >= 85) {
              return colorclassNames.green;
            } else if (value >= 77) {
              return colorclassNames.yellow;
            } else if (value < 77) {
              return colorclassNames.red;
            }
            break;
          case "rpc":
            if (value < 0.4 || value > 1.05) {
              return colorclassNames.red;
            } else if (value < 0.6) {
              return colorclassNames.yellow;
            } else {
              return colorclassNames.green;
            }
          case "rpm":
            if (value < 35 || value > 100) {
                return colorclassNames.red;
            } else if (value < 45) {
                return colorclassNames.yellow;
            } else {
                return colorclassNames.green;
            }
          case "server_searches_rpm":
              if (value < 35 || value > 100) {
                return colorclassNames.red;
              } else if (value < 45) {
                return colorclassNames.yellow;
              } else {
                return colorclassNames.green;
              }
          case "ad_coverage_server_percentage":
            if (value >= 85) {
              return colorclassNames.green;
            } else if (value >= 77) {
              return colorclassNames.yellow;
            } else if (value < 77) {
              return colorclassNames.red;
            }
            break;
          case "invalid_traffic":
            const filtered_percentage = (invalid_traffic * 100) / search_count;
            if (filtered_percentage <= 4) {
              return colorclassNames.green;
            } else if (filtered_percentage <= 15) {
              return colorclassNames.yellow;
            } else {
              return colorclassNames.red;
            }
          default:
            return colorclassNames.default;
        }
      }
    };

    if (rowValues.length === 0) return <NoData />;
    return (
      <Container className="table__container">
        <TableContainer
          component={Paper}
          elevation={3}
          className={classes.tableContainer}
        >
          <Table stickyHeader className="list-table">
            <TableHead>
              <TableRow
                className={`tableRow ${
                  rowValues.length === currentRowsSelected() &&
                  rowValues.length !== 0
                    ? "rowSelected"
                    : ""
                }`}
              >
                {!noCheckboxes && (
                  <TableCell align="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="status"
                          color="primary"
                          onChange={handleSelectAllClick}
                          checked={
                            rowValues.length === currentRowsSelected() &&
                            rowValues.length !== 0
                          }
                        />
                      }
                    />
                  </TableCell>
                )}

                {headers.map((header, index) => {
                  return (
                    <TableCell
                      align="center"
                      className={classes.capitalize}
                      key={`${header.title} + ${index}`}
                    >
                      <div
                        className="table_header"
                        onClick={
                          header.disableOrderBy === true
                            ? null
                            : () => handleOrderByChange(header.value)
                        }
                      >
                        {orderBy === header.value ? (
                          orderDirection === "DESC" ? (
                            <ArrowDownwardIcon />
                          ) : (
                            <ArrowUpwardIcon />
                          )
                        ) : (
                          ""
                        )}
                        {!["estimated_revenue"].includes(header.value) &&
                          header.title}

                        {/* {header.value === "estimated_revenue" && (
                          <div>
                            <span>{header.title}</span>

                            <Tooltip
                              title={
                                <p style={{ fontSize: "14px" }}>
                                  Estimations are done at 9pm IST, ONLY if over
                                  50% of the searches revenue data is present in
                                  our system. Data is not overwritten and won’t
                                  get calculated again.
                                </p>
                              }
                            >
                              <InfoIcon />
                            </Tooltip>
                          </div>
                        )} */}
                      </div>
                    </TableCell>
                  );
                })}
                {!noActions && (
                  <TableCell align="center" key="tbHeaderNormal">
                    Actions
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowValues.map((data, index) => {
                return (
                  <TableRow
                    key={` ${index} + 123`}
                    className={` ${isSelected(data.id) ? "rowSelected" : ""} ${
                      data["sno"] === "Total" && "font-bold"
                    } ${data.is_disabled ? "row-disabled" : "tableRow"} ${
                      tableType === "advisors" ? "rowRoot" : ""
                    }`}
                    onDoubleClick={(e) =>
                      handleValueRowDoubleClick(e, data.id, data)
                    }
                  >
                    {!noCheckboxes &&
                      (data.is_disabled ? (
                        <TableCell align="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={true}
                                name="status"
                                color="primary"
                                checked={false}
                              />
                            }
                          />
                        </TableCell>
                      ) : (
                        <TableCell align="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="status"
                                color="primary"
                                checked={isSelected(data.id)}
                                onClick={() => handleRowClick(data.id)}
                              />
                            }
                          />
                        </TableCell>
                      ))}

                    {headers.map((header) =>
                      getCellValue(header.value, data, header)
                    )}
                    {!noActions && (
                      <TableCell align="center">
                        <div style={{ display: "flex" }}>
                          {actions.map((value) => {
                            if (tableType === "publisher") {
                              return getActionColumnPublisher(
                                value,
                                data.id,
                                data?.no_of_targetings,
                                data
                              );
                            } else if (tableType === "advisors") {
                              return getActionColumnAdvisor(
                                value,
                                data.id,
                                data
                              );
                            } else if (tableType === "provider") {
                              return getActionColumnProvider(
                                value,
                                data.id,
                                data
                              );
                            } else if (tableType === "providerLinks") {
                              return getActionColumnProviderLinks(
                                value,
                                data.id,
                                data
                              );
                            } else if (tableType === "targetings") {
                              return getActionColumnTargetings(
                                value,
                                data.id,
                                data
                              );
                            } else {
                              return getActionColumn(value, data.id, data);
                            }
                          })}
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {paginateRequired && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={total}
            rowsPerPage={size}
            page={page - 1}
            component={Paper}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        )}

        {children}
      </Container>
    );
  }
);

export default WithSpinner(ListingTable);
