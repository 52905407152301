/**
 * Custom Hook Component to handle Form Value Changes for Rules
 */
import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import percentRound from "percent-round";
import { updateAdvertiserLinkGroupRules } from "../../../../reducers/advertiserLinkGroup/action";
import { useModal } from "../../../../hooks/useModal";

import {
  updateTargetingRulesBeforeDispatch,
  calculateTotalDailyCap
} from "../../helper/helper-functions";

export const useHandleFormValueChange = ({
  targeting,
  targetingRules,
  handleFormTouched,
  oldRules,
  setDailyCap,
  isWeightFieldTouched,
  setIsWeightFieldTouched
}) => {
  let newTargetingRules = [...targetingRules];
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);

  const [statusChangeOpen, openStatusChange, closeStatusChange] =
    useModal(false);
  const [statusChangeValue, setStatusChangeValue] = useState({
    originalIndex: "",
    disabled: ""
  });

  const [statusInformOpen, openStatusInform, closeStatusInform] =
    useModal(false);

  // Handler For Daily Cap , Daily Frequency and Comment
  const handleValuesChange = (
    name,
    value,
    currentTargetingRules,
    originalIndex
  ) => {
    currentTargetingRules[name] = value;
    newTargetingRules[originalIndex] = currentTargetingRules;
    dispatch(updateAdvertiserLinkGroupRules(newTargetingRules));
  };

  const handleProviderOneChange = ({
    event,
    providerDetailsArrayExist,
    currentTargetingRules,
    isValueEmpty,
    originalIndex
  }) => {
    if (providerDetailsArrayExist) {
      currentTargetingRules.provider_details[0] = {
        ...currentTargetingRules.provider_details[0],
        provider_id: event.target.value,
        provider_link: ""
      };
      let areThereTwoProviderDetails =
        currentTargetingRules.provider_details.length === 2;
      if (areThereTwoProviderDetails && isValueEmpty) {
        currentTargetingRules.provider_details.shift();
      }
    } else {
      //if provider_details don't exist create new array and push
      currentTargetingRules.provider_details = [];
      currentTargetingRules.provider_details.push({
        provider_id: event.target.value
      });
    }
    newTargetingRules[originalIndex] = currentTargetingRules;
    dispatch(updateAdvertiserLinkGroupRules(newTargetingRules));
  };

  const handleProviderTwoChange = ({
    event,
    providerDetailsArrayExist,
    currentTargetingRules,
    isValueEmpty,
    originalIndex
  }) => {
    if (providerDetailsArrayExist) {
      let areThereTwoProviderDetails =
        currentTargetingRules.provider_details.length === 2;
      if (areThereTwoProviderDetails) {
        currentTargetingRules.provider_details[1] = {
          ...currentTargetingRules.provider_details[1],
          provider_id: event.target.value,
          provider_link: ""
        };
        if (isValueEmpty) {
          currentTargetingRules.provider_details.pop();
          currentTargetingRules.provider_details[0] = {
            ...currentTargetingRules.provider_details[0],
            traffic: 100
          };
        }
      } else {
        currentTargetingRules.provider_details.push({
          provider_id: event.target.value
        });
      }
    } else {
      //if first provider detail does not exist
      //don't do anything
      return;
    }
    newTargetingRules[originalIndex] = currentTargetingRules;
    dispatch(updateAdvertiserLinkGroupRules(newTargetingRules));
  };

  const handleProviderChange = (event, originalIndex, disabledProviders) => {
    let isDisabledProviderSelected = disabledProviders.includes(
      event.target.value
    );
    if (isDisabledProviderSelected) {
      return;
    }
    let newTargetingRules = [...targetingRules];
    newTargetingRules[originalIndex] =
      { ...newTargetingRules[originalIndex] } || {};
    let currentTargetingRules = { ...newTargetingRules[originalIndex] } || {};
    let isItProviderOne = event.target.name === "providerOne";
    let isItProviderTwo = event.target.name === "providerTwo";
    let isValueEmpty = event.target.value === "";

    let providerDetailsArrayExist = currentTargetingRules.provider_details
      ? true
      : false;
    if (isItProviderOne) {
      handleProviderOneChange({
        event,
        providerDetailsArrayExist,
        currentTargetingRules,
        isValueEmpty,
        originalIndex
      });
    }

    if (isItProviderTwo) {
      handleProviderTwoChange({
        event,
        providerDetailsArrayExist,
        currentTargetingRules,
        isValueEmpty,
        originalIndex
      });
    }
    handleFormTouched();
  };

  const handleTrafficOneChange = (value, originalIndex) => {
    if (parseInt(value) > 100 || parseInt(value) < 0) return;
    let newTargetingRules = [...targetingRules];
    newTargetingRules[originalIndex] =
      { ...newTargetingRules[originalIndex] } || {};
    let currentTargetingRules = { ...newTargetingRules[originalIndex] } || {};
    let providerDetailsArrayExist = currentTargetingRules.provider_details
      ? true
      : false;

    let isValueNot100OrEmpty = parseInt(value) !== 100 || value !== "";
    if (providerDetailsArrayExist) {
      let areThereTwoProviderDetails =
        currentTargetingRules.provider_details.length === 2;

      currentTargetingRules.provider_details[0] = {
        ...currentTargetingRules.provider_details[0],
        traffic: value
      };
      if (areThereTwoProviderDetails) {
        currentTargetingRules.provider_details[1] = {
          ...currentTargetingRules.provider_details[1],
          traffic: Math.abs(100 - value)
        };
      } else {
        currentTargetingRules.provider_details.push({
          traffic: Math.abs(100 - value)
        });
      }
    }
    //if provider_details don't exist
    else {
      currentTargetingRules.provider_details = [];
      currentTargetingRules.provider_details.push({
        traffic: value
      });
      if (isValueNot100OrEmpty) {
        currentTargetingRules.provider_details.push({
          traffic: Math.abs(value - 100)
        });
      }
    }
    //dispatching the changes to the redux store
    newTargetingRules[originalIndex] = currentTargetingRules;
    dispatch(updateAdvertiserLinkGroupRules(newTargetingRules));
    handleFormTouched();
  };

  const handleTrafficTwoChange = (value, originalIndex) => {
    if (parseInt(value) > 100 || parseInt(value) < 0) return;
    let newTargetingRules = [...targetingRules];
    newTargetingRules[originalIndex] =
      { ...newTargetingRules[originalIndex] } || {};
    let currentTargetingRules = { ...newTargetingRules[originalIndex] } || {};
    let providerDetailsArrayExist = currentTargetingRules.provider_details
      ? true
      : false;
    if (providerDetailsArrayExist) {
      currentTargetingRules.provider_details[1] = {
        ...currentTargetingRules.provider_details[1],
        traffic: parseInt(value, 10)
      };
      currentTargetingRules.provider_details[0] = {
        ...currentTargetingRules.provider_details[0],
        traffic: Math.abs(100 - value)
      };
    } else {
      //if first provider detail does not exist
      //don't do anything
      return;
    }
    //dispatching the changes to the redux store
    newTargetingRules[originalIndex] = currentTargetingRules;
    dispatch(updateAdvertiserLinkGroupRules(newTargetingRules));
    handleFormTouched();
  };

  // calculate daily cap and set total daily cap on link properties
  const calculateAndSetDailyCap = (originalIndex, value) => {
    let totalDailyCap = 0;
    for (let i = 0; i < targetingRules.length; i++) {
      if (!targetingRules[i].disabled) {
        if (i === originalIndex) {
          totalDailyCap = totalDailyCap + Number(value);
        } else {
          totalDailyCap = totalDailyCap + Number(targetingRules[i].daily_cap);
        }
      }
    }

    targeting.daily_cap = Number(totalDailyCap);
    dispatch(updateAdvertiserLinkGroupRules(targeting));
    setDailyCap(totalDailyCap);
  };

  // calculate and set weight daily percentage
  const calculateAndSetDailyFrequency = (originalIndex = "", value = "") => {
    const totalDailyCap = calculateTotalDailyCap(targetingRules);

    let targetingRulesIndex = [];
    let targetingRulesWeight = [];

    for (let i = 0; i < targetingRules.length; i++) {
      if (!targetingRules[i].disabled) {
        if (i === originalIndex) {
          targetingRulesWeight.push(
            Number((value / totalDailyCap) * 100).toFixed(5)
          );
        } else {
          targetingRulesWeight.push(
            Number((targetingRules[i].daily_cap / totalDailyCap) * 100).toFixed(
              5
            )
          );
        }
        targetingRulesIndex.push(i);
      }
    }
    targetingRulesWeight = percentRound(targetingRulesWeight);

    // bind targeting rules index with it's corresponding weight
    for (let i = 0; i < targetingRulesIndex.length; i++) {
      targetingRules[targetingRulesIndex[i]].daily_frequency =
        targetingRulesWeight[i];
    }
  };

  //Original Index -> refers to the index of the rule block
  const handleChange = (name, value, originalIndex) => {
    // console.log({ name, value, original Index });
    newTargetingRules[originalIndex] = newTargetingRules[originalIndex] || {};
    let currentTargetingRules = { ...newTargetingRules[originalIndex] } || {};
    //handling value change for daily_cap, daily_frequency, comment
    if (
      name === "daily_cap" ||
      name === "daily_frequency" ||
      name === "comment" ||
      name === "revenue_share_percentage"
    ) {
      if (name === "daily_cap") {
        calculateAndSetDailyCap(originalIndex, value);
        if (!isWeightFieldTouched) {
          calculateAndSetDailyFrequency(originalIndex, value);
        }
        // new current targeting rules after calculateAndSetWeightPercentage() changes the value of daily frequency
        newTargetingRules[originalIndex] = targetingRules[originalIndex] || {};
        currentTargetingRules = { ...newTargetingRules[originalIndex] } || {};
      } else if (name === "daily_frequency") {
        // stop automatic calculation of daily frequency (weight percentage)
        setIsWeightFieldTouched(true);
      }
      handleValuesChange(name, value, currentTargetingRules, originalIndex);
    }
    handleFormTouched();
  };

  const changeStatus = (originalIndex, disabled) => {
    let newTargetingRules = [...targetingRules];
    newTargetingRules[originalIndex] =
      { ...newTargetingRules[originalIndex] } || {};
    let currentTargetingRules = { ...newTargetingRules[originalIndex] } || {};
    currentTargetingRules.disabled = !disabled;
    newTargetingRules[originalIndex] = currentTargetingRules;

    /*
          calculate weight percentage automatically if weight percentage field
          is not changed else only set daily cap value of link properties on the top
        */
    if (!isWeightFieldTouched) {
      setDailyCap(calculateTotalDailyCap(newTargetingRules));
      const updatedTargetingRules =
        updateTargetingRulesBeforeDispatch(newTargetingRules);
      dispatch(updateAdvertiserLinkGroupRules(updatedTargetingRules));
    } else {
      setDailyCap(calculateTotalDailyCap(newTargetingRules));
      dispatch(updateAdvertiserLinkGroupRules(newTargetingRules));
    }
    handleFormTouched();
  };

  const changeStatusByIndices = useCallback(
    (collectiveIndex, disabled, newRuleBlock) => {
      let updatedTargetingRules = [];
      let newTargetingRules = [...targetingRules];
      let removeIndexList = [];

      for (const originalIndex of collectiveIndex) {
        newTargetingRules[originalIndex] =
          { ...newTargetingRules[originalIndex] } || {};
        let currentTargetingRules =
          { ...newTargetingRules[originalIndex] } || {};
        if (!currentTargetingRules.id) {
          currentTargetingRules = { ...newRuleBlock, disabled: !disabled };
          removeIndexList.push(originalIndex);
        } else {
          currentTargetingRules = {
            ...currentTargetingRules,
            disabled: !disabled
          };
        }
        newTargetingRules[originalIndex] = currentTargetingRules;
      }

      /*
              calculate weight percentage automatically if weight percentage field
              is not changed else only set daily cap value of link properties on the top
            */
      if (!isWeightFieldTouched) {
        setDailyCap(calculateTotalDailyCap(newTargetingRules));
        updatedTargetingRules =
          updateTargetingRulesBeforeDispatch(newTargetingRules);
      } else {
        setDailyCap(calculateTotalDailyCap(newTargetingRules));
        updatedTargetingRules = newTargetingRules;
      }
      handleFormTouched();
      stableDispatch(updateAdvertiserLinkGroupRules(updatedTargetingRules));
      return collectiveIndex.filter((item) => !removeIndexList.includes(item));
    },
    [
      stableDispatch,
      targetingRules,
      handleFormTouched,
      setDailyCap,
      isWeightFieldTouched
    ]
  );

  const updateStatusChange = () => {
    changeStatus(statusChangeValue.originalIndex, statusChangeValue.disabled);
    closeAllModals();
  };

  const closeAllModals = () => {
    setStatusChangeValue({
      originalIndex: "",
      disabled: ""
    });
    closeStatusInform();
    closeStatusChange();
  };

  const manageOldRuleStatus = (rule_id) => {
    // if already saved rule, check for the disabled rule status. It it is disabled, show status inform model otherwise change the status
    if (rule_id) {
      let oldTargetingRules = JSON.parse(oldRules);
      let ruleToDelete = oldTargetingRules.find((rule) => rule.id === rule_id);
      if (ruleToDelete.disabled) {
        openStatusInform();
        return false;
      }
    }
    return true;
  };

  const handleEnableRule = (originalIndex, disabled, rule_id) => {
    changeStatus(originalIndex, disabled);
  };

  const handleDisableRule = (originalIndex, disabled, rule_id) => {
    setStatusChangeValue({
      originalIndex,
      disabled
    });
    openStatusChange();
  };
  // handler for disable/enable button for targeting rule
  const handleDisabledChange = (originalIndex, disabled, rule_id) => {
    if (disabled) {
      handleEnableRule(originalIndex, disabled, rule_id);
    } else {
      handleDisableRule(originalIndex, disabled, rule_id);
    }
  };

  const isProviderLinkDisabled = (id, providerLinks) => {
    let providerLink = providerLinks.find(
      (providerLink) => providerLink.value === id
    );
    return providerLink.disabled;
  };

  const handleProviderLinkOneChange = ({
    originalIndex,
    currentTargetingRules,
    providerDetailsArrayExist,
    value,
    remaining_cap,
    reusable,
    providerId
  }) => {
    if (providerDetailsArrayExist) {
      currentTargetingRules.provider_details[0] = {
        ...currentTargetingRules.provider_details[0],
        provider_id: providerId,
        provider_link: value.value,
        platform_id: value.platform_id,
        quality_score: value.quality_score,
        search_engine_id: value.search_engine_id,
        tag_type_id: value.tag_type_id
      };
      // if (reusable) {
      //   currentTargetingRules.provider_details[0].is_reusing = true;
      // }
    } else {
      //if provider_details don't exist create new array and push
      currentTargetingRules.provider_details = [];
      let provider_details_obj = {
        provider_link: value.value,
        platform_id: value.platform_id,
        quality_score: value.quality_score,
        search_engine_id: value.search_engine_id,
        tag_type_id: value.tag_type_id
      };
      // if (reusable) {
      //   provider_details_obj.is_reusing = true;
      // }
      currentTargetingRules.provider_details.push(provider_details_obj);
    }
    currentTargetingRules.daily_cap = remaining_cap;

    //dispatching the changes to the redux store
    newTargetingRules[originalIndex] = currentTargetingRules;

    if (!isWeightFieldTouched) {
      setDailyCap(calculateTotalDailyCap([...newTargetingRules]));
      const updatedTargetingRules =
        updateTargetingRulesBeforeDispatch(newTargetingRules);
      dispatch(updateAdvertiserLinkGroupRules(updatedTargetingRules));
    } else {
      setDailyCap(calculateTotalDailyCap([...newTargetingRules]));
      dispatch(updateAdvertiserLinkGroupRules(newTargetingRules));
    }
  };

  const handleProviderLinkTwoChange = ({
    originalIndex,
    currentTargetingRules,
    providerDetailsArrayExist,
    value
  }) => {
    if (providerDetailsArrayExist) {
      let areThereTwoProviderDetails =
        currentTargetingRules.provider_details.length === 2;
      if (areThereTwoProviderDetails) {
        currentTargetingRules.provider_details[1] = {
          ...currentTargetingRules.provider_details[1],
          provider_link: value
        };
      } else {
        currentTargetingRules.provider_details.push({
          provider_link: value
        });
      }
    } else {
      //if first provider detail does not exist
      //don't do anything
      return;
    }
    //dispatching the changes to the redux store
    newTargetingRules[originalIndex] = currentTargetingRules;
    dispatch(updateAdvertiserLinkGroupRules(newTargetingRules));
  };

  //handing value change for provider One Link and Provider Two Link
  /**
   *
   * @param {string} name
   * @param {object} value The value of currently selected advertiser link in dropdown
   * @param {number} originalIndex The index of rule that the user is editing
   * @param {Array} providerLinksOne The array of links in the advertiser 1 link dropdown
   * @param {Array} providerLinksTwo The array of links in advertiser 2 link dropdown
   * @returns
   */
  const handleProviderLinkChanges = (
    name,
    value,
    originalIndex,
    providerLinksOne,
    providerLinksTwo
  ) => {
    //checking if disabled link is selected
    let checkPLinks =
      name === "providerOneLink" ? providerLinksOne : providerLinksTwo;
    if (Object.keys(value).length !== 0) {
      if (isProviderLinkDisabled(value.value, checkPLinks)) {
        return;
      }
    }

    let newTargetingRules = targetingRules;
    newTargetingRules[originalIndex] = newTargetingRules[originalIndex] || {};
    let currentTargetingRules = { ...newTargetingRules[originalIndex] } || {};
    let isProviderOneLinkChanged = name === "providerOneLink";
    let isProviderTwoLinkChanged = name === "providerTwoLink";
    let isValueEmpty = Object.keys(value).length === 0;
    let providerDetailsArrayExist = currentTargetingRules.provider_details
      ? true
      : false;

    const selectedProviderLink = providerLinksOne?.find(
      (element) => element.value === value.value
    );

    let remaining_cap =
      selectedProviderLink && selectedProviderLink?.remaining_cap
        ? selectedProviderLink.remaining_cap
        : "";
    let reusable = selectedProviderLink?.reusable;
    let providerId = selectedProviderLink?.provider_id;

    if (isProviderOneLinkChanged) {
      handleProviderLinkOneChange({
        originalIndex,
        currentTargetingRules,
        providerDetailsArrayExist,
        value,
        remaining_cap,
        reusable,
        providerId
      });
    }

    if (isProviderTwoLinkChanged) {
      handleProviderLinkTwoChange({
        originalIndex,
        currentTargetingRules,
        providerDetailsArrayExist,
        isValueEmpty,
        value
      });
    }
    handleFormTouched();
  };

  return {
    handleChange,
    handleProviderChange,
    handleTrafficOneChange,
    handleTrafficTwoChange,
    handleDisabledChange,
    handleProviderLinkChanges,
    statusChangeOpen,
    statusInformOpen,
    updateStatusChange,
    closeAllModals,
    openStatusInform,
    manageOldRuleStatus,
    changeStatusByIndices
  };
};
