import { useEffect, useState } from "react";
import SimpleModalWithButton from "../SimpleModal/SimpleModalWithButton";
import Table from "../Table/Table";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import HistoryIcon from '@material-ui/icons/History';
import { Button } from "@material-ui/core";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  topInfo: {
    display: "flex",
    justifyContent: "Space-between",
    width: "100%",
    marginBottom: "1rem",
    fontSize: "15px"
  },
  header: {
    display: "flex",
    fontWeight: 500,
    fontSize: "20px",
    margin: "0.3rem 0 2rem 0"
  },
  historyIcon: {
    marginRight: '0.7rem',
  },
  historyOuterWrap: {
    width: "100%",
    marginBottom: "1.5rem"
  },
  loading: {
    margin: "0.5rem 0 1rem 0"
  }
}));

export function LatestHistoryTableModal({
  handleModalClose,
  activityLog,
  tableType,
  historyTargId = null,
  historyPage,
  setHistoryPage,
  historyLimit,
  totalHistoryCount = null,
  isLatestHistoryLoading = false,
  setStartFetchHistory
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [recordValues, setRecordValues] = useState([]);
  const [hideSeeMore, setHideSeeMore] = useState(false);

  const targetingheaders = [
    { title: "Changes on", value: "table", disableOrderBy: true },
    // { title: "Description", value: "description", disableOrderBy: true },
    { title: "Old Value", value: "oldValue", disableOrderBy: true },
    { title: "New Value", value: "newValue", disableOrderBy: true },
  ];

  const providerLinkheaders = [
    { title: "Provider Link ID", value: "provider_link_id", disableOrderBy: true },
    // { title: "Description", value: "description", disableOrderBy: true },
    { title: "Old Value", value: "old_value", disableOrderBy: true },
    { title: "New Value", value: "new_value", disableOrderBy: true },
  ];

  useEffect(() => {
    // if (isNull(recordValues) || recordValues.length === 0) {
      let logRows = [];
      let records = [];
      let accLogRows = [];
      if (tableType === "PublisherLatestHistory") {
        const activitiesLog = activityLog;

        if (activitiesLog) {
          const newActLogs = activitiesLog.map((item) => {
            accLogRows = [];
            if (item?.targeting_logs) {
              accLogRows.push({ ...item.targeting_logs, table: 'Targeting' });
            }

            if (item?.targeting_rule_logs) {
              logRows = item.targeting_rule_logs.map((val) => {
                return {...val, table: 'Targeting Rule' }
              })
              accLogRows = [ ...accLogRows, ...logRows ];
            }
            records = {
              activity_by: item?.activity_by,
              activity_time: item?.activity_time,
              records: accLogRows
            };
            return records;
          })
          setRecordValues(newActLogs);

          // calculate to hide see more
          const calc = historyPage * historyLimit;
          if (calc >= totalHistoryCount) {
            setHideSeeMore(true);
          } else {
            setHideSeeMore(false);
          }
        }
      } else {
        if (activityLog) {
          const newProvAct = activityLog.map((item) => {
            return {
              activity_by: item.user.email,
              activity_time: item.updatedAt,
              records: [{...item}]
            }
          });
          setRecordValues(newProvAct);

          // calculate to hide see more
          const calc = historyPage * historyLimit;
          if (calc >= totalHistoryCount) {
            setHideSeeMore(true);
          } else {
            setHideSeeMore(false);
          }
        };
      }
    // }
  }, [activityLog, tableType, historyLimit, historyPage, totalHistoryCount])

  const triggerShowMore = () => {
    const newPage = historyPage + 1;
    setHistoryPage(newPage);
    setStartFetchHistory(true);
  }

  return (<>
    <SimpleModalWithButton handleClose={handleModalClose} buttonText="CLOSE" hideAction={true}>
      <div className={classes.header}>
        <HistoryIcon className={classes.historyIcon} style={{ fill: theme.palette.primary.main }} />
        Latest History
        {historyTargId && tableType === "PublisherLatestHistory" ? ` of Publisher Link ID: ${historyTargId}` : ` of Provider Link ID: ${historyTargId}`}
      </div>
      {recordValues && recordValues.length > 0 && recordValues.map((logItem, index) => {
        return (
          <div className={classes.historyOuterWrap} key={index}>
            <div className={classes.topInfo}>
              <span><strong>Activity by: </strong> {logItem?.activity_by ? logItem.activity_by : logItem.user.email}</span>
              <span><strong>Activity time: </strong> {logItem?.activity_time ? moment(logItem.activity_time).format("YYYY-MM-DD hh:mm A") : moment(logItem.updatedAt).format("YYYY-MM-DD hh:mm A")}</span>
            </div>
            <Table
              tableType={tableType}
              headers={tableType === "PublisherLatestHistory" ? targetingheaders : providerLinkheaders}
              rowValues={logItem.records}
              noActions={true}
              page={1}
              setPage={() => { }}
              noIds={true}
              noCheckboxes={true}
              paginateRequired={false} />
          </div>
        )
      })}
      {isLatestHistoryLoading && <LoadingSpinner />}
      {
        recordValues && 
        recordValues.length > 0 && 
        !hideSeeMore && 
        <Button
          onClick={triggerShowMore}
          variant="contained"
          size="small"
          color="primary"
        >
          See more
        </Button>
      }
    </SimpleModalWithButton>
  </>);
}
