import { Button, TextField } from "@material-ui/core";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import SearchIcon from "@material-ui/icons/Search";
import AutocompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import { useState } from "react";

const AdvisorFilterBox = ({
    setSearchValue, 
    advisorStatus, 
    setAdvisorStatus,
    statusFilterList,
    affirmitiveText = "submit",
    dessentingText = "cancel",
    partnerFilterList,
    advisorPartner,
    setAdvisorPartner,
    setOrderBy,
    setOrderDirection,
}) => {
    const [query, setQuery] = useState("");

    const handleStatusFilterChange = (value) => {
        setAdvisorStatus(value);
    }

    const handlePartnerFilterChange = (value) => {
        setAdvisorPartner(value);
        setOrderBy("");
        setOrderDirection("");
    }

    const handleSearchButton = () => {
        setSearchValue(query);
    };

    const handleResetFilter = () => {
        setQuery("");
        setSearchValue("");
        setAdvisorStatus("active");
        setAdvisorPartner("");
        setOrderBy("id");
        setOrderDirection("DESC");
    };

    return (
        <div className="flow-content advisors-filter__container">
            <div className="advisors-filter__selections">
                <TextField
                    variant="standard"
                    margin="normal"
                    label="Search by title"
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                    onKeyPress={(e) => {
                        e.key === "Enter" && handleSearchButton();
                    }}
                    value={query}
                    fullWidth
                />
                <AutocompleteSelect
                    selected={advisorStatus && statusFilterList.some(item => item.value === advisorStatus) ? advisorStatus : ""}
                    data={statusFilterList || []}
                    handleChange={(value) => handleStatusFilterChange(value)}
                    label={"Status"}
                    showValueInLabel={false}
                    enableMultiple={false}
                    withDotsForStatus={false}
                    cannotSelectDisabled={true}
                />

                <AutocompleteSelect
                    selected={advisorPartner && partnerFilterList.some(item => item.value === advisorPartner) ? advisorPartner : ""}
                    data={partnerFilterList || []}
                    handleChange={(value) => handlePartnerFilterChange(value)}
                    label={"Partner"}
                    showValueInLabel={false}
                    enableMultiple={false}
                    withDotsForStatus={false}
                    cannotSelectDisabled={true}
                />
        
                <div className="advisors-filter__actions">
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={handleSearchButton}
                        startIcon={<SearchIcon />}
                    >
                        {affirmitiveText}
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        onClick={handleResetFilter}
                        startIcon={<ClearAllIcon />}
                    >
                        {dessentingText}
                    </Button>
                </div>
            </div >
        </div >
    );
}

export default AdvisorFilterBox;
