import PublisherActionTypes from "./publisher.types";

const INITIAL_STATE = {
  error: null,
  isFetchingPublisher: false,
  isFetchingPublishersList: false,
  publishers: [],
  selectedFilter: {
    query: '',
    active: false,
    inActive: false,
    isLive: null
  }
};

const publisherReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PublisherActionTypes.FETCH_PUBLISHER_START:
      return {
        ...state,
        isFetchingPublisher: true
      };
    case PublisherActionTypes.FETCH_PUBLISHER_SUCCESS:
      return {
        ...state,
        isFetchingPublisher: false
      };
    case PublisherActionTypes.FETCH_PULISHER_FAILURE:
      return {
        ...state,
        isFetchingPublisher: false,
        error: action.payload
      };
    case PublisherActionTypes.ADD_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload
      };
    case PublisherActionTypes.FETCH_PUBLISHERS_LIST_START:
      return {
        ...state,
        isFetchingPublishersList: true
      };
    case PublisherActionTypes.FETCH_PUBLISHERS_LIST_SUCCESS:
      return {
        ...state,
        isFetchingPublishersList: false
      };
    case PublisherActionTypes.FETCH_PUBLISHERS_LIST_FAILURE:
      return {
        ...state,
        isFetchingPublishersList: false,
        error: action.payload
      };
    case PublisherActionTypes.UPDATE_PUBLISHERS_LIST:
      return {
        ...state,
        publishers: action.payload
      };
    case PublisherActionTypes.SET_PUBLISHER_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: action.payload
      };
    default:
      return { ...state };
  }
};

export default publisherReducer;
