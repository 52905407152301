import React, { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
/**
 * Styling for accordion
 */
const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 200,
    margin: 0,
    padding: 0,
    color: "#333",
    "@media screen and (max-width: 599px)": {
      fontSize: theme.typography.pxToRem(14)
    }
  },
  expandPanel: {
    "> ": {
      margin: 0
    },
    margin: 0,
    padding: 0,
    paddingLeft: 10
  },
  smallHeading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    margin: 0,
    padding: "0px 6px",
    color: "#333",
    "@media screen and (max-width: 599px)": {
      fontSize: theme.typography.pxToRem(10)
    }
  },
}));
/**
 *
 * param: header i.e title and children i.e body of the
 * accordion
 * returns: Accordion
 */
const SimpleAccordion = ({
  header,
  children,
  defaultExpanded = false,
  type = null,
  expanded = null
}) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(expanded || defaultExpanded || false);

  const handleChange = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Accordion
      className={classes.sizeOptions}
      defaultExpanded={defaultExpanded}
      expanded={isExpanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.expandPanel}
      >
        <Typography
          className={type === "small" ? classes.smallHeading : classes.heading}
        >
          {header}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default SimpleAccordion;
