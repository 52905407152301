import React from "react";
import PropTypes from "prop-types";

import { Tooltip, IconButton } from "@material-ui/core";
import TargetIcon from "@material-ui/icons/OfflineBoltSharp";

const RelatedProviderLinkIcon = ({ openModal, noOfRules, noOfActiveRules }) => {
    return (
        <>
            <Tooltip
                title={`${noOfRules > 0 ? "Display Related Rule and Link" : "No Related Rule and Link"}`}
            >
                <IconButton
                    style={{ color: noOfActiveRules > 0 ? "var(--color-primary)" : noOfRules > 0 ? "orange" : "default" }}
                    onClick={(event) => {
                        event.stopPropagation();
                        noOfRules > 0 && openModal();
                    }}
                >
                    <TargetIcon />
                </IconButton>
            </Tooltip>
        </>
    );
};

RelatedProviderLinkIcon.propTypes = {
  openModal: PropTypes.func.isRequired,
  noOfRules: PropTypes.number,
  noOfActiveRules: PropTypes.number
};

export default RelatedProviderLinkIcon;
