import AdvisorActionTypes from "./advisor.types";

export const updateAdvisors = (advisors) => ({
    type: AdvisorActionTypes.UPDATE_ADVISORS,
    payload: advisors
});

export const updateAdvisorsStatus = (status) => ({
    type: AdvisorActionTypes.UPDATE_ADVISORS_STATUS,
    payload: status
});

export const updateAdvisorsPartner = (status) => ({
    type: AdvisorActionTypes.UPDATE_ADVISORS_PARTNER,
    payload: status
});
