import React from 'react';
import Alert from '@material-ui/lab/Alert';

const AlertMessage = ({ type, message }) => {

    return (
        <>
            <Alert severity={type}>{message}</Alert>
        </>
    );
}

export default AlertMessage;
