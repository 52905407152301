import React from "react";
import PropTypes from "prop-types";
import { PUBLISHER_TAG_ADVERTISER_LINKS } from "../../../helpers/constant/types";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import RelatedProviderLinkModal from "../../ProviderLinks/RelatedProviderLinkModal";
import { useRelatedLinkRulesModalLogic } from "../logics/rule-block/relatedLinkRulesModal.logic";

const ProviderLinkAccountSelector = ({
    selected,
    data,
    handleProviderLinkChanges,
    error,
    originalIndex,
    name,
    label,
    providerLinksOne,
    providerLinksTwo,
    customClasses = "",
    disableChange
}) => {
    const [
        modalOpen,
        openRelatedLinkModal,
        closeRelatedLinkModal,
        targetObj,
        currentlySelectedID
    ] = useRelatedLinkRulesModalLogic({ data, selected });

    return (
      <>
        <AutoCompleteSelect
          selected={selected ? selected : ""}
          data={data}
          handleChange={(value) => {
            handleProviderLinkChanges(
              name,
              value,
              originalIndex,
              providerLinksOne,
              providerLinksTwo
            );
          }}
          label={label}
          showValueInLabel={true}
          enableMultiple={false}
          optionsTooltip={false}
          valueTooltip={false}
          error={error}
          type={PUBLISHER_TAG_ADVERTISER_LINKS}
          extraDependencies={{ openModal: openRelatedLinkModal }}
          withDotsForStatus={true}
          cannotSelectDisabled={true}
          customClasses={customClasses}
          disabled={disableChange}
        />

        <RelatedProviderLinkModal
          modalOpen={modalOpen}
          closeModal={closeRelatedLinkModal}
          targetObj={targetObj}
          linkID={currentlySelectedID}
        />
      </>
    );
};

ProviderLinkAccountSelector.propTypes = {
    selected: PropTypes.any.isRequired,
    data: PropTypes.array.isRequired,
    error: PropTypes.bool.isRequired,
    originalIndex: PropTypes.any.isRequired,
    providerLinksOne: PropTypes.array.isRequired,
    providerLinksTwo: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired
};

export default ProviderLinkAccountSelector;
