import { toastr } from "react-redux-toastr";
import { Domain } from "../../../services/Domain";
import { useState } from "react";

export const useDomainTableLogic = (openEditForm, getDomains) => {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(null); // stores id
  const headers = [
    { title: "ID", value: "id" },
    { title: "Domain", value: "domain" }
  ];

  function handleEditAction(id) {
    openEditForm(id);
  }

  function confirmDomainDelete(id) {
    setConfirmDelete(id);
  }

  async function handleDelete(id) {
    setConfirmDelete(null);
    try {
      setIsLoading(true);
      const domain = await Domain.deleteDomain(id);
      setIsLoading(false);
      if (domain.success) {
        getDomains();
        toastr.success("Success", domain.msg);
      } else {
        throw new Error(domain?.error?.message || "Unable to delete Domain");
      }
    } catch (error) {
      setIsLoading(false);
      toastr.warning("Oops!", error.message);
    }
  }

  const actions = [
    {
      name: "edit",
      action: handleEditAction,
      icon: "editIcon"
    },
    {
      name: "delete",
      action: confirmDomainDelete,
      icon: "delete"
    }
  ];

  return {
    headers,
    actions,
    confirmDelete,
    handleDelete,
    isLoading,
    setConfirmDelete
  };
};
