/**
 * Function to add comma (,) after every 3 digit
 * @param {number} data
 * @returns
 */
export function formatNumberAddComma(data){
    if (!data) {
      return data;
    }

    if (data && typeof data === "string") {
      return parseFloat(data).toLocaleString();
    }
    return data.toLocaleString();

}
