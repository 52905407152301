import React from "react";
import PropTypes from "prop-types";
import UploadBox from "./UploadBox";
import NoMappedFieldMsg from "./NoMappedFieldMsg";
import { useRedirectLogic } from "../../../../hooks/useRedirectLogic";
import moment from "moment/moment";


const AdvertiserListBox = ({ advertiser }) => {
    const { redirectToAdvertiserPubAccounts } = useRedirectLogic();
    const { name, id, mapping, last_execution_date } = advertiser;
    return (
        <div className="csv-upload__advertisers">
            <div>
                <p style={{ fontSize: "1rem", marginBottom: "0" }}
                // className={`${advertiser.status === "active"
                //   ? "csv-upload__active"
                //   : "csv-upload__inactive"
                //   }`}
                >
                    <a style={{ textDecoration: 'none', color: "inherit" }} href={`/advertisers/edit/${id}`} rel="noopener noreferrer" target="_blank">
                        {`${id}-${name}`}
                    </a>
                    <button style={{ "margin": "2px", "marginLeft": "0.8rem", "padding": "4px" }} onClick={() => {
                        redirectToAdvertiserPubAccounts(id)
                    }} type="button">Linked Accounts</button>
                </p>
                <span style={{ "fontSize": "0.7rem", fontWeight: "regular", color: last_execution_date ? "blue" : "red" }}>
                    Last upload: {last_execution_date ? moment(last_execution_date).format('dddd, MMMM D, YYYY hh:mm:ss A') : 'No data'}
                </span>
            </div>
            {mapping && <UploadBox providerId={id} />}
            {!mapping && <NoMappedFieldMsg providerId={id} />}
        </div >
    );
};

AdvertiserListBox.propTypes = {
    advertiser: PropTypes.object.isRequired
};

export default AdvertiserListBox;
