import React from "react";
import PropTypes from "prop-types";

const DuplicateLinksMsg = ({ duplicatedIds }) => {
    return (
        <div className="target__duplicate-links-msg">
            <p className="error-text">
                The following advertiser links with the ID are duplicated:{" "}
                {duplicatedIds.map((id, index) => {
                    return <span key={index}>{id}</span>;
                })}
            </p>
        </div>
    );
};

DuplicateLinksMsg.propTypes = {
    duplicatedIds: PropTypes.array.isRequired
};

export default DuplicateLinksMsg;
