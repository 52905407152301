import Api from "../api";

class Domain {
  fetchDomainList = async (
    params = { order_by: "id", order_direction: "ASC" }
  ) => {
    const { body } = await Api.get(`domain/api/list`, null, params);
    return body;
  };

  addDomain = async (data) => {
    const { body } = await Api.post(`domain/api/add`, data);
    return body;
  };

  updateDomain = async (id, data, params = {}) => {
    const { body } = await Api.put(`domain/api/update/${id}`, data, params);
    return body;
  };

  fetchDomain = async (id) => {
    const { body } = await Api.get(`domain/api/${id}`);
    return body;
  };

  deleteDomain = async (id) => {
    const { body } = await Api.destroy(`domain/api/delete/${id}`, {});
    return body;
  };
}

// eslint-disable-next-line
export default new Domain();
