import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";


const IsLiveProviderLinkIcon = ({ is_live = false }) => {
  return (
    <>
      <IconButton
        color={is_live ? "primary" : "secondary"}
      >
        {/* <Brightness1 /> */}
        <span style={{ color: is_live ? 'green' : 'red', verticalAlign: 'middle', fontSize: '0.8rem', fontWeight: 'bold' }}>{is_live ? 'LIVE' : 'NOT LIVE'}</span>
      </IconButton>
    </>
  );
};

IsLiveProviderLinkIcon.propTypes = {
  is_live: PropTypes.bool
};

export default IsLiveProviderLinkIcon;
