import Api from "../api";

class User {
  addUser = async (data) => {
    const { body } = await Api.post("user", data);
    return body;
  };

  fetchUser = async (id) => {
    const { body } = await Api.get(`user/${id}`);
    return body;
  };

  fetchRole = async () => {
    const { body } = await Api.get(`role`);
    return body;
  };

  fetchAccount = async () => {
    const { body } = await Api.get(`account`);
    return body;
  };

  updateUser = async (id, data, params = {}) => {
    const { body } = await Api.put(`user/${id}`, data, params);
    return body;
  };

  postStatus = async (id, data, params = {}) => {
    const { body } = await Api.put(`user/status/${id}`, data, params);
    return body;
  };

  fetchUsers = async (params = { order_by: "id", order_direction: "ASC" }) => {
    const { body } = await Api.get(`user`, null, params);
    return body;
  };

  deleteUser = async (id, params = {}) => {
    const { body } = await Api.destroy(`user/${id}`, {}, params);
    return body;
  };

  updatePassword = async (data, userid) => {
    const { body } = await Api.put(`user/changePassword/${userid}`, data);
    return body;
  };

  fetchPublisherDisplayColumns = async (userId) => {
    const { body } = await Api.get(`publisher/display/${userId}`);
    return body;
  };

  postPublisherDisplayColumns = async (userId, data) => {
    const { body } = await Api.post(`publisher/display/${userId}`, data);
    return body;
  };

  updatePublisherDisplayColumns = async (userId, data) => {
    const { body } = await Api.put(`publisher/display/${userId}`, data);
    return body;
  };
}
// eslint-disable-next-line
export default new User();
