import { TargetActionTypes } from "./target.action";
import { TARGETING_TYPES } from "../../helpers/constant/types";

const INITIAL_STATE = {
  publisher: "",
  domain: "",
  subID: null,
  clientID: null,
  // oID: null,
  useN: false,
  useClickId: false,
  warmupMode: true,
  useSubId: false,
  notes: "",
  tagDescription: "",
  status: "draft",
  targeting: TARGETING_TYPES.ROUND_ROBIN,
  fallback: "",
  link: "",
  updatedAt: "",
  publishedAt: "",
  is_active: true,
  cusDomain: false,
  cusFallback: false,
  cusOvercapFallback: false,
  overcapFallback: "",
  targetingRules: [],
  targetingRulesError: [],
  targetingLists: [],
  deletedTargetingRules: [],
  oldTargetingRules: [],
  selectedFilter: {
    query: '',
    publisher: '',
    isLive: null,
    tagId: '',
    published: false,
    draft: false,
  },
};

const targetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TargetActionTypes.UPDATE_TARGETING_RULES:
      return {
        ...state,
        targetingRules: action.payload
      };
    case TargetActionTypes.UPDATE_TARGETING_FORM_ERRORS:
      return {
        ...state,
        targetingRulesError: action.payload
      };
    case TargetActionTypes.CLEAR_TARGETING_FORM:
      return {
        ...state,
        publisher: "",
        domain: "",
        subID: "",
        clientID: "",
        // oID: "",
        useN: false,
        useClickId: false,
        warmupMode: true,
        useSubId: false,
        notes: "",
        tagDescription: "",
        status: "draft",
        targeting: "round-robin",
        fallback: "",
        link: "",
        updatedAt: "",
        publishedAt: "",
        is_active: true,
        cusDomain: false,
        targetingRules: [],
        targetingRulesError: [],
        deletedTargetingRules: []
      };
    case TargetActionTypes.UPDATE_TARGETING_FORM:
      return {
        ...state,
        ...action.payload
      };
    case TargetActionTypes.UPDATE_TARGETING_LISTS:
      return {
        ...state,
        targetingLists: action.payload
      };
    case TargetActionTypes.UPDATE_DELETED_TARGETING_RULES:
      return {
        ...state,
        deletedTargetingRules: action.payload
      };
    case TargetActionTypes.SET_TARGET_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: action.payload
      };
    default:
      return { ...state };
  }
};

export default targetReducer;
