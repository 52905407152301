import { useState } from "react";
import moment from "moment";
import { Report } from "../../../services/Report";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useModal } from "../../../hooks/useModal";
/* Logic to remove report data */
export const useReportDataRemove = (publisherAccountId) => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isValid, setIsValid] = useState(true);

    const [removeModal, openRemoveModal, closeRemoveModal] = useModal(false);

    const history = useHistory();

    const [loadingRemovalResult, setLoadingRemovalResult] = useState({
        loading: false,
        error: false,
        success: false,
        deletedCount: 0
    });

    const handleToDate = (date) => {
        //date to be set is null , set it and return
        if (!date) {
            setToDate(null);
            return;
        }
        if (!fromDate) {
            setToDate(date);
        } else {
            if (moment(date).isAfter(fromDate) || moment(date).isSame(fromDate)) {
                setToDate(date);
            } else setToDate(fromDate);
        }
    };

    const handleFromDate = (date) => {
        //date to be set is null , set it and return
        if (!date) {
            setFromDate(null);
            return;
        }
        if (!toDate) {
            setFromDate(date);
        } else {
            if (moment(date).isBefore(toDate) || moment(date).isSame(toDate)) {
                setFromDate(date);
            } else {
                setToDate(date);
                setFromDate(date);
            }
        }
    };

    const handleDateChange = (fromDate, toDate) => {
        setLoadingRemovalResult({
            ...loadingRemovalResult,
            success: false
        });
        handleFromDate(fromDate);
        handleToDate(toDate);
        setIsValid(true);
    };

    const handleRemove = async () => {
        setLoadingRemovalResult({
            loading: true,
            error: false,
            success: false,
            deletedCount: 0
        });
        try {
            const removalResult = await Report.removeReportData(publisherAccountId, {
                from: moment(fromDate).format("YYYY-MM-DD"),
                to: moment(toDate).format("YYYY-MM-DD")
            });
            if (removalResult.success) {
                toastr.success(
                    "Success",
                    `Successfully removed ${removalResult.data.deleted} rows of data.`
                );
                setLoadingRemovalResult({
                    loading: false,
                    error: false,
                    success: true,
                    deletedCount: removalResult.data.deleted
                });
            } else {
                throw new Error("Error while removing data");
            }
        } catch (error) {
            setLoadingRemovalResult({
                loading: false,
                error: true,
                success: false,
                deletedCount: 0
            });
            toastr.error("Error", "Sorry, unable to remove data");
        }
    };

    const validate = () => {
        if (!fromDate || !toDate) {
            setIsValid(false);
            return false;
        }
        return true;
    };

    const confirmRemoveAction = async () => {
        closeRemoveModal();
        await handleRemove();
    };

    const handleRemoveAction = async () => {
        if (!validate()) return;
        openRemoveModal();
    };

    // Redirect to Publisher Ad Account List Page
    const handleCancelAction = () => {
        history.push("/pubaccount");
    };

    return {
        fromDate,
        toDate,
        handleFromDate,
        handleToDate,
        handleDateChange,
        loadingRemovalResult,
        handleRemoveAction,
        isValid,
        handleCancelAction,
        confirmRemoveAction,
        removeModal,
        closeRemoveModal
    };
};
