import React from "react";
import PropTypes from "prop-types";

import { InfoRounded } from "@material-ui/icons";
import InfoToolTip from "../../components/InfoToolTip/InfoToolTip";
import LinkInfo from "./layouts/LinkInfo";
import { IconButton } from "@material-ui/core";

const LinkPropertiesIcon = ({ link }) => {
  return (
    <>
      <InfoToolTip title={<LinkInfo link={link} />} className="targeting-option__info">
        <IconButton>
          <InfoRounded style={{ verticalAlign: 'center' }} color="primary" />
        </IconButton>
      </InfoToolTip>
    </>
  );
};

LinkPropertiesIcon.propTypes = {
  link: PropTypes.object.isRequired
};

export default LinkPropertiesIcon;
