import React from "react";
import Stats from "./Stats";
import ReportTable from "./Table/ReportTable";
import DisplayNamesInfoMsg from "../mapping/CsvFieldMapping/Layout/DisplayNamesInfoMsg";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ColumnFilters from "../../components/ColumnsFilter/ColumnsFilter";
import SimpleAccordion from "../../components/Accordion/Accordion";

const ReportsBody = ({
  dashboardDataHook,
  displayNames,
  loadingDisplayNames,
  tableLogicHook,
  orderBy,
  orderDirection,
  setOrderBy,
  setOrderDirection
}) => {
  const { dashboardData, loadingDashboardData } = dashboardDataHook;

  if (loadingDashboardData || loadingDisplayNames) {
    return (
      <div className="loading-div">
        <LoadingSpinner />
      </div>
    );
  }
  if (!displayNames) {
    return <DisplayNamesInfoMsg />;
  }
  if (!dashboardData) {
    return <pre>No data to display</pre>;
  }
  const { tableHeaders, filteredTableHeaders, updateHeadersShowValue } =
    tableLogicHook;

  let { list, stats, total } = dashboardData;
  let reportStats = stats?.[0] || {};
  let { fields } = displayNames || {}; //Mapped Display Name Field
  return (
    <div className="dashboard-report flow-content">
      {/* <h2 className="dashboard-subheading">Report Table</h2> */}
      <Stats reportStats={reportStats} fields={fields} />
      {!loadingDashboardData && list && list?.length > 0 && (
        <>
          <SimpleAccordion header="Show Table Columns" defaultExpanded={true}>
            <ColumnFilters
              columns={tableHeaders}
              handleCheckboxChange={updateHeadersShowValue}
              dynamicTableFilters={true}
            />
          </SimpleAccordion>
        </>
      )}

      <ReportTable
        reportData={list ? list : []}
        loadingDashboardData={loadingDashboardData}
        total={total ? total : 0}
        dashboardDataHook={dashboardDataHook}
        tableHeaders={filteredTableHeaders}
        orderBy={orderBy}
        orderDirection={orderDirection}
        setOrderBy={setOrderBy}
        setOrderDirection={setOrderDirection}
      />
    </div>
  );
};

export default ReportsBody;
