const uniqueMap = new Map();

// Function to add items to the unique map
const addToUniqueMap = (array, unqiueId) => {
  array.forEach(item => {
    if (!uniqueMap.has(item[unqiueId])) {
      uniqueMap.set(item[unqiueId], item);
    }
  });
};

export const uniqueMapCombined = (uniqueId, array1, array2) => {
  // Add items from array1 to the unique map
  addToUniqueMap(array1, uniqueId);

  // Add items from array2 to the unique map
  addToUniqueMap(array2, uniqueId);

  const combinedArray = Array.from(uniqueMap.values());
  return combinedArray;
}
