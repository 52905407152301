import { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";

import { roundUpString } from "../../../../../helpers/math.helper";
import { Report } from "../../../../../services/Report";

export const useFetchStatsLogic = () => {
  const [statsData, setStatsData] = useState(null);
  const [loadingStatsData, setLoadingStatsData] = useState(false);

  const fetchStats = () => {
    Report.fetchMonthlyStatsGCP()
      .then((response) => {
        if (response.stats) {
          let stats_data = {
            net_revenue: roundUpString(response.stats.net_revenue, 3),
            monetized_searches: roundUpString(
              response.stats.monetized_searches,
              0
            ),
            total_searches: roundUpString(response.stats.total_searches, 0)
          };
          setStatsData([stats_data]);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
        // changing state inside timeout to prevent slow UI re-rendering
        // of reporting table
        setTimeout(() => {
          setLoadingStatsData(false);
        }, 800);
      })
      .catch((error) => {
        toastr.error("Oops", "Unable to load dashboard report data!");
        setLoadingStatsData(false);
        setStatsData(null);
        console.trace(error.message);
      });
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return {
    statsData,
    loadingStatsData
  };
};
