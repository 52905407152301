import React from "react";
import { useZapButtonLogic } from "../logics/zapButton.logic";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
const ZapButtons = ({ targetingID }) => {
  const { loadingTargetingList, zapButtonAction, noOfTargeting } =
    useZapButtonLogic(targetingID);

  if (
    loadingTargetingList.loading ||
    loadingTargetingList.error ||
    noOfTargeting === 0 ||
    !targetingID
  ) {
    return <></>;
  }

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <Tooltip title="Go to previous Link">
        <IconButton
          aria-label="previous link"
          onClick={() => zapButtonAction("previous")}
        >
          <ArrowBackIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Go to next Link">
        <IconButton
          aria-label="next link"
          onClick={() => zapButtonAction("next")}
        >
          <ArrowForwardIcon color="primary" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ZapButtons;
