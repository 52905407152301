import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

import { findObject } from "../../helpers";
import { EMPTY_SELECTION } from "../../helpers/constant/filters";
import NormalOption from "./Layouts/NormalOption";
import TooltipTextField from "./Layouts/TooltipTextField";
import TooltipOption from "./Layouts/TooltipOption";
import NormalTextField from "./Layouts/NormalTextField";
import { PUBLISHER_TAG_ADVERTISER_LINKS } from "../../helpers/constant/types";
import PtLinksTooltipOption from "./Layouts/PtLinksTooltipOption";
import { toastr } from "react-redux-toastr";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

// constants
import * as Constants from "../../pages/target/constants";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(3)
        }
    },
    error: {
        borderBottom: "1px solid red"
    },
    muiEnd: {
        top: "auto"
    }
}));

function getLabel(data, showValueInLabel) {
    if (data && data.label) {
        return showValueInLabel ? `${data.value} - ${data.label}` : `${data.label}`;
    } else {
        return "";
    }
}

function updateParentSelectedValues(
    enableMultiple,
    handleChange,
    value,
    label
) {
    if (!value) {
        enableMultiple ? handleChange([]) : handleChange("");
        return;
    }
    let output;
    if (enableMultiple) {
      //create output array
      output = [];
      //loop through the current selected values
      // for advertiser link specific output pattern
      label === Constants.ADVERTISER_LINK
        ? value.forEach((v) =>
            output.push({
              value: v.value,
              platform_id: v.platform_id,
              quality_score: v.quality_score,
              search_engine_id: v.search_engine_id,
              tag_type_id: v.tag_type_id,
              group: v?.group
            })
          )
        : value.forEach((v) => output.push(v.value)); //add only the values to the output array
    } else {
      // for advertiser link specific output pattern
      label === Constants.ADVERTISER_LINK
        ? (output = {
            value: value.value,
            platform_id: value.platform_id,
            quality_score: value.quality_score,
            search_engine_id: value.search_engine_id,
            tag_type_id: value.tag_type_id,
            group: value?.group
          })
        : (output = value.value); //get only the value from the selected
    }
    // use handle change to change the parent selected value
    handleChange(output);
}

async function inputChangeHandler(newInputValue, handleInputChange) {
    await handleInputChange(newInputValue);
}

function onKeyDownAction(event, handleKeyDown) {
    handleKeyDown(event);
}

function getRefactoredValues(enableMultiple, list, value) {
    if (!value) {
        return enableMultiple ? [] : EMPTY_SELECTION;
    }
    let output;
    if (enableMultiple) {
        //create empty output array
        output = [];
        //loop through selected values
        value.forEach((option) => {
            //find the array object
            let object = findObject(list, option);
            //add to output array
            output.push(object);
        });
    } else {
        //find the array object and assign it to output
        output = findObject(list, value);
    }
    //return output
    return output;
}

/**
 * Function that ensures the value used by autocomplete is
 * never undefined to prevent uncontrolled and controlled error
 */
function getSafeValue(value, enableMultiple) {
    if (value === undefined) {
        return enableMultiple ? [] : "";
    }
    return value;
}

export default function AutocompleteSelect({
  error,
  selected,
  handleChange,
  label,
  data,
  showValueInLabel = true,
  enableMultiple = false,
  withDotsForStatus = false,
  optionsTooltip = false,
  valueTooltip = false,
  type = "default",
  extraDependencies = {},
  cannotSelectDisabled = false,
  disabled = false,
  customClasses = "",
  triggerOnKeyDown = false,
  handleInputChange,
  handleKeyDown,
  clearInputValue,
  inputValue = "",
  loading = false
}) {
  const [value, setValue] = useState(enableMultiple ? [] : "");
  const classes = useStyles();

  let enabledMenuClassName, disabledMenuClassName;

  enabledMenuClassName = withDotsForStatus
    ? "autoselect-value--dot--enabled"
    : "autoselect-value--enabled";
  disabledMenuClassName = withDotsForStatus
    ? "autoselect-value--dot--disabled"
    : "autoselect-value--disabled";

  useEffect(() => {
    let currentValues = getRefactoredValues(enableMultiple, data, selected);
    setValue(currentValues);
  }, [selected, setValue, data, enableMultiple]);

  return (
    <>
      <div
        className={`${classes.root} ${error && classes.error} ${customClasses}`}
      >
        <Autocomplete
          multiple={enableMultiple}
          options={data}
          getOptionLabel={(data) => getLabel(data, showValueInLabel)}
          value={getSafeValue(value, enableMultiple)}
          {...(triggerOnKeyDown && { inputValue: inputValue })}
          filterSelectedOptions={type !== PUBLISHER_TAG_ADVERTISER_LINKS}
          selectOnFocus={type === PUBLISHER_TAG_ADVERTISER_LINKS}
          disabled={disabled}
          getOptionSelected={(option, value) => {
            if (value === "" || value === option) {
              return true;
            }
          }}
          renderOption={(option) => {
            if (type === PUBLISHER_TAG_ADVERTISER_LINKS) {
              return (
                <PtLinksTooltipOption
                  option={option}
                  getLabel={getLabel}
                  showValueInLabel={showValueInLabel}
                  disabledMenuClassName={disabledMenuClassName}
                  enabledMenuClassName={enabledMenuClassName}
                  extraDependencies={extraDependencies}
                />
              );
            }

            if (optionsTooltip) {
              return (
                <TooltipOption
                  option={option}
                  getLabel={getLabel}
                  showValueInLabel={showValueInLabel}
                  disabledMenuClassName={disabledMenuClassName}
                  enabledMenuClassName={enabledMenuClassName}
                />
              );
            }
            return (
              <NormalOption
                option={option}
                getLabel={getLabel}
                showValueInLabel={showValueInLabel}
                disabledMenuClassName={disabledMenuClassName}
                enabledMenuClassName={enabledMenuClassName}
              />
            );
          }}
          // getOptionDisabled={(option) => {
          //   return option?.disabled ? true : false;
          // }}
          onChange={(event, value) => {
            if (cannotSelectDisabled && value?.disabled) {
              toastr.warning(
                "Disabled Option!",
                "Please selected another option"
              );
              return;
            }
            if (triggerOnKeyDown && value && value.length > 0) {
              clearInputValue();
            }
            updateParentSelectedValues(
              enableMultiple,
              handleChange,
              value,
              label
            );
          }}
          onInputChange={(event, value) => {
            if (triggerOnKeyDown) {
              inputChangeHandler(value, handleInputChange);
            }
          }}
          renderInput={(params) =>
            valueTooltip ? (
              <TooltipTextField params={params} label={label} />
            ) : (
              <NormalTextField
                params={{
                  ...params,
                  multiline: true,
                  placeholder: "Type to search",
                  InputProps: {
                    ...params?.InputProps,
                    onKeyDown: (event) => {
                      if (triggerOnKeyDown) {
                        onKeyDownAction(event, handleKeyDown);
                      }
                    },
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <LoadingSpinner size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }
                }}
                label={label}
              />
            )
          }
          loading={loading}
        />
        {/* {error && <div className="error-text">Please select a value.</div>} */}
      </div>
    </>
  );
}
