import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const InfoToolTip = withStyles({
  tooltip: {
    color: "#FFFEFE",
    backgroundColor: "#373846",
    minWidth: "min-content",
    minHeight: "50px",
    maxWidth: "350px",
    textAlign: "center",
    fontSize: "15px",
    padding: "5px",
    display: "grid",
    placeItems: "center"
  }
})(Tooltip);

export default InfoToolTip;
