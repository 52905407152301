import React, { useEffect, useState, useCallback } from "react";
// import FormRender from "../FormRender";
import { Provider } from "../../../services/Provider";
import CredentialsForm from "../CredentialsForm";

const Apidetails = (props) => {
  const { provider_id } = props.data;
  const [name, setName] = useState("");
  const style = { color: "#9b9595", "font-weight": "400" };

  const fetchProviders = useCallback(async () => {
    const provider = await Provider.fetchProvider(provider_id);
    if (provider.success) {
      setName(provider?.data?.provider?.name);
    }
  }, [provider_id]);

  useEffect(() => {
    fetchProviders();
  }, [fetchProviders]);

  return (
    <div className="publisher-manage">
      <div className="provider-details">
        <h2 style={style}>
          API details for the advertiser {provider_id} - {name}
        </h2>
        {/* <FormRender provider_id={provider_id} type="providerEdit" /> */}
        <CredentialsForm provider_id={provider_id} />
      </div>
    </div>
  );
};
export default Apidetails;
