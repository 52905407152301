/**
 * Component to fetch Provider Links
 */

import { useState, useEffect, useCallback } from "react";
import { Provider } from "../../../../services/Provider";
// import { toastr } from "react-redux-toastr";
// import { DEFAULT_FETCH_PARAM_SIZE } from "../../../../helpers/constant/misc";

export const useGetProviderLinks = ({
    providerOneLink,
    providerOne,
    providerTwo,
    platform,
    searchEngine,
    tagType,
    qualityScore,
    dailyCap
}) => {
    //provider links for provider one and two
    const [providerLinksOne, setProviderLinksOne] = useState([]);
    const [providerLinksTwo, setProviderLinksTwo] = useState([]);

    /**
     * Function to check if the given advertiser link is disabled or not by check the total cap and remaining cap
     * @param {boolean} is_disabled Disabled status from the api
     * @param {number} total_cap The Total cap of the advertiser link
     * @param {number} remaining_cap The remaining cap of the advertiser link
     * @returns {boolean}
     */
    function checkIfLinkDisabled(is_disabled, remaining_cap) {
        if (is_disabled) {
            return true;
        }
        if (!remaining_cap || remaining_cap === 0) {
            return true;
        }
        return false;
    }

    const fetchProviderLinks = useCallback(
        (provider, selection, linkProperty = {}) => {
            Provider.fetchProviderLinksForDropdown({
                ...(provider && { provider_id: provider }),
                platform_id: linkProperty?.platform,
                search_engine_id: linkProperty?.searchEngine,
                tag_type_id: linkProperty?.tagType,
                quality_score: linkProperty?.qualityScore,
                ...(providerOneLink && { provider_link_id: providerOneLink })
            })
                .then((response) => {
                    if (response.success) {
                        const data = response.data.rows;
                        const providerLinks = [];
                        for (let p of data) {
                            providerLinks.push({
                              label: p.link,
                              value: p.id,
                              disabled: checkIfLinkDisabled(
                                p.disabled,
                                p.remaining_cap
                              ),
                              no_of_rules: p.no_of_rules,
                              no_of_active_rules: p.no_of_active_rules,
                              daily_cap: p.daily_cap,
                              remaining_cap: p.remaining_cap,
                              targetObj: p?.targetObj || [],
                              is_live: p.is_live || false,
                              quality_score: p.quality_score,
                              reusable: p.reusable,
                              provider_id: p.provider_id,
                              search_engine_id: p.search_engine_id,
                              tag_type_id: p.tag_type_id,
                              platform_id: p.platform_id,
                              search_engine: p.search_engine,
                              platform: p.platform,
                              provider: p.provider,
                              tag_type: p.tag_type
                            });
                        }
                        if (selection === 1) {
                            setProviderLinksOne(providerLinks);
                        } else {
                            setProviderLinksTwo(providerLinks);
                        }
                    } else {
                        throw new Error(JSON.stringify(response.error));
                    }
                })
                .catch((error) => {
                    console.log({ error });
                });
        },
        [providerOneLink]
    );

    /**loading provider links when different publisher is selected */
    useEffect(() => {
        if (
            platform?.length > 0 &&
            searchEngine?.length > 0 &&
            tagType?.length > 0 &&
            qualityScore?.length > 0
        ) {
            fetchProviderLinks(providerOne, 1, {
                platform,
                searchEngine,
                tagType,
                qualityScore
            });
        } else {
            setProviderLinksOne([]);
        }
    }, [
        providerOne,
        fetchProviderLinks,
        platform,
        searchEngine,
        tagType,
        qualityScore
    ]);

    /**loading provider links when different publisher is selected */
    useEffect(() => {
        if (providerTwo) {
            fetchProviderLinks(providerTwo, 2);
        } else {
            setProviderLinksTwo([]);
        }
    }, [providerTwo, fetchProviderLinks]);

    return {
        providerLinksOne,
        providerLinksTwo
    };
};
