import {
  ADVERTISER_REPORT,
  AD_COVERAGE,
  GROSS_REVENUE,
  PUBLISHER_REVENUE,
  DATE,
  TAG_ID,
  CHANNEL,
  RULE_ID,
  GEO,
  TOTAL_SEARCHES,
  MONETIZED_SEARCHES,
  CLICKS,
  // CTR,
  NET_REVENUE,
  RPM,
  RPMM,
  RPC,
  FOLLOWON_SEARCHES,
  INITIAL_SEARCHES,
  SEARCH_ENGINE,
  LINK_PLATFORM,
  TAG_TYPE,
  PUBLISHER_AD_ACCOUNT,
  SERVER_SEARCHES_RPM,
  DIVIDED_PERCENTAGE,
  REMARKS,
  COUNTRY,
  MONETIZED_CTR,
  AD_COVERAGE_SERVER,
  PROVIDER_LINK_ID,
  QUALITY_SCORE,
  INVALID_TRAFFIC,
  SEARCH_COUNT,
  PUBLISHER_REVENUE_NEW,
  FILTERED_REASON,
  PUBLISHER_CHANNEL_ID,
  REDIRECT_FROM_PUBLISHER_CHANNEL_ID,
  PUBLISHER_ID,
  ADVERTISER_SEARCH_COUNT,
  OVERCAPPED_TRAFFIC,
  // ESTIMATED_REVENUE,
  // BUFFER,
  ADVERTISER_LINK_GROUP_ID
} from "./filters";

export const MAPPING_COLUMN_NAME = {
  date: DATE,
  subId: "channel",
  publisher: "publisher_id",
  rule_id: RULE_ID,
  tag_description: "tag_description",
  advertiser_id: ADVERTISER_REPORT,
  tag_number: "tag_number",
  geo: GEO,
  total_searches: TOTAL_SEARCHES,
  clicks: CLICKS,
  monetized_searches: MONETIZED_SEARCHES,
  gross_revenue: GROSS_REVENUE,
  net_revenue: NET_REVENUE,
  // ctr: CTR,
  rpm: RPM,
  rpmm: RPMM,
  rpc: RPC,
  monetized_ctr: MONETIZED_CTR,
  followon_searches: FOLLOWON_SEARCHES,
  initial_searches: "initial_searches"
};

export const mapping_order = [
  "date",
  // "tag_number",
  // "publisher_id",
  "rule_id",
  "advertiser_id",
  "channel",
  "geo",
  "total_searches",
  "monetized_searches",
  "gross_revenue",
  "redirected_from_cid",
  "clicks",
  // "ctr",
  "monetized_ctr",
  "net_revenue",
  "rpm",
  "rpmm",
  "rpc",
  // "tag_description",
  "followon_searches",
  "initial_searches"
];

export const csv_mapping_order = [
  "date",
  // "publisher_id",
  // "tag_number",
  "channel",
  "geo",
  "total_searches",
  "monetized_searches",
  "clicks",
  "gross_revenue",
  // "tag_description",
  "followon_searches",
  "initial_searches"
];

export const table_mapping_order = [
  DATE,
  TAG_ID,
  PUBLISHER_CHANNEL_ID,
  PUBLISHER_ID,
  REDIRECT_FROM_PUBLISHER_CHANNEL_ID,
  PUBLISHER_AD_ACCOUNT,
  CHANNEL,
  SEARCH_ENGINE,
  TAG_TYPE,
  LINK_PLATFORM,
  RULE_ID,
  ADVERTISER_REPORT,
  PROVIDER_LINK_ID,
  ADVERTISER_LINK_GROUP_ID,
  QUALITY_SCORE,
  GEO,
  "server_search_counts",
  INVALID_TRAFFIC,
  OVERCAPPED_TRAFFIC,
  TOTAL_SEARCHES,
  MONETIZED_SEARCHES,
  CLICKS,
  // CTR,
  MONETIZED_CTR,
  AD_COVERAGE,
  GROSS_REVENUE,
  PUBLISHER_REVENUE,
  NET_REVENUE,
  // ESTIMATED_REVENUE,
  // BUFFER,
  RPM,
  RPMM,
  RPC,
  SERVER_SEARCHES_RPM,
  "ad_coverage_server_percentage",
  "discrepancy_percentage",
  //   FOLLOWON_SEARCHES,
  //   INITIAL_SEARCHES,
  DIVIDED_PERCENTAGE,
  REMARKS,
  "filtered",
  FILTERED_REASON
];

export const table_mapping_order_publisher = [
  DATE,
  TAG_ID,
  // "targeting_id",
  PUBLISHER_CHANNEL_ID,
  SEARCH_ENGINE,
  TAG_TYPE,
  LINK_PLATFORM,
  COUNTRY,
  // "server_search_count",
  // "adv_search_count",
  SEARCH_COUNT,
  INVALID_TRAFFIC,
  TOTAL_SEARCHES,
  ADVERTISER_SEARCH_COUNT,
  MONETIZED_SEARCHES,
  CLICKS,
  // CTR,
  MONETIZED_CTR,
  AD_COVERAGE,
  // "net_revenue",
  PUBLISHER_REVENUE_NEW,
  RPM,
  RPMM,
  RPC,
  "ssrpm",
  FOLLOWON_SEARCHES,
  INITIAL_SEARCHES,
  AD_COVERAGE_SERVER,
  FILTERED_REASON
];
