import React from "react";
import SelectMenu from "../../../components/SelectMenu/SelectMenu";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import HelpIcon from "@material-ui/icons/Help";
import InfoToolTip from "../../../components/InfoToolTip/InfoToolTip";
import { SYSTEM_FALLBACK_HELP_TEXT } from "../../target/helper/helper-functions";
const KeyAndValueSelect = ({
  keyNames,
  selectedKey,
  handleKeyChange,
  keyValue,
  handleValueChange,
  currentIndex,
  handleDelete,
  handleFieldCloseAction
}) => {
  return (
    <>
      <div className="settings__keyValue">
        <SelectMenu
          selected={selectedKey}
          data={keyNames}
          handleChange={(e) => handleKeyChange(e, currentIndex)}
          label={"Key"}
          showValueInLabel={false}
          className="settings__keyValue__key"
        />
        <FormControl fullWidth>
          <TextField
            multiline
            variant="outlined"
            label="Value"
            value={keyValue}
            onChange={(e) => {
              handleValueChange(e, currentIndex);
            }}
            autoFocus
            placeholder={`Enter Value`}
            className="settings__keyValue__value"
          />
        </FormControl>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          {selectedKey === "system_fallback" && (
            <InfoToolTip
              title={SYSTEM_FALLBACK_HELP_TEXT}
              className="custom-fallback__info"
              style={{ marginTop: "5px" }}
            >
              <div className="gray-icon ">
                <HelpIcon fontSize="small" />
              </div>
            </InfoToolTip>
          )}
          <Tooltip title="Archive" className="settings__keyValue__action">
            <IconButton onClick={() => handleFieldCloseAction(currentIndex)}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <MoreHorizIcon className="horizontal-divider" />
    </>
  );
};

export default KeyAndValueSelect;
