import React from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import {
  Drawer,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
// icon
import { ChevronLeft } from "@material-ui/icons";
import DashboardIcon from "@material-ui/icons/DashboardSharp";
import TargetIcon from "@material-ui/icons/OfflineBoltSharp";
import PublisherIcon from "@material-ui/icons/AssignmentSharp";
import ProviderIcon from "@material-ui/icons/CardTravelSharp";
import LogoutIcon from "@material-ui/icons/ExitToAppSharp";
import { AccountCircle } from "@material-ui/icons";
// import AssessmentIcon from "@material-ui/icons/Assessment";
import SettingsIcon from "@material-ui/icons/Settings";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
//import GridOnIcon from "@material-ui/icons/GridOn";
import BlurLinearIcon from "@material-ui/icons/BlurLinear";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import GroupIcon from "@material-ui/icons/Group";
import DnsIcon from "@material-ui/icons/Dns";
import InfoIcon from "@material-ui/icons/Info";
import AdminIcon from "@material-ui/icons/VerifiedUser";
import GetAppIcon from "@material-ui/icons/GetApp";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

import RBAC from "../HOC/Rbac";
import Roles from "../routes/Role";
import { loginAsAdminDashboard } from "../services/Auth";
import { getCurrentUser } from "../helpers/AuthHelper";
import DynamicSettingLink from "./Sidebar/DynamicSettingLink";

export default function Sidebar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(getCurrentUser);
  const isAdminAvailable = Cookies.get("admin-token");
  const isPublisher = Roles.publisher.includes(currentUser.role.role);
  const returnToAdmin = async () => {
    loginAsAdminDashboard(() => {
      history.push("/publishers");
    })(dispatch);
  };

  return (
    <>
      <Drawer
        className="drawer"
        anchor="left"
        open={props.open}
        onClose={props.handleDrawerClose}
      >
        <div className="drawer__header">
          Welcome
          <IconButton onClick={props.handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </div>

        <List>
          <Link className="link" to="/">
            <ListItem button key={"Dashboard"}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItem>
          </Link>
          <RBAC allowedRoles={Roles.publisher}>
            <Link className="link" to="/publisher/apidetails">
              <ListItem button key={"Api Details"}>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary={"Api Details"} />
              </ListItem>
            </Link>
          </RBAC>

          {isAdminAvailable && isPublisher && (
            <ListItem button key={"Return to admin"} onClick={returnToAdmin}>
              <ListItemIcon>
                <AdminIcon />
              </ListItemIcon>
              <ListItemText primary={"Return to admin"} />
            </ListItem>
          )}

          <RBAC allowedRoles={Roles.admin}>
            <Link className="link" to="/publishers">
              <ListItem button key={"Publishers"}>
                <ListItemIcon>
                  <PublisherIcon />
                </ListItemIcon>

                <ListItemText primary={"Publishers"} />
              </ListItem>
            </Link>
            <Link className="link" to="/target">
              <ListItem button key={"Targeting"}>
                <ListItemIcon>
                  <TargetIcon />
                </ListItemIcon>

                <ListItemText primary={"Publisher Links"} />
              </ListItem>
            </Link>
            <Link className="link" to="/pubaccount">
              <ListItem button key={"Publisher ad Accounts"}>
                <ListItemIcon>
                  <AccountBalanceWalletIcon />
                </ListItemIcon>
                <ListItemText primary={"Publisher Ad Accounts"} />
              </ListItem>
            </Link>
            <Link className="link" to="/advertisers">
              <ListItem button key={"Advertisers"}>
                <ListItemIcon>
                  <ProviderIcon />
                </ListItemIcon>

                <ListItemText primary={"Advertisers"} />
              </ListItem>
            </Link>
            <Link className="link" to="/advertisers/links">
              <ListItem button key={"Advertiser Links"}>
                <ListItemIcon>
                  <AllInclusiveIcon />
                </ListItemIcon>
                <ListItemText primary={"Advertiser Links"} />
              </ListItem>
            </Link>
            <Link className="link" to="/advertiser-link-group">
              <ListItem button key={"Advertiser lInk Groups"}>
                <ListItemIcon>
                  <MonetizationOnIcon />
                </ListItemIcon>
                <ListItemText primary={"Advertiser lInk Groups"} />
              </ListItem>
            </Link>

            <Divider />

            {/* <Link className="link" to="/logs">
              <ListItem button key={"Logs"}>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary={"Server Search Logs"} />
              </ListItem>
            </Link> */}
            {/* <Link className="link" to="/revenuereport">
              <ListItem button key={"Revenue Reports"}>
                <ListItemIcon>
                  <MonetizationOnIcon />
                </ListItemIcon>
                <ListItemText primary={"Revenue Reports"} />
              </ListItem>
            </Link> */}
            <Link className="link" to="/revenuereport">
              <ListItem button key={"Revenue Reports"}>
                <ListItemIcon>
                  <MonetizationOnIcon />
                </ListItemIcon>
                <ListItemText primary={"Revenue Reports"} />
              </ListItem>
            </Link>
            <Link className="link" to="/csvupload">
              <ListItem button key={"CSV Upload"}>
                <ListItemIcon>
                  <AddToPhotosIcon />
                </ListItemIcon>
                <ListItemText primary={"CSV Upload"} />
              </ListItem>
            </Link>
            <Link className="link" to="/csvUploadStatus">
              <ListItem button key={"Upload CSV Status "}>
                <ListItemIcon>
                  <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary={"CSV Upload Status"} />
              </ListItem>
            </Link>
            <Link className="link" to="/apidetails">
              <ListItem button key={"API Management"}>
                <ListItemIcon>
                  <DnsIcon />
                </ListItemIcon>
                <ListItemText primary={"API Management"} />
              </ListItem>
            </Link>
            <Link className="link" to="/advisor">
              <ListItem button key={"Advisor"}>
                <ListItemIcon>
                  <AssignmentIndIcon />
                </ListItemIcon>
                <ListItemText primary={"Advisor"} />
              </ListItem>
            </Link>

            <Divider />

            <Link className="link" to="/usermanagement">
              <ListItem button key={"User Management"}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary={"User Management"} />
              </ListItem>
            </Link>
            <Link className="link" to="/displaynames">
              <ListItem button key={"Map Display Name"}>
                <ListItemIcon>
                  <BlurLinearIcon />
                </ListItemIcon>
                <ListItemText primary={"Map Display Names"} />
              </ListItem>
            </Link>
            <Link className="link" to="/systemlogs">
              <ListItem button key={"System Logs"}>
                <ListItemIcon>
                  <LibraryBooksIcon />
                </ListItemIcon>
                <ListItemText primary={"System Logs"} />
              </ListItem>
            </Link>
            <Link className="link" to="/settings">
              <ListItem button key={"Settings"}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={"Settings"} />
              </ListItem>
            </Link>
            <Link className="link" to="/profile">
              <ListItem button key={"Profile"}>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary={"Profile"} />
              </ListItem>
            </Link>
            <DynamicSettingLink
              linkKey="KYC Dashboard"
              primary="KYC Dashboard"
              icon={<DashboardIcon />}
              settingKey="kyc_url"
            />
          </RBAC>
        </List>
        <Divider />
        <List>
          <ListItem button key={"Logout"} onClick={props.handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
