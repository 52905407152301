import { Button } from "@material-ui/core";
import DomainTable from "./layouts/DomainTable";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { useFormAndTableSwitch } from "../../hooks/formAndTableSwitch.logic";
import DomainForm from "./layouts/DomainForm";

const Domain = () => {
  const { currentPage, openAddForm, openEditForm, openTable } =
    useFormAndTableSwitch();

  return (
    <>
      {currentPage.page === "form" && (
        <DomainForm
          domainId={currentPage.pageData.id ? currentPage.pageData.id : ""}
          openTable={openTable}
        />
      )}
      {currentPage.page === "table" && (
        <div
          className="flow-content"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px"
          }}
        >
          <Button
            onClick={openAddForm}
            variant="contained"
            size="medium"
            color="primary"
            startIcon={<AddBoxIcon />}
          >
            Add New Domain
          </Button>
          <DomainTable openEditForm={openEditForm} />
        </div>
      )}
    </>
  );
};

export default Domain;
