import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
    },
    textField: {
        width: "50%",
    },
    label: {
        fontSize: "15px",
        color: "#505050",
    }
}));

export default function DaysAndHours({
    label = 'Date picker',
    setDateTime,
    required = false,
    className = ""
}) {
    const classes = useStyles();
    const [date, setDate] = useState(moment().add(1, "day").format("YYYY-MM-DDTHH:mm"));

    // days and hours
    const [days, setDays] = useState(1);
    const [hours, setHours] = useState(0);


    useEffect(() => {
        let timer = moment();

        if (days) {
            timer.add(days, "days");
        }
        if (hours) {
            timer.add(hours, "hours");
        }

        const newDate = timer.format("YYYY-MM-DDTHH:mm");
        const payloadDate = new Date(newDate).toISOString();
        setDate(newDate);
        setDateTime(payloadDate);
    }, [days, hours, setDateTime]);

    const handleKeyDown = (event) => {
        // Prevent the default behavior if the key pressed is the minus sign
        if (event.key === '-') {
          event.preventDefault();
        }
    };

    return (
        <>
            <label className={classes.label}>
                <span><b>Snooze Until </b>* </span>
                [ {(moment(date).format("D MMM YYYY HH:mm"))} ]
            </label>
            <div className={classes.container}>
                <TextField
                    variant="standard"
                    margin="normal"            
                    id="days"
                    label="Day(s)"
                    type="number"
                    className={`${classes.textField} ${className}`}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        min: 0,
                        onKeyDown: handleKeyDown,
                    }}
                    onChange={(e) => {
                        if (e.target.value < 1000000) setDays(e.target.value);
                    }}
                    value={days}
                />
                <TextField
                    variant="standard"
                    margin="normal"            
                    id="days"
                    label="hour(s)"
                    type="number"
                    className={`${classes.textField} ${className}`}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        min: 0,
                        onKeyDown: handleKeyDown,
                    }}
                    onChange={(e) => {
                        if (e.target.value < 24) setHours(e.target.value);
                    }}
                    value={hours}
                />
            </div>
        </>
    );
}
