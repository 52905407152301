import React from "react";
import Table from "../../../../../components/Table/Table";

import PropTypes from "prop-types";
const ReportTable = ({
  reportData,
  loadingDashboardData,
  dashboardDataHook,
  total,
  tableHeaders,
  orderBy,
  orderDirection,
  setOrderBy,
  setOrderDirection
}) => {
  const { size, page, setPage, setSize } = dashboardDataHook;
  return (
    <div>
      <Table
        headers={tableHeaders}
        rowValues={reportData}
        isLoading={loadingDashboardData}
        noActions={true}
        noIds={true}
        noCheckboxes={true}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
        total={total}
        rowsPerPageOptions={[50, 100, 200]}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        orderDirection={orderDirection}
        setOrderDirection={setOrderDirection}
        tableType="publisherTable"
      />
    </div>
  );
};

ReportTable.propTypes = {
  reportData: PropTypes.array.isRequired,
  loadingDashboardData: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  dashboardDataHook: PropTypes.object.isRequired
};

export default ReportTable;
