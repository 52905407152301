import React from 'react';

function HTMLRenderer (props) {
    const { htmlString, className = {} } = props;

    return (
        <div className={className} dangerouslySetInnerHTML={{ __html: htmlString }} />
    );
}

export default HTMLRenderer;
