/**
 * Custom Hook to load Dashboard Data
 */
import { useState, useEffect, useCallback } from "react";
import { Report } from "../../services/Report";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { CUSTOM } from "../../helpers/constant/filters";

import AdminFormulas from "../../helpers/AdminFormulas";
import { formatNumberAddComma } from "../../helpers/formatters";
import { useDispatch } from "react-redux";
import { setRevReportSelectedFilter } from "../../reducers/revenueReport/revenueReport.action";

// after totaling the values we round off the values to 3 digit after decimal
let roundOffFields = [
  "gross_revenue",
  "net_revenue",
  "pub_revenue"
  // "estimated_revenue",
  // "buffer"
];
// these fields are rounded up to 0
let noDecimalFields = [
  "clicks",
  "total_searches",
  "monetized_searches",
  "server_search_counts",
  "initial_searches",
  "followon_searches"
];

export const useDashboardData = ({
  filterValues,
  filtersClearToogle,
  dataLists
}) => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const [dashboardData, setDashboardData] = useState(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(200);
  // const [missingSubIds, setMissingSubIds] = useState([]);
  // const [viewMissingSubIds, setViewMissingSubIds] = useState(false);
  // const [missingSubIdsTotal, setMissinSubIdsTotal] = useState(0);
  const [loadingDashboardData, setLoadingDashboardData] = useState({
    loading: true,
    error: false
  });

  const viewMissingSubIds = filterValues.missingSubids;

  function getParams() {
    let params = {};
    const {
      selectedProviders,
      selectedCountries,
      selectedPublishers,
      fromDate,
      toDate,
      selectedInterval,
      selectedGroupBy,
      selectedRuleIds,
      selectedTagIds,
      selectedAdvertiserLinkGroupIds,
      selectedTagTypes,
      selectedPlatforms,
      selectedSearchEngines,
      selectedPublisherAccounts,
      selectedChannels,
      selectedPeriod,
      selectedRemark,
      orderBy,
      orderDirection,
      missingSubids
    } = filterValues;

    if (selectedChannels?.length > 0) {
      let selection = [];
      for (let i in selectedChannels) {
        selection.push(selectedChannels[i]);
      }
      params["channels"] = selection;
    }
    if (selectedPublisherAccounts?.length > 0) {
      let selection = [];
      for (let i in selectedPublisherAccounts) {
        selection.push(selectedPublisherAccounts[i]);
      }
      params["pub_account_id"] = selection;
    }

    if (selectedProviders?.length > 0) {
      let selection = [];
      for (let i in selectedProviders) {
        selection.push(selectedProviders[i]);
      }
      params["providers"] = selection;
    }

    if (selectedRuleIds?.length > 0) {
      let selection = [];
      for (let i in selectedRuleIds) {
        selection.push(selectedRuleIds[i]);
      }
      params["rules"] = selection;
    }

    if (selectedTagIds?.length > 0) {
      let selection = [];
      for (let i in selectedTagIds) {
        selection.push(selectedTagIds[i]);
      }
      params["tags"] = selection;
    }

    if (selectedAdvertiserLinkGroupIds?.length > 0) {
      let selection = [];
      for (let i in selectedAdvertiserLinkGroupIds) {
        selection.push(selectedAdvertiserLinkGroupIds[i]);
      }
      params["advertiser_link_groups"] = selection;
    }

    if (selectedCountries?.length > 0) {
      let selection = [];
      for (let i in selectedCountries) {
        selection.push(selectedCountries[i]);
      }
      params["countries"] = selection;
    }

    if (selectedInterval) {
      params["aggregateBy"] = selectedInterval;
    }
    if (selectedRemark) {
      params["remarksFilter"] = selectedRemark;
    }

    if (selectedGroupBy?.length > 0) {
      let selection = [];
      for (let i in selectedGroupBy) {
        selection.push(selectedGroupBy[i]);
      }
      params["groupByKeys"] = selection;
    }

    if (selectedTagTypes?.length > 0) {
      let selection = [];
      for (let i in selectedTagTypes) {
        selection.push(selectedTagTypes[i]);
      }
      params["tagTypes"] = selection;
    }

    if (selectedPlatforms?.length > 0) {
      let selection = [];
      for (let i in selectedPlatforms) {
        selection.push(selectedPlatforms[i]);
      }
      params["platforms"] = selection;
    }

    if (selectedSearchEngines?.length > 0) {
      let selection = [];
      for (let i in selectedSearchEngines) {
        selection.push(selectedSearchEngines[i]);
      }
      params["searchEngines"] = selection;
    }

    if (selectedPublishers.length > 0) {
      let selection = [];
      for (let i in selectedPublishers) {
        selection.push(selectedPublishers[i]);
      }
      params["publishers"] = selection;
    }

    if (selectedPeriod !== CUSTOM) {
      if (selectedPeriod) params[selectedPeriod] = true;
    } else {
      if (fromDate && moment(fromDate).isValid()) {
        params["fromDate"] = moment(fromDate).format("YYYY-MM-DD");
      }
      if (toDate && moment(toDate).isValid()) {
        params["toDate"] = moment(toDate).format("YYYY-MM-DD");
      }
    }

    if (
      selectedInterval &&
      (selectedInterval === "day" ||
        selectedInterval === "month" ||
        selectedInterval === "hour")
    ) {
      params["orderBy"] = "date";
      params["orderDirection"] = orderDirection.toLowerCase();
    }
    if (orderBy) {
      // modify order by keys
      // TODO change the key values in the table itself
      /*
            The the keys used for ordering send in the previous might be different we have to modify the keys
            */
      // key - old name, value - new name
      let modified_order_by = orderBy;
      const key_mapping = {
        geo: "country",
        advertiser_id: "advertiser_id",
        tag_id: "publisher_channel_id",
        click: "clicks",
        gross_revenue: "shared_revenue",
        pub_revenue: "publisher_revenue",
        server_search_counts: "search_count",
        adv_search_count: "advertiser_search_count",
        server_searches_rpm: "ssrpm"
      };
      const old_keys = Object.keys(key_mapping);
      if (old_keys.includes(orderBy)) {
        modified_order_by = key_mapping[orderBy];
      }
      params["orderBy"] = modified_order_by;
      params["orderDirection"] = orderDirection.toLowerCase();
    }
    params["page"] = page;
    params["size"] = size;

    // reset selected filter value
    stableDispatch(
      setRevReportSelectedFilter({
        selectedProviders,
        selectedCountries,
        selectedPublishers,
        fromDate,
        toDate,
        selectedInterval,
        selectedGroupBy,
        selectedRuleIds,
        selectedTagIds,
        selectedTagTypes,
        selectedPlatforms,
        selectedSearchEngines,
        selectedPublisherAccounts,
        selectedChannels,
        selectedPeriod,
        selectedRemark,
        missingSubids
      })
    );
    return params;
  }

  // Format the data to be used in the table
  function formatListRow(unformattedRow, index) {
    let row = { ...unformattedRow };
    if ("date" in row) {
      if (filterValues?.selectedInterval === "daily") {
        row.date = moment(row.date).format("MMM DD,YYYY");
      } else if (filterValues?.selectedInterval === "monthly") {
        row.date = moment(row.date).format("MMM ,YYYY");
      } else if (filterValues?.selectedInterval === "hour") {
        row.date = moment(row.date).format("MMMM Do YYYY, h:mm:ss a");
      } else {
        row.date = moment(row.date).format("MMM DD,YYYY");
      }
    }

    if (viewMissingSubIds) {
      [
        "clicks",
        "rpm",
        "ad_coverage",
        "monetized_searches",
        "net_revenue",
        "invalid_traffic",
        "overcapped_traffic",
        "rpc",
        "ssrpm",
        "rpmm",
        "shared_revenue",
        "publisher_revenue",
        "monetized_ctr",
        "followon_searches",
        "initial_searches"
      ].forEach((key) => delete row[key]);
    }
    row.sno = index + 1 + size * (page - 1); // calculating the serial number of the row, size is from the state
    return row;
  }

  function checkIfAllNullValues(row) {
    let isNull = true;
    for (let key in row) {
      if (row[key] !== null) {
        isNull = false;
      }
    }
    return isNull;
  }

  function getFormattedListRows(list) {
    if (list.length === 1 && checkIfAllNullValues(list[0])) {
      return [];
    }

    let total;
    if (!viewMissingSubIds) {
      // total object for revenue report of admin
      total = {
        clicks: 0.0,
        followon_searches: 0.0,
        gross_revenue: 0.0,
        initial_searches: 0.0,
        monetized_searches: 0.0,
        net_revenue: 0.0,
        pub_revenue: 0.0,
        // estimated_revenue: 0.0,
        search_count: 0.0,
        invalid_traffic: 0.0,
        overcapped_traffic: 0.0,
        total_searches: 0.0
        // buffer: 0.0
      };
    } else {
      total = {
        search_count: 0.0
      };
    }

    let formatedList = [];
    list.forEach((row, index) => {
      formatedList.push(formatListRow(row, index));
      for (let key in total) {
        total[key] = parseFloat(total[key]) + parseFloat(row[key] || 0);
      }
    });

    if (!viewMissingSubIds) {
      // calculating the values that require formula
      total["ad_coverage"] = AdminFormulas.calculateAdCoverage(
        total.total_searches,
        total.monetized_searches
      );
      total["rpc"] = AdminFormulas.calculateRPC(
        total.clicks,
        total.gross_revenue
      );
      // total["ctr"] = AdminFormulas.calculateCTR(
      //   total.clicks,
      //   total.total_searches
      // );
      total["monetized_ctr"] = AdminFormulas.calculateMonetizedCTR(
        total.clicks,
        total.monetized_searches
      );
      total["rpm"] = AdminFormulas.calculateRPM(
        total.total_searches,
        total.gross_revenue
      );
      total["rpmm"] = AdminFormulas.calculateRPPM(
        total.gross_revenue,
        total.monetized_searches
      );
      total["server_searches_rpm"] = AdminFormulas.calculateServerSearchesRPM(
        total.gross_revenue,
        total.search_count
      );
      total["ad_coverage_server_percentage"] =
        AdminFormulas.calculateServerAdCoverage(
          total.monetized_searches,
          total.search_count
        );
      total["discrepancy_percentage"] = AdminFormulas.calculateDiscrepancy(
        total.total_searches,
        total.search_count
      );
    }

    // for those keys whose total are not calculated set - as the character
    const rowKeys = Object.keys(list[0]);
    rowKeys.forEach((item) => {
      if (total[item] === undefined || total[item] === null) {
        delete total[item];
      }
    });

    // set the SNo as Total
    total["sno"] = "Total";
    if (!viewMissingSubIds) {
      Object.keys(total).forEach((key) => {
        if (
          [
            "total_searches",
            "monetized_searches",
            "clicks",
            "followon_searches",
            "initial_searches",
            "server_search_counts"
            // "estimated_revenue",
            // "buffer"
          ].includes(key)
        )
          total[key] = parseInt(total[key]);
      });
    }

    formatedList.unshift(total);
    return formatedList;
  }

  function getAdvertiserName(id) {
    const providers = dataLists?.providers;
    if (providers) {
      const advertiser_index = providers.findIndex((p) => p.value === id);
      if (advertiser_index !== -1) {
        console.log(providers[advertiser_index]);
        return id + " - " + providers[advertiser_index].label;
      }
    }
    return id;
  }

  function getPublisherName(id) {
    const publishers = dataLists?.publishers;
    if (publishers) {
      const publisher_index = publishers.findIndex((p) => p.value === id);
      if (publisher_index !== -1) {
        return id + " - " + publishers[publisher_index].label;
      }
    }
    return id;
  }

  function getSearchEngineName(id) {
    const searchEngineList = dataLists?.searchEngines;
    if (searchEngineList) {
      const engine_index = searchEngineList.findIndex((p) => p.value === id);
      if (engine_index !== -1) {
        return id + " - " + searchEngineList[engine_index].label;
      } else {
        return id;
      }
    } else {
      return id;
    }
  }

  function getTagName(id) {
    const tagLists = dataLists?.tagTypes;
    if (tagLists) {
      const tag_index = tagLists.findIndex((p) => p.value === id);
      if (tag_index !== -1) {
        return id + " - " + tagLists[tag_index].label;
      } else {
        return id;
      }
    } else {
      return id;
    }
  }

  function getPlatformName(id) {
    const platforms = dataLists?.platforms;
    if (platforms) {
      const platform_index = platforms.findIndex((p) => p.value === id);
      if (platform_index !== -1) {
        return id + " - " + platforms[platform_index].label;
      } else {
        return id;
      }
    } else {
      return id;
    }
  }

  function fetchDashboardData(callingMissingSubIds = false) {
    setLoadingDashboardData({
      loading: true,
      error: false
    });

    const params = getParams();
    if (viewMissingSubIds) {
      params["isMissingSubIds"] = true;
    }

    Report.fetchDashboardDataV2(params, true)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          let list = response.data;
          list = list.map((response_item) => {
            if (response_item.country) {
              response_item.geo = response_item.country;
              delete response_item.country;
            } else if (
              response_item.country === null ||
              response_item.country === ""
            ) {
              response_item.geo = "-";
              delete response_item.country;
            }

            if (response_item.publisher_id) {
              response_item.publisher_id = getPublisherName(
                response_item.publisher_id
              );
            }

            if (response_item.advertiser_id) {
              response_item.advertiser_id = getAdvertiserName(
                response_item.advertiser_id
              );
            }

            if (response_item.search_engine_id) {
              response_item.search_engine_id = getSearchEngineName(
                response_item.search_engine_id
              );
            } else if (response_item.search_engine_id === null) {
              response_item.search_engine_id = "-";
            }

            if (response_item.tag_type_id) {
              response_item.tag_type_id = getTagName(response_item.tag_type_id);
            } else if (response_item.tag_type_id === null) {
              response_item.tag_type_id = "-";
            }

            if (response_item.platform_id) {
              response_item.platform_id = getPlatformName(
                response_item.platform_id
              );
            } else if (response_item.platform_id === null) {
              response_item.platform_id = "-";
            }

            if (response_item.targeting_id) {
              response_item.tag_id = response_item.targeting_id;
              delete response_item.targeting_id;
            } else if (response_item.targeting_id === null) {
              response_item.tag_id = "-";
              delete response_item.targeting_id;
            }

            if (response_item.monetized_searches) {
              response_item.monetized_searches = parseInt(
                response_item.monetized_searches
              ).toFixed(3);
            }
            if (!viewMissingSubIds) {
              // tag_id is for targeting link id not  type id
              // Link Id id tag_type id
              // only map those fields that are showed by default
              return {
                ...response_item,
                click: parseFloat(response_item.clicks) || 0,
                gross_revenue: parseFloat(response_item.shared_revenue) || 0,
                pub_revenue: parseFloat(response_item.publisher_revenue) || 0,
                server_search_counts:
                  parseFloat(response_item.server_search_count) || 0,
                search_count: parseFloat(response_item.search_count) || 0,
                total_searches:
                  parseFloat(response_item.advertiser_search_count) || 0,
                server_searches_rpm: parseFloat(response_item.ssrpm) || 0
              };
            } else {
              response_item.server_search_counts =
                response_item.server_search_count;
              delete response_item.server_search_count;
              delete response_item.clicks;
              delete response_item.gross_revenue;
              delete response_item.total_searches;
              delete response_item.server_searches_rpm;
              delete response_item.discrepancy_percentage;
              delete response_item.ad_coverage_server_percentage;
              return response_item;
            }
          });

          list = getFormattedListRows(list);
          const stats = response.stats || {
            aggregated_monetized_searches: 0,
            total_searches: 0,
            net_revenue: 0
          };
          // round of fields
          list.forEach((item) => {
            if (!viewMissingSubIds) {
              noDecimalFields.forEach((no_decimal_key) => {
                item[no_decimal_key] = parseInt(item[no_decimal_key]) || 0;
              });
              roundOffFields.forEach((round_off_key) => {
                item[round_off_key] = (
                  parseFloat(item[round_off_key]) || 0
                ).toFixed(3);
              });
              // add comma to numerical fields
              item["ad_coverage"] = formatNumberAddComma(item.ad_coverage);
              item["ad_coverage_server_percentage"] = formatNumberAddComma(
                item.ad_coverage_server_percentage
              );
              item["monetized_searches"] = formatNumberAddComma(
                item.monetized_searches
              );
              item["adv_search_count"] = formatNumberAddComma(
                item.adv_search_count
              );
              item["amount"] = formatNumberAddComma(item.amount);
              item["clicks"] = formatNumberAddComma(item.clicks);
              item["discrepancy_percentage"] = formatNumberAddComma(
                +item.discrepancy_percentage || item.discrepancy_percentage
              );
              item["followon_searches"] = formatNumberAddComma(
                item.followon_searches
              );
              item["gross_revenue"] = formatNumberAddComma(
                +item.gross_revenue || item.gross_revenue
              );
              item["initial_searches"] = formatNumberAddComma(
                item.initial_searches
              );
              item["monetized_ctr"] = formatNumberAddComma(item.monetized_ctr);
              item["net_revenue"] = formatNumberAddComma(
                +item.net_revenue || item.net_revenue
              );
              item["pub_revenue"] = formatNumberAddComma(
                +item.pub_revenue || item.pub_revenue
              );
              item["publisher_revenue"] = formatNumberAddComma(
                item.publisher_revenue
              );
              item["invalid_traffic"] = formatNumberAddComma(
                item.invalid_traffic
              );
              item["overcapped_traffic"] = formatNumberAddComma(
                item.overcapped_traffic
              );
              item["rpc"] = formatNumberAddComma(item.rpc);
              item["rpm"] = formatNumberAddComma(item.rpm);
              item["rpmm"] = formatNumberAddComma(item.rpmm);
              item["server_search_counts"] = formatNumberAddComma(
                item.server_search_counts
              );
              item["search_count"] = formatNumberAddComma(item.search_count);
              item["server_searches_rpm"] = formatNumberAddComma(
                item.server_searches_rpm
              );
              item["shared_revenue"] = formatNumberAddComma(
                item.shared_revenue
              );
              item["ssrpm"] = formatNumberAddComma(item.ssrpm);
              item["total_searches"] = formatNumberAddComma(
                item.total_searches
              );
              // item["buffer"] = formatNumberAddComma(item.buffer);
              // item["estimated_revenue"] = formatNumberAddComma(
              //   item.estimated_revenue
              // );
            }
          });
          setDashboardData({
            list,
            total: response.count,
            stats: [
              {
                net_revenue: stats.net_revenue || 0,
                total_searches: stats.total_searches || 0,
                monetized_searches:
                  parseInt(stats.aggregated_monetized_searches) || 0
              }
            ]
          });
          setTimeout(() => {
            setLoadingDashboardData({
              loading: false,
              error: false
            });
          }, 800);
        } else {
          setDashboardData({
            list: [],
            total: 0,
            stats: [
              {
                net_revenue: 0,
                total_searches: 0,
                monetized_searches: 0
              }
            ]
          });
          setLoadingDashboardData({
            loading: false,
            error: false
          });
          // throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        toastr.error("Oops", "Unable to load dashboard report data!");
        setLoadingDashboardData({
          loading: false,
          error: true
        });
        setDashboardData(null);
        console.trace(error.message);
      });
  }

  useEffect(() => {
    fetchDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    size,
    filterValues.orderBy,
    filterValues.orderDirection,
    filtersClearToogle
  ]);

  return {
    dashboardData,
    loadingDashboardData: loadingDashboardData.loading,
    size,
    page,
    setPage,
    setSize,
    fetchDashboardData
    // missingSubIds,
    // viewMissingSubIds,
    // setViewMissingSubIds
    // missingSubIdsTotal
  };
};
