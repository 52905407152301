import React, { useState, useCallback, useEffect } from "react";
import { ROW_STATUS_HEADERS } from "../../Constant/Headers";
import Table from "../../../../components/Table/Table";
import { SkippedRows } from "../../../../services/skippedRows";
import SimpleAccordion from "../../../../components/Accordion/Accordion";
import FilterBox from "./layouts/FilterBox.filter";
import { formatNumberAddComma } from "../../../../helpers/formatters";

const ApiRowStatusList = (props) => {
    const [rowsData, setRowsData] = useState([]);

    const [message, setMessage] = useState("");
    const [channel, setChannel] = useState("");

    const [page, setPage] = useState(1);
    const [size, setSize] = useState(50);
    const [orderBy, setOrderBy] = useState("id");
    const [orderDirection, setOrderDirection] = useState("DESC");
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const getRequestParams = useCallback(() => {
        let params = {};
        if (channel !== "") {
            params["channel"] = channel;
        }
        if (message !== "") {
            params["message"] = message;
        }
        params["page"] = page;
        params["provider_id"] = props.advertiser;
        params["size"] = size;
        params["order_by"] = orderBy;
        params["order_direction"] = orderDirection;

        return params;
    }, [orderBy, orderDirection, page, size, message, channel, props.advertiser]);

    const fetchData = useCallback(
        (params = {}) => {
            setLoading(true);
            SkippedRows.fetchSkippedRowsForApi(params)
                .then((response) => {
                    if (response.success) {
                        let data = response.data.data.map(item => ({
                            ...item,
                            clicks: formatNumberAddComma(parseInt(item.clicks, 0)),
                            total_searches: formatNumberAddComma(parseInt(item.total_searches, 0)),
                            monetized_searches: formatNumberAddComma(parseInt(item.monetized_searches, 0))
                        }));
                        setRowsData(data);
                        setTotal(response.data.total);
                        setLoading(false);
                    } else {
                        throw new Error(JSON.stringify(response.error));
                    }
                })
                .catch((error) => {
                    console.trace(error.message);
                    setLoading(false);
                });
        },
        // eslint-disable-next-line
        [props]
    );
    const handleFilter = () => {
        fetchData(getRequestParams());
    };
    useEffect(() => {
        fetchData(getRequestParams());
    }, [fetchData, getRequestParams]);

    return (
        <div className="api-row-status" style={{ marginTop: "70px" }}>
            <div className="header">
                <h2>Revenue report status</h2>
            </div>
            <div>
                <SimpleAccordion header="Filters" key="dat-filter-box">
                    <FilterBox
                        setChannel={setChannel}
                        channel={channel}
                        setMessage={setMessage}
                        message={message}
                        handleFilter={handleFilter}
                    />
                </SimpleAccordion>
                <br />
            </div>
            <Table
                headers={ROW_STATUS_HEADERS}
                noActions={true}
                noCheckboxes={true}
                noIds={true}
                rowValues={rowsData || []}
                tableType={"skippedrows"}
                page={page}
                setPage={setPage}
                size={size}
                setSize={setSize}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                orderDirection={orderDirection}
                setOrderDirection={setOrderDirection}
                total={total}
                isLoading={loading}
                rowsPerPageOptions={[1, 20, 50, 100, 150, 200]}
            />
        </div>
    );
};

export default ApiRowStatusList;
