import Http from "axios";
import Cookies from "js-cookie";
import qs from "qs";
import { API_URL, GCP_BACKEND_URL } from "../helpers";

class Api {
  constructor() {
    Http.defaults.baseURL = API_URL;
    Http.defaults.timeout = 300000;
  }

  async get(resource, responseType = null, params = {}, cancelToken) {
    const { token } = JSON.parse(localStorage.getItem("ninaya-token"));
    const config = {
      responseType,
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" })
    };
    if (cancelToken) {
      config.cancelToken = cancelToken;
    }
    try {
      const res = await Http.get(`${API_URL}${resource}`, config);

      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async getV2(resource, responseType = null, params = {}, cancelToken) {
    const { token } = JSON.parse(localStorage.getItem("ninaya-token"));
    const config = {
      responseType,
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      paramsSerializer: (params) => {
        let result = qs.stringify(params, { arrayFormat: "comma" });
        result = result.replaceAll("%5B%5D", "");
        return result;
      }
    };
    if (cancelToken) {
      config.cancelToken = cancelToken;
    }
    try {
      const res = await Http.get(`${API_URL}${resource}`, config);

      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async getGCP(resource, responseType = null, params = {}, cancelToken) {
    const { token } = JSON.parse(localStorage.getItem("ninaya-token"));
    const config = {
      baseURL: GCP_BACKEND_URL,
      responseType,
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      },
      paramsSerializer: (params) => {
        let result = qs.stringify(params, { arrayFormat: "comma" });
        result = result.replaceAll("%5B%5D", "");
        return result;
      }
    };
    if (cancelToken) {
      config.cancelToken = cancelToken;
    }
    try {
      const res = await Http.get(`${GCP_BACKEND_URL}${resource}`, config);
      return this.successResponse(res);
    } catch (error) {
      console.log({ error });
      return this.errorResponse(error);
    }
  }

  async destroy(resource, data, params = {}) {
    const { token } = JSON.parse(localStorage.getItem("ninaya-token"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      data,
      params
    };
    try {
      const res = await Http.delete(`${API_URL}${resource}`, config);
      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async patch(resource, data) {
    const { token } = JSON.parse(localStorage.getItem("ninaya-token"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };
    try {
      const res = await Http.patch(`${API_URL}${resource}`, data, config);
      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async post(resource, params) {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    if (localStorage.getItem("ninaya-token") !== null) {
      config.headers.Authorization = JSON.parse(
        localStorage.getItem("ninaya-token")
      ).token;
    }

    try {
      const response = await Http.post(resource, params, config);
      return this.successResponse(response);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async put(resource, data, params) {
    const { token } = JSON.parse(localStorage.getItem("ninaya-token"));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      params
    };
    try {
      const res = await Http.put(`${API_URL}${resource}`, data, config);
      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  async filePut(resource, data, params) {
    const { token } = JSON.parse(localStorage.getItem("ninaya-token"));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token
      },
      params
    };
    try {
      const res = await Http.put(`${API_URL}${resource}`, data, config);
      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }
  async filePost(resource, data, params) {
    const { token } = JSON.parse(localStorage.getItem("ninaya-token"));
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token
      },
      params
    };
    try {
      const res = await Http.post(`${API_URL}${resource}`, data, config);
      return this.successResponse(res);
    } catch (error) {
      return this.errorResponse(error);
    }
  }

  errorResponse(error) {
    if (!error?.response?.status) {
      return {
        body: {
          message: "Something went wrong"
        },
        status: 500
      };
    }
    switch (error.response.status) {
      case 401:
        // toastr.error('', 'Unauthorized');
        localStorage.removeItem("ninaya-token");
        Cookies.remove("ninaya-token");
        window.location.replace("/");
        break;
      default:
        if (!error.response.data.success) {
          return this.response(error.response);
        }
        return this.response({
          data: "Network Error",
          success: false
        });
    }
  }

  successResponse(response) {
    return this.response(response);
  }

  response({ data, status, headers }) {
    return {
      body: data,
      status,
      headers
    };
  }
}
// eslint-disable-next-line
export default new Api();
