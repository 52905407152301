import { useState, useEffect } from "react";
import { DisplayNames } from "../../services/DisplayNames";

export const useDisplayNameLogic = () => {
    const [loadingDisplayNames, setLoadingDisplayNames] = useState(true);

    const [displayNames, setDisplayNames] = useState(null);

    function fetchDisplayNames() {
        setLoadingDisplayNames(true);
        DisplayNames.fetchDisplayNames()
            .then((response) => {
                if (response.success) {
                    let displayNames = response?.data?.mappingData || null;
                    setDisplayNames(displayNames);
                    setLoadingDisplayNames(false);
                } else {
                    throw new Error(JSON.stringify(response.error));
                }
            })
            .catch((error) => {
                console.trace(error.message);
                setDisplayNames(null);
                setLoadingDisplayNames(false);
            });
    }

    useEffect(() => {
        fetchDisplayNames();
    }, []);

    return {
        loadingDisplayNames,
        displayNames
    };
};
