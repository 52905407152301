import React, { useState } from "react";
import TabMenu from "../../components/TabMenu/TabMenu";
import { useHistory } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import DefaultVariables from "../settings/layouts/DefaultVariables";
import Platform from "../Platform/Platform";
import SearchEngine from "../SearchEngine/SearchEngine";
import TagType from "../TagType/TagType";
import RevenueShare from "../ShareRevenue";
import EmailConfig from "../EmailConfig";
import Domain from "../Domain";
// import DeleteRevenueReports from "../deleteRevenueReports"

const Settings = () => {
  //current vertical tab menu
  const [currentSelectedSectionIndex, setCurrentSelectedSectionIndex] =
    useState(0);
  const icon = <SettingsIcon fontSize="inherit" color="primary" />;
  const history = useHistory();
  const handleCancel = () => history.push("/");

  const sections = [
    {
      name: "Default Variables",
      component: <DefaultVariables handleCancel={handleCancel} />
    },
    {
      name: "Platforms",
      component: <Platform />
    },
    {
      name: "Search Engines",
      component: <SearchEngine />
    },
    {
      name: "Link Types",
      component: <TagType />
    },
    {
      name: "Revenue Share(%)",
      component: <RevenueShare />
    },
    {
      name: "Email Config",
      component: <EmailConfig setIndex={setCurrentSelectedSectionIndex} />
    },
    {
      name: "Domains",
      component: <Domain />
    }
    // {
    //   name: "Delete Revenue Data",
    //   component: <DeleteRevenueReports  />
    // }
  ];

  return (
    <div className="settings-container">
      <ContentHeader icon={icon} title="Settings" />

      <TabMenu
        sections={sections}
        setCurrentSelectedSectionIndex={setCurrentSelectedSectionIndex}
        currentSelectedSectionIndex={currentSelectedSectionIndex}
      />
      <div className="settings__section">
        {sections[currentSelectedSectionIndex].component}
      </div>
    </div>
  );
};

export default Settings;
