import { useEffect, useCallback, useState } from "react";
import moment from "moment";

import {
  INTERVALS_REPORT_ADMIN,
  COUNTRIES,
  PERIODS,
  REMARKS_LIST_NEW,
  GROUP_BY_REPORT_ADMIN_NEW,
  PER_DAY
} from "../../helpers/constant/filters";
import {
  ADMIN_REPORT_RULE_ID,
  ADMIN_REPORT_ADVERTISER_ID,
  ADMIN_REPORT_FROM_DATE,
  ADMIN_REPORT_INTERVAL
} from "../../helpers/constant/localStorage";

import { useMultipleSelect } from "../../hooks/useMultipleSelect";
import { useSelect } from "../../hooks/useSelect";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { findObject } from "../../helpers";
import { MONTH_TO_DATE } from "../../helpers/constant/filters";
import { DEFAULT_FETCH_PARAM_SIZE, MINIMUM_FETCH_PARAM_SIZE } from "../../helpers/constant/misc";

import { Provider } from "../../services/Provider";
import { Publisher } from "../../services/Publisher";
import { AdvertiserLinkGroup } from "../../services/AdvertiserLinkGroup";

import { Platform } from "../../services/Platform";
import { TagType } from "../../services/TagType";
import { SearchEngine } from "../../services/SearchEngine";
import { PublisherAccount } from "../../services/publisherAccounts";
import { Report } from "../../services/Report";
import { useDispatch, useSelector } from "react-redux";
import { getRevReportSelectedFilter } from "../../reducers/revenueReport/revenueReport.selector";
import { setRevReportSelectedFilter } from "../../reducers/revenueReport/revenueReport.action";
import { useLocation } from "react-router-dom";
import { uniqueMapCombined } from "../../helpers/uniqueMapCombined";
import { toastr } from "react-redux-toastr";
import _ from "lodash";

export const useReportFiltersLogic = () => {
  // for query params that gets redirected from publisher link (list and edit screen)
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [groupByParams, setGroupByParams] = useState(
    searchParams.get("group_by") ? searchParams.get("group_by").split("-") : []
  );
  const [channelParams, setChannelParams] = useState(
    searchParams.get("channel") ? searchParams.get("channel").split("-") : []
  );
  const [pubLinkParams, setPubLinkParams] = useState(
    searchParams.get("pub_link_id")
      ? searchParams
          .get("pub_link_id")
          .split("-")
          .map((x) => parseInt(x))
      : []
  );
  const [providerParams, setProviderParams] = useState(
    searchParams.get("advertiser_id")
      ? searchParams
          .get("advertiser_id")
          .split("-")
          .map((x) => parseInt(x))
      : []
  );
  const [pubLinkIdError, setPubLinkIdError] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [pubLinkLoading, setPubLinkLoading] = useState(false);

  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const selectedFilter = useSelector(getRevReportSelectedFilter);
  const [localStorageRuleId, setLocalStorageRuleId] = useLocalStorage(
    ADMIN_REPORT_RULE_ID,
    ""
  );

  const [localStorageAdvertiserId, setLocalStorageAdvertiserId] =
    useLocalStorage(ADMIN_REPORT_ADVERTISER_ID, "");

  const [localStorageFromDate, setLocalStorageFromDate] = useLocalStorage(
    ADMIN_REPORT_FROM_DATE,
    ""
  );

  const [localStorageInterval, setLocalStorageInterval] = useLocalStorage(
    ADMIN_REPORT_INTERVAL,
    ""
  );

  const targetingMinimalAttributes = "id,is_active,status";

  const {
    setData: setProviders,
    selectedData: selectedProviders,
    data: providers,
    handleSelectedDataChange: handleProviderChange,
    removeSelectedDataFromList: removeProviderFromList,
    removeAll: removeAllProviders
  } = useMultipleSelect(
    providerParams.length > 0
      ? providerParams
      : selectedFilter.selectedProviders
  );

  const {
    setData: setCountriesData,
    selectedData: selectedCountries,
    data: countries,
    handleSelectedDataChange: handleCountryChange,
    removeSelectedDataFromList: removeCountryFromList,
    removeAll: removeAllCountries
  } = useMultipleSelect(selectedFilter.selectedCountries);

  const {
    setData: setPublishers,
    selectedData: selectedPublishers,
    data: publishers,
    handleSelectedDataChange: handlePublisherChange,
    removeSelectedDataFromList: removePublisherFromList,
    removeAll: removeAllPublishers
  } = useMultipleSelect(selectedFilter.selectedPublishers);

  const {
    setData: setIntervalData,
    selectedData: selectedInterval,
    data: intervals,
    changeSelectedData: changeSelectedInterval
    // clearSelection: clearSelectedInterval
  } = useSelect(
    searchParams.get("interval") ?? selectedFilter.selectedInterval
  );

  const {
    setData: setPeriodData,
    selectedData: selectedPeriod,
    data: periods,
    changeSelectedData: changeSelectedPeriod
    // clearSelection: clearSelectedPeriod
  } = useSelect(searchParams.get("period") ?? selectedFilter.selectedPeriod);

  const {
    setData: setRemarks,
    selectedData: selectedRemark,
    data: remarks,
    changeSelectedData: handleRemarkChange,
    clearSelection: clearRemark
  } = useSelect(selectedFilter.selectedRemark);

  const {
    setData: setGroupByData,
    selectedData: selectedGroupBy,
    data: groupByList,
    handleSelectedDataChange: handleGroupByChange,
    removeSelectedDataFromList: removeGroupByFromList,
    removeAll: removeAllGroupBy
  } = useMultipleSelect(
    groupByParams.length > 0 ? groupByParams : selectedFilter.selectedGroupBy
  );

  const {
    setData: setRuleIds,
    selectedData: selectedRuleIds,
    data: ruleIds,
    handleSelectedDataChange: handleRuleIdChange,
    removeSelectedDataFromList: removeRuleIdFromList,
    removeAll: removeAllRuleIds
  } = useMultipleSelect(selectedFilter.selectedRuleIds);

  const {
    setData: setTagIds,
    selectedData: selectedTagIds,
    data: tagIds,
    handleSelectedDataChange: handleTagIdChange,
    removeSelectedDataFromList: removeTagIdFromList,
    removeAll: removeAllTagIds
  } = useMultipleSelect(
    pubLinkParams.length > 0 ? pubLinkParams : selectedFilter.selectedTagIds
  );

  const {
    setData: setAdvertiserLinkGroupIds,
    selectedData: selectedAdvertiserLinkGroupIds,
    data: advertiserLinkGroupIds,
    handleSelectedDataChange: handleAdvertiserLinkGroupIdChange,
    removeSelectedDataFromList: removeAdvertiserLinkgroupIdFromList,
    removeAll: removeAllAdvertiserLinkGroupIds
  } = useMultipleSelect(selectedFilter.selectedAdvertiserLinkGroupIds);

  const {
    setData: setPlatforms,
    selectedData: selectedPlatforms,
    data: platforms,
    handleSelectedDataChange: handlePlatformChange,
    removeSelectedDataFromList: removePlatformFromList,
    removeAll: removeAllPlatforms
  } = useMultipleSelect(selectedFilter.selectedPlatforms);

  const {
    setData: setSearchEngines,
    selectedData: selectedSearchEngines,
    data: searchEngines,
    handleSelectedDataChange: handleSearchEngineChange,
    removeSelectedDataFromList: removeSearchEngineFromList,
    removeAll: removeAllSearchEngines
  } = useMultipleSelect(selectedFilter.selectedSearchEngines);

  const {
    setData: setTagTypes,
    selectedData: selectedTagTypes,
    data: tagTypes,
    handleSelectedDataChange: handleTagTypeChange,
    removeSelectedDataFromList: removeTagTypeFromList,
    removeAll: removeAllTagTypes
  } = useMultipleSelect(selectedFilter.selectedTagTypes);

  const {
    setData: setPublisherAccounts,
    selectedData: selectedPublisherAccounts,
    data: publisherAccounts,
    handleSelectedDataChange: handlePublisherAccountChange,
    removeSelectedDataFromList: removePublisherAccountFromList,
    removeAll: removeAllPublisherAccounts
  } = useMultipleSelect(selectedFilter.selectedPublisherAccounts);

  const {
    setData: setChannels,
    selectedData: selectedChannels,
    data: channels,
    handleSelectedDataChange: handleChannelsChange,
    removeSelectedDataFromList: removeChannelsFromList,
    removeAll: removeAllChannels
  } = useMultipleSelect(
    channelParams.length > 0 ? channelParams : selectedFilter.selectedChannels
  );

  const [filtersClearToogle, setFiltersClearToggle] = useState(false);

  const [fromDate, setFromDate] = useState(selectedFilter.fromDate);
  const [toDate, setToDate] = useState(selectedFilter.toDate);
  const [orderBy, setOrderBy] = useState("");
  const [orderDirection, setOrderDirection] = useState("DESC"); //ASC and DESC

  const [missingSubids, setMissingSubids] = useState(
    selectedFilter.missingSubids
  );
  // const [viewMissingSubIds, setViewMissingSubIds] = useState(false);

  const fetchChannelsByAdv = useCallback(() => {
    let params = {};
    if (selectedProviders?.length > 0) {
      removeChannelsFromList();
      let selection = [];
      for (let i in selectedProviders) {
        selection.push(selectedProviders[i]);
      }
      params["advertiser_id"] = selection;
    }
    Report.fetchChannelsByAdId({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE,
      ...params
    }).then((response) => {
      if (response.success) {
        const data = response?.data || [];
        const channels = [];
        for (let d of data) {
          channels.push({
            label: d?.channel,
            value: d?.channel
          });
        }
        setChannels(channels);
        const toset = channels
          .map((x) => x.value)
          .filter(
            (x) =>
              selectedFilter.selectedChannels.includes(x) ||
              channelParams.includes(x)
          );
        if (toset.length > 0) handleChannelsChange(toset);
      }
    });
    // eslint-disable-next-line
  }, [setChannels, selectedProviders]);

  useEffect(() => {
    fetchChannelsByAdv();
  }, [fetchChannelsByAdv]);

  const fetchPublisherAccounts = useCallback(() => {
    PublisherAccount.fetchPublisherAccounts({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response?.data?.data || [];
          const publisherAccounts = [];
          for (let p of data) {
            publisherAccounts.push({
              label: p.id,
              value: p.id,
              status: p.status
            });
          }
          setPublisherAccounts(publisherAccounts);
        } else {
          throw new Error(
            response.error
              ? JSON.stringify(response.error)
              : new Error("Error fetching publisher accounts")
          );
        }
      })
      .catch((error) => {
        console.trace(error);
      });
  }, [setPublisherAccounts]);

  const fetchProviders = useCallback(() => {
    Provider.fetchProviders({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.providers;
          const updatedProviders = [
            ...data,
            { id: 0, name: "System Fallback", status: "active" }
          ];
          const providers = [];
          for (let p of updatedProviders) {
            let isInactive = p.status === "inactive";
            providers.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: isInactive
            });
          }
          setProviders(providers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setProviders]);

  const fetchPublishers = useCallback(() => {
    Publisher.fetchPublishers({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.publishers;
          const publishers = [];
          for (let p of data) {
            let isInactive = p.status === "inactive";
            publishers.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: isInactive
            });
          }
          setPublishers(publishers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setPublishers]);

  const fetchPubLinkId = async (params = {}) => {
    return await Publisher.getTargeting({
      ...params,
      order_by: "id",
      order_direction: "ASC",
      size: MINIMUM_FETCH_PARAM_SIZE,
      attributes: targetingMinimalAttributes
    });
  };

  const fetchTargeting = useCallback(
    async (params = {}) => {
      try {
        const response = await fetchPubLinkId(params);

        if (response && response?.success) {
          const data = response.data.targetings;
          const targetings = [];
          for (let t of data) {
            let isInactive = !t.is_active;
            targetings.push({
              label: t.id,
              value: t.id,
              status: t.status,
              disabled: isInactive
            });
          }
          setTagIds(targetings);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      } catch (error) {
        console.trace(error.message);
      }
    },
    [setTagIds]
  );

  const fetchAdvertiserLinkGroups = useCallback(
    async (params = {}) => {
      try {
        const response = await AdvertiserLinkGroup.getList({
          ...params,
          order_by: "id",
          order_direction: "ASC",
          size: MINIMUM_FETCH_PARAM_SIZE,
          attributes: targetingMinimalAttributes
        });

        if (response && response?.success) {
          const data = response.data.advertiser_link_groups;
          const list = [];
          for (let t of data) {
            list.push({
              label: t.id,
              value: t.id,
              status: t.status,
              disabled: !t.active
            });
          }
          setAdvertiserLinkGroupIds(list);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      } catch (error) {
        console.trace(error.message);
      }
    },
    [setAdvertiserLinkGroupIds]
  );

  const fetchTargetingRules = useCallback(() => {
    Publisher.fetchTargetingRules()
      .then((response) => {
        if (response.success) {
          const data = response.data.targetingRules;
          const targetingRules = [];
          for (let t of data) {
            targetingRules.push({
              label: t.id,
              value: t.id,
              disabled: t.disabled
            });
          }
          setRuleIds(targetingRules);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setRuleIds]);

  const fetchPlatforms = useCallback(() => {
    Platform.fetchPlatforms({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.platforms;
          const platforms = [];
          for (let p of data) {
            platforms.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setPlatforms(platforms);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setPlatforms]);

  const fetchSearchEngines = useCallback(() => {
    SearchEngine.fetchSearchEngines({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.search_engines;
          const searchEngines = [];
          for (let p of data) {
            searchEngines.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setSearchEngines(searchEngines);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setSearchEngines]);

  const fetchTagTypes = useCallback(() => {
    TagType.fetchTagTypes({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.tag_types;
          const tagTypes = [];
          for (let p of data) {
            tagTypes.push({
              label: p.name,
              value: p.id,
              status: p.status,
              disabled: !p.status
            });
          }
          setTagTypes(tagTypes);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, [setTagTypes]);

  const updateTagIdList = useCallback(
    (targetings) => {
      // const oldTagIds = tagIds;
      const oldTagIds = tagIds.filter((item) =>
        selectedTagIds.includes(item.value)
      );

      if (
        !targetings ||
        (Array.isArray(targetings) && targetings.length === 0)
      ) {
        return oldTagIds;
      }
      if (!oldTagIds || (Array.isArray(oldTagIds) && oldTagIds.length === 0)) {
        return targetings;
      }

      return uniqueMapCombined("value", oldTagIds, targetings);
    },
    [selectedTagIds, tagIds]
  );

  const pubLinkFetchLogic = useCallback(
    async (params) => {
      try {
        setPubLinkLoading(true);
        const response = await fetchPubLinkId(params);
        setPubLinkLoading(false);
        if (response && response?.success) {
          const data = response?.data?.targetings || [];
          const targetings = [];
          if (data.length === 0) {
            setPubLinkIdError("No Pub Link ID found");
            setTimeout(() => setPubLinkIdError(null), 5000);
            toastr.warning("No Pub Link ID found");
          }
          for (let t of data) {
            let isInactive = !t.is_active;
            targetings.push({
              label: t.id,
              value: t.id,
              status: t.status,
              disabled: isInactive
            });
          }
          const newTargs = updateTagIdList(targetings);
          setTagIds(newTargs);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      } catch (error) {
        setPubLinkLoading(false);
        console.trace(error.message);
      }
    },
    [setTagIds, updateTagIdList]
  );

  // eslint-disable-next-line
  const debounchFunction = useCallback(_.debounce(pubLinkFetchLogic, 500), [
    pubLinkFetchLogic
  ]);

  // handle input change
  const handleInputChange = (value) => {
    try {
      if (value?.length > 2) {
        const params = {};
        params.q = value;
        // params.id = event.target.value;
        params.section = "revenue_reports";
        setPubLinkIdError(null);
        setInputValue(value);
        debounchFunction(params);
      } else if (value?.length > 0) {
        setPubLinkIdError(null);
        setInputValue(value);
      }
    } catch (error) {
      console.trace(error);
    }
  };

  const handleKeyDown = (event) => {
    if (
      event &&
      event.target.value?.length === 1 &&
      event.key === "Backspace"
    ) {
      setInputValue("");
    }
  };

  const clearInputValue = () => {
    setInputValue("");
  };

  /**
   * making api calls and setting all the list values
   * */
  useEffect(() => {
    // fetchChannelsByAdv();
    let targetingParams = {};
    if (pubLinkParams.length > 0) {
      const pubLinkParamsRaw = pubLinkParams.join(",");
      targetingParams.pub_link_id = pubLinkParamsRaw;
    }
    fetchTargeting(targetingParams);
    fetchAdvertiserLinkGroups();
    fetchTargetingRules();
    fetchProviders();
    fetchPublishers();
    fetchPlatforms();
    fetchSearchEngines();
    fetchTagTypes();
    fetchPublisherAccounts();
    setCountriesData(COUNTRIES);
    setIntervalData(INTERVALS_REPORT_ADMIN);
    setGroupByData(GROUP_BY_REPORT_ADMIN_NEW);
    setPeriodData(PERIODS);
    setRemarks(REMARKS_LIST_NEW);
  }, [
    //fetchChannelsByAdv,
    setGroupByData,
    fetchProviders,
    setCountriesData,
    fetchPublishers,
    setIntervalData,
    fetchTargetingRules,
    fetchTargeting,
    fetchPlatforms,
    fetchSearchEngines,
    fetchPublisherAccounts,
    fetchTagTypes,
    setPeriodData,
    setRemarks,
    pubLinkParams,
    fetchAdvertiserLinkGroups
  ]);

  function clearAllSelection() {
    removeAllProviders();
    removeAllPublishers();
    removeAllCountries();
    setFromDate(null);
    setToDate(null);
    setFiltersClearToggle(!filtersClearToogle);
    removeAllGroupBy();
    removeAllRuleIds();
    removeAllTagIds();
    removeAllTagTypes();
    removeAllPlatforms();
    removeAllSearchEngines();
    removeAllPublisherAccounts();
    removeAllChannels();
    removeAdvertiserLinkgroupIdFromList();
    // clearSelectedInterval();
    // clearSelectedPeriod();
    changeSelectedInterval(PER_DAY);
    changeSelectedPeriod(MONTH_TO_DATE);
    clearRemark();
    // setViewMissingSubIds(false);
    setMissingSubids(false);

    stableDispatch(
      setRevReportSelectedFilter({
        selectedProviders: [],
        selectedCountries: [],
        selectedPublishers: [],
        fromDate: "",
        toDate: "",
        selectedInterval: "",
        selectedGroupBy: [],
        selectedRuleIds: [],
        selectedTagIds: [],
        selectedTagTypes: [],
        selectedPlatforms: [],
        selectedSearchEngines: [],
        selectedPublisherAccounts: [],
        selectedChannels: [],
        selectedPeriod: "",
        selectedAdvertiserLinkGroupIds: [],
        selectedRemark: [],
        missingSubids: false
      })
    );

    // clear state stored from url search params
    setGroupByParams([]);
    setProviderParams([]);
    setChannelParams([]);
    setPubLinkParams([]);
  }

  useEffect(() => {
    if (ruleIds && ruleIds.length > 0) {
      if (
        findObject(ruleIds, localStorageRuleId) &&
        localStorageRuleId &&
        localStorageFromDate
      ) {
        handleRuleIdChange([localStorageRuleId]);
        if (localStorageFromDate === MONTH_TO_DATE) {
          changeSelectedPeriod(MONTH_TO_DATE);
        }
        setFiltersClearToggle((filtersClearToogle) => !filtersClearToogle);
        if (localStorageInterval && localStorageInterval === "daily") {
          changeSelectedInterval(localStorageInterval);
        }
        setLocalStorageInterval("");
        setLocalStorageRuleId("");
        setLocalStorageFromDate("");
      }
    }
  }, [
    ruleIds,
    changeSelectedInterval,
    handleRuleIdChange,
    setFiltersClearToggle,
    changeSelectedPeriod,
    setLocalStorageInterval,
    setLocalStorageRuleId,
    setLocalStorageFromDate,
    localStorageRuleId,
    localStorageFromDate,
    localStorageInterval
  ]);

  useEffect(() => {
    // providers is alias for advertisers
    if (providers && providers.length > 0) {
      if (
        findObject(providers, localStorageAdvertiserId) &&
        localStorageAdvertiserId &&
        localStorageFromDate
      ) {
        handleProviderChange([localStorageAdvertiserId]);
        if (localStorageFromDate === MONTH_TO_DATE) {
          changeSelectedPeriod(MONTH_TO_DATE);
        }
        setFiltersClearToggle((filtersClearToogle) => !filtersClearToogle);
        if (localStorageInterval && localStorageInterval === "daily") {
          changeSelectedInterval(localStorageInterval);
        }
        setLocalStorageInterval("");
        setLocalStorageAdvertiserId("");
        setLocalStorageFromDate("");
      }
    }
  }, [
    localStorageAdvertiserId,
    localStorageFromDate,
    localStorageInterval,
    changeSelectedInterval,
    handleProviderChange,
    setFiltersClearToggle,
    changeSelectedPeriod,
    setLocalStorageInterval,
    setLocalStorageAdvertiserId,
    setLocalStorageFromDate,
    providers
  ]);

  const handleToDate = (date) => {
    //date to be set is null , set it and return
    if (!date) {
      setToDate(null);
      return;
    }
    if (!fromDate) {
      setToDate(date);
    } else {
      if (moment(date).isAfter(fromDate) || moment(date).isSame(fromDate)) {
        setToDate(date);
      } else setToDate(fromDate);
    }
  };

  const handleFromDate = (date) => {
    //date to be set is null , set it and return
    if (!date) {
      setFromDate(null);
      return;
    }
    if (!toDate) {
      setFromDate(date);
    } else {
      if (moment(date).isBefore(toDate) || moment(date).isSame(toDate)) {
        setFromDate(date);
      } else {
        setToDate(date);
        setFromDate(date);
      }
    }
  };

  const handleDateChange = (fromDate, toDate) => {
    handleFromDate(fromDate);
    handleToDate(toDate);
  };

  let filterValues = {
    selectedChannels,
    selectedProviders,
    selectedCountries,
    selectedPublishers,
    fromDate,
    toDate,
    selectedInterval,
    selectedGroupBy,
    selectedRuleIds,
    selectedTagIds,
    selectedAdvertiserLinkGroupIds,
    selectedTagTypes,
    selectedPlatforms,
    selectedSearchEngines,
    selectedPublisherAccounts,
    selectedPeriod,
    orderBy,
    orderDirection,
    selectedRemark,
    // viewMissingSubIds,
    missingSubids,
    pubLinkIdError
  };

  let dataLists = {
    channels,
    publishers,
    providers,
    countries,
    intervals,
    groupByList,
    tagIds,
    advertiserLinkGroupIds,
    ruleIds,
    tagTypes,
    platforms,
    searchEngines,
    publisherAccounts,
    periods,
    remarks,
    inputValue,
    pubLinkLoading
  };

  let selectionAddActions = {
    handleChannelsChange,
    handleProviderChange,
    handleCountryChange,
    handlePublisherChange,
    handleFromDate,
    handleToDate,
    changeSelectedInterval,
    handleGroupByChange,
    handleRuleIdChange,
    handleTagIdChange,
    handleAdvertiserLinkGroupIdChange,
    handleDateChange,
    handleTagTypeChange,
    handlePlatformChange,
    handleSearchEngineChange,
    handlePublisherAccountChange,
    changeSelectedPeriod,
    setOrderBy,
    setOrderDirection,
    handleRemarkChange,
    setMissingSubids,
    handleInputChange,
    handleKeyDown,
    clearInputValue
  };

  return {
    filterValues,
    clearAllSelection,
    dataLists,
    selectionAddActions,
    filtersClearToogle,
    removeProviderFromList,
    removeCountryFromList,
    removeAllCountries,
    removePublisherFromList,
    removeGroupByFromList,
    removeTagIdFromList,
    removeAdvertiserLinkgroupIdFromList,
    removeRuleIdFromList,
    removeTagTypeFromList,
    removePlatformFromList,
    removeSearchEngineFromList,
    removePublisherAccountFromList,
    removeChannelsFromList
  };
};
