import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import Form from "../../components/Form/Form";
import {
  addError,
  fetchProviderStart,
  fetchProviderSuccess
} from "../../reducers/provider/provider.action";

import { Provider } from "../../services/Provider";

const Manage = (props) => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const history = useHistory();
  const [editing, setEditing] = useState(false);
  const [provider, setProvider] = useState({
    name: "",
    details: "",
    id: "",
    status: "active",
    display_in_upload_screen: false
  });
  const { pathname } = useLocation();
  const { id } = useParams() || null;
  const handleCancel = () => history.push("/advertisers");

  const fetchProvider = useCallback(
    (providerID) => {
      stableDispatch(fetchProviderStart());
      Provider.fetchProvider(providerID)
        .then((response) => {
          if (response.success) {
            setProvider(response.data.provider);
            stableDispatch(fetchProviderSuccess());
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          toastr.error("Opps", "No provider found!!!");
          history.push("/advertisers");
          console.trace(error.message);
        });
    },
    [stableDispatch, history]
  );

  useEffect(() => {
    if (id && pathname.includes("edit")) {
      setEditing(true);
      fetchProvider(id);
    }
  }, [id, pathname, fetchProvider]);

  const uploadProvider = (data) => {
    Provider.addProvider(data)
      .then((response) => {
        if (response.success) {
          history.push("/advertisers");
          toastr.success("Success", "Advertiser added successfully!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
        dispatch(addError());
      });
  };

  const editProvider = (id, data) => {
    Provider.updateProvider(id, data)
      .then((response) => {
        if (response.success) {
          history.push("/advertisers");
          toastr.success("Success", "Advertiser updated successfully!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
        dispatch(addError());
      });
  };

  return (
    <div className="publisher-manage">
      <div className="content_box">
        <fieldset className="custom-fieldset">
          <legend>{editing ? "Edit" : "Add"} Advertiser</legend>
          <Form
            editing={editing}
            onFormSubmit={editing ? editProvider : uploadProvider}
            initialValues={provider}
            handleCancel={handleCancel}
            type="provider"
          />
        </fieldset>
      </div>
    </div>
  );
};

export default Manage;
