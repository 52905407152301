import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { toastr } from "react-redux-toastr";
// import { setCurrentUser } from "../services/Auth";
import store from "../store";
import Api from "./api";

import { call } from "../helpers";

import { GET_ERRORS, SET_CURRENT_USER } from "../helpers/constant/types";
import { requestGetToken, uniqueDeviceId } from "./Firebase";

export const login = (userData, onSuccess) => (dispatch) => {
  Api.post("login", userData)
    .then(async (user) => {
      if (user.body.success) {
        const { token } = user.body.data;
        Cookies.set("ninaya-token", token);
        const object = { token: token, timestamp: new Date().getTime() };
        localStorage.setItem("ninaya-token", JSON.stringify(object));
        const decoded = jwtDecode(token);
        dispatch(setCurrentUser(decoded));
        call(onSuccess);
          
        try {
          const notiObj = {
            notificationSubscribed : decoded.notificationSubscribed,
            fcmTokenAvailable: decoded.fcmTokenAvailable
          }

          if (decoded.notificationSubscribed && !decoded.fcmTokenAvailable) {
            // 1. check for device_id in localStorage() and if not found generate unique device_id
            let device_id = uniqueDeviceId();

            // 2. get FCM token
            const fcm_token = await requestGetToken();

            // 3. send a POST request with device_id & fcm token to register device
            const resp = await Api.post("user-device", { device_id, fcm_token });
            notiObj.fcmTokenAvailable = resp.body.success;
          }
          localStorage.setItem("ninaya-notification", JSON.stringify(notiObj));
        } catch (error) {
          console.log(error);
          // toastr.error("Error", "Something went wrong!");
        }
      } else {
        toastr.error("Error", user.body.error.message);
        dispatch({
          type: GET_ERRORS,
          payload: { message: user.body.error.message, isError: true }
        });
        throw new Error(JSON.stringify(user.body.error));
      }
    })
    .catch((error) => {
      console.trace(error.message);
      // toastr.error("", error.message);
      // const { message } = JSON.parse(error.message);
      const message = error.message;
      dispatch({
        type: GET_ERRORS,
        payload: { message, isError: true }
      });
    });
};

export const loginAsAdminDashboard = (onSuccess) => (dispatch) => {
  try {
    const token = Cookies.get("admin-token");
    Cookies.remove("admin-token");
    Cookies.set("ninaya-token", token);
    const object = { token: token, timestamp: new Date().getTime() };
    localStorage.setItem("ninaya-token", JSON.stringify(object));
    const decoded = jwtDecode(token);
    store.dispatch(setCurrentUser(decoded));
    call(onSuccess);
  } catch (error) {
    console.trace(error.message);
    toastr.error("", error.message);
    // const { message } = JSON.parse(error.message);
    const message = error.message;
    dispatch({
      type: GET_ERRORS,
      payload: { message, isError: true }
    });
  }
};
export const loginAsPublisherDashboard =
  (publisherId, onSuccess) => (dispatch) => {
    Cookies.set("admin-token", Cookies.get("ninaya-token"));
    Api.post("auth/fetch-publisher-token", { publisherId: publisherId })
      .then((user) => {
        if (user.body.success) {
          call(onSuccess);
          const { token } = user.body.data;

          Cookies.set("ninaya-token", token);
          const object = { token: token, timestamp: new Date().getTime() };
          localStorage.setItem("ninaya-token", JSON.stringify(object));
          const decoded = jwtDecode(token);
          store.dispatch(setCurrentUser(decoded));
        } else {
          toastr.error("Error", user.body.error.message);
          // dispatch({
          //   type: GET_ERRORS,
          //   payload: { message: user.body.error.message, isError: true }
          // });
          // throw new Error(JSON.stringify(user.body.error));
        }
      })
      .catch((error) => {
        // console.trace(error.message);
        // toastr.error("", error.message);
        // const { message } = JSON.parse(error.message);
        // dispatch({
        //   type: GET_ERRORS,
        //   payload: { message, isError: true }
        // });
      });
  };

export const changePassword =
  (userData, onSuccess, setIsLoading) => (dispatch) => {
    Api.post("auth/reset-password", userData)
      .then((data) => {
        setIsLoading(false);
        if (data.body.status) {
          toastr.success(data.body.message);
          call(onSuccess);
        } else {
          toastr.error("Error", data.body?.error?.message);
          dispatch({
            type: GET_ERRORS,
            payload: { message: data.body.error.message, isError: true }
          });
          throw new Error(JSON.stringify(data.body.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        const message = error.message;
        dispatch({
          type: GET_ERRORS,
          payload: { message, isError: true }
        });
      });
  };
export const checkToken = (userData, onSuccess) => (dispatch) => {
  Api.post("auth/validate-token/", userData)
    .then((data) => {
      if (data.body.status) {
        // TODO call the onSuccess function
        call(onSuccess, true);
      } else {
        console.log(data);
        toastr.error("Error", data.body?.error.message);
        dispatch({
          type: GET_ERRORS,
          payload: { message: data.body.error.message, isError: true }
        });
        throw new Error(JSON.stringify(data.body.error));
      }
    })
    .catch((error) => {
      console.trace(error.message);
      const message = error.message;
      call(onSuccess, false);
      dispatch({
        type: GET_ERRORS,
        payload: { message, isError: true }
      });
    });
};
export const sendMail = (formData, onSuccess, setIsLoading) => (dispatch) => {
  Api.post("auth/forgot-password", formData)
    .then((success) => {
      console.log(setIsLoading);
      setIsLoading(false);
      if (success.body?.status) {
        // console.log(success.body);
        toastr.info("Success", success.body.message);
        call(onSuccess);
      } else {
        toastr.error("Error", success.body?.error?.message);
      }
    })
    .catch((error) => {
      console.trace(error.message);
      const message = error.message;
      dispatch({
        type: GET_ERRORS,
        payload: { message, isError: true }
      });
    });
};

export const setLoginError = () => (dispatch) => {
  dispatch({
    type: GET_ERRORS,
    payload: { isError: false }
  });
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const logout = (callback) => async (dispatch) => {
  await localStorage.removeItem("ninaya-token");
  await Cookies.remove("ninaya-token");
  await Cookies.remove("admin-token");

  dispatch(setCurrentUser({}));

  callback();
};
