import { useState, useEffect } from "react";
import {
  useRevColHeadersContext,
  updateRevColHeaders
} from "../../../../contexts/revenue-context";
import { useLocalStorage } from "../../../../hooks/useLocalStorage";
import { REVENUE_TABLE_HEADERS_PUBLISHER } from "../../../../helpers/constant/localStorage";
import {
  CHANNEL,
  AD_COVERAGE,
  PUBLISHER_REVENUE,
  DATE,
  TOTAL_SEARCHES,
  MONETIZED_SEARCHES,
  CLICKS,
  // CTR,
  NET_REVENUE,
  RPM,
  RPC,
  RPMM,
  FOLLOWON_SEARCHES,
  INITIAL_SEARCHES,
  SEARCH_ENGINE,
  LINK_PLATFORM,
  TAG_TYPE,
  // SERVER_SEARCHES_RPM,
  COUNTRY,
  AD_COVERAGE_SERVER,
  PROVIDER_LINK_ID,
  QUALITY_SCORE,
  INVALID_TRAFFIC,
  MONETIZED_CTR,
  SEARCH_COUNT,
  PUBLISHER_REVENUE_NEW,
  FILTERED_REASON,
  PUBLISHER_CHANNEL_ID,
  ADVERTISER_SEARCH_COUNT
  // ESTIMATED_REVENUE,
  // BUFFER
} from "../../../../helpers/constant/filters";
import { table_mapping_order_publisher } from "../../../../helpers/constant/mapping";

function getInitialShowState() {
  let showState = {};
  showState[DATE] = true;
  showState[CHANNEL] = true;
  showState[SEARCH_ENGINE] = true;
  showState[LINK_PLATFORM] = true;
  showState[TAG_TYPE] = true;
  showState[COUNTRY] = true;
  showState[TOTAL_SEARCHES] = true;
  showState[MONETIZED_SEARCHES] = true;
  showState[CLICKS] = true;
  // showState[CTR] = true;
  showState[AD_COVERAGE] = true;
  showState[PUBLISHER_REVENUE] = true;
  // showState[ESTIMATED_REVENUE] = true;
  // showState[BUFFER] = true;
  showState[NET_REVENUE] = true;
  showState[RPM] = true;
  showState[RPMM] = true;
  showState[FOLLOWON_SEARCHES] = true;
  showState[INITIAL_SEARCHES] = true;
  showState[PROVIDER_LINK_ID] = true;
  showState[QUALITY_SCORE] = true;
  showState[ADVERTISER_SEARCH_COUNT] = true;
  // showState["search_counts"] = true;
  showState[RPC] = true;
  // showState[SERVER_SEARCHES_RPM] = true;
  showState["ssrpm"] = true;
  showState["server_search_count"] = true;
  showState[MONETIZED_CTR] = true;
  showState["sno"] = true;
  showState[AD_COVERAGE_SERVER] = true;
  showState[INVALID_TRAFFIC] = true;

  return showState;
}

export const useReportTableFilterLogic = ({ displayNames, list }) => {
  const revHeadersCtxValues = useRevColHeadersContext(); // Use Revenue Headers Context
  const revHeadersCtxDispatch = revHeadersCtxValues[1];
  const [tableHeaders, setTableHeaders] = useState([]);
  const [filteredTableHeaders, setFilteredTableHeaders] = useState([]);
  // const [showState, setShowState] = useState(getInitialShowState());
  const [showState, setShowState] = useLocalStorage(
    REVENUE_TABLE_HEADERS_PUBLISHER,
    getInitialShowState()
  );

  const updateHeadersShowValue = (value, index) => {
    let newShowState = { ...showState };
    newShowState[value] = !newShowState[value];
    setShowState(newShowState);
  };

  /** updating table headers when display names and data changes */
  useEffect(() => {
    /**
     *  Function that returns an object of column headers from displayNames
     *  to be used in Table
     */
    function getColumnHeaders(displayNames) {
      let columnHeaders = {};
      Object.keys(displayNames).forEach((columnValue) => {
        columnHeaders[columnValue] = {
          show: showState[columnValue],
          title: displayNames[columnValue],
          value: columnValue
        };
      });

      return columnHeaders;
    }

    // Remaining Table Column not mapped to display names
    function groupByValueMappedToHeader(value) {
      switch (value) {
        case PUBLISHER_CHANNEL_ID:
          return {
            title: "Channel",
            value: PUBLISHER_CHANNEL_ID,
            show: showState[PUBLISHER_CHANNEL_ID]
          };
        case AD_COVERAGE:
          return {
            title: "Ad Coverage (%)",
            value: AD_COVERAGE,
            show: showState[AD_COVERAGE]
          };
        case PUBLISHER_REVENUE_NEW:
          return {
            title: "Net Revenue",
            value: PUBLISHER_REVENUE_NEW,
            show: showState[PUBLISHER_REVENUE_NEW]
          };
        case "server_search_count":
          return {
            title: "Server Search Counts",
            value: "server_search_count",
            show: showState["server_search_count"]
          };
        case SEARCH_COUNT:
          return {
            title: "Server Search Counts",
            value: SEARCH_COUNT,
            show: showState[SEARCH_COUNT]
          };
        case SEARCH_ENGINE:
          return {
            title: "Search Engine",
            value: SEARCH_ENGINE,
            show: showState[SEARCH_ENGINE]
          };
        case LINK_PLATFORM:
          return {
            title: "Link Platform",
            value: LINK_PLATFORM,
            show: showState[LINK_PLATFORM]
          };
        case TAG_TYPE:
          return {
            title: "Link Type",
            value: TAG_TYPE,
            show: showState[TAG_TYPE]
          };
        case "ssrpm":
          return {
            title: "Server Searches RPM",
            value: "ssrpm",
            show: showState["ssrpm"]
          };
        case COUNTRY:
          return {
            title: "Geo",
            value: COUNTRY,
            show: showState[COUNTRY]
          };
        case TOTAL_SEARCHES: {
          return {
            title: "Total searches",
            value: TOTAL_SEARCHES,
            show: showState[TOTAL_SEARCHES]
          };
        }
        case ADVERTISER_SEARCH_COUNT: {
          return {
            title: "Total searches",
            value: ADVERTISER_SEARCH_COUNT,
            show: showState[ADVERTISER_SEARCH_COUNT]
          };
        }
        case AD_COVERAGE_SERVER:
          return {
            title: "Ad Coverage Server (%)",
            value: AD_COVERAGE_SERVER,
            show: showState[AD_COVERAGE_SERVER]
          };
        case INVALID_TRAFFIC: {
          return {
            title: "Invalid Traffic",
            value: INVALID_TRAFFIC,
            show: showState[INVALID_TRAFFIC]
          };
        }
        case FILTERED_REASON: {
          return {
            title: "Filtered Reason",
            value: FILTERED_REASON,
            show: showState[FILTERED_REASON]
          };
        }
        default:
          return "";
      }
    }

    //function to get headers based on columns state whose show value are set to true
    function getTableHeaders(tableColumns, list) {
      let headers = [{ title: "SNo", value: "sno", show: showState["sno"] }];
      let oneValueFromList = list?.[0] || {};
      let keysFromValue = Object.keys(oneValueFromList);
      table_mapping_order_publisher.forEach((value) => {
        if (keysFromValue.includes(value)) {
          let header = {};
          if (value in tableColumns) {
            header = tableColumns[value];
          } else {
            header = groupByValueMappedToHeader(value);
          }
          if (header) {
            headers.push(header);
          }
        }
      });

      return headers;
    }

    if (displayNames && list && list?.length > 0) {
      let tableColumns = getColumnHeaders(displayNames.fields);
      let tableHeaders = getTableHeaders(tableColumns, list);
      setTableHeaders(tableHeaders);
    }
  }, [displayNames, list, showState]);

  useEffect(() => {
    setTableHeaders((tableHeaders) =>
      tableHeaders.map((header) => {
        header.show = showState[header.value];
        return header;
      })
    );
  }, [showState]);

  useEffect(() => {
    setFilteredTableHeaders(tableHeaders.filter((header) => header.show));
    updateRevColHeaders(revHeadersCtxDispatch, tableHeaders);
    // eslint-disable-next-line
  }, [tableHeaders]);

  return {
    tableHeaders,
    filteredTableHeaders,
    updateHeadersShowValue
  };
};
