import Api from "../api";

class EmailConfigService {
  editUpdateEmailConfig = async (data = {}) => {
    const { body } = await Api.post(`emailConfig`, data);
    return body;
  };
  getEmailConfig = async () => {
    const { body } = await Api.get(`emailConfig`);
    return body;
  };
}
// eslint-disable-next-line
export default new EmailConfigService();
