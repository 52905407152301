import RevenueReportTypes from "./revenueReport.types";
import { MONTH_TO_DATE, PER_DAY } from "../../helpers/constant/filters";

const INITIAL_STATE = {
  selectedFilter: {
    selectedProviders: [],
    selectedCountries: [],
    selectedPublishers: [],
    fromDate: '',
    toDate: '',
    selectedInterval: PER_DAY,
    selectedGroupBy: [],
    selectedRuleIds: [],
    selectedTagIds: [],
    selectedTagTypes: [],
    selectedPlatforms: [],
    selectedSearchEngines: [],
    selectedPublisherAccounts: [],
    selectedChannels: [],
    selectedPeriod: MONTH_TO_DATE,
    selectedRemark: [],
    missingSubids: false
  }
};

const revenueReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RevenueReportTypes.SET_REV_REPORT_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: action.payload
      };
    default:
      return { ...state };
  }
};

export default revenueReportReducer;
