import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { PushNotification } from "../../../services/Notification";
import { toastr } from "react-redux-toastr";
import { requestGetToken, uniqueDeviceId } from "../../../services/Firebase";

const ProfileNotification = ({ profile }) => {
    const notificationObject = JSON.parse(localStorage.getItem('ninaya-notification'));
    const [loading, setLoading] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(notificationObject?.notificationSubscribed ?? false);

    const handleSubscribe = () => {
        setLoading(true);
        // 1. get device id
        const device_id = uniqueDeviceId();

        // 2. get token
        requestGetToken().then((fcm_token) => {
            const payload = {
                device_id,
                fcm_token,
                subscription: true
            };
            updateNotificationSubscription(payload);
        })
        .catch((err) => {
            setLoading(false);
            console.log(JSON.stringify(err));
            toastr.error("Error", err.message);
        });
    }

    const handleUnsubscribe = () => {
        setLoading(true);
        const payload = {
            subscription: false
        }
        updateNotificationSubscription(payload);
    }

    const updateNotificationSubscription = (payload) => {
        PushNotification.userNotificationSubscription(payload)
            .then((response) => {
                setLoading(false);
                if (response.success) {
                    setIsSubscribed(payload.subscription);
                    const notiObj = {
                        notificationSubscribed : payload.subscription,
                        fcmTokenAvailable: payload?.fcm_token? true : false   
                    }
                    localStorage.setItem('ninaya-notification', JSON.stringify(notiObj));
                    toastr.success("Success", response.message);
                } else {
                    throw new Error(JSON.stringify(response.error));
                }
            })
            .catch((err) => {
                setLoading(false);
                toastr.error("Oops!", err.message);
                console.trace(err.response);
            });
    }

    return (
        <div className="profile_notification">
            <div className="profile_notification__alert">
                <Alert severity="info">
                    <span>User can subscribe and unsubscribe to the notification from here.</span>
                </Alert>
                {
                    isSubscribed 
                    ? (<>
                        <Button variant="contained" size="small" color="secondary" onClick={handleUnsubscribe} style={{ marginLeft: "10px" }} disabled={loading}>
                            {!loading
                            ? (<>
                                Unsubscribe <NotificationsOffIcon style={{marginLeft: '3px'}}/> 
                            </>) 
                            : 'Loading ...'}
                        </Button>
                    </>) 
                    : (<>
                        <Button variant="contained" size="small" color="primary" onClick={handleSubscribe} style={{ marginLeft: "10px" }} disabled={loading}>
                            {!loading
                            ? (<>
                                Subscribe <NotificationsIcon style={{marginLeft: '3px'}}/> 
                            </>) 
                            : 'Loading ...'}
                        </Button>
                    </>)
                }
            </div>
        </div>
    );
};

export default ProfileNotification;
