import React, { useState, useEffect } from "react";
import {
    Checkbox,
    FormControlLabel,
    FormControl,
    TextField,
    FormHelperText
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { Prompt } from "react-router-dom";
import { useFormTouched } from "../../hooks/useFormTouched";
import { changeEmptyStringToNull, changeNullToString } from "../../helpers";

export default function Form({ initialData, onSubmit, onCancel }) {
    const [share_revenue, setShareRevenue] = useState("");
    const [status, setStatus] = useState(false);
    const [api_key, setApiKey] = useState("");
    const [fieldErrors, setFieldErrors] = useState({
        status: false,
        share_revenue: false,
        api_key: false
    });

    const [showPrompt, setShowPrompt] = useState(true);
    const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
        cleanupFunction: () => { }
    });
    useEffect(
        function () {
            setShareRevenue(changeNullToString(initialData.share_revenue));
            setApiKey(changeNullToString(initialData.api_key));
            setStatus(initialData.status);
        },
        [initialData]
    );

    const onStatusChange = (e) => {
        setStatus(e.target.checked);
        handleFormTouched();
    };

    const handleSubmit = function (e) {
        e.preventDefault();
        setShowPrompt(false);
        if (validateForm()) {
            clearFormTouched();
            onSubmit({
                share_revenue: share_revenue,
                api_key: changeEmptyStringToNull(api_key),
                status: status
            });
        }
    };

    const validateForm = function () {
        setFieldErrors({});
        let parsedShareRevenue = parseFloat(share_revenue);
        if (parsedShareRevenue < 0 || parsedShareRevenue > 100 || !share_revenue) {
            setFieldErrors({
                ...fieldErrors,
                share_revenue: true
            });
            return false;
        }
        return true;
    };

    const fontStyle = {
        fontWeight: "500",
        margin: "10px"
    };

    return (
        <div>
            <div className="publisher-account-list list-Item">
                <legend>
                    <span style={fontStyle}>Publisher Account Id :</span> #
                    {initialData?.id}
                </legend>
                <legend>
                    <span style={fontStyle}>Advertiser Name :</span>{" "}
                    {initialData?.provider?.name}
                </legend>
                <legend>
                    <span style={fontStyle}>Publisher Name :</span>{" "}
                    {initialData?.publisher?.name}
                </legend>
                <legend>
                    <span style={fontStyle}>CSV Source Identifier : </span>
                    {initialData?.provider?.csv_source_identifier}
                </legend>
                <legend>
                    <span style={fontStyle}>Rule Id : </span>
                    {initialData?.rule_id}
                </legend>
                <legend>
                    <span style={fontStyle}>Channel: </span>
                    {initialData?.sin}
                </legend>
            </div>
            <form className="form">
                <div className="form__status">
                    <h3 className="dark-grey-text">Status</h3>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={onStatusChange}
                                name="status"
                                color="primary"
                                checked={status}
                                disabled
                            />
                        }
                        label="Active"
                    />
                </div>
                <FormControl fullWidth>
                    <TextField
                        variant="standard"
                        margin="normal"
                        label="Revenue Share (%)"
                        name="share_revenue"
                        value={share_revenue}
                        error={fieldErrors.share_revenue}
                        onChange={(e) => {
                            handleFormTouched();
                            setShareRevenue(e.target.value);
                            setFieldErrors({
                                ...fieldErrors,
                                share_revenue: false
                            });
                        }}
                        placeholder="Enter Revenue Share"
                        type="number"
                    />
                    {fieldErrors.share_revenue && (
                        <FormHelperText color="secondary">
                            Please Enter valid Revenue Share (%)
                        </FormHelperText>
                    )}
                </FormControl>

                <FormControl fullWidth>
                    <TextField
                        variant="standard"
                        margin="normal"
                        label="API Key"
                        name="api_key"
                        value={api_key}
                        error={fieldErrors.api_key}
                        onChange={(e) => {
                            handleFormTouched();
                            setApiKey(e.target.value);
                            setFieldErrors({
                                ...fieldErrors,
                                api_key: false
                            });
                        }}
                        placeholder="Enter API key"
                    />
                    {fieldErrors.api_key && (
                        <FormHelperText color="secondary">
                            Please Enter valid API Key
                        </FormHelperText>
                    )}
                </FormControl>

                <div className="form__controls">
                    <Button
                        color="primary"
                        onClick={(e) => handleSubmit(e)}
                        variant="contained"
                        size="medium"
                        startIcon={<CheckIcon />}
                        type="submit"
                    >
                        Save
                    </Button>
                    <Button
                        onClick={onCancel}
                        variant="contained"
                        size="medium"
                        color="secondary"
                        startIcon={<ClearIcon />}
                    >
                        cancel
                    </Button>
                </div>
            </form>
            <Prompt
                when={showPrompt && formTouched.current}
                message="Are you sure you want to leave?"
            />
        </div>
    );
}
