import { IconButton, Tooltip, useTheme } from "@material-ui/core";
import HistoryIcon from '@material-ui/icons/History';
import { useState } from "react";
import { LatestHistoryTableModal } from "../../../components/HistoryModal/LatestHistoryTableModal";

const AdditionalHeaderActions = ({ 
  activityLog, 
  tableType, 
  historyTargId, 
  historyPage, 
  setHistoryPage, 
  historyLimit, 
  totalHistoryCount, 
  isLatestHistoryLoading,
  setStartFetchHistory
}) => {
  const theme = useTheme();
  const [historyOpen, setHistoryOpen] = useState(false);

  const handleHistoryClick = () => {
    setHistoryOpen(true);
  }
  
  const handleModalClose = () => {
    setHistoryOpen(false);
  }

  return (
    <div>
      {
        activityLog &&
        (
          <div>
            <Tooltip title="Latest History">
              <IconButton onClick={() => handleHistoryClick()}>
                <HistoryIcon style={{ fill: theme.palette.primary.main }} />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
      {activityLog && historyOpen && (
        <LatestHistoryTableModal 
          handleModalClose={handleModalClose} 
          activityLog={activityLog} 
          tableType={tableType}
          historyTargId={historyTargId}
          historyPage={historyPage}
          setHistoryPage={setHistoryPage}
          historyLimit={historyLimit}
          totalHistoryCount={totalHistoryCount}
          isLatestHistoryLoading={isLatestHistoryLoading}
          setStartFetchHistory={setStartFetchHistory} />
      )}
    </div>
  )
}

export default AdditionalHeaderActions;
