import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getCurrentUser } from "../helpers/AuthHelper";
import { auth } from "../helpers";

// Role Based Access Controller
const RBAC = ({ allowedRoles, children }) => {
  let currentUser = useSelector(getCurrentUser);

  let currentUserRole = currentUser.role.role;

  let access = auth.checkAccess(currentUserRole, allowedRoles);
  //we need to remove this on admin.
  // let adminAvailable=children.adminAvailable!==null;
  return (access && children)
};

RBAC.propTypes = {
  allowedRoles: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired
};

export default RBAC;
