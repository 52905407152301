import Api from "../api";

class ReportingLogs {
  fetchReports = async (params = {}) => {
    const response = await Api.get("skippedrows", null, params);
    return response.body;
  };
  fetchReportsById = async (id, params = {}) => {
    const response = await Api.get(`skippedrows/${id}`, null, params);
    return response.body;
  };
}
// eslint-disable-next-line
export default new ReportingLogs();
