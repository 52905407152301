import React, { useState, useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Table from "../../../components/Table/Table";
import GetAppIcon from "@material-ui/icons/GetApp";

import SimpleAccordion from "../../../components/Accordion/Accordion";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import { HEADERS } from "./constant/header";
import FilterBox from "./layouts/FilterBox.filter";
import { SkippedRows } from "../../../services/skippedRows";
import moment from "moment";
import { useRedirectLogic } from "../../../hooks/useRedirectLogic";
import {
  ADMIN_REPORT_ADVERTISER_ID,
  ADMIN_REPORT_FROM_DATE,
  ADMIN_REPORT_INTERVAL,
} from "../../../helpers/constant/localStorage";
import { MONTH_TO_DATE } from "../../../helpers/constant/filters";

const RevenueReportStatus = () => {
  const { redirectToSkippedRows } = useRedirectLogic();
  const [revenueReportStatus, setRevenueReportStatus] = useState([]);
  const location = useLocation();
  const getInitialProvider = useCallback(() => {
    return location?.state?.provider || "";
  }, [location]);
  const [provider, setProvider] = useState(() => getInitialProvider());
  const [status, setStatus] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  //states for fitler box
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const prevPage = useRef(1);
  const prevOrderBy = useRef(null);
  const prevOrderDirection = useRef(null);
  const prevSize = useRef(null);

  const getRequestParams = useCallback(() => {
    let params = {};

    if (provider !== "") {
      params["provider_id"] = provider;
    }
    if (status !== "") {
      params["status"] = status;
    }
    if (toDate) {
      params["to"] = moment(toDate).format("YYYY-MM-DD");
    }
    if (fromDate) {
      params["from"] = moment(fromDate).format("YYYY-MM-DD");
    }
    params["page"] = page;
    params["size"] = size;
    params["order_by"] = orderBy;
    params["order_direction"] = orderDirection;

    return params;
  }, [fromDate, toDate, status, provider, orderBy, orderDirection, page, size]);

  const fetchData = useCallback(
    (params = {}) => {
      setLoading(true);
      SkippedRows.fetchAggregatedCsvUploadStatus(params)
        .then((response) => {
          if (response.success) {
            let data = response.data.data;
            setRevenueReportStatus(data);
            setTotal(response.data.total);
            setLoading(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          setLoading(false);
        });
    },
    // eslint-disable-next-line
    [provider, toDate, fromDate]
  );

  // useEffect(() => {
  //   fetchData(getRequestParams());
  // }, [fetchData, getRequestParams]);

  useEffect(() => {
    if (
      prevPage.current !== page ||
      prevOrderBy.current !== orderBy ||
      prevOrderDirection.current !== orderDirection ||
      prevSize.current !== size
    ) {
      fetchData(getRequestParams());
      prevPage.current = page;
      prevOrderBy.current = orderBy;
      prevOrderDirection.current = orderDirection;
      prevSize.current = size;
    }
  }, [page, size, orderDirection, orderBy, fetchData, getRequestParams]);

  const handleFilter = () => {
    fetchData(getRequestParams());
  };
  const viewDetails = (id, data) => {
    redirectToSkippedRows(+data?.advertiser_id, data.csv_name);
  };
  const handleRedirectAction = (id, data) =>
    window.open(`/advertisers/edit/${data.advertiser_id}`, "_blank");
  const handleRedirectRevenueAction = (id, data) => {
    window.localStorage.setItem(
      ADMIN_REPORT_ADVERTISER_ID,
      JSON.stringify(data.advertiser_id)
    );
    window.localStorage.setItem(
      ADMIN_REPORT_FROM_DATE,
      JSON.stringify(MONTH_TO_DATE)
    );
    window.localStorage.setItem(ADMIN_REPORT_INTERVAL, JSON.stringify("daily"));
    window.open("/revenuereport", "_blank");
  };
  const actions = [
    {
      name: "redirect",
      action: handleRedirectAction,
      icon: "redirectIcon"
    },
    {
      name: "redirectToRevenue",
      action: handleRedirectRevenueAction,
      icon: "redirectToRevenue"
    },
    {
      name: "Details",
      action: viewDetails,
      icon: "details"
    }
  ];
  const icon = <GetAppIcon fontSize="inherit" color="primary" />;
  return (
    <div>
      <ContentHeader icon={icon} title="Revenue Reports Upload Status" />
      <div>
        <SimpleAccordion header="Filters" key="dat-filter-box">
          <FilterBox
            provider={provider}
            setProvider={setProvider}
            handleFilter={handleFilter}
            status={status}
            setStatus={setStatus}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
          />
        </SimpleAccordion>
        <br />
      </div>
      <div className="providers-container__table">
        {revenueReportStatus && (
          <Table
            headers={HEADERS}
            actions={actions}
            noCheckboxes={true}
            noIds={true}
            rowValues={revenueReportStatus || []}
            tableType={"skippedrows"}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            total={total}
            isLoading={loading}
            rowsPerPageOptions={[1, 20, 50, 100, 150, 200]}
          />
        )}
      </div>
    </div>
  );
};

export default RevenueReportStatus;
