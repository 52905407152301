import React from "react";

//
import styles from "./404Page.module.css";

const ErrorPage = () => {
  return <div className={styles.heading}>Page Not Found</div>;
};

export default ErrorPage;
