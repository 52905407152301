/**
 * Custom Hook to load dashboard data
 */
import { useEffect, useState } from "react";
import { Report } from "./../../../../services/Report";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { CUSTOM } from "../../../../helpers/constant/filters";
import { formatNumberAddComma } from "../../../../helpers/formatters";
// import { calculateAdCoverageServer } from "../../../../helpers/AdminFormulas";

export const useDashboardData = ({
  filterValues,
  filtersClearToggle,
  dataLists
}) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  // const [orderBy, setOrderBy] = useState("");
  // const [orderDirection, setOrderDirection] = useState("DESC"); //ASC abd DESC

  const [loadingDashboardData, setLoadingDashboardData] = useState({
    loading: true,
    error: false
  });

  function getParams() {
    let params = {};
    const {
      selectedCountries,
      selectedInterval,
      selectedGroupBy,
      fromDate,
      toDate,
      selectedTagTypes,
      selectedPlatforms,
      selectedSearchEngines,
      selectedPeriod,
      orderBy,
      orderDirection
    } = filterValues;

    if (selectedCountries?.length > 0) {
      let selection = [];
      for (let i in selectedCountries) {
        selection.push(selectedCountries[i]);
      }
      params["countries"] = selection;
    }

    if (selectedInterval) {
      params["aggregateBy"] = selectedInterval;
    }

    if (selectedGroupBy?.length > 0) {
      let selection = [];
      for (let i in selectedGroupBy) {
        if (selectedGroupBy[i] === "geo") {
          selection.push("country");
        } else selection.push(selectedGroupBy[i]);
      }
      params["groupByKeys"] = selection;
    }

    if (selectedTagTypes?.length > 0) {
      let selection = [];
      for (let i in selectedTagTypes) {
        selection.push(selectedTagTypes[i]);
      }
      params["tagTypes"] = selection;
    }

    if (selectedPlatforms?.length > 0) {
      let selection = [];
      for (let i in selectedPlatforms) {
        selection.push(selectedPlatforms[i]);
      }
      params["platforms"] = selection;
    }

    if (selectedSearchEngines?.length > 0) {
      let selection = [];
      for (let i in selectedSearchEngines) {
        selection.push(selectedSearchEngines[i]);
      }
      params["searchEngines"] = selection;
    }

    if (selectedPeriod !== CUSTOM) {
      params[selectedPeriod] = true;
      if (selectedPeriod === "today") {
        params["fromDate"] = moment().format("YYYY-MM-DD");
        params["toDate"] = moment().format("YYYY-MM-DD");
      } else if (selectedPeriod === "yesterday") {
        params["fromDate"] = moment().subtract(1, "days").format("YYYY-MM-DD");
        params["toDate"] = moment().subtract(1, "days").format("YYYY-MM-DD");
      } else if (selectedPeriod === "previousmonths") {
        params["fromDate"] = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        params["toDate"] = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (selectedPeriod === "currentmonths") {
        params["fromDate"] = moment().startOf("month").format("YYYY-MM-DD");
        params["toDate"] = moment().format("YYYY-MM-DD");
      } else {
        params["fromDate"] = moment().startOf("month").format("YYYY-MM-DD");
        params["toDate"] = moment().format("YYYY-MM-DD");
      }
    } else if (fromDate & toDate) {
      if (fromDate && moment(fromDate).isValid()) {
        params["fromDate"] = moment(fromDate).format("YYYY-MM-DD");
      }
      if (toDate && moment(toDate).isValid()) {
        params["toDate"] = moment(toDate).format("YYYY-MM-DD");
      }
    } else {
      params["fromDate"] = moment().startOf("month").format("YYYY-MM-DD");
      params["toDate"] = moment().format("YYYY-MM-DD");
    }

    if (
      selectedInterval &&
      (selectedInterval === "daily" || selectedInterval === "monthly")
    ) {
      params["orderBy"] = "date";
      params["orderDirection"] = orderDirection.toLowerCase();
    }

    if (orderBy) {
      // modify order by keys
      // TODO change the key values in the table itself
      /*
            The the keys used for ordering send in the previous might be different we have to modify the keys
            */
      // key - old name, value - new name
      let modified_order_by = orderBy;
      const key_mapping = {
        geo: "country",
        advertiser_id: "provider_id",
        channel: "targeting_id",
        click: "clicks",
        gross_revenue: "shared_revenue",
        pub_revenue: "publisher_revenue",
        server_search_counts: "server_search_count",
        total_searches: "adv_search_count"
      };
      console.log(orderBy);
      const old_keys = Object.keys(key_mapping);
      if (old_keys.includes(orderBy)) {
        modified_order_by = key_mapping[orderBy];
      }
      params["orderBy"] = modified_order_by;
      params["orderDirection"] = orderDirection.toLowerCase();
    }

    params["page"] = page;
    params["size"] = size;

    return params;
  }

  // Format the data to be used in the table
  function formatListRow(unformattedRow, index) {
    let row = { ...unformattedRow };
    if ("date" in row) {
      if (filterValues?.selectedInterval === "daily") {
        row.date = moment(row.date).format("MMM DD,YYYY");
      } else if (filterValues?.selectedInterval === "monthly") {
        row.date = moment(row.date).format("MMM ,YYYY");
      } else {
        row.date = moment(row.date).format("MMM DD,YYYY");
      }
    }

    if ("adv_search_count" in row) {
      row.total_searches = row.adv_search_count;
      delete row.adv_search_count;
    }

    [
      "clicks",
      "invalid_traffic",
      "overcapped_traffic",
      "monetized_searches",
      "advertiser_search_count",
      "followon_searches",
      "initial_searches",
      "server_search_count",
      "search_count",
      "publisher_revenue",
      "total_searches",
      "ad_coverage_server_percentage"
    ].forEach((key) => {
      if (key in row) {
        row[key] = formatNumberAddComma(row[key]);
      }
    });

    row.sno = index + 1 + size * (page - 1); // calculating the serial number of the row, size is from the state
    return row;
  }

  function checkIfAllNullValues(row) {
    let isNull = true;
    for (let key in row) {
      if (row[key] !== null) {
        isNull = false;
      }
    }
    return isNull;
  }

  function getFormattedListRows(list) {
    /**
               * Backend returns the data in the following format when it finds no value
               *      {
                          "net_revenue": null,
                          "total_searches": null,
                          "monetized_searches": null,
                          "clicks": null,
                          "ctr": null,
                          "rpm": null,
                          "rpmm": null,
                          "rpc": null
                      }
                      we need to return an empty array to prevent a row of null values in table UI
               */
    if (list.length === 1 && checkIfAllNullValues(list[0])) {
      return [];
    }
    let formatedList = [];
    list.forEach((row, index) => {
      formatedList.push(formatListRow(row, index));
    });
    return formatedList;
  }

  function getSearchEngineName(id) {
    const searchEngineList = dataLists?.searchEngines;
    if (searchEngineList) {
      const engine_index = searchEngineList.findIndex((p) => p.value === id);
      if (engine_index !== -1) {
        return id + " - " + searchEngineList[engine_index].label;
      } else {
        return id;
      }
    } else {
      return id;
    }
  }
  function getTagName(id) {
    const tagLists = dataLists?.tagTypes;
    if (tagLists) {
      const tag_index = tagLists.findIndex((p) => p.value === id);
      if (tag_index !== -1) {
        return id + " - " + tagLists[tag_index].label;
      } else {
        return id;
      }
    } else {
      return id;
    }
  }
  function getPlatformName(id) {
    const platforms = dataLists?.platforms;
    if (platforms) {
      const platform_index = platforms.findIndex((p) => p.value === id);
      if (platform_index !== -1) {
        return id + " - " + platforms[platform_index].label;
      } else {
        return id;
      }
    } else {
      return id;
    }
  }

  function fetchDashboardData() {
    setLoadingDashboardData({
      loading: true,
      error: false
    });
    Report.fetchDashboardDataV2(getParams(), true, true)
      .then((response) => {
        if (response) {
          let data = response;
          if (response?.data?.length > 0) {
            data.list = getFormattedListRows(response.data);
            data.list.forEach((response_item) => {
              if (response_item.search_engine_id) {
                response_item.search_engine_id = getSearchEngineName(
                  response_item.search_engine_id
                );
              } else if (response_item.search_engine_id === null) {
                response_item.search_engine_id = "-";
              }

              if (response_item.tag_type_id) {
                response_item.tag_type_id = getTagName(
                  response_item.tag_type_id
                );
              } else if (response_item.tag_type_id === null) {
                response_item.tag_type_id = "-";
              }

              if (response_item.platform_id) {
                response_item.platform_id = getPlatformName(
                  response_item.platform_id
                );
              } else if (response_item.platform_id === null) {
                response_item.platform_id = "-";
              }
              if (response_item.channel === null) {
                response_item.channel = "-";
              }
            });
            delete response.data;
          } else {
            data.list = [];
          }

          if (response.count) {
            response.total = response.count;
            delete response.count;
          }
          setDashboardData({
            list: response.list,
            total: response.total,
            stats: [
              {
                publisher_revenue: response?.stats?.publisher_revenue || 0,
                total_searches: response?.stats?.total_searches || 0,
                monetized_searches:
                  response?.stats?.aggregated_monetized_searches || 0
              }
            ]
          });
          // changing state inside timeout to prevent slow UI re-rendering
          // of reporting table
          setTimeout(() => {
            setLoadingDashboardData({
              loading: false,
              error: false
            });
          }, 800);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        toastr.error("Oops", "Unable to load dashboard report data!");
        setLoadingDashboardData({
          loading: false,
          error: true
        });
        setDashboardData(null);
        console.trace(error.message);
      });
  }

  useEffect(() => {
    fetchDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    size,
    filterValues.orderBy,
    filterValues.orderDirection,
    filtersClearToggle
  ]);

  return {
    dashboardData,
    loadingDashboardData: loadingDashboardData.loading,
    size,
    page,
    setPage,
    setSize,
    fetchDashboardData
  };
};
