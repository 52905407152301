import Api from "../api";

class Targeting {
  fetchPublisherChannelStats = async (id) => {
    const { body } = await Api.getGCP(
      "api/v1/bigquery/publisher-channel-stats/" + id,
      null,
      null
    );
    return body;
  };

  fetchConnectedAdvertiserLinks = async (id) => {
    const { body } = await Api.get(`targeting-connected-advertiser-links/${id}`);
    return body;
  }

  fetchLatestHistory = async (id, queryParamString = '') => {
    const url = `targeting/get-latest-history/${id}${queryParamString}`
    const { body } = await Api.get(url);
    return body;
  }
}
// eslint-disable-next-line
export default new Targeting();
