import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getApiDetails } from "../../reducers/apiDetails/apiDetails.selecter";
import { updateList } from "../../reducers/apiDetails/apiDetails.action";
import DnsIcon from "@material-ui/icons/Dns";
import Table from "../../components/Table/Table";
import { ProviderApiService } from "../../services/providerApi";
import FilterBox from "./layouts/FilterBox.filter";
import SimpleAccordion from "../../components/Accordion/Accordion";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import RunApi from "./layouts/RunApi.component";
import { HEADERS } from "./Constant/Headers";
import moment from "moment";

import {
  fetchStart,
  fetchSuccess,
  fetchFailure
} from "../../reducers/apiDetails/apiDetails.action";

import {
  ADMIN_REPORT_ADVERTISER_ID,
  ADMIN_REPORT_FROM_DATE,
  ADMIN_REPORT_INTERVAL
} from "../../helpers/constant/localStorage";

import { MONTH_TO_DATE } from "../../helpers/constant/filters";

const Index = () => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const [selected, setSelected] = useState([]);
  const apidetails = useSelector(getApiDetails);
  const location = useLocation();
  const getInitialProvider = useCallback(() => {
    return location?.state?.provider || "";
  }, [location]);
  const [provider, setProvider] = useState(() => getInitialProvider());
  const [status, setStatus] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  //states for fitler box
  const [active, setActive] = useState(false);
  const [inActive, setInActive] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const prevPage = useRef(1);
  const prevOrderBy = useRef(null);
  const prevOrderDirection = useRef(null);
  const prevSize = useRef(null);

  const getRequestParams = useCallback(() => {
    let params = {};

    if (active) {
      params["status"] = true;
    }

    if (provider !== "") {
      params["provider_id"] = provider;
    }
    if (inActive) {
      params["status"] = false;
    }
    if (status !== "") {
      params["status"] = status;
    }
    if (toDate) {
      params["to"] = moment(toDate).format("YYYY-MM-DD");
    }
    if (fromDate) {
      params["from"] = moment(fromDate).format("YYYY-MM-DD");
    }
    params["page"] = page;
    params["size"] = size;
    params["order_by"] = orderBy;
    params["order_direction"] = orderDirection;

    return params;
  }, [
    fromDate,
    toDate,
    active,
    provider,
    status,
    inActive,
    orderBy,
    orderDirection,
    page,
    size
  ]);

  const fetchApiDetails = useCallback(
    (params = {}) => {
      setLoading(true);
      stableDispatch(fetchStart());
      ProviderApiService.fetchProvidersAoiDetails(params)
        .then((response) => {
          if (response.success) {
            let apidetails = response.data.data;
            stableDispatch(updateList(apidetails));
            setTotal(response.data.total);
            stableDispatch(fetchSuccess());
            setLoading(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          stableDispatch(fetchFailure(error.message));
          setLoading(false);
        });
    },
    [stableDispatch]
  );

  const fetchApiAfterRunApi = useCallback(
    (params = {}) => {
      params["order_by"] = "updatedAt";
      params["order_direction"] = "DESC";
      setLoading(true);
      stableDispatch(fetchStart());
      ProviderApiService.fetchProvidersAoiDetails(params)
        .then((response) => {
          if (response.success) {
            let apidetails = response.data.data;
            stableDispatch(updateList(apidetails));
            setTotal(response.data.total);
            stableDispatch(fetchSuccess());
            setLoading(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          stableDispatch(fetchFailure(error.message));
          setLoading(false);
        });
    },
    [stableDispatch]
  );

  const handleFilter = () => {
    fetchApiDetails(getRequestParams());
    prevPage.current === page && setSelected([]);
    if (page !== prevPage.current) {
      prevPage.current = page;
    }
  };

  useEffect(() => {
    if (
      prevPage.current !== page ||
      prevOrderBy.current !== orderBy ||
      prevOrderDirection.current !== orderDirection ||
      prevSize.current !== size
    ) {
      fetchApiDetails(getRequestParams());
      prevPage.current = page;
      prevOrderBy.current = orderBy;
      prevOrderDirection.current = orderDirection;
      prevSize.current = size;
    }

    prevPage.current === page && setSelected([]);
  }, [page, size, orderDirection, orderBy, fetchApiDetails, getRequestParams]);

  const headers = HEADERS;

  const icon = <DnsIcon fontSize="inherit" color="primary" />;

  const handleRedirectAction = (id, data) =>
    window.open(`/advertisers/edit/${data.advertiser_id}`, "_blank");

  const viewDetails = (id, data) => window.open(`/apidetails/${id}`, "_blank");

  const handleRedirectRevenueAction = (id, data) => {
    window.localStorage.setItem(
      ADMIN_REPORT_ADVERTISER_ID,
      JSON.stringify(data.advertiser_id)
    );
    window.localStorage.setItem(
      ADMIN_REPORT_FROM_DATE,
      JSON.stringify(MONTH_TO_DATE)
    );
    window.localStorage.setItem(ADMIN_REPORT_INTERVAL, JSON.stringify("daily"));

    window.open("/revenuereport", "_blank");
  };

  const actions = [
    {
      name: "redirect",
      action: handleRedirectAction,
      icon: "redirectIcon"
    },
    {
      name: "redirectToRevenue",
      action: handleRedirectRevenueAction,
      icon: "redirectToRevenue"
    },
    {
      name: "Details",
      action: viewDetails,
      icon: "details"
    }
  ];

  // function that set the value key true if the api is active else false
  const setStatusValue = (item) => {
    let is_disabled = true;
    // TODO: remove api credentials dependency from providers
    if (
      item.provider &&
      ((item.provider.api_credentials &&
        item.provider.api_credentials.length > 0 &&
        item.provider.api_credentials[0].is_active) ||
        (item.provider.advertiser_configuration &&
          typeof item.provider.advertiser_configuration === "object" &&
          item.provider.advertiser_configuration.is_active))
    ) {
      is_disabled = false;
    }
    return { ...item, is_disabled };
  };

  return (
    <div>
      <ContentHeader icon={icon} title="API Details" />
      <RunApi selected={selected} fetchApiDetails={fetchApiAfterRunApi} />
      <div>
        <SimpleAccordion header="Filters" key="provider-filter-box">
          <FilterBox
            active={active}
            inActive={inActive}
            setActive={setActive}
            setInActive={setInActive}
            provider={provider}
            setProvider={setProvider}
            status={status}
            setStatus={setStatus}
            setSelected={setSelected}
            handleFilter={handleFilter}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
          />
        </SimpleAccordion>
        <br />
      </div>

      <div className="providers-container__table">
        {apidetails && (
          <Table
            headers={headers}
            rowValues={apidetails?.map(setStatusValue) || []}
            actions={actions}
            selected={selected}
            setSelected={setSelected}
            tableType={"apiDetails"}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            total={total}
            isLoading={loading}
            rowsPerPageOptions={[1, 20, 50, 100, 150, 200]}
          />
        )}
      </div>
    </div>
  );
};

export default Index;
