import React, { useEffect, useState, useCallback } from "react";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import InfoIcon from "@material-ui/icons/Info";
import { ProviderApiService } from "../../../services/providerApi";
import { useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import ApiRowStatusList from "./RowStatusList";
import moment from "moment";
import { formatNumberAddComma } from "../../../helpers/formatters";

const DetailsInfo = (props) => {
  const [details, setDetails] = useState([]);
  const { id } = useParams() || null;
  const [advertiser, setAdvertiser] = useState("");
  const [advertiser_id, setAdvertiserId] = useState(null);
  const [uploadedSubids, setUploadedSubids] = useState([]);
  const [
    skippedSubIdWithNotFoundPubAccount,
    setSkippedSubIdWithNotFoundPubAccount
  ] = useState([]);
  const [skippedWithValidation, setSkippedWithValidation] = useState([]);
  const [missingTagsWithSubIds, setMissingTagsWithSubIds] = useState([]);
  const [existTagsWithSubIds, setExistTagsWithSubIds] = useState([]);

  const getDetails = useCallback(async () => {
    try {
      const details = await ProviderApiService.fetchProvidersAoiDetailsById(id);
      if (details.success) {
        setDetails(details.data);
        setUploadedSubids(details?.data?.uploaded_subid);
        setSkippedSubIdWithNotFoundPubAccount(
          details?.data?.skippedSubIdWithNotFoundPubAccount
        );
        setSkippedWithValidation(details?.data?.skippedSubIdWithValidation);
        setMissingTagsWithSubIds(details?.data?.missing_tags_with_subIds);
        setExistTagsWithSubIds(details?.data?.exist_tags_with_subIds);
        setAdvertiser(
          details.data.provider.id + "-" + details.data.provider.name
        );
        setAdvertiserId(details.data.provider.id);
      } else {
        throw new Error(details?.message || "Something went wrong");
      }
    } catch (error) {
      toastr.error("Opps", error.message || error);
    }
  }, [id]);
  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const icon = <InfoIcon fontSize="inherit" color="primary" />;

  const dataRender = (details) => {
    if (
      details.uploaded_status === "Uploaded Successfully" ||
      details.uploaded_status === "Partially Uploaded" ||
      details.uploaded_status === "Zero Records Uploaded"
    ) {
      return (
        <>
          <li>
            <span>Total records :</span> {formatNumberAddComma(details?.total_rows)}
          </li>
          <li>
            <span>Total skipped records :</span> {formatNumberAddComma(details?.total_skipped)}
          </li>
          <li>
            <span>Total uploaded records :</span>
            {formatNumberAddComma(details?.total_uploaded)}
          </li>
          <li>
            <span>Total checksum in API:</span>
            {formatNumberAddComma(details?.check_sum)}
          </li>

          <li>
            <span>Total checksum in DB :</span>
            {formatNumberAddComma(details?.check_sum_in_db)}
          </li>
          <li className="subid">
            <div>
              <span>Uploaded channels :</span>
            </div>
            {uploadedSubids?.length > 0 && (
              <div className="skipped-uploaded-subId">
                {uploadedSubids.join(",  ")}
              </div>
            )}
          </li>
          <li className="subid">
            <div>
              <span>Validation failed for Channels :</span>
            </div>
            {skippedWithValidation?.length > 0 && (
              <div className="skipped-uploaded-subId">
                {skippedWithValidation.join(",  ")}
              </div>
            )}
          </li>
          <li className="subid">
            <div>
              <span>Missing Ad-accounts for Channels :</span>
            </div>
            {skippedSubIdWithNotFoundPubAccount?.length > 0 && (
              <div className="skipped-uploaded-subId">
                {skippedSubIdWithNotFoundPubAccount.join(",  ")}
              </div>
            )}
          </li>
          <li className="subid">
            <div>
              <span>Missing Advertiser links for Channels :</span>
            </div>
            {missingTagsWithSubIds?.length > 0 && (
              <div className="skipped-uploaded-subId">
                {missingTagsWithSubIds.join(",  ")}
              </div>
            )}
          </li>

          <li className="subid">
            <div>
              <span>Missing Ad-accounts for Channels (Links exist) :</span>
            </div>
            {existTagsWithSubIds?.length > 0 && (
              <div className="skipped-uploaded-subId">
                {existTagsWithSubIds.join(",  ")}
              </div>
            )}
          </li>

          {advertiser_id && <ApiRowStatusList advertiser={advertiser_id} />}
        </>
      );
    }
  };

  return (
    <div>
      <ContentHeader icon={icon} title="API Informations" />
      <div className="api-details">
        <div className="header">
          <h2>Last API call details for the advertiser {advertiser}</h2>
          <h2>
            Last applied date(UTC) :
            {details?.apply_to_date
              ? moment(details?.apply_to_date).format("dddd, MMMM Do YYYY, hA")
              : "Not Applied"}
          </h2>
        </div>
        <ul>
          <li>
            <span>Advertiser :</span> {advertiser}
          </li>
          <li>
            <span> Applied from date : </span>{" "}
            {details?.apply_from_data
              ? moment(details?.apply_from_data).format("YYYY-MM-DD")
              : "Not applied yet"}
          </li>
          <li>
            <span>Applied to date :</span>{" "}
            {details?.apply_to_date
              ? moment(details?.apply_to_date).format("YYYY-MM-DD")
              : "Not applied yet"}
          </li>
          <li>
            <span>Message/Info :</span> {details?.message}
          </li>
          <li>
            <span>Status :</span>{" "}
            {details?.uploaded_status === "Constant"
              ? "Initialised"
              : details?.uploaded_status}
          </li>
          {dataRender(details)}
        </ul>
        <br />
      </div>
    </div>
  );
};

export default DetailsInfo;
