import React from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useDisplayColumnsList } from "./displayColumnsList.logic";
import { Prompt } from "react-router-dom";
import CloseIcon from "@material-ui/icons/CloseSharp";
import CheckIcon from "@material-ui/icons/Check";
import Button from "@material-ui/core/Button";
const SelectionForm = ({ displayColumns, userId }) => {
    const {
        selectedColumns,
        columnsList,
        removeFromSelectedColumn,
        addToSelectedColumn,
        submitSelectedColumns,
        isSaving,
        formTouched,
        getLabel,
        handleCancel,
        isValid
    } = useDisplayColumnsList(displayColumns, userId);

    return (
        <div className="publisher-display-columns__form flow-content">
            <h3 className="gray-text">
                Please Select the display columns you would like the publisher to see in
                his dashboard
            </h3>
            <div className="publisher-display-columns__list">
                {columnsList.map((column) => {
                    const checked = selectedColumns.includes(column);
                    return (
                        <FormControlLabel
                            key={column}
                            control={
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<CheckBoxIcon />}
                                    checked={checked}
                                    onChange={() =>
                                        checked
                                            ? removeFromSelectedColumn(column)
                                            : addToSelectedColumn(column)
                                    }
                                />
                            }
                            label={getLabel(column)}
                        />
                    );
                })}
            </div>
            <div className="publisher-display-columns__error-msg error-text">
                {!isValid &&
                    "Please select at least one column from Total Searches, Publisher Revenue or Monetized Searches"}
            </div>
            <div className="publisher-display-columns__buttons">
                <Button
                    color="primary"
                    variant="contained"
                    size="medium"
                    startIcon={<CheckIcon />}
                    disabled={isSaving}
                    onClick={submitSelectedColumns}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCancel}
                    disabled={isSaving}
                    startIcon={<CloseIcon />}
                >
                    Cancel
                </Button>
            </div>

            <Prompt
                when={formTouched.current}
                message="Are you sure you want to leave?"
            />
        </div>
    );
};

export default SelectionForm;
