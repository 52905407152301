import { useState, useCallback, useEffect } from "react";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { Targeting } from "../../../services/Targeting";
export const useGetTagStats = (tagId) => {
  const [publisherChannelStats, setPublisherChannelStats] = useState({
    data: null,
    loading: false,
    error: false
  });

  const isMounted = useIsMounted();

  const fetchPublisherChannelStats = useCallback(
    async (tagId, setPublisherChannelStats) => {
      try {
        setPublisherChannelStats({ loading: true, error: false });
        const fetched = await Targeting.fetchPublisherChannelStats(tagId);
        if (fetched && isMounted()) {
          setPublisherChannelStats({
            data: fetched.stats,
            loading: false,
            error: false
          });
        }
      } catch (error) {
        console.trace(
          error?.message ||
            "Oops! there was an error loading publisher channel stats"
        );
        if (isMounted()) {
          setPublisherChannelStats({ loading: false, error: true });
        }
      }
    },
    [isMounted]
  );

  useEffect(() => {
    if (tagId && isMounted()) {
      fetchPublisherChannelStats(tagId, setPublisherChannelStats);
    }
  }, [tagId, fetchPublisherChannelStats, isMounted]);

  return {
    publisherChannelStats
  };
};
