import AdvisorActionTypes from "./advisor.types";

const INITIAL_STATE = {
    advisors: [],
    advisorStatus: [],
    advisorPartner: []
};
  
const advisorReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case AdvisorActionTypes.UPDATE_ADVISORS:
        return {
          ...state,
          advisors: action.payload
        };
    case AdvisorActionTypes.UPDATE_ADVISORS_STATUS:
        return {
            ...state,
            advisorStatus: action.payload
        };
    case AdvisorActionTypes.UPDATE_ADVISORS_PARTNER:
        return {
            ...state,
            advisorPartner: action.payload
        };
    default:
        return { ...state };
    }
};

export default advisorReducer;
