import React, { useState, useEffect, useCallback } from "react";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import Form from "../../components/Form/Form";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addError,
  fetchPublisherStart,
  fetchPublisherSuccess,
  fetchPublisherFailure
} from "../../reducers/publisher/publisher.action";
import { useHistory } from "react-router-dom";
import { Publisher } from "../../services/Publisher";
import { toastr } from "react-redux-toastr";

const ManagePublishers = () => {
  const [editing, setEditing] = useState(false);
  const [publisher, setPublisher] = useState({
    name: "",
    details: "",
    id: "",
    status: "active"
  });
  //checking the url param to see if edit page is to be used
  const { pathname } = useLocation();
  const { id } = useParams() || null;

  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const history = useHistory();

  const fetchPublisher = useCallback(
    (publisherID) => {
      stableDispatch(fetchPublisherStart());
      Publisher.fetchPublisher(publisherID)
        .then((response) => {
          if (response.success) {
            setPublisher(response.data.publisher);
            stableDispatch(fetchPublisherSuccess());
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          stableDispatch(fetchPublisherFailure(error));
          console.trace(error.message);
        });
    },
    [stableDispatch]
  );

  useEffect(() => {
    if (id && pathname.includes("edit")) {
      setEditing(true);
      fetchPublisher(id);
    }
  }, [id, pathname, fetchPublisher]);

  const icon = <PostAddIcon fontSize="inherit" color="primary" />;

  const handleCancel = () => history.push("/publishers");

  const uploadPublisher = (data) => {
    Publisher.addPublisher(data)
      .then((response) => {
        if (response.success) {
          history.push("/publishers");
          toastr.success("Success", "Publisher Saved");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
        dispatch(addError());
      });
  };

  const editPublisher = (id, data) => {
    Publisher.updatePublisher(id, data)
      .then((response) => {
        if (response.success) {
          history.push("/publishers");
          toastr.success("Success", "Publisher updated!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
        dispatch(addError());
      });
  };

  return (
    <div className="publisher-manage">
      <ContentHeader icon={icon} title="Publisher" />
      <div className="content_box">
        <fieldset className="custom-fieldset">
          <legend>{editing ? "Edit" : "Add"} Publisher</legend>

          <Form
            editing={editing}
            onFormSubmit={editing ? editPublisher : uploadPublisher}
            initialValues={publisher}
            handleCancel={handleCancel}
            type="publisher"
          />
        </fieldset>
      </div>
    </div>
  );
};

export default ManagePublishers;
