import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { Card } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import CloseIcon from "@material-ui/icons/CloseSharp";
import CheckIcon from "@material-ui/icons/Check";
import GroupIcon from "@material-ui/icons/Group";
import { useHistory } from "react-router-dom";
import { Prompt } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Button, IconButton, InputAdornment } from "@material-ui/core";
import { useFormControls } from "./addUserFromControll";
import ContentHeader from "../../../components/ContentHeader/ContentHeader";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";

//services
import { User } from "../../../services/ReportingUser";
import { Publisher } from "../../../services/Publisher";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../helpers/constant/misc";
export default function AddUser() {
  // const [accounts, set_Account] = useState([]);
  const [roles, set_Role] = useState([]);
  const [publishers, set_Publishers] = useState([]);
  const [roleId, set_roleId] = useState("");
  const [pubId, set_pubId] = useState("");
  const [is_password_visible, setIsPasswordVisible] = useState(false);
  const [is_confirm_password_visible, setIsConfirmPasswordVisible] =
    useState(false);
  // const [advertisers, set_Advertisers] = useState([]);
  const history = useHistory();

  const {
    values,
    handleInputValue,
    handleFormSubmit,
    errors,
    formTouched,
    handleselectbox
  } = useFormControls();

  const params = {
    order_by: "id",
    order_direction: "ASC",
    size: DEFAULT_FETCH_PARAM_SIZE
  };

  useEffect(() => {
    // fetchAccounts();
    fetchRolls();
    fetchPublisher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const fetchAccounts = async () => {
  //   try {
  //     const account = await User.fetchAccount();
  //     if (account.success) {
  //       set_Account(account.data.accounts);
  //     } else {
  //       throw new Error("Failed to fetch accounts");
  //     }
  //   } catch (error) {
  //     console.trace(error.message);
  //   }
  // };

  const fetchRolls = async () => {
    try {
      const role = await User.fetchRole();
      if (role.success) {
        const roleData = role.data.roles.map((role) => {
          return { value: role.id, label: role.role };
        });
        // console.log(roleData, "role data");
        set_Role(roleData);
      } else {
        throw new Error("Failed to fetch roles");
      }
    } catch (error) {
      console.trace(error.message);
    }
  };

  const fetchPublisher = async () => {
    try {
      const publisher = await Publisher.fetchPublishers(params);
      if (publisher.success) {
        const publisherData = publisher.data.publishers.map((pub) => {
          return { value: pub.id, label: pub.name, status: pub.status };
        });
        set_Publishers(publisherData);
      } else {
        throw new Error("Failed to fetch Publisher");
      }
    } catch (error) {
      console.trace(error.message);
    }
  };

  const FORMCARDSTYLE = { padding: "10px 30px", margin: "auto", width: "45%" };
  const icon = <GroupIcon fontSize="inherit" color="primary" />;
  return (
    <div className="userform">
      <div className="formsection">
        <div className="publishers-container__header">
          <ContentHeader icon={icon} title="Create User" />
        </div>
        <Card style={FORMCARDSTYLE}>
          {/* <h2>User Form</h2> */}
          <FormControl fullWidth style={{ margin: "10px 0px" }}>
            <TextField
              variant="outlined"
              label="First Name"
              name="first_name"
              placeholder="First Name"
              type="text"
              value={values.first_name}
              autoComplete="none"
              onChange={handleInputValue}
            />
          </FormControl>

          <FormControl fullWidth style={{ margin: "10px 0px" }}>
            <TextField
              variant="outlined"
              label="Last Name"
              name="last_name"
              placeholder="Last Name"
              type="text"
              value={values.last_name}
              autoComplete="none"
              onChange={handleInputValue}
            />
          </FormControl>

          <FormControl fullWidth style={{ margin: "10px 0px" }}>
            <TextField
              variant="outlined"
              label="Email"
              name="email"
              placeholder="Enter your email"
              type="email"
              autoComplete="none"
              value={values.email}
              onChange={handleInputValue}
              onBlur={handleInputValue}
              {...(errors["email"] && {
                error: true
              })}
            />
            {errors["email"] && (
              <FormHelperText style={{ color: "red" }}>
                {errors["email"]}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            fullWidth
            variant="outlined"
            style={{ margin: "10px 0px" }}
          >
            {/* <Autocomplete
              options={publishers}
              onChange={(event, newValue) => {
                handleselectbox(newValue?.id, "publisher_id");
              }}
              getOptionLabel={(option) => option?.name || ""}
              // renderOption={(option) => option?.name || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Publisher"
                  variant="outlined"
                />
              )}
            /> */}
            <AutoCompleteSelect
              selected={pubId ? pubId : ""}
              data={publishers || []}
              handleChange={(value) => {
                set_pubId(value);
                handleselectbox(value, "publisher_id");
              }}
              label={"Select Publisher"}
              showValueInLabel={true}
              enableMultiple={false}
              withDotsForStatus={true}
              cannotSelectDisabled={false}
            />
            {errors["publisher_id"] && (
              <FormHelperText style={{ color: "red", margin: 0 }}>
                {errors["publisher_id"]}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            fullWidth
            variant="outlined"
            style={{ margin: "10px 0px" }}
          >
            <AutoCompleteSelect
              selected={roleId ? roleId : ""}
              data={roles || []}
              handleChange={(value) => {
                set_roleId(value);
                handleselectbox(value, "role_id");
              }}
              label={"Select Role"}
              showValueInLabel={true}
              enableMultiple={false}
              withDotsForStatus={true}
              cannotSelectDisabled={false}
            />
            {errors["role_id"] && (
              <FormHelperText style={{ color: "red", margin: 0 }}>
                {errors["role_id"]}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth style={{ margin: "10px 0px" }}>
            <TextField
              variant="outlined"
              label="Password"
              name="password"
              placeholder="Enter your password"
              type={is_password_visible ? "text" : "password"}
              autoComplete="none"
              value={values.password}
              onChange={handleInputValue}
              onBlur={handleInputValue}
              {...(errors["password"] && {
                error: true
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setIsPasswordVisible(!is_password_visible)}
                    >
                      {is_password_visible ? (
                        <Visibility color="primary" />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {errors["password"] && (
              <FormHelperText style={{ color: "red" }}>
                {errors["password"]}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth style={{ margin: "10px 0px" }}>
            <TextField
              variant="outlined"
              label="Confirm Password"
              name="cmpassword"
              placeholder="Enter confirm password"
              type={is_confirm_password_visible ? "text" : "password"}
              autoComplete="none"
              onChange={handleInputValue}
              onBlur={handleInputValue}
              value={values.cmpassword}
              // error={errors['cmpassword']}
              {...(errors["cmpassword"] && {
                error: true
                // helperText: errors['cmpassword']
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setIsConfirmPasswordVisible(
                          !is_confirm_password_visible
                        )
                      }
                    >
                      {is_confirm_password_visible ? (
                        <Visibility color="primary" />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {errors["cmpassword"] && (
              <FormHelperText style={{ color: "red" }}>
                {errors["cmpassword"]}
              </FormHelperText>
            )}
          </FormControl>
          <div
            className="profile__basic-info__form__buttons"
            style={{ margin: "10px 0px" }}
          >
            <Button
              color="primary"
              variant="contained"
              size="medium"
              startIcon={<CheckIcon />}
              type="submit"
              onClick={(e) => {
                handleFormSubmit(e, values);
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                history.push(`/usermanagement`);
              }}
              variant="contained"
              color="secondary"
              startIcon={<CloseIcon />}
            >
              Cancel
            </Button>
          </div>
        </Card>
        <Prompt
          when={formTouched.current}
          message="Are you sure you want to leave?"
        />
      </div>
    </div>
  );
}
