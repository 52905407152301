import ProviderLinkActionTypes from "./providerLink.types";

const INITIAL_STATE = {
  selectedFilter: {
    query: "",
    search_id: "",
    selectedProviders: [],
    selectedPublishers: [],
    selectedPlatforms: [],
    selectedSearchEngines: [],
    selectedTagTypes: [],
    selectedQualityScore: [],
    isLinked: false,
    isLive: false,
    isReusable: false,
    isNotLinked: false,
    isNotLive: false,
    isNotReusable: false
  }
};

const providerLinkReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProviderLinkActionTypes.SET_PROVIDER_LINK_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: action.payload
      };
    default:
      return { ...state };
  }
};

export default providerLinkReducer;
