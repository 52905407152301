import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadingSpinner = ({ size = 40 }) => (
  <div className="spinner-container">
    <CircularProgress size={size}/>
  </div>
);

export default LoadingSpinner;
