import { useState, useEffect } from "react";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { textFieldValid } from "../../../helpers/validation";
import { SkippedRows } from "../../../services/skippedRows";
import { formatNumberAddComma } from "../../../helpers/formatters"
export const useFetchSkippedRows = ({ filterValues, filtersClearToggle }) => {
  const [skippedRows, setskippedRows] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [loadingskippedRows, setLoadingskippedRows] = useState({
    isLoading: false,
    error: false
  });

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [total, setTotal] = useState(0);

  function getParams() {
    let params = {};
    const { query, selectedProviders, selectedFiles, status, message } =
      filterValues;
    if (textFieldValid(query)) {
      params["q"] = query.trim();
    }
    if (orderBy) {
      params["order_by"] = orderBy;
    }

    if (selectedProviders?.length > 0) {
      let selection = [];
      for (let i in selectedProviders) {
        selection.push(selectedProviders[i]);
      }
      params["provider_id"] = selection;
    }

    if (selectedFiles?.length > 0) {
      let selection = [];
      for (let i in selectedFiles) {
        selection.push(selectedFiles[i]);
      }
      params["file"] = selection;
    }
    params["order_direction"] = orderDirection;
    if (status) {
      params["status"] = status.value;
    }
    if (!status) {
      params["status"] = "";
    }
    if (message !== "") {
      params["message"] = message;
    }
    params["page"] = page;
    params["size"] = size;
    return params;
  }

  const isMounted = useIsMounted();

  function formatskippedRows(skippedRows) {
    return skippedRows.map((row) => {
      return {
        ...row,
        clicks: formatNumberAddComma(parseInt(row.clicks, 10)),
        monetized_searches: formatNumberAddComma(parseInt(row.monetized_searches, 10)),
        total_searches: formatNumberAddComma(parseInt(row.total_searches, 10)),
        publisher_id: `${row?.publisher?.id || ""} - ${row?.publisher?.name || ""}`,
        provider_id: `${row?.provider?.id || ""} - ${row?.provider?.name || ""}`
      };
    });
  }

  async function updateStatus(id, currentStatuses) { }

  async function getskippedRows() {
    try {
      setLoadingskippedRows({ isLoading: true, error: false });
      const response = await SkippedRows.fetchSkippedRows(getParams());
      if (response.success && isMounted()) {
        const fetchedskippedRows = response.data.skippedRows.data || [];
        setUploadStatus(response?.data?.csvUploadStatus);
        setskippedRows(formatskippedRows(fetchedskippedRows));
        setTotal(response.data.skippedRows.total);
        setLoadingskippedRows({ isLoading: false, error: false });
      } else {
        throw new Error(response.error.message);
      }
    } catch (error) {
      if (isMounted()) {
        setskippedRows([]);
        setUploadStatus(null);
        setLoadingskippedRows({ isLoading: false, error: true });
      }
      console.trace(error.message || error);
    }
  }
  useEffect(() => {
    getskippedRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, orderBy, orderDirection, filtersClearToggle]);

  return {
    skippedRows,
    uploadStatus,
    loadingskippedRows,
    page,
    size,
    orderBy,
    orderDirection,
    total,
    getskippedRows,
    setPage,
    setSize,
    setOrderBy,
    setOrderDirection,
    updateStatus
  };
};
