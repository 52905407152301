/**
 * Component to fetch Provider Links
 */

import { useState, useEffect, useCallback, useContext } from "react";
import { Provider } from "../../../../services/Provider";
import { AdvertiserLinkGroup } from "../../../../services/AdvertiserLinkGroup";
import { PlatformContext, SearchEngineContext, TagTypeContext } from "../..";

// import { toastr } from "react-redux-toastr";
// import { DEFAULT_FETCH_PARAM_SIZE } from "../../../../helpers/constant/misc";

export const useGetProviderLinks = ({
  providerOneLink,
  providerOne,
  providerTwo,
  platform,
  searchEngine,
  tagType,
  qualityScore,
  isGroup = false,
  dailyCap
}) => {
  //provider links for provider one and two
  const [providerLinksOne, setProviderLinksOne] = useState([]);
  const [providerLinksTwo, setProviderLinksTwo] = useState([]);
  const platforms = useContext(PlatformContext);
  const searchEngines = useContext(SearchEngineContext);
  const tagTypes = useContext(TagTypeContext);

  /**
   * Function to check if the given advertiser link is disabled or not by check the total cap and remaining cap
   * @param {boolean} is_disabled Disabled status from the api
   * @param {number} total_cap The Total cap of the advertiser link
   * @param {number} remaining_cap The remaining cap of the advertiser link
   * @returns {boolean}
   */
  function checkIfLinkDisabled(is_disabled, remaining_cap) {
    if (is_disabled) {
      return true;
    }
    if (!remaining_cap || remaining_cap === 0) {
      return true;
    }
    return false;
  }

  const fetchProviderLinks = useCallback(
    (provider, selection, linkProperty = {}) => {
      const providerLinks = [];
      if (isGroup) {
        AdvertiserLinkGroup.fetchForDropdown({
          platform_id: linkProperty?.platform,
          search_engine_id: linkProperty?.searchEngine,
          tag_type_id: linkProperty?.tagType,
          quality_score: linkProperty?.qualityScore,
          ...(providerOneLink && {
            advertiser_link_group_id: providerOneLink
          })
        })
          .then((response) => {
            if (response.success) {
              const data = response.data.advertiser_link_groups;
              for (let p of data) {
                providerLinks.push({
                  label: "Advertiser Link Group " + p.id,
                  value: p.id,
                  disabled: checkIfLinkDisabled(
                    !p.active,
                    p.remaining_capacity
                  ),
                  daily_cap: p.capacity,
                  remaining_cap: p.remaining_capacity,
                  quality_scores: p.quality_scores,
                  search_engines: p.search_engines.map(
                    (x) =>
                      searchEngines.find((item) => item.value === x)?.label ||
                      ""
                  ),
                  platforms: p.platforms.map(
                    (x) =>
                      platforms.find((item) => item.value === x)?.label || ""
                  ),
                  tag_types: p.tag_types.map(
                    (x) =>
                      tagTypes.find((item) => item.value === x)?.label || ""
                  ),
                  group: true
                });
              }
            } else {
              throw new Error(JSON.stringify(response.error));
            }
            if (selection === 1) {
              setProviderLinksOne(providerLinks);
            } else {
              setProviderLinksTwo(providerLinks);
            }
          })
          .catch((error) => {
            console.log({ error });
          });
      } else {
        Provider.fetchProviderLinksForDropdown({
          ...(provider && { provider_id: provider }),
          platform_id: linkProperty?.platform,
          search_engine_id: linkProperty?.searchEngine,
          tag_type_id: linkProperty?.tagType,
          quality_score: linkProperty?.qualityScore,
          ...(providerOneLink && {
            provider_link_id: providerOneLink
          })
        })
          .then((response) => {
            if (response.success) {
              const data = response.data.rows;
              for (let p of data) {
                providerLinks.push({
                  label: p.link,
                  value: p.id,
                  disabled: checkIfLinkDisabled(p.disabled, p.remaining_cap),
                  no_of_rules: p.no_of_rules,
                  no_of_active_rules: p.no_of_active_rules,
                  daily_cap: p.daily_cap,
                  remaining_cap: p.remaining_cap,
                  targetObj: p?.targetObj || [],
                  is_live: p.is_live || false,
                  quality_score: p.quality_score,
                  reusable: p.reusable,
                  provider_id: p.provider_id,
                  search_engine_id: p.search_engine_id,
                  tag_type_id: p.tag_type_id,
                  platform_id: p.platform_id,
                  search_engine: p.search_engine,
                  platform: p.platform,
                  tag_type: p.tag_type,
                  provider: p.provider,
                  group: false
                });
              }
              if (selection === 1) {
                setProviderLinksOne(providerLinks);
              } else {
                setProviderLinksTwo(providerLinks);
              }
            } else {
              throw new Error(JSON.stringify(response.error));
            }
          })
          .catch((error) => {
            console.log({ error });
          });
      }
    },
    [providerOneLink, isGroup]
  );

  /**loading provider links when different publisher is selected */
  useEffect(() => {
    if (
      platform?.length > 0 &&
      searchEngine?.length > 0 &&
      tagType?.length > 0 &&
      qualityScore?.length > 0
    ) {
      fetchProviderLinks(providerOne, 1, {
        platform,
        searchEngine,
        tagType,
        qualityScore
      });
    } else {
      setProviderLinksOne([]);
    }
  }, [
    providerOne,
    fetchProviderLinks,
    platform,
    searchEngine,
    tagType,
    qualityScore
  ]);

  /**loading provider links when different publisher is selected */
  useEffect(() => {
    if (providerTwo) {
      fetchProviderLinks(providerTwo, 2);
    } else {
      setProviderLinksTwo([]);
    }
  }, [providerTwo, fetchProviderLinks]);

  return {
    providerLinksOne,
    providerLinksTwo
  };
};
