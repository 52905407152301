import SimpleModal from "../../../components/SimpleModal/SimpleModal";

const ConnectedAdvertiserLinksModal = ({
  id = null,
  providerLinkObj,
  closeAction
}) => {
  console.log({ providerLinkObj });
  return (
    <>
      <SimpleModal handleClose={closeAction}>
        <div className="related-connected-box flow-content">
          <h2 className="related-connected-box__heading">
            Advertiser Links list
            {id && <span> (Advertiser Link Group: {id}) </span>}
          </h2>
          <div>
            {providerLinkObj?.length > 0 &&
              providerLinkObj.map((el, index) => (
                <div
                  className={`advertiser-link-group-rule-box flow-content--small white-border-box`}
                  style={{ borderColor: el.active ? "green" : "red" }}
                  key={index}
                  onClick={() =>
                    window.open(
                      `/advertisers/links?edit=true&provider_link_id=${el?.provider_link.id}`,
                      "_blank"
                    )
                  }
                >
                  <div className="connected-ad-box__nuetral">
                    Advertiser: {el?.provider.name}
                  </div>
                  <div className="connected-ad-box__nuetral">
                    Advertiser Link Id: {el?.provider_link.id}
                  </div>
                  <div className="connected-ad-box__nuetral">
                    Advertiser Link: {el?.provider_link.link}
                  </div>
                  <div className="connected-ad-box__nuetral">
                    Rule status: {el.active ? "active" : "disabled"}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </SimpleModal>
    </>
  );
};

export default ConnectedAdvertiserLinksModal;
