import Api from "../api";

class DisplayNames {
  fetchDisplayNames = async () => {
    const { body } = await Api.get(`displaymapping/1`);
    return body;
  };

  storeDisplayNames = async ({ data }) => {
    const { body } = await Api.post(`store/displaymapping`, data);
    return body;
  };

  updateDisplayNames = async ({ data, id }) => {
    const { body } = await Api.put(`update/displaymapping/${id}`, data);
    return body;
  };
}
// eslint-disable-next-line
export default new DisplayNames();
