import React, { useState, useCallback } from "react";
import { Button, FormControl, FormControlLabel } from "@material-ui/core";
import Rule from "./layouts/Rule";
import AddIcon from "@material-ui/icons/Add";
import { ruleArchiveMessage } from "./layouts/RuleArchiveMessage";
// import InfoSharp from "@material-ui/icons/InfoSharp";
import { useSelector, useDispatch } from "react-redux";
import {
  updateTargetingRules,
  updateDeletedTargetingRules
} from "../../reducers/target/target.action";

import { getDeletedTargetingRules } from "../../reducers/target/target.selector";
import { getDisabledProviders } from "../../reducers/provider/provider.selector";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import { toastr } from "react-redux-toastr";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ErrorIcon from "@material-ui/icons/Error";
import { TARGETING_TYPES } from "../../helpers/constant/types";
import { useHandleFormValueChange } from "./logics/rule-block/handleFormValueChange.logic";
import { useGetProviders } from "./logics/rule-block/getProviders.logic";
import {
  calculateTotalDailyCap,
  updateTargetingRulesBeforeDispatch
} from "./helper/helper-functions";

const TargetRule = ({
  targeting,
  targeting_type,
  rulesBlockNumber,
  addMoreRulesBlock,
  logicError,
  oldRules,
  targetingID,
  editing,
  publisher,
  targetingRules,
  targetingRulesError,
  handleFormTouched,
  linkStatus,
  targetingLinkStatus,
  platform,
  searchEngine,
  tagType,
  qualityScore,
  dailyCap,
  publisherChannelStats,
  setDailyCap,
  isWeightFieldTouched,
  setIsWeightFieldTouched,
  defaultRevenueShare
}) => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const formValueHandlers = useHandleFormValueChange({
    targeting,
    targetingRules,
    handleFormTouched,
    oldRules,
    setDailyCap,
    isWeightFieldTouched,
    setIsWeightFieldTouched,
    openInfoModal
  }); //Rule Value Form Change
  const disabledProviders = useSelector(getDisabledProviders);
  const deletedTargetingRules = useSelector(getDeletedTargetingRules);
  const { providers } = useGetProviders();

  //state for modal
  const [open, setOpen] = useState(false);
  const handleModalClose = () => setOpen(false);

  //index of targeting Rule to Delete
  const [deleteIndex, setDeleteIndex] = useState(null);
  const handleDeleteAction = (index) => {
    if (!targetingRules[index]) {
      return;
    }
    if (targetingRules[index].id) {
      setDeleteIndex(index);
      setOpen(true);
    } else {
      let newTargetingRules = [...targetingRules];
      newTargetingRules.splice(index, 1);

      if (!isWeightFieldTouched) {
        setDailyCap(calculateTotalDailyCap(newTargetingRules));
        const updatedTargetingRules =
          updateTargetingRulesBeforeDispatch(newTargetingRules);
        stableDispatch(updateTargetingRules(updatedTargetingRules));
      } else {
        setDailyCap(calculateTotalDailyCap(newTargetingRules));
        stableDispatch(updateTargetingRules(newTargetingRules));
      }
      setDeleteIndex(null);
    }
  };

  const deleteRuleSet = () => {
    let newTargetingRules = [...targetingRules];
    let idToDelete = newTargetingRules[deleteIndex].id;
    let oldTargetingRules = JSON.parse(oldRules);
    let ruleToDelete = oldTargetingRules.find((rule) => rule.id === idToDelete);
    ruleToDelete.deleted = true;
    let newDeletedTargetingRules = [...deletedTargetingRules].concat(
      ruleToDelete
    );
    stableDispatch(updateDeletedTargetingRules(newDeletedTargetingRules));
    newTargetingRules.splice(deleteIndex, 1);

    if (!isWeightFieldTouched) {
      setDailyCap(calculateTotalDailyCap(newTargetingRules));
      const updatedTargetingRules =
        updateTargetingRulesBeforeDispatch(newTargetingRules);
      stableDispatch(updateTargetingRules(updatedTargetingRules));
    } else {
      setDailyCap(calculateTotalDailyCap());
      stableDispatch(updateTargetingRules(newTargetingRules));
    }
    setDeleteIndex(null);
  };

  /**
   * handle duplicate provider links in rules
   */
  const [dupProviderLinkMsg, setDupProviderLinkMsg] = useState(null);

  async function openInfoModal(message = "") {
    setDupProviderLinkMsg(message);
  }

  /**
   * Sets field to disable if one of the link properties have no value
   * @returns
   */
  const handleFieldDisabled = () => {
    if (
      platform?.length > 0 &&
      searchEngine?.length > 0 &&
      tagType?.length > 0 &&
      qualityScore?.length > 0
    ) {
      return false;
    }
    return true;
  };

  let rulesBlock = [];
  //pushing rules block as per array value
  for (let j = 0; j < rulesBlockNumber; j++) {
    if (targetingRules[j]) {
      const stats = publisherChannelStats.data
        ? publisherChannelStats.data.rule_stats.find(
            (x) => x.rule_id === targetingRules[j].id
          )
        : null;

      rulesBlock.push(
        <Rule
          key={j}
          className="flex-item"
          originalIndex={j}
          targeting_type={targeting_type}
          targetingRuleError={targetingRulesError?.[j] || {}}
          targetingRule={targetingRules?.[j] || {}}
          providers={providers}
          handleDeleteAction={handleDeleteAction}
          disabledProviders={disabledProviders}
          targetingID={targetingID}
          editing={editing}
          publisher={publisher}
          formValueHandlers={formValueHandlers}
          linkStatus={linkStatus}
          platform={platform}
          searchEngine={searchEngine}
          tagType={tagType}
          qualityScore={qualityScore}
          linkDailyCap={dailyCap}
          stats={stats}
          publisherChannelStats={publisherChannelStats}
          style={{ display: handleFieldDisabled() ? "none" : "block" }}
        />
      );
    }
  }

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    let rules = [...targetingRules];
    if (rules[result.source.index]) {
      let [reorderedItem] = rules.splice(result.source.index, 1);
      rules.splice(result.destination.index, 0, reorderedItem);
      stableDispatch(updateTargetingRules(rules));
    } else {
      toastr.info("Cannot move empty rule block");
    }
  };

  return (
    <div className="target-rules-section">
      <div className="target-rules-section__labels">
        {/* <div className="target-rules-section__labels__info">
          <FormControlLabel
            control={
              <InfoSharp
                className="info-icon"
                style={{ color: "blue" }}
                fontSize="large"
              />
            }
            label="Default Value of Daily Cap is 0."
          />
        </div> */}
        {targeting_type === TARGETING_TYPES.ROUND_ROBIN && logicError && (
          <div className="target-rules-section__labels__warning--weights">
            <FormControlLabel
              control={
                <ErrorIcon
                  className="info-icon"
                  color="secondary"
                  fontSize="large"
                />
              }
              label="Sum of all weights must not exceed 100 and each weight must be less than or equal to 100."
            />
          </div>
        )}

        {targeting_type === TARGETING_TYPES.WATERFALL && logicError && (
          <div className="target-rules-section__labels__warning--dailycap">
            <FormControlLabel
              control={
                <ErrorIcon
                  className="info-icon"
                  color="secondary"
                  fontSize="large"
                />
              }
              label="Atleast one daily cap must be of default value (0)."
            />
          </div>
        )}
      </div>

      <FormControl component="fieldset">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="targetRules">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {rulesBlock.map((block, idx) => {
                  return (
                    <Draggable
                      key={`block${idx}`}
                      draggableId={`id-${idx}`}
                      index={idx}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          {block}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </FormControl>
      <div
        className="add-rules"
        style={{ display: "flex", flexDirection: "row", gap: 10 }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => addMoreRulesBlock()}
          startIcon={<AddIcon />}
          disabled={
            handleFieldDisabled() || targetingRules.length !== rulesBlockNumber
          }
        >
          Add rule
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => addMoreRulesBlock("group")}
          startIcon={<AddIcon />}
          disabled={
            handleFieldDisabled() || targetingRules.length !== rulesBlockNumber
          }
        >
          Add group rule
        </Button>
      </div>
      {open && (
        <ConfirmBox
          message={ruleArchiveMessage}
          confirmAction={() => {
            deleteRuleSet();
            handleModalClose();
          }}
          denyAction={handleModalClose}
        />
      )}
      {dupProviderLinkMsg && (
        <ConfirmBox
          message={dupProviderLinkMsg}
          hideCloseButton={true}
          confirmAction={() => setDupProviderLinkMsg(null)}
          affirmativeText="Okay"
        />
      )}
    </div>
  );
};
export default TargetRule;
