import React, { useState } from "react";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import { useProviderLinksFilters } from "./logic/providerLinksFilters.logic";
import { useFetchProviderLinks } from "./logic/fetchProviderLinks.logic";
import ProviderLinksFilter from "./layouts/ProviderLinksFilter";
import ProviderLinksTable from "./layouts/ProviderLinksTable";
import SimpleAccordion from "../../components/Accordion/Accordion";

import ProviderLinksFormContainer from "./ProviderLinksFormContainer";
import { useFormUILogic } from "./logic/formUI.logic";
import { useLocation } from "react-router-dom";

const ProviderLinksList = () => {
  const icon = <AllInclusiveIcon fontSize="inherit" color="primary" />;
  const location = useLocation();
  const edit = new URLSearchParams(location.search).get("edit");
  const editingProviderLinkId = new URLSearchParams(location.search).get(
    "provider_link_id"
  );


  const providerId = location?.state?.provider || "";
  const [redirectedProviderId, setRedirectedProviderId] = useState(providerId);
  const [loadedEdit, setLoadedEdit] = useState(false);
  const filterValuesLogic = useProviderLinksFilters({
    redirectedProviderId,
    setRedirectedProviderId
  });
  // eslint-disable-next-line
  const { filterValues, filtersClearToggle, dataLists, selectionAddActions } =
    filterValuesLogic;
  const { tagTypes, platforms, searchEngines, providers } = dataLists;

  const fetchProviderLinksLogic = useFetchProviderLinks({
    filterValues,
    filtersClearToggle,
    selectionAddActions
  });
  const {
    providerLinks,
    getProviderLinks,
    loadingProviderLinks,
    updateProviderLinks
  } = fetchProviderLinksLogic;
  const formUILogic = useFormUILogic(edit, fetchProviderLinksLogic);
  const { showEditForm, showDuplicateForm } = formUILogic;

  if (loadedEdit === false && providerLinks.length > 0 && edit) {
    showEditForm(
      providerLinks.find((link) => link.id === +editingProviderLinkId)
    );
    setLoadedEdit(true);
  }
  return (
    <div className="provider-links-list__container">
      <div className="provider-links-list__header">
        <ContentHeader icon={icon} title="Advertiser Links List" />
      </div>
      <div className="flow-content">
        <SimpleAccordion
          header="Filters"
          key="providerLinks-filters"
          defaultExpanded={true}
        >
          <ProviderLinksFilter
            filterValuesLogic={filterValuesLogic}
            handleSearch={getProviderLinks}
            loadingProviderLinks={loadingProviderLinks}
          />
        </SimpleAccordion>
        <div className="providerLinks__form__container">
          <ProviderLinksFormContainer
            tagTypes={tagTypes}
            platforms={platforms}
            searchEngines={searchEngines}
            providers={providers}
            formUILogic={formUILogic}
            getProviderLinks={getProviderLinks}
          />
        </div>
        <ProviderLinksTable
          fetchProviderLinksLogic={fetchProviderLinksLogic}
          updateProviderLinks={updateProviderLinks}
          showEditForm={showEditForm}
          showDuplicateForm={showDuplicateForm}
        />
      </div>
    </div>
  );
};

export default ProviderLinksList;
