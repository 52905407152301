import api from "../api";

class PushNotification {
    userNotificationSubscription = async (data) => {
        const { body } = await api.post(
            '/user-subscribe',
            data
        );
        return body;
    };
}
// eslint-disable-next-line
export default new PushNotification();

