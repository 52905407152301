import React from "react";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { QualityScoreDropDown } from "../../../helpers/constant/quality-score";

const ProviderLinksFilterSelections = ({
  selectionAddActions,
  filterValues,
  handleSearch,
  dataLists
}) => {
  const {
    handleQueryChange,
    handleSearchIdChange,
    handleTagTypeChange,
    handlePlatformChange,
    handleSearchEngineChange,
    handleProviderChange,
    handlePublisherChange,
    handleQualityScoreChange,
    setIsLive,
    setIsLinked,
    setIsReusable,
    setIsNotLive,
    setIsNotLinked,
    setIsNotReusable
  } = selectionAddActions;
  const {
    query,
    search_id,
    selectedTagTypes,
    selectedPlatforms,
    selectedSearchEngines,
    selectedProviders,
    selectedPublishers,
    selectedQualityScore,
    isLive,
    isLinked,
    isReusable,
    isNotLive,
    isNotLinked,
    isNotReusable
  } = filterValues;

  const { tagTypes, platforms, searchEngines, providers, publishers } =
    dataLists;

  return (
    <>
      <div className="providerLinks-filter__selections">
        <TextField
          variant="standard"
          margin="normal"
          label="Free text Search"
          onKeyPress={(e) => {
            e.key === "Enter" && handleSearch();
          }}
          value={query}
          onChange={handleQueryChange}
          fullWidth
        />
        <TextField
          variant="standard"
          type="number"
          margin="normal"
          label="Search by Link Id"
          value={search_id}
          onChange={handleSearchIdChange}
          fullWidth
        />
        <AutoCompleteSelect
          selected={selectedProviders}
          data={providers}
          handleChange={handleProviderChange}
          label={"Advertisers"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={true}
        />
        <AutoCompleteSelect
          selected={selectedPublishers}
          data={publishers}
          handleChange={handlePublisherChange}
          label={"Publishers"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={true}
        />
        <AutoCompleteSelect
          selected={selectedPlatforms}
          data={platforms}
          handleChange={handlePlatformChange}
          label={"Platform"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={false}
        />
        <AutoCompleteSelect
          selected={selectedSearchEngines}
          data={searchEngines}
          handleChange={handleSearchEngineChange}
          label={"Search Engine"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={false}
        />
        <AutoCompleteSelect
          selected={selectedTagTypes}
          data={tagTypes}
          handleChange={handleTagTypeChange}
          label={"Link Type"}
          showValueInLabel={true}
          enableMultiple={true}
          withDotsForStatus={false}
        />
        <AutoCompleteSelect
          selected={selectedQualityScore}
          data={QualityScoreDropDown}
          handleChange={handleQualityScoreChange}
          label={"Quality Scores"}
          showValueInLabel={false}
          enableMultiple={true}
          withDotsForStatus={false}
        />

        <div
          style={{
            display: "flex",
            gap: "2rem"
          }}
        >
          <div>
            <div className="filter-box__options-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isLinked}
                    onChange={() => setIsLinked(!isLinked)}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="cusDomain"
                  />
                }
                label="Linked"
              />
            </div>
            <div className="filter-box__options-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isNotLinked}
                    onChange={() => setIsNotLinked(!isNotLinked)}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="cusDomain"
                  />
                }
                style={{ whiteSpace: "nowrap" }}
                label="Not linked"
              />
            </div>
          </div>
          <div>
            <div className="filter-box__options-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isLive}
                    onChange={() => setIsLive(!isLive)}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="cusDomain"
                  />
                }
                style={{ whiteSpace: "nowrap" }}
                label="Is live"
              />
            </div>
            <div className="filter-box__options-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isNotLive}
                    onChange={() => setIsNotLive(!isNotLive)}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="cusDomain"
                  />
                }
                style={{ whiteSpace: "nowrap" }}
                label="Is not live"
              />
            </div>
          </div>
          <div>
            <div className="filter-box__options-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isReusable}
                    onChange={() => setIsReusable(!isReusable)}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="cusDomain"
                  />
                }
                style={{ whiteSpace: "nowrap" }}
                label="Ready to use"
              />
            </div>
            <div className="filter-box__options-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isNotReusable}
                    onChange={() => setIsNotReusable(!isNotReusable)}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    name="cusDomain"
                  />
                }
                style={{ whiteSpace: "nowrap" }}
                label="Not ready to use"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ProviderLinksFilterSelections.propTypes = {
  selectionAddActions: PropTypes.object.isRequired,
  filterValues: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired
};

export default ProviderLinksFilterSelections;
