import percentRound from "percent-round";
/**
 * Helper Functions for the Target Form Page
 */
/*pushing targeting id to all targeting rules array when updating */
export const formatTargetDataForSave = (
  targetingRules,
  advertiserLinkGroupId,
    targetingType,
  active
) => {
  return targetingRules.map((item) => ({
    ...(item.id && { id: item.id }),
    capacity: item?.daily_cap || 0,
    advertiser_id: item?.provider_details[0]?.provider_id || null,
    advertiser_link_id: item?.provider_details[0]?.provider_link || null,
    weight: item.daily_frequency || 0,
    active: active ? item.disabled ? !item.disabled : true : false
  }));
};

export const sumOfInputedWeights = (targetingRules) => {
  let sum = 0;
  let noOfZeroWeights = 0;
  for (let j = 0; j < targetingRules.length; j++) {
    let isTheRuleDisabled = targetingRules?.[j]?.disabled || false; // adding false because newly added rules does not have this field
    switch (isTheRuleDisabled) {
      case false:
        // check the sum and zero weights for enabled rules
        if (targetingRules?.[j]?.["daily_frequency"]) {
          let value = parseFloat(targetingRules[j]["daily_frequency"]);
          if (value !== 0 && !isNaN(value)) {
            sum = sum + value;
          } else {
            noOfZeroWeights++;
          }
        } else {
          noOfZeroWeights++;
        }
        break;
      default:
        //do nothing for the default case
        break;
    }
  }
  return { sum, noOfZeroWeights };
};

/**
 * Check the sum of all the weights(frequency) of targeting rules
 * @return true if sum is less than equal to 100, return false if not valid
 */
export const areTheWeightsValid = (targetingRules) => {
  let { sum, noOfZeroWeights } = sumOfInputedWeights(targetingRules);
  if (sum === 100 && noOfZeroWeights !== 0) {
    return false;
  }
  if (sum <= 100) {
    return true;
  }
  return false;
};

/**
 * Checks if targeting type of waterfall has atleast one limitless daily cap
 * @returns true if there is atleast one limitless daily_cap value for
 * targeting type of waterfall
 */
export const isThereLimitlessDailyCap = (targetingRules) => {
  let valueIndex = targetingRules.findIndex(
    (rule) => parseInt(rule.daily_cap) === 0
  );
  return valueIndex === -1 ? false : true;
};

/**
 * Input: Targeting Rules,
 * Output: Number of Active Targeting Rules i.e the rule is not disabled
 */
export const getNumberOfActiveRules = (targetingRules) => {
  let noOfActiveRules = 0;
  for (let j = 0; j < targetingRules.length; j++) {
    let isTheRuleDisabled = targetingRules?.[j]?.disabled || false; // adding false because newly added rules does not have this field
    if (!isTheRuleDisabled) {
      noOfActiveRules = noOfActiveRules + 1;
    }
  }
  return noOfActiveRules;
};

/**
 * Calculate total daily cap
 *
 * @param {object[]} targetingRules
 * @returns number
 */
export const calculateTotalDailyCap = (targetingRules) => {
  let totalDailyCap = 0;
  targetingRules.forEach((ruleSet) => {
    if (!ruleSet.disabled) {
      totalDailyCap = totalDailyCap + Number(ruleSet.daily_cap);
    }
  });
  return totalDailyCap;
};

/**
 * Update targeting rules before dispatch
 *
 * @param {*} newTargetingRules
 * @returns {{object} newTargetingRules, {number} totalDailyCap }
 */

export const updateTargetingRulesBeforeDispatch = (targetingRules) => {
  let totalDailyCap = calculateTotalDailyCap(targetingRules);

  let targetingRulesIndex = [];
  let targetingRulesWeight = [];

  for (let i = 0; i < targetingRules.length; i++) {
    if (!targetingRules[i].disabled) {
      targetingRulesIndex.push(i);
      targetingRulesWeight.push(
        totalDailyCap && totalDailyCap !== null
          ? Number((targetingRules[i].daily_cap / totalDailyCap) * 100).toFixed(
              5
            )
          : 0
      );
    }
  }
  targetingRulesWeight = percentRound(targetingRulesWeight);

  // bind targeting rules index with it's corresponding weight
  for (let i = 0; i < targetingRulesIndex.length; i++) {
    targetingRules[targetingRulesIndex[i]].daily_frequency =
      targetingRulesWeight[i];
  }

  return targetingRules;
};

export const ROUND_ROBIN_HELP_TEXT = `Each rule is executed once, then cycle starts all over again.`;
export const WATERFALL_HELP_TEXT = `Each rule is executed until it reached its daily cap, only
  then next rule shall be executed.`;
export const CUSTOM_FALLBACK_HELP_TEXT = `Custom Fallback Format should be without any query parameters such as https://bing.com. Adding query parameters in fallback URL will not be taken into consideration.`;
export const SYSTEM_FALLBACK_HELP_TEXT = `System Fallback Format should be without any query parameters such as https://bing.com. Adding query parameters in fallback URL will not be taken into consideration.`;
