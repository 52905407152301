import api from "../api";

class AdvertiserLinkGroup {
  getList = async (params = {}) => {
    const { body } = await api.get("advertiser-link-groups", null, params);
    return body;
  };

  fetchOne = async (id) => {
    const { body } = await api.get(`advertiser-link-groups/${id}`);
    return body;
  };

  save = async (data) => {
    const { body } = await api.post(`advertiser-link-groups`, data);
    return body;
  };
  update = async (id, data) => {
    const { body } = await api.patch(`advertiser-link-groups/${id}`, data);
    return body;
  };
  delete = async (id) => {
    const { body } = await api.destroy(`advertiser-link-groups/${id}`);
    return body;
  };
  restore = async (id) => {
    const { body } = await api.put(`advertiser-link-groups/${id}`);
    return body;
  };
  fetchForDropdown = async (queryData) => {
    let endpoint = "advertiser-link-groups?";
    let extras = [];
    Object.keys(queryData).forEach((key) => {
      extras.push(`${key}=${queryData[key]}`);
    });
    endpoint = endpoint + extras.join("&");
    const { body } = await api.get(endpoint);
    return body;
  };
  fetchStats = async (id) => {
    const { body } = await api.getGCP(
      "api/v1/bigquery/advertiser-link-group-stats/" + id,
      null,
      null
    );
    return body;
  };
}
// eslint-disable-next-line
export default new AdvertiserLinkGroup();
