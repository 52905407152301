export const getAdvertiserLinkGroupRules = (state) =>
  state.advertiserLinkGroup.rules;
export const getAdvertiserLinkGroupRulesError = (state) =>
  state.advertiserLinkGroup.rulesError;
export const getAdvertiserLinkGroup = (state) => state.advertiserLinkGroup;
export const getAdvertiserLinkGroups = (state) =>
  state.advertiserLinkGroup.advertiserLinkGroupLists;
export const getDeletedAdvertiserLinkGroupRules = (state) =>
  state.advertiserLinkGroup.deletedRules;
export const getSelectedFilter = (state) =>
  state.advertiserLinkGroup.selectedFilter;
