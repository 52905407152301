import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import ReduxToastr from "react-redux-toastr";
//
import { setCurrentUser } from "../services/Auth";
import store from "../store";
import { withTitle } from "../layouts/";
// pages
import Advisor from "../pages/Advisor";
import Home from "../pages/Home";
import Login from "../pages/auth/Login";
import Publishers from "../pages/Publishers/index";
import ManagePublishers from "../pages/Publishers/Manage";
import PublisherLinks from "../pages/Publishers/Links/index";
import Providers from "../pages/Provider";
import PrivateRoute from "./PrivateRoute";
import AdvertiserLinkGroup from "../pages/AdvertiserLinkGroup/List";
import AdvertiserLinkGroupAdd from "../pages/AdvertiserLinkGroup";
import Target from "../pages/target/List";
import TargetAdd from "../pages/target";
// import Logs from "../pages/logs/Logs";
import Profile from "../pages/profile/Profile";
import Settings from "../pages/settings/Settings";
import SystemLogs from "../pages/SystemLogs/SystemLogs";
import ProviderLinks from "../pages/ProviderLinks";
import FieldMapping from "../pages/mapping/CsvFieldMapping/FieldMapping";
import DisplayNamesMapping from "../pages/mapping/DisplayNames/DisplayNamesMapping";
import CsvUpload from "../pages/mapping/csvUpload/CsvUpload";
import Users from "../pages/usermanagemet/index";
import AddUser from "../pages/usermanagemet/addUser/addUser";
import EditUser from "../pages/usermanagemet/editUser/editUser";
import PublisherDisplayColumns from "../pages/usermanagemet/displayColumns/displayColumns";
import LinkedAccounts from "../pages/linkedAccounts/LinkedAccounts";
import RevenueReport from "../pages/RevenueReportv2/RevenueReportContainer";
import ReportingLogs from "../pages/reportingLogs";
import ReportsDetails from "../pages/reportingLogs/logsDetails";
import PublisherAccountList from "../pages/publisherAccounts/PublisherAccountList";
import ProviderLinksList from "../pages/ProviderLinksList/ProviderLinksList";
import PublisherAccountRemove from "../pages/publisherAccounts/PublisherAccountRemove";
import SkippedRowsList from "../pages/skippedReports/SkippedRowsList";
import ProviderApiInfo from "../pages/ProviderApiInfo/index";
import ProviderApiDetails from "../pages/ProviderApiDetails";
import DetailsInfo from "../pages/ProviderApiDetails/layouts/DetailsInfo";

import { ErrorPage } from "../pages/404Page";
import { ForgotPassword, ChangePassword } from "../pages/auth";

import ROLES from "./Role";

import PublisherApiInfo from "../pages/Publishers/ApiInfo";
import RevenueReportStatus from "../pages/skippedReports/uploadStatus";
import AdvertiserRevenueLogDelete from "../pages/Provider/DeleteRevenueLog";

const cookieToken = Cookies.get("ninaya-token");

if (localStorage["ninaya-token"] && cookieToken) {
  const parsedToken = JSON.parse(localStorage["ninaya-token"]);
  const { token } = parsedToken;
  const { timestamp } = parsedToken;
  const today = new Date();

  const previousDate = new Date(timestamp);
  previousDate.setDate(previousDate.getDate() + 7);

  const fullDateToday = today.getTime();
  const fullPreviousDate = previousDate.getTime();

  const decoded = jwtDecode(token);
  if (fullDateToday <= fullPreviousDate && decoded?.role?.role) {
    store.dispatch(setCurrentUser(decoded));
  } else {
    localStorage.removeItem("ninaya-token");
    Cookies.remove("ninaya-token");
  }
}

/**
 * Word fo caution: Because the title layout is a hoc and does not pass the pros, IT WILL NOT PASS match pas props to the component.
 * SO in cause you want to get params value use useParams() hook instead of match
 * and if you want to use match do not use withTitle() hook
 */

const Routes = () => {
  return (
    <>
      <div>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="bottom-left"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar={false}
          closeOnToastrClick
        />
      </div>
      <BrowserRouter>
        <Switch>
          <PrivateRoute
            exact
            path="/"
            component={withTitle(Home, "Dashboard")}
            roles={ROLES.all}
          />
          <PrivateRoute
            exact
            path="/publisher/apidetails"
            component={withTitle(PublisherApiInfo, "Api Info")}
            roles={ROLES.publisher}
          />
          <PrivateRoute
            exact
            path="/publishers"
            component={withTitle(Publishers, "Publishers")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/publishers/add"
            component={withTitle(ManagePublishers, "Add Publishers")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/publishers/edit/:id"
            component={withTitle(ManagePublishers, "Edit Publishers")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/publishers/links/edit"
            component={withTitle(PublisherLinks, "Edit Link")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/publishers/details/:id"
            component={withTitle(PublisherApiInfo, "Publisher Details")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/advertisers"
            component={withTitle(Providers, "Advertisers")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/advertisers/add"
            component={withTitle(ProviderApiInfo, "Add Advertisers")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/advertisers/edit/:id"
            component={withTitle(ProviderApiInfo, "Edit Advertisers")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/apidetails"
            component={withTitle(ProviderApiDetails, "API Details")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/apidetails/:id"
            component={withTitle(DetailsInfo, "API Detail")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/advertisers/links/add"
            component={withTitle(ProviderLinks, "Add Links")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/advertisers/links"
            component={withTitle(ProviderLinksList, "Advertisers Links")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/advertiser-link-group"
            component={withTitle(AdvertiserLinkGroup, "Advertiser lInk Groups")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/advertiser-link-group/add"
            component={withTitle(
              AdvertiserLinkGroupAdd,
              "Add Advertiser Link Group"
            )}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/advertiser-link-group/edit/:id"
            component={withTitle(
              AdvertiserLinkGroupAdd,
              "Edit Advertiser Link Group"
            )}
            roles={ROLES.admin}
          />
          {/* <PrivateRoute
            exact
            path="/logs"
            component={withTitle(Logs, "Logs")}
            roles={ROLES.admin}
          /> */}
          <PrivateRoute
            exact
            path="/advisor"
            component={withTitle(Advisor, "Advisors")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/settings"
            component={withTitle(Settings, "Settings")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/systemlogs"
            component={withTitle(SystemLogs, "System Logs")}
            roles={ROLES.admin}
          />
          <Route
            exact
            path="/login"
            component={withTitle(Login, "Login")}
            roles={ROLES.all}
          />
          <Route
            exact
            path="/reset-password/:id/:token"
            component={withTitle(ChangePassword, "Change Password")}
            roles={ROLES.all}
          />
          <Route
            exact
            path="/forgot-password"
            component={withTitle(ForgotPassword, "Forgot Password")}
            roles={ROLES.all}
          />
          <PrivateRoute
            exact
            path="/target"
            component={withTitle(Target, "Target")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/target/add"
            component={withTitle(TargetAdd, "Add Target")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/target/edit/:id"
            component={withTitle(TargetAdd, "Edit Target")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/profile"
            component={withTitle(Profile, "Profile")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/mappedFields"
            component={withTitle(FieldMapping, "Field Mapping")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/displaynames"
            component={withTitle(DisplayNamesMapping, "Display Names")}
            roles={ROLES.admin}
          />{" "}
          <PrivateRoute
            exact
            path="/csvupload"
            component={withTitle(CsvUpload, "CSV upload")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/usermanagement"
            component={withTitle(Users, "User Management")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/usermanagement/adduser"
            component={withTitle(AddUser, "Add Users")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/usermanagement/edit/:id"
            component={withTitle(EditUser, "Edit Users")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/publisherDisplayColumns"
            component={withTitle(
              PublisherDisplayColumns,
              "Publisher Display Columns"
            )}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/publisheraccountmanagement/edit/:id"
            component={withTitle(LinkedAccounts, "Edit Accounts")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/revenuereport"
            component={withTitle(RevenueReport, "Revenue Report")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/pubaccount"
            component={withTitle(PublisherAccountList, "Publisher Accounts")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/reportinglogs"
            component={withTitle(ReportingLogs, "Reporting Logs")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/reportinglogs/:id/:action"
            component={withTitle(ReportsDetails, "Report Details")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/revenueReportStatus"
            component={withTitle(SkippedRowsList, "CSV Upload details")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/csvUploadStatus"
            component={withTitle(RevenueReportStatus, "CSV upload status")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/remove/reports/:id"
            component={withTitle(PublisherAccountRemove, "Remove Reports")}
            roles={ROLES.admin}
          />
          <PrivateRoute
            exact
            path="/advertisers/delete-revenue-log/:id"
            component={withTitle(
              AdvertiserRevenueLogDelete,
              "Delete advertiser revenue log"
            )}
            roles={ROLES.admin}
          />
          <PrivateRoute path="*" component={ErrorPage} roles={ROLES.all} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps)(Routes);
