import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Setting } from "../../services/Settings";
import { toastr } from "react-redux-toastr";
import { getSettingsObject } from "../../reducers/setting/setting.selector";
import { updateSettingsObject } from "../../reducers/setting/setting.action";
import PropTypes from "prop-types";

function DynamicSettingLink({ linkKey, primary, settingKey, icon }) {
  const [toLink, setToLink] = useState("");
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const settingsObject = useSelector(getSettingsObject);

  useEffect(() => {
    if (settingKey) {
      if (Object.keys(settingsObject).includes(settingKey)) {
        setToLink(settingsObject.kyc_url);
      } else {
        Setting.fetchSettingsByKey(settingKey)
          .then((response) => {
            if (response.success) {
              if (response?.data?.setting) {
                setToLink(response.data.setting?.value);
                stableDispatch(
                  updateSettingsObject({
                    ...settingsObject,
                    [settingKey]: response.data.setting?.value
                  })
                );
              }
            } else {
              throw new Error(JSON.stringify(response.error));
            }
          })
          .catch((error) => {
            toastr.error("Opps", "Cannot get dynamic setting link!!");
            console.trace(error.message);
          });
      }
    }
  }, [settingKey, settingsObject, stableDispatch]);

  return (
    <>
      {toLink && (
        <a
          className="link"
          href={toLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItem button key={linkKey}>
            <ListItemIcon>{icon}</ListItemIcon>

            <ListItemText primary={primary} />
          </ListItem>
        </a>
      )}
    </>
  );
}

DynamicSettingLink.propTypes = {
  icon: PropTypes.object.isRequired,
  linkKey: PropTypes.string.isRequired,
  primary: PropTypes.string.isRequired,
  settingKey: PropTypes.string.isRequired
};

export default DynamicSettingLink;
