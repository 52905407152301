import Modal from "@material-ui/core/Modal";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    maxHeight: `70vh`,
    overflowY: 'scroll',
    minWidth: '1000px'
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: 750,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "unset",
    outline: "none",
    borderRadius: "5px"
  },
  actionButton: {
    width: '100%',
    textAlign: 'right',
    margin: "0.2rem 0"
  }
}));

export const SimpleModalWithButton = ({ children, handleClose, message, buttonText = "Okay", hideAction = false }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <div className="delete-confirm-box">
          {message && (
            <Typography component="h3">{message}</Typography>
          )}
          <hr />
          {children}
          {
          !hideAction &&
            (
              <>
                <hr />
                <div className={classes.actionButton}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleClose()}
                  >
                    {buttonText}
                  </Button>
                </div>
              </>
            )
          }
        </div>
      </div>
    </Modal>
  );
};

export default SimpleModalWithButton;
