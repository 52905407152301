import React from "react";
import SimpleModal from "../../../components/SimpleModal/SimpleModal";
import Button from "@material-ui/core/Button";

const DuplicateWarningModal = ({
  frontEndDuplicates,
  backEndDuplicates,
  confirmAction,
  denyAction
}) => {
  let { duplicateLinkIdsInForm } = frontEndDuplicates;
  duplicateLinkIdsInForm = [...new Set(duplicateLinkIdsInForm)]; // removing duplicate ids
  const { duplicateLinkIdsInBackend } = backEndDuplicates;
  return (
    <SimpleModal handleClose={denyAction}>
      <h2 className="provider-links__duplicates__heading">
        Identical Links Found
      </h2>
      {duplicateLinkIdsInForm.length > 0 && (
        <div className="provider-links__duplicates-in-form">
          <h3 className="provider-links__duplicates-in-form__title">
            The following links are duplicated in the form
          </h3>
          <p>
            ID:{" "}
            {duplicateLinkIdsInForm.map((linkId) => (
              <span
                className="provider-links__duplicates-in-form__id"
                key={linkId}
              >
                {linkId}
              </span>
            ))}
          </p>
        </div>
      )}
      {duplicateLinkIdsInBackend.length > 0 && (
        <div className="provider-links__duplicates-in-form">
          <h3 className="provider-links__duplicates-in-form__title">
            The following links are identical to links in the database
          </h3>
          <div>
            {duplicateLinkIdsInBackend.map((link, index) => (
              <p
                className="provider-links__duplicates-in-form__id"
                key={`${link.linkId}-${index}`}
              >
                {link.providerName} with Advertiser Link ID: - {link.linkId}
              </p>
            ))}
          </div>
        </div>
      )}
      <h3 className="provider-links__duplicates__confirm">
        Are you sure you want to proceed ?
      </h3>
      <div className="provider-links__duplicates-in-form__buttons">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => confirmAction()}
        >
          Yes
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => denyAction()}
        >
          No
        </Button>
      </div>
    </SimpleModal>
  );
};

export default DuplicateWarningModal;
