import React, { useState, useEffect, useRef, useCallback } from "react";
import Table from "../../components/Table/Table";

//releted to services
import { useSelector, useDispatch } from "react-redux";
//releted to services
import { User } from "../../services/ReportingUser";
import {
  fetchUsersListStart,
  fetchUsersListSuccess,
  fetchUsersListFailure,
  updateUsersList
} from "../../reducers/user/user.action";
import { getUsers } from "../../reducers/user/user.selecter";

import { useHistory } from "react-router-dom";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import GroupIcon from "@material-ui/icons/Group";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddBoxIcon from "@material-ui/icons/AddBox";
import SimpleModal from "../../components/SimpleModal/SimpleModal";
import FilterBox from "../../components/FilterBox/UserManagementFilterBox";
import { toastr } from "react-redux-toastr";
import { textFieldValid } from "../../helpers/validation";
import SimpleAccordion from "../../components/Accordion/Accordion";
import WarningIcon from "@material-ui/icons/Warning";
import moment from "moment";
import { capitalizeFirstLetter } from "../../helpers/index";
import Roles from "../../helpers/constant/role-types";

const Users = () => {
  const users = useSelector(getUsers);

  const headers = [
    { title: "ID", value: "id" },
    { title: "Email", value: "email" },
    { title: "Status", value: "blocked", disableOrderBy: true },
    { title: "Publisher", value: "publisher" },
    // { title: "Provider", value: "provider" },
    // { title: "Account", value: "account" },
    { title: "Created At (UTC)", value: "createdAt" },
    { title: "Updated At (UTC)", value: "updatedAt" }
  ];

  // eslint-disable-next-line no-unused-vars
  const [currentSelectedSectionIndex, setCurrentSelectedSectionIndex] =
    useState(0);
  let choiceOfWord = "Delete user";
  const [changeID, setChangeID] = useState(null);

  //table rows selected
  const [selected, setSelected] = React.useState([]);
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const history = useHistory();

  //state for modal for deleting, bulk delete , status toggle, and restore
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [currentStatus, set_CurrentStatus] = useState(true);
  // const [accounts, set_Account] = useState([]);
  // const [accountSelect, set_AccountSelect] = useState(null);

  //state for filter box that will be used as params
  const [searchValue, setSearchValue] = useState("");
  const [active, setActive] = useState(false);
  const [inActive, setInActive] = useState(false);
  //state for pagination that will be used as params
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC"); //ASC and DESC
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const prevPage = useRef(1); //!!IMP : SET IT SAME AS DEFAULT VALUE FOR PAGE STATE

  const getRequestParams = useCallback(() => {
    let params = {};

    if (textFieldValid(searchValue)) {
      params["q"] = searchValue;
    }
    if (active) {
      params["status"] = "active";
    }

    // if (accountSelect) {
    //   params["account"] = accountSelect;
    // }

    if (inActive) {
      params["status"] = "inactive";
    }

    params["page"] = page;
    params["size"] = size;
    params["order_by"] = orderBy;
    params["order_direction"] = orderDirection;
    params["role"] = Roles.PUBLISHER;

    return params;
  }, [
    active,
    inActive,
    page,
    size,
    orderBy,
    orderDirection,
    searchValue
    // accountSelect
  ]);

  const fetchUsers = useCallback(
    (params = {}) => {
      setLoading(true);
      stableDispatch(fetchUsersListStart());
      User.fetchUsers(params)
        .then((response) => {
          if (response.success) {
            let users = response.data.users
              .map((user) => {
                user.updatedAt = moment(user.updatedAt).utc().format("lll");
                user.createdAt = moment(user.createdAt).utc().format("lll");
                // user.blocked=!user.blocked
                return user;
              })
              .filter(
                (user) => user?.role?.role && user?.role?.role !== "SuperAdmin"
              );
            stableDispatch(updateUsersList(users));
            setTotal(response.data.total);
            stableDispatch(fetchUsersListSuccess());
            setLoading(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          stableDispatch(fetchUsersListFailure(error.message));
          setLoading(false);
        });
    },
    [stableDispatch]
  );

  useEffect(() => {
    // fetchAccounts();
    fetchUsers(getRequestParams());
    prevPage.current === page && setSelected([]);
    if (page !== prevPage.current) {
      prevPage.current = page;
    }
  }, [
    active,
    // accountSelect,
    inActive,
    size,
    orderBy,
    page,
    orderDirection,
    getRequestParams,
    fetchUsers
  ]);

  // const fetchAccounts = async () => {
  //   try {
  //     const account = await User.fetchAccount();
  //     if (account.success) {
  //       set_Account(account.data.accounts);
  //     } else {
  //       throw new Error("Failed to fetch accounts");
  //     }
  //   } catch (error) {
  //     console.trace(error.message);
  //   }
  // };

  const handleModalClose = () => {
    setChangeID(null);
    setOpen(false);
  };

  //functions to handle different actions in the list
  const handleEditAction = (id) => history.push(`/usermanagement/edit/${id}`);
  const handleDeleteAction = (id) => {
    setChangeID(id);
    setOpen(true);
  };
  const openDisplayColumnsPage = (id) => {
    history.push({
      pathname: "/publisherDisplayColumns",
      state: {
        userId: id
      }
    });
  };

  const icon = <GroupIcon fontSize="inherit" color="primary" />;

  const handleDelete = () => {
    let params = {};
    User.deleteUser(changeID, params)
      .then((response) => {
        if (response.success) {
          fetchUsers(getRequestParams());
          toastr.success("Success", "User removed permanently!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", "Not able to remove at the moment");
      });
    setChangeID(null);
    setOpen(false);
  };

  //action list with icon and corresponding action creator
  const actions = [
    {
      name: "edit",
      action: handleEditAction,
      icon: "editIcon"
    },
    {
      name: "delete",
      action: handleDeleteAction,
      icon: "delete"
    },
    {
      name: "display-column",
      action: openDisplayColumnsPage,
      icon: "display-column"
    }
  ];

  const deleteUserModal = (
    <SimpleModal handleClose={handleModalClose}>
      <div className="delete-confirm-box">
        {open && changeID ? (
          <div className="delete-confirm-box__warning">
            <WarningIcon style={{ fontSize: 30 }} className="blink" />
            <Typography component="h3">
              This user has associated with different table ahead and{" "}
              {choiceOfWord}?
            </Typography>
          </div>
        ) : (
          <Typography component="h3">
            Are you sure you want to {choiceOfWord}?
          </Typography>
        )}
        <div className="delete-confirm-box__buttons">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleDelete();
            }}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleModalClose()}
          >
            No
          </Button>
        </div>
      </div>
    </SimpleModal>
  );

  const updateUsersValue = (id, key, value) => {};

  const handleCellValueUpdate = (data, key, value) => {
    let id = data.id;
    let dataToSend = {};
    dataToSend[key] = value;
    User.updateUser(id, dataToSend)
      .then((response) => {
        if (response.success) {
          updateUsersValue(id, key, value);
          toastr.success("Success", `${capitalizeFirstLetter(key)} updated.`);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
      });
  };

  // const handleChangeSelctbox = (event) => {
  //   set_AccountSelect(event.target.value);
  // };

  //update publishers value when change made in table rows
  //on redux state, for UI purpose
  const updateUserValue = (id, key, value) => {
    let tempUsers = [...users];
    let changedIndex = tempUsers.findIndex((user) => user.id === id);
    tempUsers[changedIndex][key] = value.blocked;
    dispatch(updateUsersList(tempUsers));
  };

  async function postUserStatus(id, currentStatuses) {
    const poststatus = await User.postStatus(id, { blocked: currentStatuses });
    if (poststatus.success) {
      updateUserValue(id, "blocked", { blocked: currentStatuses });
      toastr.success("Success", "Status updated");
    } else {
      toastr.error("Oops", "Failed to update");
    }
  }

  const handleStatusToggle = (id, currentStatuses) => {
    set_CurrentStatus(!currentStatuses);
    postUserStatus(id, !currentStatuses);
  };

  return (
    <div className="publishers-container">
      <div className="publishers-container__header">
        <ContentHeader icon={icon} title="User Management" />
      </div>
      <div className="publishers-container__addBtn">
        <Button
          onClick={() => history.push("/usermanagement/adduser")}
          variant="contained"
          size="medium"
          color="primary"
          startIcon={<AddBoxIcon />}
        >
          Add User
        </Button>
      </div>
      <div className="publishers-container__filter-box">
        <SimpleAccordion header="Filters" key="user-filter-box">
          <FilterBox
            active={active}
            inActive={inActive}
            setSearchValue={setSearchValue}
            setActive={setActive}
            setInActive={setInActive}
            archive={currentSelectedSectionIndex === 1}
            setSelected={setSelected}
            selectbox={false}
            handleChangeSelcetBox={{}}
          />
        </SimpleAccordion>
      </div>
      <br />
      <div className="publishers-container__table">
        {users && (
          <Table
            headers={headers}
            rowValues={users}
            actions={actions}
            selected={selected}
            setSelected={setSelected}
            tableType={"user"}
            noCheckboxes={true}
            handleCellValueUpdate={handleCellValueUpdate}
            handleStatusToggle={handleStatusToggle}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            total={total}
            isLoading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
          />
        )}
        {open && deleteUserModal}
      </div>
    </div>
  );
};

export default Users;
