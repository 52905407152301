import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";

import { FormControl, TextField } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/CloseSharp";
import Button from "@material-ui/core/Button";

import { useFormTouched } from "../../../hooks/useFormTouched";
import { changeNullToString } from "../../../helpers";

const RevenueShareForm = ({ revenueShare, onCancel, onSubmit }) => {
    const [share_revenue, setShareRevenue] = useState("");
    const [showPrompt, setShowPrompt] = useState(true);
    const [fieldErrors, setFieldErrors] = useState({
        share_revenue: false,
        message: ""
    });
    const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
        cleanupFunction: () => { }
    });

    useEffect(() => {
        setShareRevenue(changeNullToString(revenueShare));
    }, [revenueShare]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowPrompt(false);
        if (validateForm()) {
            clearFormTouched();
            onSubmit({
                share_revenue: share_revenue
            });
        }
    };

    const validateForm = function () {
        setFieldErrors({});
        let parsedShareRevenue = parseFloat(share_revenue);
        if (!parsedShareRevenue) {
            setFieldErrors({
                ...fieldErrors,
                share_revenue: true,
                message: `Revenue Share(%) is required field`
            });
            return false;
        }
        if (parsedShareRevenue < 0 || parsedShareRevenue > 100 || !share_revenue) {
            setFieldErrors({
                ...fieldErrors,
                share_revenue: true,
                message: `Please enter valid Revenue Share(%)`
            });
            return false;
        }
        return true;
    };

    return (
        <div className="default-values__form">
            <h5>Add Revenue Share(%)</h5>
            <form className="flow-content">
                <div>
                    <FormControl fullWidth>
                        <TextField
                            variant="outlined"
                            label="Revenue Share(%)"
                            value={share_revenue || ""}
                            onChange={(e) => {
                                handleFormTouched();
                                setShareRevenue(e.target.value);
                                setFieldErrors({
                                    ...fieldErrors,
                                    share_revenue: false,
                                    message: ""
                                });
                            }}
                            placeholder="Enter Revenue Share(%)"
                            type="number"
                            name="share_revenue"
                        />
                        {fieldErrors.share_revenue && (
                            <div className="error-text">{fieldErrors.message}</div>
                        )}
                    </FormControl>
                </div>
                <div className="default-values__form__buttons">
                    <Button
                        color="primary"
                        variant="contained"
                        size="medium"
                        onClick={handleSubmit}
                        startIcon={<CheckIcon />}
                        type="submit"
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        onClick={onCancel}
                        startIcon={<CloseIcon />}
                    >
                        cancel
                    </Button>
                </div>
            </form>
            <Prompt
                when={showPrompt && formTouched.current}
                message="Are you sure you want to leave?"
            />
        </div>
    );
};

export default RevenueShareForm;
