/**
 *
 * @param {string|number} value
 * @param {number} point
 * Function to round up value to a certain decimal
 * converts string to number and to fixed
 * @returns
 */
export const roundUpString = (value, point = 3) => {
  return (parseInt(value) || 0).toFixed(point);
};
