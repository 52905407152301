export const TargetActionTypes = {
  UPDATE_ADVERTISER_GROUP_RULES: "UPDATE_ADVERTISER_GROUP_RULES",
  UPDATE_ADVERTISER_GROUP_FORM_ERRORS: "UPDATE_ADVERTISER_GROUP_FORM_ERRORS",
  CLEAR_ADVERTISER_GROUP_FORM: "CLEAR_ADVERTISER_GROUP_FORM",
  UPDATE_ADVERTISER_GROUP_FORM: "UPDATE_ADVERTISER_GROUP_FORM",
  UPDATE_ADVERTISER_GROUP_LISTS: "UPDATE_ADVERTISER_GROUP_LISTS",
  UPDATE_DELETED_ADVERTISER_GROUP_RULES:
    "UPDATE_DELETED_ADVERTISER_GROUP_RULES",
  SET_TARGET_SELECTED_FILTER: "SET_TARGET_SELECTED_FILTER"
};

export const updateAdvertiserLinkGroupRules = (targetingRules) => ({
  type: TargetActionTypes.UPDATE_ADVERTISER_GROUP_RULES,
  payload: targetingRules
});

export const updateAdvertiserLinkGroupFormErrors = (targetingFormErrors) => ({
  type: TargetActionTypes.UPDATE_ADVERTISER_GROUP_FORM_ERRORS,
  payload: targetingFormErrors
});

export const clearAdvertiserLinkGroupForm = () => ({
  type: TargetActionTypes.CLEAR_ADVERTISER_GROUP_FORM
});

export const updateAdvertiserLinkGroupForm = (formValues) => ({
  type: TargetActionTypes.UPDATE_ADVERTISER_GROUP_FORM,
  payload: formValues
});

export const updateAdvertiserLinkGroupLists = (targetings) => ({
  type: TargetActionTypes.UPDATE_ADVERTISER_GROUP_LISTS,
  payload: targetings
});

export const updateDeletedAdvertiserLinkGroupRules = (deletedRules) => ({
  type: TargetActionTypes.UPDATE_DELETED_ADVERTISER_GROUP_RULES,
  payload: deletedRules
});

export const setSelectedFilter = (filter) => ({
  type: TargetActionTypes.SET_TARGET_SELECTED_FILTER,
  payload: filter
});
