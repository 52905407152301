import React from "react";
// import moment from "moment";
//
// import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
// import AssessmentIcon from "@material-ui/icons/Assessment";
import BarChartIcon from '@material-ui/icons/BarChart';
import KeyboardCapslockIcon from '@material-ui/icons/KeyboardCapslock';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
//
import { sortRelatedRules, getUniqueRelatedRules } from "../helpers";
// import { useRedirectLogic } from "../../../hooks/useRedirectLogic";
// import { useFetchRuleRevenue } from "../logics/fetchRuleRevenue.logic";
// import { useRevenueAssign } from "../logics/revenueAssign.logic";
import { useFetchRelatedLinkStats } from "../logics/fetchRelatedLinkStats.logic";
import { useRelatedLinkStatAssign } from "../logics/relatedLinkStatAssign.logic";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";


// const currentMonth = moment().format("MMMM, YYYY");


// const RevenueToolTip = ({ grossRevenue }) => {
//   return (
//     <div className="default-padding">
//       <pre>Gross Revenue: {grossRevenue} </pre>
//       <pre>UTC Date: {currentMonth}</pre>
//     </div>
//   )
// }
// const SearchLogsToolTipTitle = ({ total_searches }) => {
//   return (
//     <div className="default-padding">
//       <pre>Total Searches: {total_searches}</pre>
//     </div>
//   )
// }

const DailyCapTooltip = ({ dailyCap }) => {
  return (
    <div className="default-padding">
      <pre>Current cap: {dailyCap} </pre>
    </div>
  )
}
const YesterdaySearchCountTooltip = ({ yesterdaySearchCount }) => {
  return (
    <div className="default-padding">
      <pre>Yesterday's search count: {yesterdaySearchCount}</pre>
    </div>
  )
}

const RelatedTagBox = ({ relatedTags, linkID }) => {
  const temp = getUniqueRelatedRules(sortRelatedRules(relatedTags));
  // const { revenueData } = useFetchRuleRevenue(temp.map(item => item.rule_id), temp.map(item => item.tag_id));
  // const tagsInUse = useRevenueAssign(revenueData, temp)
  const { loadingLinkStats, relatedLinkStat } = useFetchRelatedLinkStats(temp.map(item => item.rule_id));
  const tagsInUse = useRelatedLinkStatAssign(relatedLinkStat, temp);

  // const { redirectToReportPage, redirectToSearchLogsPage } = useRedirectLogic();
  return (
    <div className="related-tag-box flow-content ">
      <h2 className="related-tag-box__heading">Advertiser Link ID: {linkID}</h2>
      {tagsInUse.map((tag) => {
        return (
          <div
            className={`tag-box flow-content--small white-border-box ${tag.disabled && "tag-box--disabled"
              }`}
            key={`${tag.tag_id}+${tag.rule_id}}`}
            onClick={() => window.open(`/target/edit/${tag.tag_id}`, "_blank")}
          >
            <div className="tag-box__publisher">{`Publisher : ${tag?.publisher_id || ""
              } - ${tag?.publisher_name || ""}`}</div>
            <div className="tag-box__tag">Pub Link ID : {tag.tag_id}</div>
            <div className="tag-box__rules">Rule ID : {tag.rule_id}</div>
            <div className="tag-box__rules">
              <span className="tag-box__rule">
                Status:
                {tag.disabled ? " Inactive" : " Active"}
              </span>
              {/* <Tooltip title={<RevenueToolTip grossRevenue={tag.gross_revenue} />}>
                <IconButton
                  className={tag.rule_id ? "green-icon" : "gray-icon"}
                  onClick={() => redirectToReportPage(tag.rule_id, "daily")}
                >
                  <AttachMoneyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={<SearchLogsToolTipTitle total_searches={tag.total_searches} />}>
                <IconButton
                  className={tag.rule_id ? "blue-icon" : "gray-icon"}
                  onClick={() => redirectToSearchLogsPage(tag.rule_id, "day", "currentmonths")}
                >
                  <AssessmentIcon />
                </IconButton>
              </Tooltip> */}

              {
              loadingLinkStats && loadingLinkStats?.isLoading?
                <LoadingSpinner size={25} /> : 
                (
                  <>
                    <Tooltip title={<DailyCapTooltip dailyCap={tag.dailyCap} />}>
                      <IconButton
                        className={tag.rule_id ? "blue-icon" : "gray-icon"}
                      >
                        <KeyboardCapslockIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={<YesterdaySearchCountTooltip yesterdaySearchCount={tag.yesterdaysSearchCount} />}>
                      <IconButton
                        className={tag.rule_id ? "blue-icon" : "gray-icon"}
                      >
                        <BarChartIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )
              }

            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RelatedTagBox;
