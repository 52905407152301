import React, { useState } from "react";

// material ui
import { TextField, Grid } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { FileCopy, Link } from "@material-ui/icons";

// components
// import LinkedAccountButton from "./LinkedAccountButton";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
// import SearchStatsBtn from "./StatButtons/SearchStatsBtn";
// import LifeStats from "./StatButtons/LifeStats";
// import GrossRevenueStatsBtn from "./StatButtons/GrossRevenueStatsBtn";
import SimpleModal from "../../../components/SimpleModal/SimpleModal";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";

// helpers
import { TARGETING_TYPES } from "../../../helpers/constant/types";
import { useGetFormValues } from "../logics/rule-block/getFormValues.logic";
import { useGetProviderLinks } from "../logics/rule-block/getProviderLinks.logic";
// import { useRedirectLogic } from "../../../hooks/useRedirectLogic";
import ProviderLinkAccountSelector from "./ProviderLinkAccountSelector";
// import { useGetRuleStatsLogic } from "../logics/rule-block/getRuleStats.logic";
import { formatNumberAddComma } from "../../../helpers/formatters";
import { toastr } from "react-redux-toastr";
import LinkPropertiesIcon from "../../ProviderLinks/LinkPropertiesIcon";

// constants
// import * as Constants from "../constants";

const Rule = ({
  originalIndex,
  targeting_type,
  targetingRuleError,
  targetingRule,
  providers,
  handleDeleteAction,
  disabledProviders,
  targetingID,
  editing,
  publisher,
  formValueHandlers,
  linkStatus,
  handleFieldDisabled,
  platform,
  searchEngine,
  tagType,
  qualityScore,
  linkDailyCap,
  stats,
  style
}) => {
  const { formValues, formErrors } = useGetFormValues({
    targetingRule,
    targetingRuleError,
    linkStatus
  });

  const disableChange = targetingRule.disabled || !!stats;
  const isGroup = targetingRule.group;

  const {
    dailyCap,
    dailyFrequency,
    disabled,
    providerOne,
    providerTwo,
    providerOneLink,
    rule_id,
    revenueSharePercentage
  } = formValues;

  const {
    dailyCapError,
    providerOneError,
    providerOneLinkError,
    dailyFrequencyError,
    revenueSharePercentageError
  } = formErrors;

  const { providerLinksOne, providerLinksTwo } = useGetProviderLinks({
    providerOneLink,
    providerOne,
    providerTwo,
    platform,
    searchEngine,
    tagType,
    qualityScore,
    isGroup: targetingRule.group
  });

  const {
    handleChange,
    handleProviderChange,
    handleDisabledChange,
    handleProviderLinkChanges,
    statusChangeOpen,
    statusInformOpen,
    updateStatusChange,
    closeAllModals,
    openStatusInform,
    manageOldRuleStatus
  } = formValueHandlers;

  const NotAvailable = "n/a";

  const [disableSwitchModal, setDisableSwitch] = useState(false);

  const handleDisableSwitch = (originalIndex, disabled) => {
    if (disabled && manageOldRuleStatus(rule_id)) {
      checkForSelectedProviderLink()
        ? handleDisabledChange(originalIndex, disabled, rule_id)
        : setDisableSwitch(true);
    } else if (!disabled) {
      handleDisabledChange(originalIndex, disabled, rule_id);
    } else {
      openStatusInform();
    }
  };

  const checkForSelectedProviderLink = () => {
    let proceed = false;
    providerLinksOne.forEach((link) => {
      if (link.value === providerOneLink) {
        if (
          searchEngine.includes(link.search_engine_id) &&
          platform.includes(link.platform_id) &&
          tagType.includes(link.tag_type_id) &&
          qualityScore.includes(link.quality_score)
        ) {
          proceed = true;
        }
        return;
      }
    });
    return proceed;
  };

  return (
    <form noValidate autoComplete="off" style={style}>
      <fieldset
        className="rule__section"
        style={{ backgroundColor: targetingRule.group ? "#F6F6F6" : "#FFFFFF" }}
      >
        {rule_id && <legend>{rule_id}</legend>}
        <div className="rule__section__id">
          <Tooltip title="Drag and drop to change priority">
            <ImportExportIcon color="primary" className="drag-drop-icon" />
          </Tooltip>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              value={rule_id}
              disabled={true}
              label="ID"
            />
          </FormControl>
        </div>
        <Grid container spacing={4}>
          <Grid item md={9}>
            <Grid container spacing={4} style={{ marginBottom: "10px" }}>
              {!isGroup && (
                <Grid item xs={12} sm={12} md={3} lg={2}>
                  <div className="rule__section__testing__block rule__section__testing__block--adv">
                    <AutoCompleteSelect
                      selected={providerOne ? providerOne : ""}
                      data={providers}
                      handleChange={(value) =>
                        handleProviderChange(
                          {
                            target: {
                              name: "providerOne",
                              value
                            }
                          },
                          originalIndex,
                          disabledProviders
                        )
                      }
                      label={"Advertiser"}
                      showValueInLabel={true}
                      enableMultiple={false}
                      withDotsForStatus={true}
                      error={providerOneError}
                      cannotSelectDisabled={true}
                      disabled={disableChange}
                    />
                  </div>
                </Grid>
              )}
              {isGroup && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={2}
                  style={{ display: "grid", placeItems: "center" }}
                >
                  <span>NO ADVERTISER</span>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ProviderLinkAccountSelector
                    selected={providerOneLink}
                    data={providerLinksOne}
                    handleProviderLinkChanges={handleProviderLinkChanges}
                    error={providerOneLinkError}
                    originalIndex={originalIndex}
                    name="providerOneLink"
                    label="Advertiser Link"
                    providerLinksOne={providerLinksOne}
                    providerLinksTwo={providerLinksTwo}
                    disableChange={disableChange}
                  />

                  {providerOneLink && (
                    <Tooltip
                      title="Copy Link"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          providerLinksOne.find(
                            (item) => item.value === providerOneLink
                          ).label
                        );
                        toastr.success("Success", "Link copied!");
                      }}
                    >
                      <IconButton>
                        <FileCopy color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {providerOneLink && (
                    <LinkPropertiesIcon
                      link={
                        providerLinksOne.find(
                          (item) => item.value === providerOneLink
                        ) || {}
                      }
                    ></LinkPropertiesIcon>
                  )}
                  {providerOneLink && !targetingRule.group && (
                    <Tooltip
                      title="Edit advertiser link"
                      onClick={() => {
                        window.open(
                          window.location.origin +
                            "/advertisers/links?edit=true&provider_link_id=" +
                            providerOneLink,
                          "_blank"
                        );
                      }}
                    >
                      <IconButton>
                        <Link color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {providerOneLink && targetingRule.group && (
                    <Tooltip
                      title="Edit advertiser link group"
                      onClick={() => {
                        window.open(
                          window.location.origin +
                            "/advertiser-link-group/edit/" +
                            providerOneLink,
                          "_blank"
                        );
                      }}
                    >
                      <IconButton>
                        <Link color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label="Daily Cap"
                    name="daily_cap"
                    value={dailyCap === undefined ? "" : dailyCap}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value, originalIndex)
                    }
                    error={dailyCapError}
                    type="number"
                  />
                  {dailyCapError && (
                    <FormHelperText className="error-text">
                      Numeric value starting from 0 required!
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={2}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label="Weight(%)"
                    name="daily_frequency"
                    type="number"
                    value={dailyFrequency === undefined ? "" : dailyFrequency}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value, originalIndex)
                    }
                    disabled={targeting_type === TARGETING_TYPES.WATERFALL}
                    error={dailyFrequencyError}
                    className="min-width-field"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={2}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label={"Rev-Share (%)"}
                    name="revenue_share_percentage"
                    type="number"
                    value={
                      revenueSharePercentage === undefined
                        ? ""
                        : revenueSharePercentage
                    }
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value, originalIndex)
                    }
                    disabled={targeting_type === TARGETING_TYPES.WATERFALL}
                    error={revenueSharePercentageError}
                    className="min-width-field"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} md={6} lg={8}>
                {/* {stats && ( */}
                <div
                  className="rule__section__report"
                  style={{ textAlign: "right" }}
                >
                  <p>
                    Last reporting date:{" "}
                    {stats?.last_report?.date?.value ? (
                      stats.last_report.date.value
                    ) : (
                      <i>{NotAvailable}</i>
                    )}
                  </p>
                  <p>
                    Last RPM:{" "}
                    {stats?.last_report?.rpm ? (
                      formatNumberAddComma(stats.last_report.rpm)
                    ) : (
                      <i>{NotAvailable}</i>
                    )}
                  </p>
                  <p>
                    Last Revenue:{" "}
                    {stats?.last_report?.shared_revenue ? (
                      "$" +
                      formatNumberAddComma(stats.last_report.shared_revenue)
                    ) : (
                      <i>{NotAvailable}</i>
                    )}
                    {/* <span style={{ paddingLeft: "5px", color: "blue" }}>
                      {stats?.last_report?.estimated_revenue && isGroup
                        ? "(+$" +
                          formatNumberAddComma(
                            stats.last_report.estimated_revenue
                          ) +
                          ")"
                        : ""}
                    </span> */}
                  </p>
                  <p>
                    Searches:{" "}
                    {stats?.last_report?.search_count ? (
                      formatNumberAddComma(stats.last_report.search_count)
                    ) : (
                      <i>{NotAvailable}</i>
                    )}
                  </p>
                  <p>
                    <b>
                      Today's searches:{" "}
                      {stats?.today_search_count ? (
                        formatNumberAddComma(stats.today_search_count)
                      ) : (
                        <i>{NotAvailable}</i>
                      )}
                    </b>
                  </p>
                </div>
                {/* )} */}
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4}>
                <FormControlLabel
                  control={
                    <Tooltip
                      title={`${!disabled ? "Disable Rule" : "Enable Rule"}`}
                    >
                      <Switch
                        checked={!disabled}
                        onChange={() =>
                          handleDisableSwitch(originalIndex, disabled)
                        }
                        name="ruleChecked"
                      />
                    </Tooltip>
                  }
                />
                <Tooltip title="Archive">
                  <IconButton
                    className={rule_id ? "red-icon" : "gray-icon"}
                    onClick={() => {
                      rule_id && handleDeleteAction(originalIndex);
                    }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <span className="rule__section__closeBtn">
          <Tooltip title="Delete Rule">
            <IconButton
              className={!rule_id ? "red-icon" : "gray-icon"}
              onClick={() => {
                !rule_id && handleDeleteAction(originalIndex);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </span>
      </fieldset>
      {statusChangeOpen && (
        <ConfirmBox
          message="Disabling the rule will deactivate the publisher account related to this rule box, publisher account will be valid till yesterday.. Do you wish to proceed?"
          confirmAction={updateStatusChange}
          denyAction={closeAllModals}
        />
      )}
      {statusInformOpen && (
        <SimpleModal handleClose={closeAllModals}>
          <div className="rule-disable-inform">
            <p>
              Sorry, cannot enable the rule. This is done to maintain the
              integrity of the publisher ad accounts which are disabled. Please
              create a new rule if you wish to use the same values instead.
            </p>
          </div>
        </SimpleModal>
      )}
      {disableSwitchModal && (
        <ConfirmBox
          message="Current link properties do not match with advertiser link properties."
          confirmAction={() => setDisableSwitch(!disableSwitchModal)}
          hideCloseButton={true}
          affirmativeText="OK"
        />
      )}
    </form>
  );
};

export default Rule;
