import Api from "../api";
class PublisherApiService {
  fetchPublisherApiInfo = async (id = null) => {
    const { body } = await Api.get(`publisher/api/details/${id}`);
    return body;
  };
  fetchPublisherApiInfoV2 = async (id = null) => {
    const { body } = await Api.get(`api/publisher-details/${id}`);
    return body;
  };
  postPublisherApiInfo = async (data = {}, id = null) => {
    const { body } = await Api.post(`publisher/api/details/${id}`, data);
    return body;
  };
  regenerateTokeV2 = async (data = {}, id = null) => {
    const { body } = await Api.post(`api/generate-publisher-token/${id}`, data);
    return body;
  };
  fetchPublisherApiInfoByAccountId = async (id = null) => {
    const { body } = await Api.get(`publisher/api/details/account/${id}`);
    return body;
  };
  fetchPublisherApiInfoByAccountIdV2 = async (id = null) => {
    const { body } = await Api.get(`api/publisher-details-by-id/${id}`);
    return body;
  };
}
// eslint-disable-next-line
export default new PublisherApiService();
