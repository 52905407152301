import React, { useState, useEffect, useCallback } from "react";
import ProfileBasicInfo from "./layouts/ProfileBasicInfo";
import TabMenu from "../../components/TabMenu/TabMenu";
import ProfileSummary from "./layouts/ProfileSummary";
import ProfilePasswordForm from "./layouts/ProfilePasswordForm";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import { AccountCircle } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { User } from "../../services/User";
import { toastr } from "react-redux-toastr";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../helpers/AuthHelper";
import { changeNullToString } from "../../helpers/index";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ProfileNotification from "./layouts/ProfileNotification";

const Profile = () => {
  const [currentSelectedSectionIndex, setCurrentSelectedSectionIndex] =
    useState(0);
  const history = useHistory();
  const handleCancel = () => history.push("/");
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector(getCurrentUser);
  const [userId, set_UserId] = useState(null);
  const [profile, setProfile] = useState({
    first_name: "",
    last_name: "",
    profile_link: "",
    skype_details: "",
    email: "",
    contact_number: "",
    // username:'',
    role_id: null
  });
  const icon = <AccountCircle fontSize="inherit" color="primary" />;

  const updateProfileInfo = (user) => {
    setProfile({
      first_name: changeNullToString(user.first_name),
      last_name: changeNullToString(user.last_name),
      profile_link: changeNullToString(user.profile_link),
      skype_details: changeNullToString(user.skype_details),
      email: changeNullToString(user.email),
      contact_number: changeNullToString(user.contact_number),
      // username: changeNullToString(user.username),
      role_id: user?.role.id
    });
  };

  const fetchProfile = useCallback(
    (userID) => {
      setIsLoading(true);
      User.fetchUserProfile(userID)
        .then((response) => {
          if (response.success) {
            const user = response.data.user;
            updateProfileInfo(user);
            set_UserId(user.id);
            setIsLoading(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toastr.error("Opps", "Cannot Load Profile Data");
          history.push("/");
          console.trace(error.message);
        });
    },
    [history]
  );

  useEffect(() => {
    if (currentUser?.id) {
      fetchProfile(currentUser?.id);
    }
  }, [currentUser, fetchProfile]);

  const refreshProfileInfo = () => {
    fetchProfile(currentUser?.id);
  };

  const sections = [
    {
      name: "Home",
      component: <ProfileSummary profile={profile} />
    },
    {
      name: "Personal Info",
      component: (
        <ProfileBasicInfo
          handleCancel={handleCancel}
          profile={profile}
          currentUser={currentUser}
          refreshProfileInfo={refreshProfileInfo}
        />
      )
    },
    {
      name: "Password",
      component: (
        <ProfilePasswordForm handleCancel={handleCancel} userId={userId} />
      )
    },
    {
        name: "Notification",
        component: (
          <ProfileNotification />
        )
    },
  ];
  return (
    <div className="profile-container">
      <ContentHeader icon={icon} title="Profile" />
      <TabMenu
        sections={sections}
        setCurrentSelectedSectionIndex={setCurrentSelectedSectionIndex}
        currentSelectedSectionIndex={currentSelectedSectionIndex}
      />
      <div className="profile__section">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          sections[currentSelectedSectionIndex].component
        )}
      </div>
    </div>
  );
};

export default Profile;
