import { useState, useEffect, useCallback } from "react";
import { Provider } from "../../../../services/Provider";
import { useDispatch } from "react-redux";
import {
  updateProviders,
  updateDisabledProviders
} from "../../../../reducers/provider/provider.action";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../../../helpers/constant/misc";

/**
 * Logic to fetch providers and selected a particular provider*
 * @returns List of Providers, Selected Provider and Set Provider Function
 */
export const useGetProviders = () => {
  const [providers, setProviders] = useState([]);
  const [loadingProviders, setLoadingProviders] = useState(false);
  const [provider, setProvider] = useState("");
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);

  const fetchProviders = useCallback(
    (
      params = {
        order_by: "id",
        order_direction: "ASC",
        size: DEFAULT_FETCH_PARAM_SIZE
      }
    ) => {
      setLoadingProviders(true);
      Provider.fetchProviders(params)
        .then((response) => {
          if (response.success) {
            const data = response.data.providers;
            const providers = [];
            let inactiveProviders = [];
            for (let p of data) {
              let isInactive = p.status === "inactive";
              isInactive && inactiveProviders.push(p.id);
              providers.push({
                label: p.name,
                value: p.id,
                disabled: isInactive
              });
            }
            stableDispatch(updateDisabledProviders(inactiveProviders));
            stableDispatch(updateProviders(providers));
            setLoadingProviders(false);
            setProviders(providers);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          console.trace(error.message);
          setLoadingProviders(false);
        });
    },
    [stableDispatch]
  );
  // Loading Providers(Advertisers) on intial load
  useEffect(() => {
    fetchProviders();
  }, [fetchProviders]);

  return {
    providers,
    provider,
    setProvider,
    loadingProviders
  };
};
