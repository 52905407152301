import React from "react";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { useSelector } from "react-redux";

import ErrorBoundary from "../../../../components/ErrorBoundary/ErrorBoundary";
import SimpleAccordion from "../../../../components/Accordion/Accordion";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import ContentHeader from "../../../../components/ContentHeader/ContentHeader";
import { useFetchDisplayColumns } from "../../../usermanagemet/displayColumns/fetchDisplayColumns.logic";
import { getCurrentUser } from "../../../../helpers/AuthHelper";
import { useDisplayNameLogic } from "../../../mapping/display-name.logic";
import { useReportFiltersLogic } from "./report-filters.logic";
import { useDashboardData } from "./useDashboardData";
import ReportFallback from "../../../RevenueReport/ReportFallback/ReportFallback";
import FilterBox from "./FilterBox/FilterBox";
import { useReportTableFilterLogic } from "./report-table-filter.logic";
import { useReportDownload } from "./report-download-logic";
import ReportsBody from "./ReportsBody";

const PublisherDashboard = (publisherId = null) => {
  let currentUser = useSelector(getCurrentUser);
  //fields that the publisher can see, set by the administrator
  const { displayColumns, loading: loadingDisplayColumns } =
    useFetchDisplayColumns({ userId: currentUser.id, forAdmin: false });

  // UI display names for the publisher
  const { loadingDisplayNames, displayNames } = useDisplayNameLogic({
    userId: currentUser.id,
    forAdmin: false
  });

  const filterValuesLogic = useReportFiltersLogic({ displayColumns });

  const {
    filterValues,
    clearAllSelection,
    filtersClearToggle,
    selectionAddActions,
    dataLists
  } = filterValuesLogic;

  const dashboardDataHook = useDashboardData({
    filterValues,
    filtersClearToggle,
    dataLists
  });

  const { downloadReport } = useReportDownload({ filterValues });

  const { fetchDashboardData, loadingDashboardData } = dashboardDataHook;
  const list = dashboardDataHook?.dashboardData?.list || [];

  const tableLogicHook = useReportTableFilterLogic({
    displayNames,
    list
  });

  function clearAllFilters() {
    clearAllSelection();
    fetchDashboardData();
  }

  if (loadingDisplayColumns.isLoading || loadingDisplayNames)
    return (
      <div className="loading-div">
        <LoadingSpinner />
      </div>
    );

  if (displayColumns.length === 0 || !displayNames)
    return (
      <div className="noData_container">
        Unable to configure the dashboard.Please contact the administrator.
      </div>
    );

  return (
    <div className="flow-content">
      <ContentHeader
        icon={<MonetizationOnIcon fontSize="inherit" color="primary" />}
        title="Revenue Report"
      />
      <ErrorBoundary FallbackComponent={ReportFallback}>
        {/* <h2 className="dashboard-subheading">Report Filters</h2> */}
        <SimpleAccordion
          header="Report Filters"
          key="report-filter-selection"
          defaultExpanded={true}
        >
          <FilterBox
            handleSearch={fetchDashboardData}
            filterValuesLogic={filterValuesLogic}
            clearAllSelection={clearAllFilters}
            loadingDashboardData={loadingDashboardData}
            displayColumns={displayColumns}
          />
        </SimpleAccordion>
        <ReportsBody
          dashboardDataHook={dashboardDataHook}
          displayNames={displayNames}
          loadingDisplayNames={loadingDisplayNames}
          tableLogicHook={tableLogicHook}
          downloadReport={downloadReport}
          displayColumns={displayColumns}
          orderBy={filterValues.orderBy}
          orderDirection={filterValues.orderDirection}
          setOrderBy={selectionAddActions.setOrderBy}
          setOrderDirection={selectionAddActions.setOrderDirection}
        />
      </ErrorBoundary>
    </div>
  );
};

export default PublisherDashboard;
