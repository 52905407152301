import React from "react";
import PropTypes from "prop-types";

const StatCard = ({ title, children }) => (
  < div className="stat-card box-shadow" >
    <span className="stat-card__header">{title}</span>

    <p className="stat-card__children">{children}</p>
  </div >
);

StatCard.defaultProps = {
  title: "Card Title",
  children: <span>Card Stat</span>
};

StatCard.propTypes = {
  title: PropTypes.string,
};

export default StatCard;
