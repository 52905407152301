import * as authHelper from "./AuthHelper";

export const call = (handler, ...data) => {
    if (handler) {
        handler(...data);
    }
};

export const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    const selected =
        document.getSelection().rangeCount > 0
            ? document.getSelection().getRangeAt(0)
            : false;
    el.select();
    const success = document.execCommand("copy");
    document.body.removeChild(el);
    if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
    }
    return success;
};

export const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getNDayBeforeDate = (daysBefore) => {
    var d = new Date();
    d.setDate(d.getDate() - daysBefore);
    return d;
};

export function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

export function changeNullToString(value) {
    if (!value) return "";
    return value;
}

export function changeEmptyStringToNull(value) {
    if (!value) return null;
    if (value.trim() === "") {
        return null;
    }
    return value;
}

export function getKeyByValue(object, value) {
    if (!object) return "";
    return Object.keys(object).find((key) => object[key] === value);
}

export const auth = authHelper;
export const user = authHelper;
export const API_URL = process.env.REACT_APP_API_URL;
export const GCP_BACKEND_URL = process.env.REACT_APP_GCP_BACKEND_URL;
/**
 * Helper function to find object from array of the form using the value
 * [{value:'some value', label:'some label'}]
 */
export const findObject = (array, value) => {
    return array.find((element) => element.value === value);
};

/**
 * Remove element from array using index
 * */

export const removeElementFromArray = (array, index) => {
    if (index === -1) return array;
    let outputArray = [];
    if (index === 0) {
        outputArray = outputArray.concat(array.slice(1));
    } else if (index === array.length - 1) {
        outputArray = outputArray.concat(array.slice(0, -1));
    } else if (index > 0) {
        outputArray = outputArray.concat(
            array.slice(0, index),
            array.slice(index + 1)
        );
    }
    return outputArray;
};

/* gets label from [{value:"",label:""}] form of arrays */
export function getLabel(array, value) {
    let foundObject = findObject(array, value);
    return foundObject.label;
}

/** function to find and remove value from an array */
export function removeValueFromArray(array, value) {
    let index = array.indexOf(value);
    return removeElementFromArray(array, index);
}

/** function to get the domain of the url */
export function getDomain(url, subdomain) {
    subdomain = subdomain || false;

    url = url.replace(/(https?:\/\/)?(www.)?/i, "");

    if (!subdomain) {
        url = url.split(".");

        url = url.slice(url.length - 2).join(".");
    }

    if (url.indexOf("/") !== -1) {
        return url.split("/")[0];
    }

    return url;
}
