import React, { useEffect, useState, useCallback } from "react";
import ContentHeader from "../../components/ContentHeader/ContentHeader";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import { useHistory, useLocation } from "react-router-dom";
import { Prompt } from "react-router-dom";
import { Provider } from "../../services/Provider";
import ProviderLink from "./ProviderLink";
import {
  capitalizeFirstLetter,
  changeEmptyStringToNull
} from "../../helpers/index";
import { useSelector, useDispatch } from "react-redux";
import {
  updateProviderLinks,
  updateDeletedProviderLinks,
  updateProviderLinksError,
  clearProviderLinksAndErrors
} from "../../reducers/provider/provider.action";
import {
  getProviderLinks,
  getProviderLinksError
  // getDeletedProviderLinks
} from "../../reducers/provider/provider.selector";
import { toastr } from "react-redux-toastr";
import { validURL } from "../../helpers/validation";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useVerifyingProviderLinks } from "./verifying-links.logic";
import DuplicateWarningModal from "./layouts/DuplicateWarningModal";
// import VerifyingMessage from "./layouts/VerifyingMessage";
import { sortProviderLinks, clearLinkErrorValues } from "./helpers";
import { useFormTouched } from "../../hooks/useFormTouched";
import AutoCompleteSelect from "../../components/AutoCompleteSelect/AutoCompleteSelect";
import { DEFAULT_FETCH_PARAM_SIZE } from "../../helpers/constant/misc";
import { useFetchPlatforms } from "./logics/fetchPlatforms.logic";
import { useFetchSearchEngines } from "./logics/fetchSearchEngines.logic";
import { useFetchTagTypes } from "./logics/fetchTagTypes.logic";
const ProviderLinks = () => {
  const icon = <AllInclusiveIcon fontSize="inherit" color="primary" />;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const providerLinks = useSelector(getProviderLinks);
  const providerLinksError = useSelector(getProviderLinksError);
  // const deletedProviderLinks = useSelector(getDeletedProviderLinks);
  const [showPrompt, setShowPrompt] = useState(true);
  const [provider, setProvider] = useState(() => {
    return location?.state?.provider || "";
  });
  const [providers, setProviders] = useState([]);
  const [providerChangeId, setProviderChangeId] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDuplicateWarning, setOpenDuplicateWarning] = useState(false);
  const [isLoadingLinks, setIsLoadingLinks] = useState(false);
  const { platforms, loadingPlatforms } = useFetchPlatforms();
  const { searchEngines, loadingSearchEngines } = useFetchSearchEngines();
  const { tagTypes, loadingTagTypes } = useFetchTagTypes();
  const [submittingForm, setSubmittingForm] = useState(false);

  const {
    // currentlyVerifying,
    setCurrentlyVerifying,
    checkFrontEndIdenticalLinks,
    frontEndDuplicates,
    backEndDuplicates,
    clearAllVerifyingStateValues,
    checkBackEndIdenticalLinks
  } = useVerifyingProviderLinks();

  const handleModalClose = () => {
    setOpen(false);
    setOpenDuplicateWarning(false);
    clearAllVerifyingStateValues();
    setCurrentlyVerifying(false);
    setProviderChangeId(false);
    setSubmittingForm(false);
  };

  const cleanupFunction = useCallback(() => {
    stableDispatch(clearProviderLinksAndErrors());
  }, [stableDispatch]);

  const { formTouched, handleFormTouched, clearFormTouched } = useFormTouched({
    cleanupFunction
  });

  /**
   * loading providers(advertisers)
   */
  const fetchProviders = useCallback(() => {
    Provider.fetchProviders({
      order_by: "id",
      order_direction: "ASC",
      size: DEFAULT_FETCH_PARAM_SIZE
    })
      .then((response) => {
        if (response.success) {
          const data = response.data.providers;
          const providers = [];
          for (let p of data) {
            providers.push({
              label: p.name,
              value: p.id,
              status: p.status
            });
          }
          setProviders(providers);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
      });
  }, []);

  useEffect(() => {
    fetchProviders();
  }, [fetchProviders]);
  /**loading provider links when different publisher is selected */

  const fetchProviderLinks = useCallback(
    (provider) => {
      setIsLoadingLinks(true);
      Provider.fetchProviderLinks(provider)
        .then((response) => {
          if (response.success) {
            const providerLinks = response.data.provider.provider_links;
            const sortedProviderLinks = sortProviderLinks(providerLinks);
            stableDispatch(updateProviderLinks(sortedProviderLinks));
            stableDispatch(updateDeletedProviderLinks([]));
            stableDispatch(
              updateProviderLinksError(
                clearLinkErrorValues(providerLinks.length)
              )
            );
            setIsLoadingLinks(false);
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          setIsLoadingLinks(false);
          console.trace(error.message);
        });
    },
    [stableDispatch]
  );

  useEffect(() => {
    if (provider) {
      fetchProviderLinks(provider);
    } else {
      stableDispatch(updateProviderLinks([]));
      stableDispatch(updateProviderLinksError([]));
    }
  }, [provider, stableDispatch, fetchProviderLinks]);

  const handleProviderChange = (value) => {
    //if no provider selected or form not touched, change the provider without the confirm message
    if (provider === "" || !formTouched.current) {
      setProvider(value);
      return;
    }
    setProviderChangeId(value);
    setOpen(true);
  };

  const changeProvider = () => {
    setProvider(providerChangeId);
    handleModalClose();
    clearFormTouched();
  };

  const handleCancel = () => {
    history.push("/advertisers/links");
  };

  const formValidate = (formData, formErrorData) => {
    let isValid = true;
    let formErrors = [...formErrorData];
    if (formData.length <= 0) {
      toastr.warning("Oops!", "Please add link before saving!");
      isValid = false;
      return { isValid, formErrors };
    }
    formData.forEach((data, index) => {
      formErrors[index] = {
        link: false,
        search_engine_type: false,
        p_sid: false,
        search_engine_id: false,
        platform_id: false,
        tag_type_id: false
      };
      if (!validURL(data.link)) {
        isValid = false;
        formErrors[index].link = true;
      }

      if (validURL(data.link)) {
        if (
          data.link.includes("{") ||
          data.link.includes("}") ||
          data.link.includes("(") ||
          data.link.includes(")")
        ) {
          isValid = false;
          formErrors[index].link = true;
        }

        if (!data.link.includes("[Q]")) {
          isValid = false;
          formErrors[index].link = true;
        }
      }

      if (!data["search_engine_id"]) {
        isValid = false;
        formErrors[index].search_engine_id = true;
      }
      if (!data["platform_id"]) {
        isValid = false;
        formErrors[index].platform_id = true;
      }
      if (!data["tag_type_id"]) {
        isValid = false;
        formErrors[index].tag_type_id = true;
      }
    });

    return { isValid, formErrors };
  };

  const formatProviderLinkData = (data) => {
    let providerLink = { ...data };
    if (!providerLink.searchq_val) providerLink.searchq_val = null;
    if (!providerLink.n_val) providerLink.n_val = null;
    if (!providerLink.sub_id_val) providerLink.sub_id_val = null;
    providerLink.description = changeEmptyStringToNull(
      providerLink.description
    );
    if (!providerLink.click_id_val) providerLink.click_id_val = null;
    if (providerLink.p_sid) {
      providerLink.p_sid = parseInt(providerLink.p_sid);
    } else {
      providerLink.p_sid = null;
    }
    if (providerLink.hasOwnProperty("no_of_rules")) {
      delete providerLink.no_of_rules;
    }
    if (providerLink.hasOwnProperty("no_of_active_rules")) {
      delete providerLink.no_of_active_rules;
    }
    if (providerLink.hasOwnProperty("targetObj")) {
      delete providerLink.targetObj;
    }
    if (providerLink.hasOwnProperty("linkIndex")) {
      delete providerLink.linkIndex;
    }
    providerLink.search_engine_type = null;
    return providerLink;
  };

  const filterFormMappedData = (providerLinks) => {
    let filteredProviderLinks = providerLinks.map((providerLink) => {
      return formatProviderLinkData(providerLink);
    });
    return filteredProviderLinks;
  };

  const submitLinks = (filteredFormData) => {
    setShowPrompt(false);
    formTouched.current = false;
    Provider.saveProviderLinks(
      provider,
      filteredFormData.map((item) => ({
        click_id_val: item.click_id_val,
        description: item.description,
        disabled: item.disabled,
        id: item.id,
        link: item.link,
        n_val: item.n_val,
        p_sid: item.p_sid,
        platform_id: item.platform_id,
        provider_id: item.provider_id,
        search_engine_id: item.search_engine_id,
        search_engine_type: item.search_engine_type,
        searchq_val: item.searchq_val,
        sub_id_val: item.sub_id_val,
        tag_type_id: item.tag_type_id,
        quality_score: item.quality_score,
        link_source_identifier: item.link_source_identifier,
        link_source_identifier_value: item.link_source_identifier_value
      }))
    )
      .then((response) => {
        if (response.success) {
          fetchProviderLinks(provider);
          setCurrentlyVerifying(false);
          toastr.success("Success", "Advertiser link saved!");
          setSubmittingForm(false);
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        setCurrentlyVerifying(false);
        toastr.error("Oops!", error.message);
        setSubmittingForm(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmittingForm(true);
    let filteredFormData = filterFormMappedData(providerLinks);
    let { isValid, formErrors } = formValidate(
      filteredFormData,
      providerLinksError
    );
    dispatch(updateProviderLinksError(formErrors));
    if (!isValid) {
      toastr.warning("Oops!", "Please fix the errors before saving!");
      setShowPrompt(true);
      setSubmittingForm(false);
      return;
    }
    //set Verifying Identifying Links to true
    setCurrentlyVerifying(true);
    //checkFront end Duplicate Links
    const { hasDuplicatesInForm } =
      checkFrontEndIdenticalLinks(filteredFormData);
    //check back end Duplicate Links
    const { hasDuplicatesInBackend } = await checkBackEndIdenticalLinks(
      provider,
      filteredFormData
    );
    // If no duplicate links, submit Links
    if (!hasDuplicatesInForm && !hasDuplicatesInBackend) {
      submitLinks(filteredFormData);
    } else {
      // If duplicate Links, open confirmation model box
      setOpenDuplicateWarning(true);
    }
  };

  const getCurrentProviderStatus = (providerId) => {
    //when providers are loaded
    if (providers.length > 0) {
      let providerIndex = providers.findIndex(
        (provider) => provider.value === providerId
      );
      //if provider is found
      if (providerIndex !== -1) {
        return providers[providerIndex]?.status;
      }
    }
    return "";
  };

  return (
    <>
      <div className="provider-links">
        <div className="provider-links__header">
          <ContentHeader icon={icon} title="Advertiser Links" />
        </div>
        {/* {currentlyVerifying && <VerifyingMessage />} */}
        {(loadingPlatforms.isLoading ||
          loadingTagTypes.isLoading ||
          loadingSearchEngines.isLoading) && (
          <div className="loading-div">
            <LoadingSpinner />
          </div>
        )}
        {!loadingPlatforms.isLoading &&
          !loadingSearchEngines.isLoading &&
          !loadingTagTypes.isLoading && (
            <form className="provider-links__form  white-border-box flow-content">
              <div className="provider-links__form__header">
                <div className="provider-links__form__provider">
                  <AutoCompleteSelect
                    selected={provider ? provider : ""}
                    data={providers}
                    handleChange={(value) => handleProviderChange(value)}
                    label={"Advertiser"}
                    showValueInLabel={true}
                    enableMultiple={false}
                    withDotsForStatus={true}
                    cannotSelectDisabled={false}
                  />
                </div>
                <div className="provider-links__form__provider-info">
                  <span>ID</span>
                  <span>{provider}</span>
                </div>
                <div className="provider-links__form__provider-info">
                  <span>Status</span>
                  <span>
                    {capitalizeFirstLetter(getCurrentProviderStatus(provider))}
                  </span>
                </div>
              </div>
              {isLoadingLinks ? (
                <div className="loading-div">
                  <LoadingSpinner />
                </div>
              ) : (
                <ProviderLink
                  currentSelectedProvider={provider}
                  handleFormTouched={handleFormTouched}
                  handleSubmit={handleSubmit}
                  handleCancel={handleCancel}
                  platforms={platforms}
                  tagTypes={tagTypes}
                  searchEngines={searchEngines}
                  submittingForm={submittingForm}
                />
              )}
            </form>
          )}
        {openDuplicateWarning && (
          <DuplicateWarningModal
            frontEndDuplicates={frontEndDuplicates}
            backEndDuplicates={backEndDuplicates}
            denyAction={handleModalClose}
            confirmAction={() => {
              submitLinks(filterFormMappedData(providerLinks));
              handleModalClose();
            }}
          />
        )}
        {open && (
          <ConfirmBox
            message="Changes made will not be saved. Are you sure you want to select another advertiser?"
            confirmAction={changeProvider}
            denyAction={handleModalClose}
          />
        )}
        <Prompt
          when={showPrompt && formTouched.current}
          message="Are you sure you want to leave?"
        />
      </div>
    </>
  );
};

export default ProviderLinks;
