import React, { useState } from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useSelector, useDispatch } from "react-redux";
import {
  getProviderLinks,
  // getDeletedProviderLinks,
  getProviderLinksError
} from "../../reducers/provider/provider.selector";
import {
  updateProviderLinks,
  // updateDeletedProviderLinks,
  updateProviderLinksError
} from "../../reducers/provider/provider.action";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import { clearLinkErrorValues, getActiveRules } from "./helpers";
import { toastr } from "react-redux-toastr";
import { Provider } from "../../services/Provider";
import HelpIcon from "@material-ui/icons/Help";
import InfoToolTip from "../../components/InfoToolTip/InfoToolTip";
import { useModal } from "../../hooks/useModal";
import RelatedProviderLinkWarningModal from "./RelatedProviderLinkWarningModal";
import TextField from "@material-ui/core/TextField";
import { useFilterProviderLinks } from "./logics/filterProviderLinks.logic";
import Links from "./Links";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import ParamInfo from "./layouts/ParamInfo";
const ProviderLink = ({
  currentSelectedProvider,
  handleFormTouched,
  handleSubmit,
  handleCancel,
  tagTypes,
  searchEngines,
  platforms,
  submittingForm
}) => {
  const providerLinks = useSelector(getProviderLinks);
  // const deletedProviderLinks = useSelector(getDeletedProviderLinks);
  const providerLinksError = useSelector(getProviderLinksError);
  const dispatch = useDispatch();
  const [deleteIndex, setDeleteIndex] = useState(null); //index to be deleted
  const [open, setOpen] = useState(false); //delete modal

  const [warningModalOpen, openWarningModal, closeWarningModal] = useModal();
  const [relatedActiveRules, setRelatedActiveRules] = useState([]);

  const {
    filteredProviderLinks,
    filterText,
    onFilterTextChange,
    setFilterText
  } = useFilterProviderLinks(providerLinks, providerLinksError);

  function closeModal() {
    closeWarningModal();
    setRelatedActiveRules();
  }

  const addMoreLink = () => {
    const newLink = {
      link: "",
      search_engine_type: "",
      p_sid: "",
      searchq_val: "",
      n_val: "",
      sub_id_val: "",
      click_id_val: "",
      tag_type_id: "",
      search_engine_id: "",
      platform_id: "",
      disabled: false,
      description: "",
      quality_score: null,
    };
    const linkError = {
      link: false,
      search_engine_type: false,
      p_sid: false
    };
    const updatedProviderLinks = [...providerLinks];
    updatedProviderLinks.push(newLink);
    const updatedLinkErrors = [...providerLinksError];
    updatedLinkErrors.push(linkError);
    dispatch(updateProviderLinks(updatedProviderLinks));
    dispatch(updateProviderLinksError(updatedLinkErrors));
  };

  /**
   * F orm Component Change Handlers
   */
  const handleLinkFormValueChange = (event, index) => {
    handleFormTouched();
    if (event.target.name === "disabled") {
      handleStatusChange(event, index);
      return;
    }
    if (event.target.name === "search_engine") {
      handleSearchEngineChange(event.target.value, index);
      return;
    }
    if (event.target.name === "tag_type") {
      handleTagTypeChange(event.target.value, index);
      return;
    }
    if (event.target.name === "platform") {
      handlePlatformChange(event.target.value, index);
      return;
    }
    if(event.target.name === "quality_score"){
      handleQualityScoreChange(event.target.value, index);
      return;
    }
    let newProviderLinks = [...providerLinks];
    let currentLinkValue = newProviderLinks[index] || {};
    currentLinkValue[event.target.name] = event.target.value;
    newProviderLinks[index] = currentLinkValue;
    dispatch(updateProviderLinks(newProviderLinks));
  };

  const handleSearchEngineChange = (value, index) => {
    let newProviderLinks = [...providerLinks];
    let currentLinkValue = newProviderLinks[index] || {};
    currentLinkValue["search_engine_id"] = value;
    newProviderLinks[index] = currentLinkValue;
    dispatch(updateProviderLinks(newProviderLinks));
  };

  const handleTagTypeChange = (value, index) => {
    let newProviderLinks = [...providerLinks];
    let currentLinkValue = newProviderLinks[index] || {};
    currentLinkValue["tag_type_id"] = value;
    newProviderLinks[index] = currentLinkValue;
    dispatch(updateProviderLinks(newProviderLinks));
  };

  const handleQualityScoreChange = (value, index) => {
    let newProviderLinks = [...providerLinks];
    let currentLinkValue = newProviderLinks[index] || {};
    currentLinkValue["quality_score"] = value;
    newProviderLinks[index] = currentLinkValue;
    dispatch(updateProviderLinks(newProviderLinks));
  };

  const handlePlatformChange = (value, index) => {
    let newProviderLinks = [...providerLinks];
    let currentLinkValue = newProviderLinks[index] || {};
    currentLinkValue["platform_id"] = value;
    newProviderLinks[index] = currentLinkValue;
    dispatch(updateProviderLinks(newProviderLinks));
  };

  const handleStatusChange = (event, index) => {
    if (!event.target.checked && providerLinks[index].id) {
      if (parseInt(providerLinks[index]?.no_of_active_rules) > 0) {
        setRelatedActiveRules(
          getActiveRules(providerLinks[index]?.targetObj || [])
        );
        openWarningModal();
        return;
      }
    }
    let newProviderLinks = [...providerLinks];
    let currentLinkValue = newProviderLinks[index] || {};
    currentLinkValue[event.target.name] = !event.target.checked;
    newProviderLinks[index] = currentLinkValue;
    dispatch(updateProviderLinks(newProviderLinks));
  };

  const handleDeleteAction = (index) => {
    if (!providerLinks[index]) {
      return;
    }
    if (providerLinks[index].id) {
      if (parseInt(providerLinks[index]?.no_of_rules) > 0) {
        toastr.info("Oops", "Link is currently used in some targeting rule!");
        return;
      }
      setDeleteIndex(index);
      setOpen(true);
    } else {
      let newProviderLinks = [...providerLinks];
      newProviderLinks.splice(index, 1);
      dispatch(updateProviderLinks(newProviderLinks));
      dispatch(
        updateProviderLinksError(clearLinkErrorValues(newProviderLinks.length))
      );
      setDeleteIndex(null);
    }
  };

  const deletePublisherLink = () => {
    Provider.deleteProviderLink(providerLinks[deleteIndex].id)
      .then((response) => {
        if (response.success) {
          let newProviderLinks = [...providerLinks];
          newProviderLinks.splice(deleteIndex, 1);
          dispatch(updateProviderLinks(newProviderLinks));
          dispatch(
            updateProviderLinksError(
              clearLinkErrorValues(newProviderLinks.length)
            )
          );
          toastr.success("Success", "Advertiser link removed!");
        }
      })
      .catch((error) => {
        toastr.error("Oops", error.message);
        console.trace(error.message);
        setDeleteIndex(null);
      });
  };

  return (
    <>
      <div className="provider-links__helpers">
        <h4>Supported Dynamic Parameters</h4>
        <InfoToolTip title={<ParamInfo />} className="targeting-option__info">
          <div className="gray-icon ">
            <HelpIcon fontSize="small" />
          </div>
        </InfoToolTip>
      </div>
      <div className="flow-content">
        <TextField
          variant="outlined"
          margin="normal"
          label="Type to filter through advertisers"
          onChange={onFilterTextChange}
          value={filterText}
          fullWidth
          className="csv-upload__filterInput"
        />
        <div>
          <Links
            filteredProviderLinks={filteredProviderLinks}
            providerLinksError={providerLinksError}
            handleDeleteAction={handleDeleteAction}
            handleLinkFormValueChange={handleLinkFormValueChange}
            searchEngines={searchEngines}
            tagTypes={tagTypes}
            platforms={platforms}
          />
        </div>
        <div>
          {!filterText && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={addMoreLink}
              disabled={!currentSelectedProvider}
            >
              Add more link
            </Button>
          )}
        </div>
        <div className="provider-links__form__buttons">
          <Button
            color="primary"
            onClick={(e) => {
              setFilterText("");
              handleSubmit(e);
            }}
            variant="contained"
            size="medium"
            startIcon={<CheckIcon />}
            type="submit"
            disabled={submittingForm}
          >
            {" "}
            Save
          </Button>
          <Button
            onClick={handleCancel}
            variant="contained"
            size="medium"
            color="secondary"
            startIcon={<ClearIcon />}
          >
            Cancel
          </Button>
        </div>
        {open && (
          <ConfirmBox
            message="Are you sure you want to delete?"
            confirmAction={() => {
              deletePublisherLink();
              setOpen(false);
            }}
            denyAction={() => setOpen(false)}
          />
        )}
        <RelatedProviderLinkWarningModal
          relatedActiveRules={relatedActiveRules || []}
          modalOpen={warningModalOpen}
          closeModal={closeModal}
        />
      </div>
    </>
  );
};

export default ProviderLink;
