import { useState, useEffect, useCallback } from "react";
import { toastr } from "react-redux-toastr";

import { User } from "../../../services/ReportingUser";
import { useIsMounted } from "../../../hooks/useIsMounted";

export const useFetchDisplayColumns = ({ userId, forAdmin = true }) => {
  const [displayColumns, setDisplayColumns] = useState([]);
  const [loading, setLoading] = useState({
    isLoading: !forAdmin,
    error: false
  });
  const isMounted = useIsMounted();

  const getPublisherDisplayColumns = useCallback(
    async (userId) => {
      try {
        setLoading({ isLoading: true, error: false });
        const displayColumns = await User.fetchPublisherDisplayColumns(userId);
        if (displayColumns.success && isMounted()) {
          setLoading({ isLoading: false, error: false });
          setDisplayColumns(
            displayColumns?.data?.publisherDisplayFields?.fields
          );
        } else {
          setDisplayColumns([]);
          throw new Error(displayColumns.error.message);
        }
      } catch (error) {
        if (isMounted()) {
          setLoading({ isLoading: false, error: true });
          if (forAdmin) {
            console.trace(error.message);
            toastr.warning("Oops!", error.message);
          }
        }
      }
    },
    [isMounted, forAdmin]
  );

  useEffect(() => {
    if (userId) {
      getPublisherDisplayColumns(userId);
    }
  }, [userId, getPublisherDisplayColumns]);

  return {
    displayColumns,
    loading
  };
};
