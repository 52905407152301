import { TargetActionTypes } from "./action";

const INITIAL_STATE = {
  list: [],
  rules: [],
  rulesError: [],
  targetingLists: [],
  deletedRules: [],
  oldRules: [],
  advertiserLinkGroup: {
    active: true
  },
  advertiserLinkGroupLists: [],
  selectedFilter: {
    query: "",
    publisher: "",
    isLive: null,
    tagId: "",
    published: false,
    draft: false
  }
};

const targetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TargetActionTypes.UPDATE_ADVERTISER_GROUP_RULES:
      return {
        ...state,
        rules: action.payload
      };
    case TargetActionTypes.UPDATE_ADVERTISER_GROUP_FORM_ERRORS:
      return {
        ...state,
        rulesError: action.payload
      };
    case TargetActionTypes.CLEAR_ADVERTISER_GROUP_FORM:
      return {
        ...state,
        description: "",
        status: "draft",
        active: true,
        rules: [],
        rulesError: [],
        deletedRules: []
      };
    case TargetActionTypes.UPDATE_ADVERTISER_GROUP_FORM:
      return {
        ...state,
        ...action.payload
      };
    case TargetActionTypes.UPDATE_ADVERTISER_GROUP_LISTS:
      return {
        ...state,
        list: action.payload
      };
    case TargetActionTypes.UPDATE_DELETED_ADVERTISER_GROUP_RULES:
      return {
        ...state,
        rulesError: action.payload
      };
    case TargetActionTypes.SET_TARGET_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: action.payload
      };
    default:
      return { ...state };
  }
};

export default targetReducer;
