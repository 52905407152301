import { useEffect, useState } from "react";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { Domain } from "../../../services/Domain";

export const useFetchDomain = () => {
  const [domainData, setDomainData] = useState([]);
  const [loadingDomainData, setLoadingDomainData] = useState({
    isLoading: false,
    error: false
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [total, setTotal] = useState(0);

  function getParams() {
    let params = {};
    if (orderBy) {
      params["order_by"] = orderBy;
    }
    params["order_direction"] = orderDirection;

    params["page"] = page;
    params["size"] = size;

    return params;
  }

  const isMounted = useIsMounted();

  async function getDomains() {
    try {
      setLoadingDomainData({ isLoading: true, error: false });
      const data = await Domain.fetchDomainList(getParams());
      if (data.success && isMounted()) {
        setDomainData(data.data.domains.rows);
        setTotal(data.data.domains.count);
        setLoadingDomainData({ isLoading: false, error: false });
      } else {
        throw new Error(data.error.message);
      }
    } catch (error) {
      if (isMounted()) {
        setDomainData([]);
        setLoadingDomainData({ isLoading: false, error: true });
      }
      console.trace(error.message || error);
    }
  }

  useEffect(() => {
    getDomains();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, orderBy, orderDirection]);

  return {
    loadingDomainData,
    domainData,
    page,
    setPage,
    size,
    setSize,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    total,
    getDomains
  };
};
