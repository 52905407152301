const ProviderActionTypes = {
  FETCH_PROVIDER_START: "FETCH_PROVIDER_START",
  FETCH_PROVIDER_SUCCESS: "FETCH_PROVIDER_SUCCESS",
  FETCH_PULISHER_FAILURE: "FETCH_PROVIDER_FAILURE",
  ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
  UPDATE_PROVIDERS: "UPDATE_PROVIDERS",
  FETCH_PROVIDERS_LIST_START: "FETCH_PROVIDERS_LIST_START",
  FETCH_PROVIDERS_LIST_FAILURE: "FETCH_PROVIDERS_LIST_FAILURE",
  FETCH_PROVIDERS_LIST_SUCCESS: "FETCH_PROVIDERS_LIST_SUCCESS",
  UPDATE_DISABLED_PROVIDERS_LIST: "UPDATE_DISABLED_PROVIDERS_LIST",
  UPDATE_PROVIDER_LINKS: "UPDATE_PROVIDER_LINKS",
  UPDATE_DELETED_PROVIDER_LINKS: "UPDATE_DELETED_PROVIDER_LINKS",
  UPDATE_PROVIDER_LINKS_ERRORS: "UPDATE_PROVIDER_LINKS_ERROR",
  CLEAR_PROVIDER_LINKS_AND_ERRORS: "CLEAR_PROVIDER_LINKS_AND_ERRORS",
  SET_PROVIDER_SELECTED_FILTER: "SET_PROVIDER_SELECTED_FILTER",
};

export default ProviderActionTypes;
