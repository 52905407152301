import React from "react";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { API_URL } from "../../../../helpers";
import { copyToClipboard } from "../../../../helpers";
import { toastr } from "react-redux-toastr";
import moment from "moment";

const Details = (props) => {
  const { dataNew } = props;
  const system = process.env.REACT_APP_SYSTEM;
  const NINAYA_REPORT_URL = API_URL + "api/publisher-revenue";
  const CONSIGO_REPORT_URL = API_URL + "api/v1/report";
  const reportURL =
    system === "consigo" ? CONSIGO_REPORT_URL : NINAYA_REPORT_URL;

  function createData(params, format, example) {
    return { params, format, example };
  }

  const from_date = moment().startOf("month").format("YYYY-MM-DD");
  const to_date = moment().format("YYYY-MM-DD");

  const rows = [
    createData("from_date", "YYYY-MM-DD", from_date),
    createData("to_date", "YYYY-MM-DD", to_date),
    createData("format", "JSON/CSV", "JSON"),
    createData("api_key", "String", dataNew.token)
  ];

  const successResponse = {
    ninaya: {
      success: true,
      msg: "Revenue reports",
      data: [
        {
          Date: from_date,
          "Total Searches": 6701,
          Clicks: 6341,
          Subid: 71,
          "Publisher ID": dataNew.id || 7,
          "Monetized Searches": 5748,
          Country: "RU",
          "Net Revenue": 345.78
        },
        {
          Date: to_date,
          "Total Searches": 7765,
          Clicks: 59,
          Subid: 71,
          "Publisher ID": dataNew.id || 7,
          "Monetized Searches": 7114,
          Country: "US",
          "Net Revenue": 457.89
        }
      ]
    },
    consigo: {
      success: true,
      data: [
        {
          Date: from_date,
          Subid: 71,
          Country: "RU",
          "Total Searches": 1210,
          "Monetized Searches": 981,
          Clicks: 1098,
          "Net Revenue": 133.16
        },
        {
          Date: to_date,
          Subid: 71,
          Country: "US",
          "Total Searches": 1151,
          "Monetized Searches": 881,
          Clicks: 959,
          "Net Revenue": 97.33
        }
      ]
    }
  };

  const failed_response = {
    error: {
      code: 401,
      message: "Unauthorized"
    },
    success: false
  };

  const handleCopyTagToClipboard = (value) => {
    copyToClipboard(value);
    toastr.success("Success", "Link copied!");
  };

  return (
    <div className="publisherapi-body">
      <div className="body-content">
        {dataNew.token ? (
          <div className="content">
            <div className="left">Api Token :</div>
            <div className="right hover-cell">
              <span>{dataNew.token}</span>
              <button
                onClick={() => {
                  handleCopyTagToClipboard(dataNew?.token);
                }}
                className="hover-cell__button btn"
              >
                Copy Token
              </button>
            </div>
          </div>
        ) : null}
        {dataNew.token ? (
          <div className="content">
            <div className="left">Base URl :</div>
            <div className="right hover-cell">
              {reportURL}
              <button
                onClick={() => {
                  handleCopyTagToClipboard(reportURL);
                }}
                className="hover-cell__button btn"
              >
                Copy Base URL
              </button>
            </div>
          </div>
        ) : null}
        <div className="content">
          <div className="left">Required Params :</div>
          <div className="right">
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Params</TableCell>
                    <TableCell align="right">Format</TableCell>
                    <TableCell align="right">Example</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.params}>
                      <TableCell component="th" scope="row">
                        {row.params}
                      </TableCell>
                      <TableCell align="right">{row.format}</TableCell>
                      <TableCell align="right">{row.example}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {dataNew.token ? (
          <div className="content">
            <div className="left">Example Request Url :</div>
            <div className="right hover-cell">
              {reportURL}?from_date={from_date}&to_date={to_date}&api_key=
              {dataNew?.token}&format=JSON
              <button
                onClick={() => {
                  handleCopyTagToClipboard(
                    reportURL +
                      "?from_date=" +
                      from_date +
                      "&to_date=" +
                      to_date +
                      "&api_key=" +
                      dataNew?.token +
                      "&format=JSON"
                  );
                }}
                className="hover-cell__button btn"
              >
                Copy URL
              </button>
            </div>
          </div>
        ) : null}
        <div className="content">
          <div className="left">Success Response :</div>
          <div className="right">
            <pre>{JSON.stringify(successResponse[system], null, 2)}</pre>
          </div>
        </div>
        <div className="content">
          <div className="left">Failed Response :</div>
          <div className="right">
            <pre>{JSON.stringify(failed_response, null, 2)}</pre>
          </div>
        </div>
        <Divider />
        <div className="footer">
          <div className="content">
            <div className="left">Note :</div>
            <div className="right">
              Please, read the message and try to fix the problem. In case you
              can’t fix it, please, contact the company representative
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
