import React from "react";
import PropTypes from "prop-types";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { GetApp } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";

const FilterActionButtons = ({
  clearAllSelection,
  handleSearch,
  loadingDashboardData,
  dashboardDataHook,
  downloadReport
}) => {
  return (
    <div className="dashboard-report__filters__action-buttons">
      <Button
        variant="contained"
        size="medium"
        color={loadingDashboardData ? "default" : "primary"}
        disabled={loadingDashboardData}
        onClick={(event) => {
          if (dashboardDataHook && dashboardDataHook.setPage) {
            dashboardDataHook.setPage(1);
          }
          handleSearch();
        }}
        startIcon={<SearchIcon />}
      >
        Search Revenue
      </Button>
      <Button
        variant="contained"
        size="medium"
        color={loadingDashboardData ? "default" : "secondary"}
        disabled={loadingDashboardData}
        onClick={() => {
          clearAllSelection();
        }}
        startIcon={<ClearAllIcon />}
      >
        Reset All
      </Button>
      <Button
        variant="contained"
        size="medium"
        onClick={downloadReport}
        color={loadingDashboardData ? "default" : "primary"}
        disabled={loadingDashboardData}
        startIcon={<GetApp />}
        className="download-btn"
      >
        Download
      </Button>
    </div>
  );
};

FilterActionButtons.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  clearAllSelection: PropTypes.func.isRequired
};

export default FilterActionButtons;
