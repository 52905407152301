/**
 * Custom Hook to redirect to report dashboard and search logs page
 * along with the required params (Rule ID, date)
 */

import {
  ADMIN_REPORT_RULE_ID,
  ADMIN_LOG_FROM_DATE,
  ADMIN_REPORT_FROM_DATE,
  ADMIN_LOG_RULE_ID,
  ADMIN_REPORT_INTERVAL,
  ADMIN_LOG_INTERVAL,
  ADMIN_LOG_PERIOD,
  ADMIN_ADVERTISER_ID,
  ADMIN_ADVERTISER_FROM_DATE,
  ADMIN_ADVERTISER_INTERVAL,
  ADMIN_PROVIDER_ID,
  ADMIN_CSVNAME,
  ADMIN_PROVIDER_FROM_DATE,
  ADMIN_PROVIDER_INTERVAL
} from "../helpers/constant/localStorage";
import { MONTH_TO_DATE } from "../helpers/constant/filters";

export const useRedirectLogic = () => {
  function redirectToReportPage(id, interval = null) {
    if (!id) return;
    window.localStorage.setItem(ADMIN_REPORT_RULE_ID, JSON.stringify(id));
    window.localStorage.setItem(
      ADMIN_REPORT_FROM_DATE,
      JSON.stringify(MONTH_TO_DATE)
    );
    if (interval) {
      window.localStorage.setItem(
        ADMIN_REPORT_INTERVAL,
        JSON.stringify(interval)
      );
    }
    window.open("/revenuereport", "_blank");
  }

  function redirectToSearchLogsPage(id, interval = null, period=null) {
    if (!id) return;
    window.localStorage.setItem(ADMIN_LOG_RULE_ID, JSON.stringify(id));
    window.localStorage.setItem(
      ADMIN_LOG_FROM_DATE,
      JSON.stringify(MONTH_TO_DATE)
    );
    if (interval) {
      window.localStorage.setItem(ADMIN_LOG_INTERVAL, JSON.stringify(interval));
    }
    if(period){
      window.localStorage.setItem(ADMIN_LOG_PERIOD, JSON.stringify(period));
    }
    window.open("/logs", "_blank");
  }

  function redirectToAdvertiserPubAccounts(id, interval = null) {
    if (!id) return;
    window.localStorage.setItem(ADMIN_ADVERTISER_ID, JSON.stringify(id));
    window.localStorage.setItem(
      ADMIN_ADVERTISER_FROM_DATE,
      JSON.stringify(MONTH_TO_DATE)
    );
    if (interval) {
      window.localStorage.setItem(
        ADMIN_ADVERTISER_INTERVAL,
        JSON.stringify(interval)
      );
    }
    window.open("/pubaccount", "_blank");
  }

  function redirectToSkippedRows(id, csvfile, interval = null) {
    if (!id || !csvfile) return;
    window.localStorage.setItem(ADMIN_PROVIDER_ID, JSON.stringify(id));
    window.localStorage.setItem(ADMIN_CSVNAME, JSON.stringify(csvfile));
    window.localStorage.setItem(
      ADMIN_PROVIDER_FROM_DATE,
      JSON.stringify(MONTH_TO_DATE)
    );
    if (interval) {
      window.localStorage.setItem(
        ADMIN_PROVIDER_INTERVAL,
        JSON.stringify(interval)
      );
    }
    window.open("/revenueReportStatus", "_blank");
  }

  return {
    redirectToReportPage,
    redirectToSearchLogsPage,
    redirectToAdvertiserPubAccounts,
    redirectToSkippedRows
  };
};
