import React, { useState, useEffect, useCallback } from "react";
import { hasDuplicates, validate } from "../../../helpers/validation";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/CloseSharp";
import { Setting } from "../../../services/Settings";
import { toastr } from "react-redux-toastr";
// import { useHistory } from "react-router-dom";
import { getSettings } from "../../../reducers/setting/setting.selector";
import { useSelector, useDispatch } from "react-redux";
import SimpleModal from "../../../components/SimpleModal/SimpleModal";
import Typography from "@material-ui/core/Typography";
import {
  updateDefaultSettings,
  updateSettingsObject
} from "../../../reducers/setting/setting.action";
import KeyAndValueSelect from "./KeyAndValueSelect";
import Infos from "../Infos/Infos";
import { getDomain } from "../../../helpers/index";

//setting up Default Variable Keys
const KEY_NAMES = [
  {
    label: "DEFAULT URL",
    value: "default_domain"
  },
  {
    label: "SYSTEM FALLBACK",
    value: "system_fallback"
  },
  {
    label: "WARMUP LINK",
    value: "warmup_link"
  },
  {
    label: "KYC URL",
    value: "kyc_url"
  },
  {
    label: "WARMUP LINK - Yahoo",
    value: "warmup_link_yahoo"
  }
];

const DefaultVariables = ({ handleCancel }) => {
  const settings = useSelector(getSettings);
  const [errorMsg, setErrorMsg] = useState("");
  //state for modal for deleting
  const [open, setOpen] = useState(false);
  //index for delete
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [idsInSettings, setIdsInSettings] = useState(() => {
    let ids = [];
    settings.forEach((setting) => {
      ids.push(setting.id);
    });
    return ids;
  });

  //Keys In Settings By Index
  const [keysInSettings, setKeysInSettings] = useState(() => {
    let keys = [];
    settings.forEach((setting) => {
      keys.push(setting.key);
    });
    return keys;
  });

  //Values in Settings By Index
  const [valuesInSettings, setValuesInSettings] = useState(() => {
    const values = [];
    settings.forEach((setting) => {
      values.push(setting.value);
    });
    return values;
  });

  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  // const history = useHistory();

  //loading setting on mount
  useEffect(() => {
    if (settings.length === 0) {
      Setting.fetchSettings()
        .then((response) => {
          if (response.success) {
            const settings = response.data?.settings || [];

            //Add default domain to top
            const defaultDomain = settings.filter(
              (item) => item.key === "default_domain"
            )[0];
            if (defaultDomain) {
              const index = settings.indexOf(defaultDomain);
              settings.splice(index, 1);
              settings.unshift(defaultDomain);
            }

            let settingsObject = {};
            settings.forEach((setting) => {
              settingsObject[setting.key] = setting.value;
            });

            stableDispatch(updateSettingsObject(settingsObject));
            stableDispatch(updateDefaultSettings(settings));
            setIdsInSettings(() => {
              let ids = [];
              settings.forEach((setting) => {
                ids.push(setting.id);
              });
              return ids;
            });
            setKeysInSettings(() => {
              let keys = [];
              settings.forEach((setting) => {
                keys.push(setting.key);
              });
              return keys;
            });
            setValuesInSettings(() => {
              const values = [];
              settings.forEach((setting) => {
                values.push(setting.value);
              });
              return values;
            });
          } else {
            throw new Error(JSON.stringify(response.error));
          }
        })
        .catch((error) => {
          toastr.error("Opps", "Cannot load default settings!!!");
          console.trace(error.message);
        });
    }
  }, [stableDispatch, settings.length]);

  const validateForm = () => {
    let isValid = true;
    //checking if any key selected
    if (keysInSettings.length === 0) {
      setErrorMsg("No key added!");
      return false;
    }
    //checking for empty key
    if (keysInSettings.includes("")) {
      setErrorMsg("Empty Key Selected! Either remove it or select a valid key");
      return false;
    }

    if (hasDuplicates(keysInSettings)) {
      setErrorMsg("Duplicate Keys Found");
      return false;
    }

    //checking for empty values
    valuesInSettings.forEach((value) => {
      if (value.trim() === "") {
        setErrorMsg("Empty value fields found!");
        isValid = false;
      }
    });
    if (!isValid) return false;
    //checking id default_domain and system fallback are selected, that they
    //are valid links

    let indexOfDefaultDomain = keysInSettings.findIndex(
      (key) => key === "default_domain"
    );
    let indexOfFallbackUrl = keysInSettings.findIndex(
      (key) => key === "system_fallback"
    );

    if (indexOfDefaultDomain !== -1) {
      if (!validate("validurl", valuesInSettings[indexOfDefaultDomain])) {
        setErrorMsg("Default Domain key should have a valid link");
        isValid = false;
        return false;
      }
    }

    if (!isValid) return false;

    if (indexOfFallbackUrl !== -1) {
      if (!validate("validurl", valuesInSettings[indexOfFallbackUrl])) {
        setErrorMsg("System Fallback URL should have a valid URL link");
        isValid = false;
        return false;
      }
    }

    if (indexOfDefaultDomain !== -1 && indexOfFallbackUrl !== -1) {
      if (
        getDomain(valuesInSettings[indexOfDefaultDomain]) ===
        getDomain(valuesInSettings[indexOfFallbackUrl])
      ) {
        setErrorMsg("Default Domain and System Fallback URL cannot be same");
        isValid = false;
        return false;
      }
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let data = [];
      if (idsInSettings.length === 0) {
        //no settings before, call save settings
        keysInSettings.forEach((key, index) => {
          data.push({
            key: keysInSettings[index],
            value: valuesInSettings[index]
          });
        });

        saveSetting(data);
      } else {
        //setting already saved in database, just update the settings
        keysInSettings.forEach((key, index) => {
          if (idsInSettings?.[index]) {
            data.push({
              id: idsInSettings[index],
              key: keysInSettings[index],
              value: valuesInSettings[index]
            });
          } else {
            data.push({
              key: keysInSettings[index],
              value: valuesInSettings[index]
            });
          }
        });
        updateSetting(data);
      }
    }
    //send to backend
    //update the redux store
  };

  const saveSetting = (data) => {
    Setting.saveSetting(data)
      .then((response) => {
        if (response.success) {
          // history.push("/");
          toastr.success("Success", "Settings saved successfully!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
      });
  };

  const updateSetting = (data) => {
    Setting.updateSetting(data)
      .then((response) => {
        if (response.success) {
          // history.push("/");
          const settings = response.data?.settings || [];
          let settingsObject = {};
          settings.forEach((setting) => {
            settingsObject[setting.key] = setting.value;
          });
          stableDispatch(updateSettingsObject(settingsObject));
          stableDispatch(updateDefaultSettings(settings));
          toastr.success("Success", "Settings saved successfully!");
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        console.trace(error.message);
        toastr.error("Oops!", JSON.parse(error.message).message);
      });
  };

  const handleKeyChange = (e, index) => {
    setErrorMsg("");
    let tempKeyInSettings = [...keysInSettings];
    tempKeyInSettings[index] = e.target.value;
    setKeysInSettings(tempKeyInSettings);
  };

  const handleValueChange = (e, index) => {
    setErrorMsg("");
    let tempValueInSettings = [...valuesInSettings];
    tempValueInSettings[index] = e.target.value;
    setValuesInSettings(tempValueInSettings);
  };

  const deleteSetting = () => {
    let index = deleteIndex;
    Setting.deleteSetting(idsInSettings[index])
      .then((response) => {
        if (response.success) {
          setDeleteIndex(null);
          setOpen(false);
          removeIndexfromSettingStates(index);
          const settings = response.data?.settings || [];
          let settingsObject = {};
          settings.forEach((setting) => {
            settingsObject[setting.key] = setting.value;
          });
          stableDispatch(updateSettingsObject(settingsObject));
          stableDispatch(updateDefaultSettings(settings));
        } else {
          throw new Error(JSON.stringify(response.error));
        }
      })
      .catch((error) => {
        setDeleteIndex(null);
        console.trace(error.message);
        toastr.error("Oops!", "Not able to remove the setting at the moment!");
      });
  };

  const handleFieldCloseAction = (index) => {
    setErrorMsg("");
    if (idsInSettings.length === 0) {
      removeIndexfromSettingStates(index);
    } else {
      if (idsInSettings?.[index]) {
        //opening modal for confirmation
        setOpen(true);
        setDeleteIndex(index);
      } else {
        removeIndexfromSettingStates(index);
      }
    }
  };
  //removes a particular index value from tempKeysInSettings,
  //tempValuesInSettings, and tempIdsInSettings
  const removeIndexfromSettingStates = (index) => {
    let tempKeysInSettings = [...keysInSettings];
    tempKeysInSettings.splice(index, 1);
    let tempValuesInSettings = [...valuesInSettings];
    tempValuesInSettings.splice(index, 1);
    let tempIdsInSettings = [...idsInSettings];
    tempIdsInSettings.splice(index, 1);
    setIdsInSettings(tempIdsInSettings);
    setKeysInSettings(tempKeysInSettings);
    setValuesInSettings(tempValuesInSettings);
  };

  const handleAddVariableButton = () => {
    let tempKeysInSettings = [...keysInSettings];
    tempKeysInSettings.push("");
    let tempValuesInSettings = [...valuesInSettings];
    tempValuesInSettings.push("");
    setKeysInSettings(tempKeysInSettings);
    setValuesInSettings(tempValuesInSettings);
  };

  const handleModalCloseAction = () => {
    setOpen(false);
    setDeleteIndex(null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        flexDirection: "column"
      }}
    >
      <Infos />
      <div className="default-values">
        <div className="default-values__title">
          <h4>Default Variables</h4>
          <span>
            Set default variables such as Default Domain and System Fallback URL
          </span>
        </div>
        <div className="default-values__form" style={{ maxWidth: "80%" }}>
          <h5>Change Default Values</h5>
          <form onSubmit={handleSubmit}>
            {keysInSettings.length !== 0 ? (
              keysInSettings.map((settingKey, index) => {
                return (
                  <KeyAndValueSelect
                    selectedKey={settingKey}
                    key={`${settingKey}+${index}`}
                    handleKeyChange={handleKeyChange}
                    currentIndex={index}
                    handleValueChange={handleValueChange}
                    keyValue={valuesInSettings?.[index] || ""}
                    handleFieldCloseAction={handleFieldCloseAction}
                    keyNames={KEY_NAMES}
                  />
                );
              })
            ) : (
              <h4 className="default-values__no-variable-msg">
                No Variables Set
              </h4>
            )}
            <Button
              disabled={KEY_NAMES.length === keysInSettings.length}
              variant="contained"
              onClick={handleAddVariableButton}
            >
              Add Variable
            </Button>
            <div>
              <h4 className="default-values__err-msg error-text">
                {errorMsg && errorMsg}
              </h4>
            </div>
            <div className="default-values__form__buttons">
              <Button
                color="primary"
                variant="contained"
                size="medium"
                startIcon={<CheckIcon />}
                type="submit"
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancel}
                startIcon={<CloseIcon />}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
        {open && (
          <SimpleModal handleClose={handleModalCloseAction}>
            <div className="delete-confirm-box">
              <Typography component="h3">
                Setting will be removed from records! Are you sure?
              </Typography>
              <div className="delete-confirm-box__buttons">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={deleteSetting}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleModalCloseAction}
                >
                  No
                </Button>
              </div>
            </div>
          </SimpleModal>
        )}
      </div>
    </div>
  );
};

export default DefaultVariables;
