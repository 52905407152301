import { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import moment from "moment";
import { useIsMounted } from "../../../hooks/useIsMounted";
import { textFieldValid } from "../../../helpers/validation";
import { Provider } from "../../../services/Provider";
import { formatNumberAddComma } from "../../../helpers/formatters";
import { useDispatch } from "react-redux";
import { setProviderLinkSelectedFilter } from "../../../reducers/providerLink/providerLink.action";

export const useFetchProviderLinks = ({ filterValues, filtersClearToggle, selectionAddActions }) => {
    const dispatch = useDispatch();
    const stableDispatch = useCallback(dispatch, [dispatch]);
    const [providerLinks, setProviderLinks] = useState([]);
    const sourceRef = useRef(null);
    const [loadingProviderLinks, setLoadingProviderLinks] = useState({
        isLoading: false,
        error: false
    });
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(50);
    const [orderBy, setOrderBy] = useState("id");
    const [orderDirection, setOrderDirection] = useState("DESC");
    const [total, setTotal] = useState(0);

    function getParams() {
      let params = {};
      const {
        query,
        selectedTagTypes,
        selectedPlatforms,
        selectedProviders,
        selectedSearchEngines,
        selectedPublishers,
        selectedQualityScore,
        isLive,
        isLinked,
        isReusable,
        isNotLive,
        isNotLinked,
        isNotReusable,
        search_id
      } = filterValues;
      if (textFieldValid(query)) {
        params["link"] = query.trim();
      }

      if (search_id && search_id.trim().length > 0) {
        params["id"] = search_id;
      }

      if (orderBy && orderBy !== "isLinked" && orderBy !== "isLive") {
        params["order_by"] = orderBy;
      }
      if (selectedTagTypes?.length > 0) {
        let selection = [];
        for (let i in selectedTagTypes) {
          selection.push(selectedTagTypes[i]);
        }
        params["tag_type_id"] = selection;
      }

      if (selectedPublishers?.length > 0) {
        let selection = [];
        for (let i in selectedPublishers) {
          selection.push(selectedPublishers[i]);
        }
        params["publisher_id"] = selection;
      }

      if (selectedPlatforms?.length > 0) {
        let selection = [];
        for (let i in selectedPlatforms) {
          selection.push(selectedPlatforms[i]);
        }
        params["platform_id"] = selection;
      }

      if (selectedSearchEngines?.length > 0) {
        let selection = [];
        for (let i in selectedSearchEngines) {
          selection.push(selectedSearchEngines[i]);
        }
        params["search_engine_id"] = selection;
      }

      if (selectedProviders?.length > 0) {
        let selection = [];
        for (let i in selectedProviders) {
          selection.push(selectedProviders[i]);
        }
        params["provider_id"] = selection;
      }
      if (selectedQualityScore?.length > 0) {
        let selection = [];
        for (let i in selectedQualityScore) {
          selection.push(selectedQualityScore[i]);
        }
        params["quality_score"] = selection;
      }

      if (isLive && isNotLive) {
        // do nothing
      } else {
        if (isLive) {
          params["is_live"] = true;
        } else if (isNotLive) {
          params["is_live"] = false;
        }
      }

      if (isLinked && isNotLinked) {
        // do nothing
      } else {
        // value: linked (for linked), not_linked (for not linked)
        if (isLinked) {
          params["isLinked"] = "linked";
        } else if (isNotLinked) {
          params["isLinked"] = "not_linked";
        }
      }

      if (isReusable && isNotReusable) {
        // do nothing
      } else {
        if (isReusable) {
          params["reusable"] = true;
        } else if (isNotReusable) {
          params["reusable"] = false;
        }
      }

      params["order_direction"] = orderDirection;

      params["page"] = page;
      params["size"] = size;

      stableDispatch(
        setProviderLinkSelectedFilter({
          query,
          search_id,
          selectedProviders,
          selectedPublishers,
          selectedPlatforms,
          selectedSearchEngines,
          selectedTagTypes,
          selectedQualityScore,
          isLinked: isLinked,
          isLive: isLive,
          isReusable: isReusable,
          isNotLinked: isNotLinked,
          isNotLive: isNotLive,
          isNotReusable: isNotReusable
        })
      );
      return params;
    }

    const isMounted = useIsMounted();

    function formatProviderLinks(providerLinks) {
      return providerLinks.map((providerLink) => {
        return {
          ...providerLink,
          search_engine_id: `${providerLink?.search_engine?.name || ""}`,
          platform_id: `${providerLink?.platform?.name || ""}`,
          tag_type_id: `${providerLink?.tag_type?.name || ""}`,
          provider_id: `${providerLink?.provider?.id || ""}-${
            providerLink?.provider?.name || ""
          }`,
          is_live: providerLink.is_live ? "Yes" : "No",
          quality_score: providerLink.quality_score?.toUpperCase(),
          reusable: providerLink.reusable || false,
          isLinked: providerLink.is_linked,

          cap_consumed: providerLink?.advertiser_channel_search_counts[0]?.count
            ? formatNumberAddComma(
                providerLink?.advertiser_channel_search_counts[0]?.count || 0
              ) +
              ` (${moment(
                providerLink?.advertiser_channel_search_counts[0]?.date
              ).format("YYYY-MM-DD")})`
            : 0,
          last_live: providerLink.last_live
            ? moment(providerLink.last_live).format("MMM DD, YYYY hh:mm A")
            : "-",
          createdAt: providerLink.createdAt
            ? moment(providerLink.createdAt).format("MMM DD, YYYY hh:mm A")
            : "-",
          daily_cap: formatNumberAddComma(providerLink.daily_cap),
          remaining_cap: formatNumberAddComma(providerLink.remaining_cap)
        };
      });
    }

    async function getProviderLinks() {
      try {
        setLoadingProviderLinks({
          ...loadingProviderLinks,
          isLoading: true,
          error: false
        });
        sourceRef.current = axios.CancelToken.source();
        const providerLinks = await Provider.fetchProviderLinksList(
          getParams(),
          sourceRef.current.token
        );

        if (providerLinks.success && isMounted()) {
          const fetchedProviderLinks =
            providerLinks.data.providerLinks.providerLinks || [];
          setProviderLinks(formatProviderLinks(fetchedProviderLinks));
          setTotal(providerLinks.data.providerLinks.total);
          setLoadingProviderLinks({
            ...loadingProviderLinks,
            isLoading: false,
            error: false
          });
        } else {
          throw new Error(providerLinks?.error?.message);
        }
      } catch (error) {
        if (isMounted()) {
          setProviderLinks([]);
          setLoadingProviderLinks({
            ...loadingProviderLinks,
            isLoading: false,
            error: true
          });
        }
        console.trace(error?.message || error);
      }
    }

    function updateProviderLinks(id, key, value) {
        let tempProviderLinks = [...providerLinks];
        let changedIndex = tempProviderLinks.findIndex(
            (provider) => provider.id === id
        );
        tempProviderLinks[changedIndex][key] = value;
        setProviderLinks(tempProviderLinks);
    }

    useEffect(() => {
        if (loadingProviderLinks.isLoading) {
            sourceRef.current.cancel();
            sourceRef.current = null;
        }
        getProviderLinks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, size, orderBy, orderDirection, filtersClearToggle]);

    return {
        providerLinks,
        loadingProviderLinks,
        page,
        size,
        orderBy,
        orderDirection,
        total,
        getProviderLinks,
        setPage,
        setSize,
        setOrderBy,
        setOrderDirection,
        updateProviderLinks
    };
};
